import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { LicenseEntity, LicenseRequestEntity } from 'src/entities/licenseEntity';

const requestPath = '/api/v2/session';

export const useSessionMutation = () => ({
  post: useMutation(
    (dto: LicenseRequestEntity) => axios.post<LicenseEntity>(requestPath, dto)
  ),
  destroy: useMutation(
    () => axios.delete(requestPath)
  )
});
