import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { CompanyEntity, CompanyRequestEntity } from 'src/entities/admin/companyEntity';

const BASE_URL = '/api/admin/v1/companies/';

const getCompanies = (queryClient: QueryClient, page: number, pageSize: number) => async () => {
  const res = await axios.get<ApiResponse>(`${BASE_URL}?page=${page + 1}&per=${pageSize}`);
  const list = res.data.data;
  const total = res.data.total_pages_count;
  const entities = list.map((entity: CompanyEntity) => {
    queryClient.setQueryData(['companies', { id: entity.id }], entity);
    return entity;
  });
  return { entities, total };
};

export const useQueryCompanies = (page: number, pageSize: number) => {
  const queryClient = useQueryClient();
  return useQuery(['companies', { page, pageSize }], getCompanies(queryClient, page, pageSize), {
    staleTime: Infinity
  });
};

const getCompany = (id: number | string) => async () => {
  const res = await axios.get<ApiResponse>(`${BASE_URL}${id}`);
  return res.data as unknown as CompanyEntity;
};

export const useQueryCompany = (id: number | string) => {
  const queryClient = useQueryClient();
  return useQuery(['companies', { id }], getCompany(id), {
    staleTime: Infinity,
    enabled: Boolean(id)
  });
};

export const useMutationCompany = () => {
  const queryClient = useQueryClient();
  return {
    addCompany: useMutation(
      (company: CompanyRequestEntity) => axios.post(BASE_URL, company),
      {
        onSuccess: () => queryClient.invalidateQueries(['companies'])
      }
    ),
    updateCompany: useMutation(
      (company: CompanyEntity) => axios.put(`${BASE_URL}${company.id}`, company),
      {
        onSuccess: () => queryClient.invalidateQueries(['companies'])
      }
    ),
    deleteCompany: useMutation(
      (id: number) => axios.delete(`${BASE_URL}${id}`),
      {
        onSuccess: () => queryClient.invalidateQueries(['companies'])
      }
    ),
  };
};
