import { GridToolbarFilterButton } from '@mui/x-data-grid-premium';
import { FC, memo, useState } from 'react';

const MyGridToolbarFilterButton: FC = memo(() => {
  // 画面を再描画しないとフィルターが開かないので、再描画を矯正するために値を更新する
  const [filterOpen, setFilterOpen] = useState(false);
  const toggleFilterOpen = () => {
    setFilterOpen(!filterOpen);
  };

  return <GridToolbarFilterButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} onMouseOver={toggleFilterOpen} />;
});

export default MyGridToolbarFilterButton;
