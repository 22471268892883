import { FC, memo } from 'react';
import { ImportProvider } from 'src/contexts/ImportContext';

import ImportPresenter from './ImportPresenter';

const Import: FC = memo(() => (
  <ImportProvider>
    <ImportPresenter />
  </ImportProvider>
));

export default Import;
