import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { ButtonBase, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import numberDecorator from 'src/decorators/number.decorator';
import { OrderEntity } from 'src/entities/orderEntity';

type Props = {
  id: number;
  data: OrderEntity;
  selectedIds: number[];
  addSelectedId: (id: number) => void;
  removeSelectedId: (id: number) => void;
  groupedOrderIds: number[];
  orderEntityMap: Map<number, OrderEntity>;
  groupingConditions: string[];
  buildOrderDiplay: (id: number, data: OrderEntity) => JSX.Element;
  unit: string;
}

export const GroupedOrdersPresenter: FC<Props> = memo(({
  id,
  data,
  selectedIds,
  addSelectedId,
  removeSelectedId,
  groupedOrderIds,
  orderEntityMap,
  groupingConditions,
  buildOrderDiplay,
  unit,
}) => {
  const orderIds = useMemo(() => [id, ...groupedOrderIds], [groupedOrderIds, id]);
  const orders = useMemo(() => orderIds.map((orderId) => orderEntityMap.get(orderId)), [orderEntityMap, orderIds]);
  const totalItemCount = useMemo(() => orders.reduce((sum, it) => sum + it.item_count, 0), [orders]);
  const totalItemWeight = useMemo(() => orders.reduce((sum, it) => sum + it.item_total_weight_kg, 0), [orders]);
  const volumes = useMemo(() => orders.filter((it) => it.item_total_volume_m3), [orders]);
  const totalItemVolume = useMemo(() => (volumes.length > 0 ? volumes.reduce((sum, it) => sum + it.item_total_volume_m3, 0) : undefined), [volumes]);

  const [isExpanded, setIsExpanded] = useState(false);
  const expandButtonOnClick = useCallback((event: React.MouseEvent) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `案件グループ展開 ${isExpanded ? '閉じる' : '開く'}` });
    event.preventDefault();
    event.stopPropagation();
    setIsExpanded((prev) => !prev);
  }, [isExpanded]);

  const toDayAndHourMinutes = useCallback((date: string) => datetimeDecorator.toDayAndHourMinutes(new Date(date)), []);

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(
      orderIds.every((it) => selectedIds.includes(it))
    );
  }, [orderIds, selectedIds]);

  const onClickGroupTitle = useCallback((event: React.MouseEvent) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `案件グループ選択 ${selected ? 'OFF' : 'ON'}` });
    if (selected) {
      orderIds.forEach((it) => removeSelectedId(it));
    } else {
      orderIds.forEach((it) => addSelectedId(it));
    }
    setSelected((prev) => !prev);
  }, [addSelectedId, orderIds, removeSelectedId, selected]);

  const theme = useTheme();
  const [bgColor, setBgColor] = useState<string>(theme.colors.alpha.black[10]);
  useEffect(() => {
    if (selected) {
      setBgColor(theme.colors.primary.light);

      return;
    }

    setBgColor(theme.colors.alpha.black[10]);
  }, [selected, theme.colors]);

  const groupHeaderMemo = useMemo(() => (
    <ButtonBase
      onClick={onClickGroupTitle}
      sx={{
        width: '100%',
        textAlign: 'left',
        mb: 0.1,
      }}
    >
      <Stack
        direction="row"
        alignItems="stretch"
        spacing={0}
        sx={{
          bgcolor: bgColor,
          width: '100%',
        }}
        pl={0.5}
      >
        <Stack
          p={1}
          sx={{ width: '100%' }}
          bgcolor={theme.palette.background.paper}
        >
          <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">{`案件数: ${orderIds.length}件`}</Typography>
              <Typography>{`数量: ${totalItemCount}個`}</Typography>
            </Stack>
            <Stack>
              <IconButton
                onClick={expandButtonOnClick}
                size="small"
              >
                {isExpanded
                  ? <KeyboardArrowUpRoundedIcon fontSize="small" />
                  : <ExpandMoreRoundedIcon fontSize="small" />}
              </IconButton>
            </Stack>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography>{`重量: ${numberDecorator.toRoundedUnit(totalItemWeight, 'kg', 2)}`}</Typography>
            <Typography>{`体積: ${numberDecorator.toRoundedUnit(totalItemVolume, 'm3', 2)}`}</Typography>
          </Stack>
          {
            (groupingConditions.includes('shipperName') && (
              <Stack direction="row" gap={1}>
                <Typography>{`荷主: ${data.shipper_name}`}</Typography>
              </Stack>
            ))
          }
          {
            (groupingConditions.includes('loadingName') && (
              <Stack direction="row" gap={1}>
                <Typography>{`積地: ${data.loading_name}`}</Typography>
              </Stack>
            ))
          }
          {
            (groupingConditions.includes('loadingAddress') && (
              <Stack direction="row" gap={1}>
                <Typography>{`積地住所: ${data.loading_address}`}</Typography>
              </Stack>
            ))
          }
          {
            (groupingConditions.includes('loadingSpecifiedTime') && (
              <Stack direction="row" gap={1}>
                <Typography>{`積地指定時間: ${toDayAndHourMinutes(data.loading_start_at)} ~ ${toDayAndHourMinutes(data.loading_end_at)}`}</Typography>
              </Stack>
            ))
          }
          {
            (groupingConditions.includes('unloadingName') && (
              <Stack direction="row" gap={1}>
                <Typography>{`降地: ${data.unloading_name}`}</Typography>
              </Stack>
            ))
          }
          {
            (groupingConditions.includes('unloadingAddress') && (
              <Stack direction="row" gap={1}>
                <Typography>{`降地住所: ${data.unloading_address}`}</Typography>
              </Stack>
            ))
          }
          {
            (groupingConditions.includes('unloadingSpecifiedTime') && (
              <Stack direction="row" gap={1}>
                <Typography>{`降地指定時間: ${toDayAndHourMinutes(data.unloading_start_at)} ~ ${toDayAndHourMinutes(data.unloading_end_at)}`}</Typography>
              </Stack>
            ))
          }
        </Stack>
      </Stack>
    </ButtonBase>
  ), [bgColor, data, expandButtonOnClick, groupingConditions, isExpanded, onClickGroupTitle, orderIds, theme.palette.background.paper, toDayAndHourMinutes, totalItemCount, totalItemVolume, totalItemWeight, unit]);

  return (
    <>
      <Stack alignItems="center" justifyContent="space-between">
        {groupHeaderMemo}
        {isExpanded && (
          <Stack
            pl={0.5}
            sx={{
              width: '100%',
            }}
          >
            {orders.map((it) => {
              if (id === it.id) {
                return buildOrderDiplay(id, data);
              }
              return buildOrderDiplay(it.id, it);
            })}
          </Stack>
        )}
      </Stack>
      <Divider sx={{ height: '1px' }} />
    </>
  );
});
