import { Stack, Typography } from '@mui/material';
import { FC, memo, useEffect, useState } from 'react';
import numberDecorator from 'src/decorators/number.decorator';
import { PlanningOrderStatisticsEntity } from 'src/entities/PlanningOrderStatistics.entity';

type Props = {
  planningOrderStatisticsEntity: PlanningOrderStatisticsEntity | undefined;
  totalCount: number;
}

const PlanningOrderStatisticsPresenter:FC<Props> = memo((
  {
    planningOrderStatisticsEntity,
    totalCount,
  }
) => {
  const [entity, setEntity] = useState<PlanningOrderStatisticsEntity>(null);

  useEffect(() => {
    if (planningOrderStatisticsEntity) {
      setEntity(planningOrderStatisticsEntity);
    }
  }, [planningOrderStatisticsEntity]);

  return (
    <Stack>
      <Stack direction="row" gap={1}>
        <Typography>
          {
              [
                [
                  entity?.selectedCount.toLocaleString(),
                  '件'
                ].join(''),
                [
                  totalCount.toLocaleString(),
                  '件'
                ].join('')
              ].join('/')
            }
        </Typography>
        <Typography>
          {
            [
              '数量',
              [
                entity?.selectedItemCount.toLocaleString(),
                entity?.totalItemCount.toLocaleString()
              ].join('/')
            ].join(' ')
          }
        </Typography>
      </Stack>
      <Stack direction="row" gap={1}>
        <Typography>
          {
              [
                numberDecorator.convertGramToKg(entity?.selectedWeightG) || '- kg',
                numberDecorator.convertGramToKg(entity?.totalWeightG) || '- kg'
              ].join('/')
            }
        </Typography>
        <Typography>
          {
              [
                numberDecorator.convertMm3ToM3(entity?.selectedVolumeMm3) || '- m3',
                numberDecorator.convertMm3ToM3(entity?.totalVolumeMm3) || '- m3'
              ].join('/')
            }
        </Typography>
      </Stack>
    </Stack>
  );
});

export default PlanningOrderStatisticsPresenter;
