import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { HavePaginationEntity } from 'src/entities/HavePagination.entity';
import { PlaceEntity as Entity } from 'src/entities/placeEntity';

const requestPathBase = '/api/v4/master/places';
export const queryKey = 'usePlacesRequest';

const getResources = async (page: number, sortCondition: string, searchCondition: string, per?: number) => {
  const requestParams = [
    `page=${page + 1}`,
    sortCondition ? `sort_condition=${sortCondition}` : '',
    searchCondition ? `${searchCondition}` : '',
    per ? `per=${per}` : '',
  ].filter((maybe) => maybe).join('&');

  const requestPath = [
    requestPathBase,
    requestParams,
  ].join('?');

  const response: AxiosResponse<HavePaginationEntity<Entity>> = await axios.get<HavePaginationEntity<Entity>>(requestPath);

  return response.data;
};

export const usePlacesRequest = (page: number, sortCondition: string, searchCondition: string, per?: number) => useQuery(
  [queryKey, { page, sortCondition, searchCondition, per }],
  () => getResources(page, sortCondition, searchCondition, per),
);
