import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';
import { ButtonGroup, MenuItem, IconButton, Tooltip, Typography, Divider } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FC, memo, MouseEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import StyledMenu from 'src/components/StyledMenu';
import AdminContext from 'src/contexts/AdminContext';

export const AdminSideNav: FC<{ disabled?: boolean }> = memo(({ disabled }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const adminContext = useContext(AdminContext);

  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteSession = () => {
    adminContext.logout()
      .then(() => {
        navigate('/admin/login');
      })
      .catch(() => enqueueSnackbar('エラーが発生しました'))
      .finally(() => {
        // eslint-disable-next-line no-void
        void queryClient.resetQueries({
          predicate: (_query) => true,
        });
        window.location.reload();
      });
  };

  return (
    <ButtonGroup
      variant="contained"
      sx={{
      bgcolor: '#fff'
    }}
    >
      <Typography variant="h4" marginY={1}>
        LOG 管理画面 (
        {adminContext.entity?.email}
        )
      </Typography>

      <Tooltip title="共通メニューを表示する">
        <IconButton
          disabled={disabled}
          id="master-customized-button"
          aria-controls={open ? 'master-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <StyledMenu
        id="master-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'master-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            navigate('/admin');
          }}
          disableRipple
        >
          <BusinessIcon />
          企業管理
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={disabled}
          onClick={deleteSession}
          disableRipple
        >
          <ApartmentIcon />
          ログアウト
        </MenuItem>
      </StyledMenu>
    </ButtonGroup>

  );
});
