/* eslint-disable max-classes-per-file */
import { DeliveryListGroupingValues } from 'src/entities/licenseEntity';
import { OperationStatusEntity } from 'src/entities/PlanningsOperation.entity';
import { PlanningsOperationEntityWithStatsEntity } from 'src/entities/PlanningsOperationEntityWithStats.entity';
import { PositionEntity } from 'src/entities/PositionEntity';
import { ActionKind } from 'src/pages/OperationDirectionPresenter';

/**
 * - 勤務
 *   - 回転
 *     - 地点
 *       - 作業時間帯
 *         - 作業 (PlanningsOperationEntityWithStatsEntity)
 */
export class PlanningsOperationDelivery {
  cycles: PlanningsOperationCycle[];

  deliveryListGrouping: DeliveryListGroupingValues;

  unloadPlaceCount: number;

  itemCount: number;

  maxWeightG: number;

  maxVolumeMm3: number;

  empty = false;

  private operations: PlanningsOperationEntityWithStatsEntity[];

  constructor(operations: PlanningsOperationEntityWithStatsEntity[], deliveryListGrouping: DeliveryListGroupingValues) {
    this.cycles = [];
    this.deliveryListGrouping = deliveryListGrouping;
    this.operations = operations;
    this.unloadPlaceCount = 0;
    this.itemCount = 0;

    if (operations.length === 0) {
      return;
    }
    this.itemCount = operations.filter((it) => it.action === '降').reduce((acc, it) => acc + it.itemCount, 0);
    this.maxWeightG = Math.max(...this.operations.map((it) => it.currentWeightG));
    this.maxVolumeMm3 = Math.max(...this.operations.map((it) => it.currentVolumeMm3));

    const splitted: PlanningsOperationEntityWithStatsEntity[][] = [];
    let cycleOperations: PlanningsOperationEntityWithStatsEntity[] = [];

    operations.sort((a, b) => {
      let diff = a.cycle - b.cycle;
      if (diff === 0) {
        diff = new Date(a.arrivalAt).getTime() - new Date(b.arrivalAt).getTime();
      }
      if (diff === 0) {
        diff = new Date(a.departureAt).getTime() - new Date(b.departureAt).getTime();
      }
      return diff;
    });
    let prevCycle = operations[0].cycle;
    operations.forEach((it) => {
      if (prevCycle !== it.cycle) {
        splitted.push(cycleOperations);
        cycleOperations = [];
      }
      cycleOperations.push(it);
      prevCycle = it.cycle;
    });
    if (cycleOperations.length > 0) {
      splitted.push(cycleOperations);
    }
    splitted.forEach((it) => {
      this.cycles.push(new PlanningsOperationCycle(this, it[0].cycle, it));
    });
    const maxIndex = Math.max(...this.cycles.map((it) => it.cycleIndex));
    for (let i = maxIndex; i > 0; i--) {
      const c = this.cycles.find((it) => it.cycleIndex === i);
      if (!c) {
        this.cycles.push(PlanningsOperationCycle.empty(this, i));
      }
    }
    this.cycles = this.cycles.sort((a, b) => a.cycleIndex - b.cycleIndex);
    let seriesIndex = 0;
    this.cycles.forEach((cycle) => {
      cycle.places.forEach((place) => {
        seriesIndex++;
        place.seriesIndex = seriesIndex;
      });
    });
    this.unloadPlaceCount = this.cycles.reduce((acc, it) => acc + it.places.filter((place) => place.isAction('降')).length, 0);
  }

  static empty(deliveryListGrouping: DeliveryListGroupingValues): PlanningsOperationDelivery {
    const ret = new PlanningsOperationDelivery([], deliveryListGrouping);
    ret.empty = true;
    ret.cycles.push(PlanningsOperationCycle.empty(ret));
    return ret;
  }

  allOperations() {
    return this.operations;
  }

  lastOperationIndex(): number {
    return this.operations.length - 1;
  }

  addEmptyCycle() {
    this.cycles.push(PlanningsOperationCycle.empty(this));
  }

  removeEmptyCycle(cycleIndex: number) {
    const newCycles = this.cycles.filter((it) => it.cycleIndex !== cycleIndex);
    newCycles.filter((it) => it.cycleIndex >= cycleIndex).forEach((it) => it.cycleIndex -= 1);
    this.cycles = newCycles;
  }
}

export class PlanningsOperationCycle {
  delivery: PlanningsOperationDelivery;

  cycleIndex: number;

  places: PlanningsOperationPlace[];

  deliveryListGrouping: DeliveryListGroupingValues;

  itemCount: number;

  maxWeightG: number;

  maxVolumeMm3: number;

  empty = false;

  id: string;

  private operations: PlanningsOperationEntityWithStatsEntity[];

  constructor(delivery: PlanningsOperationDelivery, cycleIndex: number, operations: PlanningsOperationEntityWithStatsEntity[]) {
    this.delivery = delivery;
    this.cycleIndex = cycleIndex;
    this.places = [];
    this.deliveryListGrouping = delivery.deliveryListGrouping;
    this.operations = operations;
    this.id = ['PlanningsOperationCycle', cycleIndex].join('-');

    if (operations.length === 0) {
      return;
    }
    this.itemCount = operations.filter((it) => it.action === '降').reduce((acc, it) => acc + it.itemCount, 0);
    this.maxWeightG = Math.max(...this.operations.map((it) => it.currentWeightG));
    this.maxVolumeMm3 = Math.max(...this.operations.map((it) => it.currentVolumeMm3));

    let index = 0;
    let prevLatLng: string = null;
    const grouped = operations.reduce((acc, ops) => {
      const latlng = `${Number(ops.latitude)}-${Number(ops.longitude)}`;
      if (ops.drivingDistanceMm > 0 && latlng !== prevLatLng) {
        index += 1;
        prevLatLng = latlng;
      }
      const latLngWithIndex = `${prevLatLng}-${index}`;
      const arr = acc.get(latLngWithIndex) || [];
      arr.push(ops);
      acc.set(latLngWithIndex, arr);
      return acc;
    }, new Map<string, PlanningsOperationEntityWithStatsEntity[]>());

    grouped.forEach((ops) => {
      const sorted = ops.sort((a, b) => {
        let comp = new Date(a.arrivalAt).getTime() - new Date(b.arrivalAt).getTime();
        if (comp !== 0) return comp;

        comp = new Date(a.departureAt).getTime() - new Date(b.departureAt).getTime();
        if (comp !== 0) return comp;

        return a.shipperName.localeCompare(b.shipperName);
      });
      if (this.deliveryListGrouping === 'groupByAddress') {
        this.places.push(new PlanningsOperationPlace(this, sorted, sorted[0].arrivalAt, sorted.slice(-1)[0].departureAt));
      } else {
        const firstOperation = sorted[0];
        const firstName = PlanningsOperationCycle.getName(firstOperation);
        const sortByArrivalAtAndName = sorted.sort((a, b) => {
          const comp = new Date(a.arrivalAt).getTime() - new Date(b.arrivalAt).getTime();
          if (comp !== 0) return comp;

          const aName = PlanningsOperationCycle.getName(a);
          const bName = PlanningsOperationCycle.getName(b);

          // 1件目と同じ名前のものを先にする
          if (aName === firstName) {
            return -1;
          }
          if (bName === firstName) {
            return 1;
          }
          // 1件目と同じ名前以外は名前順
          return aName.localeCompare(bName);
        });
        const splitByName: PlanningsOperationEntityWithStatsEntity[][] = [];
        let ary: PlanningsOperationEntityWithStatsEntity[] = [];
        let prevName = PlanningsOperationCycle.getName(sortByArrivalAtAndName[0]);
        sortByArrivalAtAndName.forEach((operation) => {
          if (PlanningsOperationCycle.getName(operation) !== prevName) {
            splitByName.push(ary);
            ary = [];
          }
          prevName = PlanningsOperationCycle.getName(operation);
          ary.push(operation);
        });
        if (ary.length > 0) {
          splitByName.push(ary);
        }
        const { arrivalAt, departureAt } = splitByName[0][0];
        splitByName.forEach((operation) => {
          this.places.push(new PlanningsOperationPlace(this, operation, arrivalAt, departureAt));
        });
      }
    });
  }

  private static getName(ops: PlanningsOperationEntityWithStatsEntity) {
    return ops.action === '積' ? ops.loadingName : ops.unloadingName;
  }

  static empty(delivery: PlanningsOperationDelivery, cycleIndex?: number): PlanningsOperationCycle {
    const ret = new PlanningsOperationCycle(delivery, cycleIndex ?? delivery.cycles.length + 1, []);
    ret.empty = true;
    return ret;
  }

  allOperations() {
    return this.operations;
  }

  firstOperationIndex(): number {
    const firstOperation = this.operations[0];
    return this.delivery.allOperations().findIndex((it) => it === firstOperation);
  }
}

export class PlanningsOperationPlace {
  cycle: PlanningsOperationCycle;

  timeWindows: PlanningsOperationTimeWindow[];

  private operations: PlanningsOperationEntityWithStatsEntity[];

  arrivalAt: string;

  departureAt: string;

  name: string;

  address: string;

  waitingSeconds: number;

  workingSeconds: number;

  maxWeightG: number;

  maxVolumeM3: number;

  arrivalWeightG: number;

  arrivalVolumeMm3: number;

  operationWeightG: number;

  operationVolumeMm3: number;

  latitude: string;

  longitude: string;

  id: string;

  orderIds: number[];

  position: PositionEntity;

  seriesIndex: number;

  status: OperationStatusEntity;

  constructor(cycle: PlanningsOperationCycle, operations: PlanningsOperationEntityWithStatsEntity[], arrivalAt: string, departureAt: string) {
    this.cycle = cycle;
    this.timeWindows = [];
    this.operations = operations;
    this.id = ['PlanningsOperationPlace', operations.length > 0 ? operations.map((it) => it.id).join('-') : `0${new Date().getTime()}`].join('-');
    this.orderIds = [];
    this.seriesIndex = 0;

    if (operations.length === 0) {
      return;
    }

    this.status = operations[0].orderOperationStatus;

    this.arrivalAt = arrivalAt;
    this.departureAt = departureAt;

    const firstOperation = operations.sort((a, b) => new Date(a.arrivalAt).getTime() - new Date(b.arrivalAt).getTime())[0];
    this.name = firstOperation.action === '積' ? firstOperation.loadingName : firstOperation.unloadingName;
    this.address = firstOperation.action === '積' ? firstOperation.loadingAddress : firstOperation.unloadingAddress;
    this.latitude = firstOperation.latitude;
    this.longitude = firstOperation.longitude;
    this.position = [Number(this.latitude), Number(this.longitude)];

    this.waitingSeconds = operations.reduce((acc, it) => acc + it.waitingDurationSeconds, 0);
    this.workingSeconds = operations.reduce((acc, it) => acc + it.operationDurationSeconds, 0);

    this.maxWeightG = Math.max(...this.operations.map((it) => it.currentWeightG));
    this.maxVolumeM3 = Math.max(...this.operations.map((it) => it.currentVolumeMm3));

    this.arrivalWeightG = firstOperation.currentWeightG - firstOperation.operationWeightG;
    this.arrivalVolumeMm3 = (firstOperation.currentVolumeMm3 || 0) - (firstOperation.operationVolumeMm3 || 0);

    this.operationWeightG = operations.reduce((sum, ops) => sum + ops.itemTotalWeightForCalculation, 0);
    this.operationVolumeMm3 = operations.reduce((sum, ops) => sum + ops.itemTotalVolumeMm3, 0);

    this.orderIds = Array.from(new Set(operations.map((it) => it.orderId)));

    const grouped = operations.reduce((acc, ops) => {
      const arr = acc.get(ops.departureAt) || [];
      arr.push(ops);
      acc.set(ops.departureAt, arr);
      return acc;
    }, new Map<string, PlanningsOperationEntityWithStatsEntity[]>());

    grouped.forEach((ops) => {
      const sorted = ops.sort((a, b) => a.id - b.id);
      this.timeWindows.push(new PlanningsOperationTimeWindow(this, sorted));
    });
  }

  allOperations() {
    return this.operations;
  }

  isAction(action: ActionKind) {
    return action === null || this.operations.some((it) => it.action === action);
  }

  includesSplitOrder() {
    return this.operations.filter((it) => it.orderWasSplit).length > 0;
  }

  isOutsideSpecifiedTime() {
    return this.operations.filter((it) => {
      const maxEndAt = it.action === '積' ? it.maxLoadingEndAt : it.maxUnloadingEndAt;
      const minStartAt = it.action === '積' ? it.minLoadingStartAt : it.minUnloadingStartAt;
      return (new Date(maxEndAt).getTime() - new Date(this.arrivalAt).getTime()) < 0
        || (new Date(this.departureAt).getTime() - new Date(minStartAt).getTime()) < 0;
    }).length > 0;
  }
}

export class PlanningsOperationTimeWindow {
  operations: PlanningsOperationEntityWithStatsEntity[];

  place: PlanningsOperationPlace;

  arrivalAt: string;

  departureAt: string;

  constructor(place: PlanningsOperationPlace, operations: PlanningsOperationEntityWithStatsEntity[]) {
    this.place = place;
    this.operations = operations;
    this.operations.forEach((it) => it.timeWindow = this);
    this.arrivalAt = operations.sort((a, b) => new Date(a.arrivalAt).getTime() - new Date(a.arrivalAt).getTime())[0].arrivalAt;
    this.departureAt = operations.sort((a, b) => new Date(b.arrivalAt).getTime() - new Date(a.arrivalAt).getTime())[0].departureAt;
  }
}
