import { holidays } from 'src/constants/holidays';
import datetimeDecorator from 'src/decorators/datetime.decorator';

const diffHours = (startAt: Date, endAt: Date) => Math.abs(
    (startAt.getTime() - endAt.getTime()) / (60 * 60 * 1000)
  );

const diffMinutes = (startAt: Date, endAt: Date, abs = true) => {
  const diff = (startAt.getTime() - endAt.getTime()) / (60 * 1000);
  return abs ? Math.abs(diff) : diff;
};

const diffSeconds = (startAt: Date, endAt: Date) => Math.abs(
  (startAt.getTime() - endAt.getTime()) / 1000
);

const roundToNearestTenMinutes = (date: Date): Date => {
  const roundedDate = new Date(date);
  const minutes = roundedDate.getMinutes();
  const roundedMinutes = Math.round(minutes / 10) * 10;
  roundedDate.setMinutes(roundedMinutes);
  roundedDate.setSeconds(0);
  roundedDate.setMilliseconds(0);
  return roundedDate;
};

const invalidDate = (date: Date): boolean => Number.isNaN(date.getTime());

const isHoliday = (date: Date | null | undefined): boolean => {
  if (!date) return false;

  return holidays
    .map(({ on }) => on)
    .includes(datetimeDecorator.toYyyyMmDd(date));
};

const holidayName = (date: Date | null | undefined): string | null => {
  if (!date) return null;

  const holiday = holidays
    .find(({ on }) => on === datetimeDecorator.toYyyyMmDd(date));

  return holiday ? holiday.name : null;
};

export const datetimeUtil = {
  diffHours,
  diffMinutes,
  diffSeconds,
  roundToNearestTenMinutes,
  invalidDate,
  isHoliday,
  holidayName,
};
