import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MenuItem, Checkbox, Divider, Button, ListItemText, Menu, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { TruckDisplayStatusVo } from 'src/vo/TruckDisplayStatusVo';

type Props = {
  truckDisplayStatus: TruckDisplayStatusVo;
  setTruckDisplayStatus: (stauts: TruckDisplayStatusVo) => void;
  groupEntities: PlanningsGroupEntity[];
  updateSelectedGroupEntities: (selectedGroupEntities: PlanningsGroupEntity[]) => void;
}

function TruckSelectMenuPresenter(props: Props) {
  const { truckDisplayStatus, setTruckDisplayStatus, groupEntities, updateSelectedGroupEntities } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTruckDisplayStatusOnClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const value = event.currentTarget.textContent as TruckDisplayStatusVo;
    ReactGA.event('select', { screen_name: SCREEN_NAMES.PLANNING, button_name: 'トラック表示選択', label: value });
    setTruckDisplayStatus(value);
    handleClose();
  };

  const handleCheckboxChange = (value: number) => {
    setSelectedCheckboxes((prev) => {
      let values: number[] = null;
      if (value === 0) {
        values = prev.includes(value) ? [] : groupEntities.map((_it, i) => i);
      } else if (prev.includes(value)) {
        values = prev.filter((it) => ![0, value].includes(it)); // なにかチェックを外したら「すべて表示する」もチェックを外す
      } else {
        values = [...prev, value];
      }
      ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '車両グループ選択', label: values });
      return values;
    });
  };

  useEffect(() => {
    if (!groupEntities) return;
    updateSelectedGroupEntities(selectedCheckboxes.map((it) => groupEntities[it]));
  }, [groupEntities, selectedCheckboxes, updateSelectedGroupEntities]);

  useEffect(() => {
    if (!groupEntities) return;
    setSelectedCheckboxes(groupEntities.map((_it, i) => i));
  }, [groupEntities]);

  const truckDisplayStatuses: TruckDisplayStatusVo[] = useMemo(() => [
    'すべて表示',
    '配車済み車両のみ表示',
    '未配車の車両のみ表示',
    '選択中のみ表示する'
  ], []);

  return (
    <Stack
      sx={{ width: '100%' }}
      justifyContent="flex-start"
    >
      <Button
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
        style={{ justifyContent: 'flex-start' }}
      >
        {truckDisplayStatus}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ sx: { p: 0, m: 0, } }}
      >
        {truckDisplayStatuses.map((it) => (
          <MenuItem
            onClick={handleTruckDisplayStatusOnClick}
            key={['truckDisplayStatus', it].join('-')}
          >
            {it}
          </MenuItem>
        ))}
        {groupEntities && (groupEntities.length > 0) && (
          <div>
            <Divider />
            {groupEntities.map((it, index) => (
              <MenuItem
                sx={{ p: 0 }}
                onClick={() => handleCheckboxChange(index)}
                key={['truckSelectMenu', it.name].join('-')}
              >
                <Checkbox
                  checked={selectedCheckboxes.includes(index)}
                  onChange={(event) => event.stopPropagation()}
                  value={index}
                />
                <ListItemText primary={it.name} />
              </MenuItem>
            ))}
          </div>
        )}
      </Menu>
    </Stack>
  );
}

export default TruckSelectMenuPresenter;
