export class Co2CalculatorModel {
  distanceKm: number;

  loadingCapacityKg: number;

  constructor(distanceKm: number, loadingCapacityKg: number) {
    this.distanceKm = distanceKm;
    this.loadingCapacityKg = loadingCapacityKg;
  }

  performCalculation(): number {
    return (
      this.distanceKm * this.fuelEfficiencyByKm() * (1 / 1000)
    ) * (
      this.unitCalorificValue() * this.emissionFactor() * (44 / 12)
    );
  }

  fuelEfficiencyByKm(): number {
    if (this.loadingCapacityKg <= 350) {
      return 9.33;
    }

    if (this.loadingCapacityKg <= 999) {
      return 9.32;
    }

    if (this.loadingCapacityKg <= 1999) {
      return 6.19;
    }

    if (this.loadingCapacityKg <= 3999) {
      return 4.58;
    }

    if (this.loadingCapacityKg <= 5999) {
      return 3.79;
    }

    if (this.loadingCapacityKg <= 7999) {
      return 3.38;
    }

    if (this.loadingCapacityKg <= 9999) {
      return 3.09;
    }

    if (this.loadingCapacityKg <= 11999) {
      return 2.89;
    }

    if (this.loadingCapacityKg <= 16999) {
      return 2.62;
    }

    return 1;
  }

  unitCalorificValue() {
    if (this.loadingCapacityKg <= 350) {
      return 34.6;
    }

    return 38.2;
  }

  emissionFactor() {
    if (this.loadingCapacityKg <= 350) {
      return 0.0183;
    }

    return 0.0187;
  }
}
