import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { ManualPlanningRequestEntity } from 'src/entities/registration/ManualPlanning.request.entity';
import * as yup from 'yup';

import { useManualPlanningRequest } from '../hooks/useManualPlanning.request';

const schema: yup.ObjectSchema<ManualPlanningRequestEntity> = yup.object().shape({
  company_name: yup.string().required('会社名を入力してください'),
  address: yup.string().required('住所を入力してください'),
  phone_number: yup.string().required('電話番号を入力してください'),
  email: yup.string().email('メールアドレスの形式で入力してください').required('メールアドレスを入力してください'),
  password: yup.string().required('パスワードを入力してください').min(10, 'パスワードは10文字以上で入力してください'),
  branch_name: yup.string().optional(),
  person_name: yup.string().optional(),
  person_phone_number: yup.string().optional(),
});

type Form = yup.InferType<typeof schema>;

const RegistrationPage: FC = memo(() => {
  const queryClient = useQueryClient();
  const { create } = useManualPlanningRequest();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agreeToTheTermsOfUse, setAgreeToTheTermsOfUse] = useState<boolean>(false);
  const updateAgreeToTheTermsOfUse = (bool: boolean) => {
    setAgreeToTheTermsOfUse(bool);
  };

  const defaultValues: Form = {
    company_name: '',
    address: '',
    phone_number: '',
    email: '',
    password: '',
    branch_name: '',
    person_name: '',
    person_phone_number: '',
  };

  const form = useForm<Form>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = (data: Form) => {
    setIsLoading(true);

    create.mutate(data as ManualPlanningRequestEntity, {
      onSuccess: (response: AxiosResponse<string>) => {
        alert('お支払い情報入力画面に移動します');
        window.location.href = response.data;
      },
      onError: (error: AxiosError<string[]>) => {
        error.response.data.map((message: string) => {
          enqueueSnackbar(message, {
            variant: 'error'
          });
          return message;
        });
        // eslint-disable-next-line no-void
        void queryClient.clear();
      },
      onSettled: () => {
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>会員登録</title>
      </Helmet>
      <Stack
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          p={{
            sm: 1,
            md: 3,
            lg: 5,
          }}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Card component="form" onSubmit={form.handleSubmit(onSubmit)}>
            <CardHeader
              title="会員登録"
            />
            <CardContent>
              <TextField
                label="会社名"
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2
                }}
                {...form.register('company_name')}
                error={!!form.formState.errors.company_name}
                helperText={form.formState.errors.company_name?.message}
                required
              />
              <TextField
                label="支店名"
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2
                }}
                {...form.register('branch_name')}
                error={!!form.formState.errors.branch_name}
                helperText={form.formState.errors.branch_name?.message}
              />
              <TextField
                label="ご担当者名"
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2
                }}
                {...form.register('person_name')}
                error={!!form.formState.errors.person_name}
                helperText={form.formState.errors.person_name?.message}
              />
              <TextField
                label="住所"
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2
                }}
                {...form.register('address')}
                error={!!form.formState.errors.address}
                helperText={form.formState.errors.address?.message}
                required
              />
              <TextField
                label="電話番号"
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2
                }}
                {...form.register('phone_number')}
                error={!!form.formState.errors.phone_number}
                helperText={form.formState.errors.phone_number?.message}
                required
              />
              <TextField
                label="ご担当者様電話番号"
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2
                }}
                {...form.register('person_phone_number')}
                error={!!form.formState.errors.person_phone_number}
                helperText={form.formState.errors.person_phone_number?.message}
              />
              <TextField
                label="メールアドレス"
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2
                }}
                {...form.register('email')}
                error={!!form.formState.errors.email}
                helperText={form.formState.errors.email?.message}
                required
              />
              <TextField
                label="パスワード １０文字以上"
                variant="outlined"
                fullWidth
                type="password"
                {...form.register('password')}
                error={!!form.formState.errors.password}
                helperText={form.formState.errors.password?.message}
                required
              />
              <Stack
                py={1}
              >
                <FormControlLabel
                  control={<Checkbox />}
                  label={(
                    <>
                      <Button
                        variant="text"
                        onClick={() => {
                          window.open('/term.pdf', '_blank');
                        }}
                      >
                        利用規約
                      </Button>
                      に同意する
                    </>
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    updateAgreeToTheTermsOfUse(event.target.checked);
                  }}
                />
              </Stack>
            </CardContent>
            <CardActions>
              <LoadingButton
                type="submit"
                color="primary"
                disabled={form.formState.isSubmitting || !agreeToTheTermsOfUse}
                fullWidth
                size="large"
                loading={isLoading}
              >
                決済情報入力に進む
              </LoadingButton>
            </CardActions>
          </Card>
        </Stack>
      </Stack>
    </>
  );
});

export default RegistrationPage;
