import { Stack, Typography } from '@mui/material';
import { FC, memo } from 'react';

import LogoComponent from './LogoComponent';

const LoadingComponent: FC = memo(() => (
  <Stack
    justifyContent="center"
    alignItems="center"
    direction="column"
    gap={0.5}
    sx={{
        height: '100vh',
        overflow: 'hidden'
      }}
  >
    <Typography textAlign="center" variant="h6">
      読み込み中
    </Typography>
    <LogoComponent width={400} />
  </Stack>
  ));

export default LoadingComponent;
