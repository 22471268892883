import { Checkbox, FormControlLabel, Theme, useTheme } from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { Controller, Path, UseFormReturn } from 'react-hook-form';

type MultiCheckBoxProps<T, S> = {
  form: UseFormReturn<T>;
  control: UseFormReturn<T>['control'];
  name: Path<T>;
  options: string[];
  defaultCheckedValues: string[];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  valueSetter: (value: S[]) => void;
};

function MultiCheckbox<T, S>({ form, control, name, options, defaultCheckedValues, onChange, valueSetter }: MultiCheckBoxProps<T, S>) {
  const theme: Theme = useTheme();

  const handleCheck = (
    option: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let values = defaultCheckedValues;
    if (Array.isArray(values)) {
      values = values.filter((v) => v); // 空要素削除
    } else {
      values = [];
    }

    let newValues = [];
    if ((event.target as HTMLInputElement).checked) {
      newValues = [...(values ?? []), option];
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      newValues = values.filter((value) => value !== option);
    }

    valueSetter(newValues as S[]);

    if (onChange) onChange(event);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return newValues;
  };

  // 初期値としてサーバーサイドから選択できないはずの値が入っていた場合に除外する処理。
  // 主にCSVインポートで正しくない値が入っていた場合に有効。
  useEffect(() => {
    let values = form.getValues(name);
    if (Array.isArray(values)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      values = (values as string[]).filter((v) => options.includes(v)) as any;
    }
    form.setValue(name, values);
  }, [form, name, options]);

  return (
    <Controller<T>
      name={name}
      control={control}
      rules={{ required: '選択してください。' }}
      render={({ field, fieldState }) => (
        <>
          {options.map((option) => (
            <FormControlLabel
              {...field}
              key={option}
              label={option}
              value={option}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => field.onChange(handleCheck(option, event))}
              control={(
                <Checkbox
                  checked={defaultCheckedValues.includes(option)}
                />
              )}
            />
          ))}
        </>
      )}
    />
  );
}

export default MultiCheckbox;
