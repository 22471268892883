import { FC, memo } from 'react';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PlanningsOperationPlace } from 'src/models/PlanningsOperationGroup.model';
import { SelectedStatusVo } from 'src/vo/SelectedStatus.vo';

import PlanningMapPositionPresenter from './PlanningMapPosition.presenter';

type Props = {
  deliveryId: number;
  places: PlanningsOperationPlace[];
  loadCapacityWeightG: number;
  loadCapacityVolumeMm3: number;
  selectedOrderIds: number[];
  addSelectedOrderIds: (orderIds: number[]) => void;
  removeSelectedOrderIds: (orderIds: number[]) => void;
  updateDisplayOrderId: (orderId: number) => void;
  isLoading: boolean;
  forceSelect: boolean;
  forceUnselect: boolean;
  updateSelectedStatus: (idx: number, deliveryId: number, status: SelectedStatusVo) => void;
  addTransferFromDeliveryId: (id: number) => void;
  removeTransferFromDeliveryId: (id: number) => void;
  truckEntity: PlanningsTruckEntity;
  driverColor: string;
}

const PlanningMapPositionsPresenter: FC<Props> = memo((
  {
    places,
    deliveryId,
    loadCapacityWeightG,
    loadCapacityVolumeMm3,
    selectedOrderIds,
    addSelectedOrderIds,
    removeSelectedOrderIds,
    updateDisplayOrderId,
    isLoading,
    forceSelect,
    forceUnselect,
    updateSelectedStatus,
    addTransferFromDeliveryId,
    removeTransferFromDeliveryId,
    truckEntity,
    driverColor,
  }
) => (
  <>
    {places.map((place) => (
      <PlanningMapPositionPresenter
        key={[
          'PlanningMapDeliveryPresenter',
          deliveryId,
          'PlanningMapPositionPresenter',
          place.seriesIndex
        ].join('-')}
        deliveryId={deliveryId}
        place={place}
        loadCapacityWeightG={loadCapacityWeightG}
        loadCapacityVolumeMm3={loadCapacityVolumeMm3}
        selectedOrderIds={selectedOrderIds}
        addSelectedOrderIds={addSelectedOrderIds}
        removeSelectedOrderIds={removeSelectedOrderIds}
        updateDisplayOrderId={updateDisplayOrderId}
        isLoading={isLoading}
        forceSelect={forceSelect}
        forceUnselect={forceUnselect}
        updateSelectedStatus={updateSelectedStatus}
        addTransferFromDeliveryId={addTransferFromDeliveryId}
        removeTransferFromDeliveryId={removeTransferFromDeliveryId}
        truckEntity={truckEntity}
        driverColor={driverColor}
      />
    ))}
  </>
));

export default PlanningMapPositionsPresenter;
