import { Collapse, Table, TableContainer, TableHead, TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { FC, memo } from 'react';
import { DriverShiftSummaryEntity } from 'src/entities/driverShiftEntity';

type Props = {
  driverShifts: DriverShiftSummaryEntity,
  openWorkSummary: boolean
}

const ShiftsTableSummaryTableRow: FC<Props> = memo(({ driverShifts, openWorkSummary }) => {
  const multipleMonths = driverShifts.summaries.length === 2;
  const tableWidth = multipleMonths ? 700 : 400;

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9} align="left">
        <Collapse in={openWorkSummary} timeout="auto" unmountOnExit>
          <TableContainer sx={{ maxWidth: tableWidth }}>
            <Table size="small">
              <TableHead>
                <TableCell />
                <TableCell colSpan={2} align="center">
                  {driverShifts.summaries[0]?.month}
                  月
                </TableCell>
                {multipleMonths
                ? (
                  <TableCell colSpan={2} align="center">
                    {driverShifts.summaries[1].month}
                    月
                  </TableCell>
                )
                : '' }
              </TableHead>
              <TableRow>
                <TableCell>休日</TableCell>
                <TableCell colSpan={2}>
                  {driverShifts.summaries[0]?.day_off_count}
                  日
                </TableCell>
                {multipleMonths
                ? (
                  <TableCell colSpan={2}>
                    {driverShifts.summaries[1].day_off_count}
                    日
                  </TableCell>
                  )
                : '' }
              </TableRow>
              <TableRow>
                <TableCell>労働時間</TableCell>
                <TableCell>
                  配車計画:
                  {driverShifts.summaries[0]?.working_time_hours_on_plan}
                  時間
                </TableCell>
                <TableCell>
                  シフト:
                  {driverShifts.summaries[0]?.working_time_hours_on_shift}
                  時間
                </TableCell>
                {multipleMonths
                ? (
                  <>
                    <TableCell>
                      配車計画:
                      {driverShifts.summaries[1].working_time_hours_on_plan}
                      時間
                    </TableCell>
                    <TableCell>
                      シフト:
                      {driverShifts.summaries[1].working_time_hours_on_shift}
                      時間
                    </TableCell>
                  </>
                )
                : '' }
              </TableRow>
              <TableRow>
                <TableCell>休憩時間</TableCell>
                <TableCell>
                  配車計画:
                  {driverShifts.summaries[0]?.break_time_hours_on_plan}
                  時間
                </TableCell>
                <TableCell>
                  シフト:
                  {driverShifts.summaries[0]?.break_time_hours_on_shift}
                  時間
                </TableCell>
                {multipleMonths
                ? (
                  <>
                    <TableCell>
                      配車計画:
                      {driverShifts.summaries[1].break_time_hours_on_plan}
                      時間
                    </TableCell>
                    <TableCell>
                      シフト:
                      {driverShifts.summaries[1].break_time_hours_on_shift}
                      時間
                    </TableCell>
                  </>
                )
                : '' }
              </TableRow>
              <TableRow>
                <TableCell>13h超え</TableCell>
                <TableCell colSpan={2}>
                  {driverShifts.summaries[0]?.over_thirteen_hours_count}
                  回
                </TableCell>
                {multipleMonths
                ? (
                  <TableCell colSpan={2}>
                    {driverShifts.summaries[1].over_thirteen_hours_count}
                    回
                  </TableCell>
                )
                : '' }
              </TableRow>
            </Table>
          </TableContainer>
        </Collapse>
      </TableCell>
    </TableRow>
  );
});

export default ShiftsTableSummaryTableRow;
