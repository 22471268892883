import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { MappingRule } from 'src/entities/import';

const requestUrl = '/api/v3/orders/mappings';

const getMappingRules = (queryClient: QueryClient) => async () => {
  const res = await axios.get(requestUrl);
  const list = res.data as unknown as MappingRule[];
  return list.map((entity: MappingRule) => {
    queryClient.setQueryData(['mappingRules', { id: entity.id }], entity);
    return entity;
  });
};

export const useQueryMappingRules = () => {
  const queryClient = useQueryClient();
  return useQuery(['mappingRules'], getMappingRules(queryClient), {
    staleTime: Infinity
  });
};

export const useMutationMappingRules = () => {
  const queryClient = useQueryClient();
  return {
    updateMappingRule: useMutation(
      (mappingRule: MappingRule) => axios.put(`${requestUrl}/${mappingRule.id}`, mappingRule),
      {
        onSuccess: () => queryClient.invalidateQueries(['mappingRules']),
      }
    ),
    deleteMappingRule: useMutation(
      (id: number) => axios.delete(`${requestUrl}/${id}`),
      {
        onSuccess: () => queryClient.invalidateQueries(['mappingRules']),
      }
    ),
  };
};
