import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { GarageEntity, GarageRequestEntity } from 'src/entities/garageEntity';

const getGarages = (queryClient: QueryClient, page: number, allVisible?: boolean) => async () => {
  const res = await axios.get<ApiResponse>(`/api/v2/garages?with_data=1&all_visible=${String(!!allVisible)}`);
  const list = res.data.data;
  return list.map((entity: GarageEntity) => {
    queryClient.setQueryData(['garages', { id: entity.id }], entity);
    return entity;
  });
};

const getGarage = (id: number) => async () => {
  const res = await axios.get<ApiResponse>(`/api/v2/garages/${id}`);
  const entity = JSON.parse(res.data as unknown as string) as GarageEntity;
  return res.data;
};

export const useQueryGarages = (page: number, params: { enabled: boolean, allVisible?: boolean }) => {
  const queryClient = useQueryClient();
  return useQuery(['garages', { page, allVisible: params.allVisible }], getGarages(queryClient, page, params.allVisible), {
    enabled: params.enabled,
    staleTime: Infinity
  });
};

export const useQueryGarage = (id: number) => {
  const queryClient = useQueryClient();
  return useQuery(['garages', { id }], getGarage(id), {
    staleTime: Infinity,
    enabled: Boolean(id)
  });
};

export const useMutationGarage = () => {
  const queryClient = useQueryClient();
  return {
    addGarage: useMutation(
      (driver: GarageRequestEntity) => axios.post('/api/v2/garages', driver),
      {
        onSuccess: () => queryClient.invalidateQueries(['garages'])
      }
    ),
    updateGarage: useMutation(
      (driver: GarageEntity) => axios.put(`/api/v2/garages/${driver.id}`, driver),
      {
        onSuccess: () => queryClient.invalidateQueries(['garages'])
      }
    ),
    deleteGarages: useMutation(
      (ids: number[]) => axios.delete('/api/v2/garages/bulk', { data: { ids } }),
      {
        onSuccess: () => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['garages']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['trucks']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['drivers']);
        }
      }
    )
  };
};
