import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { PlanningScenario, PlanningScenarioRequest } from 'src/entities/PlanningHistory.entity';

const getIndex = () => async () => {
  const requestPath = '/api/v4/planning_scenarios';
  const response: AxiosResponse<PlanningScenario[]> = await axios.get(requestPath);

  return response.data;
};

export const useQueryPlanningScenario = (enabled: boolean) => useQuery(
  ['useQueryPlanningScenario'],
  getIndex(),
  {
    staleTime: Infinity,
    enabled,
  }
);

const convertToRequestBody = (
  scenario: PlanningScenario | PlanningScenarioRequest,
) => ({
    name: scenario.name,
    company_id: scenario.companyId,
    company_name: scenario.companyName,
    planning_scenario_items_attributes: scenario.planningScenarioItemsAttributes.map((it) => (
      { position: it.position, planning_setting_id: it.planningSettingId }
    )),
  });

export const useMutationPlanningScenario = () => {
  const queryClient = useQueryClient();
  return {
    addScenario: useMutation(
      (scenario: PlanningScenarioRequest) => axios.post('/api/v4/planning_scenarios', convertToRequestBody(scenario)),
      {
        onSuccess: () => queryClient.invalidateQueries(['useQueryPlanningScenario']),
      }
    ),
    updateScenario: useMutation(
      (scenario: PlanningScenario) => axios.put(
        `/api/v4/planning_scenarios/${scenario.id}`,
        { id: scenario.id, ...convertToRequestBody(scenario) }
      ),
      {
        onSuccess: () => queryClient.invalidateQueries(['useQueryPlanningScenario']),
      }
    ),
    deleteScenario: useMutation(
      (id: number) => axios.delete(`/api/v4/planning_scenarios/${id}`),
      {
        onSuccess: () => queryClient.invalidateQueries(['useQueryPlanningScenario']),
      }
    )
  };
};
