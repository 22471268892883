import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { LoadingButton } from '@mui/lab';
import { Typography, Paper, Box, Theme, ThemeOptions, useTheme, IconButton, Stack } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ConfirmDialog } from 'src/components/ConfirmDialog';
import LicenseContext from 'src/contexts/LicenseContext';
import { CompanyConnectionEntity } from 'src/entities/CompanyConnectionEntity';
import { useMutationCompanyConnection } from 'src/hooks/useQueryComapnyConnections';

interface Props {
  connectedCompanies: CompanyConnectionEntity[];
}

interface Company {
  id: number;
  name: string;
  status: string;
  statusJa: string;
  requester: boolean;
}

export const ConnectedCompanyList: FC<Props> = memo((props: Props) => {
  const { connectedCompanies } = props;

  const licenseContext = useContext(LicenseContext);
  const theme: Theme & ThemeOptions = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [companies, setCompanies] = useState<Company[]>([]);
  const { updateConnection, deleteConnection } = useMutationCompanyConnection();
  const [entityToDelete, setEntityToDelete] = useState<Company>(null);

  const onSubmitRemove = useCallback((entity: Company) => {
    setEntityToDelete(entity);
    setConfirmDialogMessage('連携を解除します。よろしいですか？');
    setConfirmDialogIsOpen(true);
  }, []);

  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState<boolean>(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState<string>('');
  const confirmDialogHandleOk = useCallback(() => {
    if (!entityToDelete) return;
    deleteConnection.mutate(entityToDelete.id);
  }, [deleteConnection, entityToDelete]);

  const confirmDialogHandleCancel = useCallback(() => {
    setConfirmDialogIsOpen(false);
  }, []);

  const onClickAccept = useCallback((entity: Company) => {
    updateConnection.mutate(
      { id: entity.id, status: 'approved' },
      {
        onError: (error: AxiosError<{ message: string }>) => {
          enqueueSnackbar(error.response.data.message);
        }
      }
    );
  }, [enqueueSnackbar, updateConnection]);

  const onClickReject = useCallback((entity: Company) => {
    updateConnection.mutate(
      { id: entity.id, status: 'rejected' },
      {
        onError: (error: AxiosError<{ message: string }>) => {
          enqueueSnackbar(error.response.data.message);
        }
      }
    );
  }, [enqueueSnackbar, updateConnection]);

  const Columns: GridColDef[] = useMemo(() => [
    {
      field: 'name',
      headerName: '会社名',
      width: 400,
    },
    {
      field: 'statusJa',
      headerName: '状態',
      width: 200,
    },
    {
      field: 'edit',
      headerName: '編集',
      width: 200,
      renderCell: (params: { row: Company }) => {
        if (!params.row.requester && licenseContext.config.role.name === 'admin' && params.row.status === 'requested') {
          return (
            <Stack direction="row" spacing={1}>
              <LoadingButton variant="contained" onClick={() => onClickAccept(params.row)}>許可</LoadingButton>
              <LoadingButton variant="outlined" onClick={() => onClickReject(params.row)}>拒否</LoadingButton>
            </Stack>
          );
        }
        return '-';
      }
    },
    {
      field: 'delete',
      headerName: '削除',
      renderCell: (params: { row: Company }) => {
        if (params.row.requester && licenseContext.config.role.name === 'admin') {
          return (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            <IconButton onClick={() => onSubmitRemove(params.row)}>
              <DeleteForeverOutlinedIcon />
            </IconButton>
          );
        }
        return '-';
      }
    }
  ], [licenseContext.config, onClickAccept, onClickReject, onSubmitRemove]);

  const statusJa = useCallback((status: string) => {
    switch (status) {
      case 'requested':
        return '確認待ち';
      case 'approved':
        return '確認済み';
      case 'rejected':
        return '拒否';
      default:
        return '';
    }
  }, []);

  useEffect(() => {
    if (!licenseContext || !licenseContext.config) return;

    const list = connectedCompanies.map((it) => {
      const ret: Company = { id: it.id, name: '', status: '', statusJa: '', requester: false };
      if (it.company.name === licenseContext.config.company_name) {
        ret.name = it.connected_company.name;
        ret.requester = true;
      } else {
        ret.name = it.company.name;
      }
      ret.status = it.status;
      ret.statusJa = statusJa(it.status);
      return ret;
    });
    setCompanies(list);
  }, [connectedCompanies, licenseContext, statusJa]);

  const confirmDialogMmoe = useMemo(() => (
    <ConfirmDialog
      open={confirmDialogIsOpen}
      message={confirmDialogMessage}
      handleOk={confirmDialogHandleOk}
      handleCancel={confirmDialogHandleCancel}
    />
  ), [confirmDialogHandleCancel, confirmDialogHandleOk, confirmDialogIsOpen, confirmDialogMessage]);

  if (!licenseContext || !licenseContext.config || companies.length === 0) {
    return null;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      {confirmDialogMmoe}
      <Box flexGrow={1}>
        <Typography variant="h4" ml={2} pt={2}>
          連携事業所一覧
        </Typography>
        <Paper
          style={{
            width: '100%',
            height: `calc(30vh - ${theme.spacing(4)})`,
            padding: '8px'
          }}
        >
          <DataGridPremium
            columns={Columns}
            rows={companies}
            rowCount={companies.length}
            pagination
            paginationMode="server"
            page={1}
            pageSize={25}
          />
        </Paper>
      </Box>
    </Box>
  );
});
