/* eslint-disable @typescript-eslint/no-misused-promises */
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControlLabel, Link, Paper, Stack, TextField, Typography } from '@mui/material';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import LogoComponent from 'src/components/LogoComponent';
import { fiveZeroZeroErrorMessage } from 'src/constants/messages';
import LicenseContext from 'src/contexts/LicenseContext';
import * as yup from 'yup';

const schema = yup.object({
  password: yup.string().required('パスワードを入力してください').min(10, '10文字以上入力してください'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'パスワードが一致しません')
});

const V2ResetPassword: FC<{ create?: boolean }> = memo((props) => {
  const { create } = props;
  interface ResetPassword {
    password: string;
    confirmPassword: string;
  }

  const licenseContext = useContext(LicenseContext);
  const { register, handleSubmit, reset, formState: { errors } } = useForm<ResetPassword>({
    resolver: yupResolver(schema)
  });

  const { enqueueSnackbar } = useSnackbar();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string>(null);
  const [title, setTitle] = useState<string>('パスワードリセット');
  const [buttonText, setButtonText] = useState<string>('パスワードをリセットする');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (create) {
      setTitle('パスワードを設定してください');
      setButtonText('パスワードを設定する');
    }
  }, [create]);

  useEffect(() => {
    if (searchParams.get('token')) {
      setToken(searchParams.get('token'));
    }
  }, [searchParams]);

  const onSubmit: SubmitHandler<ResetPassword> = async (
    data: ResetPassword
  ): Promise<void> => {
    setShowSuccessMessage(false);

    try {
      if (create) {
        await axios
          .post(`/api/v2/accounts/password_creates/?token=${token}`, data)
          .then((res: AxiosResponse<{redirect_to: string}>) => window.location.href = res.data.redirect_to)
          .catch((err) => console.log(err));
      } else {
        await axios.put(`/api/v2/accounts/password_resets/${token}`, data);
        setShowSuccessMessage(true);
        reset({});
      }
    } catch (e) {
      if (axios.isAxiosError(e) && e.response && e.response.status < 500) {
        const error = e as unknown as AxiosError<{ message: string }>;
        setErrorMessage(error.response.data.message || fiveZeroZeroErrorMessage);
      } else {
        enqueueSnackbar(fiveZeroZeroErrorMessage);
        throw e;
      }
      setShowSuccessMessage(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>パスワードリセット</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <Paper
          sx={{
            py: 2,
            px: 4,
            minWidth: 380
          }}
        >
          <Typography textAlign="center" mb={5}>
            <LogoComponent width={196} />
          </Typography>
          <Typography variant="h3">
            {title}
          </Typography>
          {showSuccessMessage && (
            <Typography variant="h4" style={{ color: '#00f' }} mt={5} mb={2}>
              { create ? '会員登録' : 'パスワード変更' }
              が完了しました。
              <br />
              ログインしてご利用ください。
              <br />
              <Link href="/login">
                ログインへ
              </Link>
            </Typography>
          )}
          {errorMessage && (
            <Typography variant="h4" style={{ color: '#00f' }} mt={5}>
              {errorMessage}
              <br />
            </Typography>
          )}
          <Stack component="form" noValidate spacing={2}>
            <TextField
              required
              margin="dense"
              id="password"
              label="パスワード"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              variant="standard"
              {...register('password')}
              error={'password' in errors}
              helperText={errors.password?.message}
            />
            <TextField
              required
              margin="dense"
              id="confirmPassword"
              label="パスワード確認"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              variant="standard"
              {...register('confirmPassword')}
              error={'confirmPassword' in errors}
              helperText={errors.confirmPassword?.message}
            />
            <FormControlLabel
              control={<Checkbox color="primary" checked={showPassword} onChange={handleClickShowPassword} />}
              label="パスワードを表示する"
            />
            <LoadingButton
              loading={licenseContext.loading}
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleSubmit(onSubmit)}
            >
              {buttonText}
            </LoadingButton>
          </Stack>
        </Paper>
      </Box>
    </>
  );
});

export default V2ResetPassword;
