import { QueryKey } from '@tanstack/query-core/src/types';
import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { ShipperEntity } from 'src/entities/shipperEntity';

const buildRequestPath: (page: number, withData: boolean) => string = (
  page,
  withData
) => [`/api/v2/shippers?page=${page}`, withData ? 'with_data=1' : null].filter((maybe) => !!maybe).join('&');

const fetchShippers: (ctx: QueryFunctionContext<
  QueryKey,
  number
  >) => Promise<{
  ids: number[];
  total_pages_count: number;
  data: ShipperEntity[];
}> = async ({ pageParam = 1 }) => {
  const requestPath = buildRequestPath(pageParam, true);

  const response: AxiosResponse<{
    ids: number[],
    total_pages_count: number,
    data: ShipperEntity[],
  }> = await axios.get(requestPath);
  return response.data;
};

export const useQueryShippers = (enabled: boolean) => useInfiniteQuery(
    ['shippers'],
    fetchShippers,
    {
      enabled,
      staleTime: Infinity,
      getNextPageParam: (lastPage, allPages) => (allPages.length < lastPage.total_pages_count ? allPages.length + 1 : undefined),
    }
  );
