import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';

const getIndex = () => async () => {
  const requestPath = '/api/v3/plannings/groups';
  const response: AxiosResponse<PlanningsGroupEntity[]> = await axios.get(requestPath);

  return response.data;
};

export const useQueryPlanningGroups = () => useQuery(
  ['useQueryPlanningGroups'],
  getIndex(),
  {
    staleTime: Infinity,
  }
);
