import { LoadingButton } from '@mui/lab';
import { DialogContent, DialogTitle, Stack } from '@mui/material';
import { ChangeEvent, FC, memo, useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import LicenseContext from 'src/contexts/LicenseContext';

type Props = {
  importButtonOnClick: (event: ChangeEvent<HTMLInputElement>) => void;
  customImportButtonOnClick: () => void;
  importForUpdateButtonOnClick: (event: ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
}

const SelectImportMethodPresenter: FC<Props> = memo((
  {
    importButtonOnClick,
    customImportButtonOnClick,
    importForUpdateButtonOnClick,
    isLoading,
  }
) => {
  const licenseContext = useContext(LicenseContext);
  const [canUseUpload, setCanUseUpload] = useState<boolean>(false);

  useEffect(() => {
    if (!licenseContext.config) return;
    setCanUseUpload(licenseContext.config.selected_company_id !== 0);
  }, [licenseContext.config]);

  const onClickSelectImportFile = () => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_ORDER_IMPORT, button_name: '標準ファイルアップロード' });
  };

  return (
    <>
      <DialogTitle>インポート形式を選択してください</DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          gap={1}
        >
          <LoadingButton
            fullWidth
            variant="outlined"
            loading={isLoading}
            disabled={isLoading || !canUseUpload}
          >
            標準ファイルアップロード
            <input
              type="file"
              disabled={isLoading || !canUseUpload}
              onClick={onClickSelectImportFile}
              onChange={importButtonOnClick}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              style={{
                  opacity: 0,
                  appearance: 'none',
                  position: 'absolute',
                  width: '100%',
                  cursor: 'pointer'
                }}
            />
          </LoadingButton>
          <LoadingButton
            fullWidth
            variant="outlined"
            loading={isLoading}
            onClick={customImportButtonOnClick}
          >
            カスタムフォーマット
          </LoadingButton>
          {/* <LoadingButton
            fullWidth
            variant="outlined"
            loading={isLoading}
            disabled={isLoading || !canUseUpload}
          >
            上書きアップロード (エクスポートしたファイルを利用してください)
            <input
              type="file"
              disabled={isLoading || !canUseUpload}
              onChange={importForUpdateButtonOnClick}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              style={{
                  opacity: 0,
                  appearance: 'none',
                  position: 'absolute',
                  width: '100%',
                  cursor: 'pointer'
                }}
            />
          </LoadingButton> */}
        </Stack>
      </DialogContent>
    </>
  );
});

export default SelectImportMethodPresenter;
