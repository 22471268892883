import { useTheme } from '@mui/material';
import { FC, memo, useEffect, useState } from 'react';
import { Polyline } from 'react-leaflet';
import { OrderEntity } from 'src/entities/orderEntity';

import { PositionEntity } from '../../../entities/PositionEntity';

type Props = {
  id: number;
  orderEntityMap: Map<number, OrderEntity>;
}

const OrderPolylinePresenter: FC<Props> = memo((
  {
    id,
    orderEntityMap
  }
) => {
  const theme = useTheme();
  const data = orderEntityMap.get(id);

  const [positions, setPositions] = useState<[PositionEntity, PositionEntity]>(undefined);

  useEffect(() => {
    if (!data) return;

    setPositions(
      [
        [data.loading_lat, data.loading_lng],
        [data.unloading_lat, data.unloading_lng]
      ]
    );
  }, [data]);

  if (!positions) return null;

  return (
    <Polyline
      pathOptions={{
        color: theme.colors.primary.main,
        weight: 5,
        opacity: 1
      }}
      positions={positions}
    />
  );
});

export default OrderPolylinePresenter;
