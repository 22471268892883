import Leaflet, { LatLngTuple } from 'leaflet';
import { FC, memo, useEffect, useState } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import { DriverEventLogEntity } from 'src/entities/DriverEventLog.entity';
import { useQueryDriverEventLogs } from 'src/hooks/useQueryDriverEventLogs';

type Props = {
  startOn: string;
  endOn: string;
}

const PlanningMapCurrentPositionsPresenter: FC<Props> = memo((
  {
    startOn,
    endOn,
  }
) => {
  const { data: driverEventLogs } = useQueryDriverEventLogs(startOn, endOn);
  const [driverEventLogEntities, setDriverEventLogEntities] = useState<DriverEventLogEntity[]>([]);
  useEffect(() => {
    if (!driverEventLogs) return;

    setDriverEventLogEntities(
      driverEventLogs.map((it) => {
        let eventTypeJa = '';
        let iconUrl = '';
        switch (it.eventType) {
          case 'StartOfWork':
            eventTypeJa = '勤務開始';
            iconUrl = '/static/images/markers/truck_location.png';
            break;
          case 'EndOfWork':
            eventTypeJa = '勤務終了';
            iconUrl = '/static/images/markers/truck_stopped.png';
            break;
          case 'StartOfBreak':
            eventTypeJa = '休憩中';
            iconUrl = '/static/images/markers/truck_breaking.png';
            break;
          case 'StartOfWaiting':
            eventTypeJa = '待機中';
            iconUrl = '/static/images/markers/truck_waiting.png';
            break;
          case 'Stopped':
            eventTypeJa = '停車中';
            iconUrl = '/static/images/markers/truck_stopped.png';
            break;
          case 'EndOfBreak':
          case 'EndOfWaiting':
          case 'Location':
            eventTypeJa = '移動中';
            iconUrl = '/static/images/markers/truck_location.png';
            break;
          default:
            eventTypeJa = '';
            iconUrl = '';
        }
        return { ...it, eventTypeJa, iconUrl };
      })
    );
  }, [driverEventLogs]);

  function createIcon(driverEvent: DriverEventLogEntity) {
    return new Leaflet.Icon({
      iconUrl: driverEvent.iconUrl,
      iconSize: [32, 42],
      iconAnchor: [16, 42],
      tooltipAnchor: [-60, -60],
    });
  }

  if (!driverEventLogEntities) return null;

  return (
    <>
      {driverEventLogEntities.map((it) => {
        const position: LatLngTuple = [it.latitude, it.longitude];
        return (
          <Marker
            key={['PlanningMapCurrentPositionsPresenter', 'Marker', it.id].join(',')}
            position={position}
            icon={createIcon(it)}
          >
            <Tooltip>
              <>
                {it.driverName}
                <br />
                {it.eventTypeJa}
              </>
            </Tooltip>
          </Marker>
        );
      })}
    </>
  );
});

export default PlanningMapCurrentPositionsPresenter;
