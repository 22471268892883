// import axios, { AxiosResponse } from 'axios';
import axios, { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Context,
  createContext,
  FC,
  memo,
  ReactNode,
  useEffect,
  useMemo,
  useReducer
} from 'react';
import { fiveZeroZeroErrorMessage } from 'src/constants/messages';
import {
  Action,
  ContextEntity,
  ContextValueEntity, ErrorValues,
  InitialAct, Mapping, MappingRule,
  SetAttributesForImport,
  SetPlaceSaveCondition,
  SetCsvColumns,
  SetErrorValues, SetFile, SetImportId,
  SetIsLoading,
  SetMappingRules,
  SetMappings, SetOnlyDatetimeAndBlankCsvColumns, SetOnlyDatetimeCsvColumns,
  SetSampleValues,
  SetSelectedFileAct,
  SetSelectedKeysAct,
  SetSelectedRuleAct, SetSelectedRuleId,
  SetStepAct,
  SetTotal,
  StepNumber, Values,
  SetUsePlaceComplement,
  SetPrioritizePlaces,
  SetSkipMapping,
} from 'src/entities/import';

type SerializedImportOrderCache = {
  id: string;
  sample_values: Values;
  error_values: ErrorValues;
  attributes_for_import: {en: string; ja: string, required: boolean}[];
  csv_columns: string[];
  mappings: Mapping;
  total: number;
  only_datetime_csv_columns: string[];
  only_datetime_and_blank_csv_columns: string[];
}

const initialState: ContextEntity = {
  currentStep: 1,
  selectedRule: 0,
  selectedFile: null,
  selectedKeys: [],
  importId: '',
  mappingRules: [],
  sampleValues: [],
  attributesForImport: [],
  mappings: {},
  csvColumns: [],
  errorValues: [],
  isLoading: false,
  file: null,
  selectedRuleId: 0,
  placeSaveCondition: 'unloadingPlace',
  total: 0,
  only_datetime_csv_columns: [],
  only_datetime_and_blank_csv_columns: [],
  usePlaceComplement: true,
  prioritizePlaces: true,
  skipMapping: false,
};

const handlers: Record<
  string,
  (state: ContextEntity, action: Action) => ContextEntity
> = {
  INITIALIZE: (state: ContextEntity, { data }: InitialAct): ContextEntity => ({
    ...data
  }),
  SETSTEP: (state: ContextEntity, { next }: SetStepAct): ContextEntity => ({
    ...state,
    currentStep: next
  }),
  SETSELECTEDRULE: (
    state: ContextEntity,
    { ruleId }: SetSelectedRuleAct
  ): ContextEntity => ({
    ...state,
    selectedRule: ruleId
  }),
  SETSELECTEDFILE: (
    state: ContextEntity,
    { file }: SetSelectedFileAct
  ): ContextEntity => ({
    ...state,
    selectedFile: file
  }),
  SETSELECTEDKEYS: (
    state: ContextEntity,
    { keys }: SetSelectedKeysAct
  ): ContextEntity => ({
    ...state,
    selectedKeys: keys
  }),
  SET_IMPORT_ID: (
    state: ContextEntity,
    { id }: SetImportId
  ): ContextEntity => ({
    ...state,
    importId: id
  }),
  SET_MAPPING_RULES: (
    state: ContextEntity,
    { values }: SetMappingRules
  ): ContextEntity => ({
    ...state,
    mappingRules: values
  }),
  SET_SAMPLE_VALUES: (
    state: ContextEntity,
    { values }: SetSampleValues
  ): ContextEntity => ({
    ...state,
    sampleValues: values
  }),
  SET_ERROR_VALUES: (
    state: ContextEntity,
    { values }: SetErrorValues
  ): ContextEntity => ({
    ...state,
    errorValues: values
  }),
  SET_ATTRIBUTES_FOR_IMPORT: (
    state: ContextEntity,
    { values }: SetAttributesForImport
  ): ContextEntity => ({
    ...state,
    attributesForImport: values
  }),
  SET_CSV_COLUMNS: (
    state: ContextEntity,
    { values }: SetCsvColumns
  ): ContextEntity => ({
    ...state,
    csvColumns: values
  }),
  SET_MAPPINGS: (
    state: ContextEntity,
    { mappings }: SetMappings
  ): ContextEntity => ({
    ...state,
    mappings
  }),
  SET_IS_LOADING: (
    state: ContextEntity,
    { value }: SetIsLoading
  ): ContextEntity => ({
    ...state,
    isLoading: value
  }),
  SET_FILE: (
    state: ContextEntity,
    { value }: SetFile
  ): ContextEntity => ({
    ...state,
    file: value
  }),
  SET_SELECTED_RULE_ID: (
    state: ContextEntity,
    { value }: SetSelectedRuleId
  ): ContextEntity => ({
    ...state,
    selectedRuleId: value
  }),
  SET_PLACE_SAVE_CONDITION: (
    state: ContextEntity,
    { value }: SetPlaceSaveCondition
  ): ContextEntity => ({
    ...state,
    placeSaveCondition: value
  }),
  SET_TOTAL: (
    state: ContextEntity,
    { value }: SetTotal
  ): ContextEntity => ({
    ...state,
    total: value
  }),
  SET_ONLY_DATETIME_CSV_COLUMNS: (
    state: ContextEntity,
    { values }: SetOnlyDatetimeCsvColumns
  ): ContextEntity => ({
    ...state,
    only_datetime_csv_columns: values
  }),
  SET_ONLY_DATETIME_AND_BLANK_CSV_COLUMNS: (
    state: ContextEntity,
    { values }: SetOnlyDatetimeAndBlankCsvColumns
  ): ContextEntity => ({
    ...state,
    only_datetime_and_blank_csv_columns: values
  }),
  SET_USE_PLACE_COMPLEMENT: (
    state: ContextEntity,
    { value }: SetUsePlaceComplement
  ): ContextEntity => ({
    ...state,
    usePlaceComplement: value
  }),
  SET_PRIORITIZE_PLACES: (
    state: ContextEntity,
    { value }: SetPrioritizePlaces
  ): ContextEntity => ({
    ...state,
    prioritizePlaces: value
  }),
  SET_SKIP_MAPPING: (
    state: ContextEntity,
    { value }: SetSkipMapping
  ): ContextEntity => ({
    ...state,
    skipMapping: value
  }),
};

const reducer = (state: ContextEntity, action: Action): ContextEntity => (handlers[action.type] ? handlers[action.type](state, action) : state);

const ImportContext: Context<ContextValueEntity> = createContext<ContextValueEntity>({
  ...initialState,
  setStep: (next: StepNumber) => false,
  setSelectedRule: (ruleId: number) => false,
  setSelectedFile: (file: File) => false,
  setSelectedKeys: (keys: string[]) => false,
  postImports: () => Promise.resolve(),
  patchImportCacheMappings: () => Promise.resolve(),
  setImportId: (id: string) => false,
  setMappingRules: (values: { id: number; name: string; }[]) => false,
  setSampleValues: (values: Values) => false,
  setErrorValues: (values: ErrorValues) => false,
  setAttributesForImport: (values: {en: string; ja: string, required: boolean}[]) => false,
  setCsvColumns: (values: string[]) => false,
  setMappings: (mappings: {[key: string]: string}) => false,
  setIsLoading: (value: boolean) => false,
  setFile: (value: File) => false,
  setSelectedRuleId: (value: number) => false,
  setPlaceSaveCondition: (value: string) => false,
  isDefaultMappings: () => false,
  setUsePlaceComplement: (value: boolean) => false,
  setPrioritizePlaces: (value: boolean) => false,
  setSkipMapping: (value: boolean) => false,
});

export const defaultMappingsId = -1;

export const ImportProvider: FC<{ children: ReactNode }> = memo((props) => {
  const { children } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      type: 'INITIALIZE',
      data: initialState
    });
  }, []);

  const setStep = (next: StepNumber) => {
    dispatch({
      type: 'SETSTEP',
      next
    });
    return true;
  };

  const setSelectedRule = (ruleId: number) => {
    dispatch({
      type: 'SETSELECTEDRULE',
      ruleId
    });
    return true;
  };

  const setSelectedFile = (file: File) => {
    dispatch({
      type: 'SETSELECTEDFILE',
      file
    });
    return true;
  };

  const setSelectedKeys = (keys: string[]) => {
    dispatch({
      type: 'SETSELECTEDKEYS',
      keys
    });
    return true;
  };

  const setImportId = (id: string) => {
    dispatch({
      type: 'SET_IMPORT_ID',
      id
    });
    return true;
  };

  const setMappingRules = (values: MappingRule[]) => {
    dispatch({
      type: 'SET_MAPPING_RULES',
      values
    });
    return true;
  };

  const setSampleValues = (values: Values) => {
    dispatch({
      type: 'SET_SAMPLE_VALUES',
      values
    });
    return true;
  };

  const setErrorValues = (values: ErrorValues) => {
    dispatch({
      type: 'SET_ERROR_VALUES',
      values
    });
    return true;
  };

  const setAttributesForImport = (values: {en: string; ja: string, required: boolean}[]) => {
    dispatch({
      type: 'SET_ATTRIBUTES_FOR_IMPORT',
      values
    });
    return true;
  };

  const setCsvColumns = (values: string[]) => {
    dispatch({
      type: 'SET_CSV_COLUMNS',
      values
    });
    return true;
  };

  const setMappings = (mappings: {[key: string]: string}) => {
    dispatch({
      type: 'SET_MAPPINGS',
      mappings
    });
    return true;
  };

  const setIsLoading = (value: boolean) => {
    dispatch({
      type: 'SET_IS_LOADING',
      value
    });
    return true;
  };

  const setFile = (value: File) => {
    dispatch({
      type: 'SET_FILE',
      value
    });
    return true;
  };

  const setSelectedRuleId = (value: number) => {
    dispatch({
      type: 'SET_SELECTED_RULE_ID',
      value
    });
    return true;
  };

  const setPlaceSaveCondition = (value: string) => {
    dispatch({
      type: 'SET_PLACE_SAVE_CONDITION',
      value
    });
    return true;
  };

  const setTotal = (value: number) => {
    dispatch({
      type: 'SET_TOTAL',
      value
    });
    return true;
  };

  const setOnlyDatetimeCsvColumns = (values: string[]) => {
    dispatch({
      type: 'SET_ONLY_DATETIME_CSV_COLUMNS',
      values
    });

    return true;
  };

  const setOnlyDatetimeAndBlankCsvColumns = (values: string[]) => {
    dispatch({
      type: 'SET_ONLY_DATETIME_AND_BLANK_CSV_COLUMNS',
      values
    });

    return true;
  };

  const setUsePlaceComplement = (value: boolean) => {
    dispatch({
      type: 'SET_USE_PLACE_COMPLEMENT',
      value
    });

    return true;
  };

  const setPrioritizePlaces = (value: boolean) => {
    dispatch({
      type: 'SET_PRIORITIZE_PLACES',
      value,
    });

    return true;
  };

  const setSkipMapping = (value: boolean) => {
    dispatch({
      type: 'SET_SKIP_MAPPING',
      value,
    });

    return true;
  };

  const defaultMappings = {
    code: '注文コード',
    shipper_name: '荷主名',
    loading_name: '積地',
    loading_address: '積地住所',
    loading_on: '積日',
    loading_at_start: '積地受付',
    loading_at_end: '積地締切',
    loading_at_start_two: '積地受付②',
    loading_at_end_two: '積地締切②',
    loading_at_start_three: '積地受付③',
    loading_at_end_three: '積地締切③',
    loading_staying_minutes: '積地作業分数',
    unloading_name: '降地名称',
    unloading_address: '降地住所',
    unloading_on: '降日',
    unloading_at_start: '降地受付',
    unloading_at_end: '降地締切',
    unloading_at_start_two: '降地受付②',
    unloading_at_end_two: '降地締切②',
    unloading_at_start_three: '降地受付③',
    unloading_at_end_three: '降地締切③',
    unloading_staying_minutes: '降地作業分数',
    item_count: '数量',
    item_total_weight_kg: '総重量',
    item_total_volume_m3: '総体積',
    item_klass: '輸送区分',
    item_name: '品名',
    item_packing_style: '荷姿',
    item_handling_of_cargo_style: '荷扱い',
    item_can_be_mixed: '混載可否',
    designated_truck_klasses: '車両タイプ',
    designated_truck_car_models: '指定車種',
    designated_truck_loading_platform_heights: '荷台高',
    designated_truck_loading_platform_widths: '荷台幅',
    designated_truck_loading_platform_lengths: '荷台長',
    designated_truck_floor_specifications: '床仕様',
    designated_truck_features: '指定装置・特徴',
    charge_basic_fee_yen: '基本運賃',
    charge_highway_fee_yen: '高速代金',
    charge_loading_fee_yen: '積込料金',
    charge_ancillary_fee_yen: '付帯作業料金',
    charge_waiting_time_fee_yen: '待機料金',
    charge_unloading_fee_yen: '取卸料',
    charge_expenses_fee_yen: '諸経費',
    charge_ancillary_content: '付帯作業備考',
    memo: '備考',
    allowed_trucks: '指定トラック',
    denied_drivers: 'NGドライバー'
  };

  const setDefaultMappings = () => {
    if (state.selectedRuleId !== defaultMappingsId) return;

    dispatch({
      type: 'SET_MAPPINGS',
      mappings: defaultMappings
    });
  };

  const isDefaultMappings = () => {
    if (state.selectedRuleId !== defaultMappingsId) return false;

    return Object.keys(defaultMappings).every((key) => defaultMappings[key] === state.mappings[key]);
  };

  const handleResponse = (entity: SerializedImportOrderCache) => {
    setImportId(entity.id);
    setCsvColumns(entity.csv_columns);
    setErrorValues(entity.error_values);
    setSampleValues(entity.sample_values);
    setAttributesForImport(entity.attributes_for_import);
    if (state.selectedRuleId !== defaultMappingsId) setMappings(entity.mappings);
    setTotal(entity.total);
    setOnlyDatetimeCsvColumns(entity.only_datetime_csv_columns);
    setOnlyDatetimeAndBlankCsvColumns(entity.only_datetime_and_blank_csv_columns);
  };

  // TODO: ReactQueryにしたいです
  const getMappings = () => {
    setIsLoading(true);

    const requestPath = '/api/v3/orders/mappings';

    axios.get(requestPath)
      .then((response: AxiosResponse<MappingRule[]>) => {
        setMappingRules(response.data);
      })
      .catch((e) => {
        enqueueSnackbar(fiveZeroZeroErrorMessage);
        throw e;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const postImports: () => Promise<void> = async () => {
    if (!state.file) return;

    const requestPath = '/api/v3/orders/imports';
    const requestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const data = new FormData();
    data.append('file', state.file);
    if (state.selectedRuleId !== 0) {
      data.append('mapping_id', `${state.selectedRuleId}`);
      data.append('skip_mapping', state.skipMapping.toString());
    }
    data.append('place_save_condition', state.placeSaveCondition);
    data.append('use_place_complement', state.usePlaceComplement.toString());
    if (state.usePlaceComplement && state.prioritizePlaces) {
      data.append('prioritize_places', state.prioritizePlaces.toString());
    }

    const response: AxiosResponse<SerializedImportOrderCache> = await axios.post(
      requestPath,
      data,
      requestConfig
    );

    handleResponse(response.data);
  };

  const getImportCache = () => {
    if (!state.importId) return;

    const requestPath = `/api/v3/orders/imports/${state.importId}`;

    setIsLoading(true);

    axios.get(requestPath)
      .then((response: AxiosResponse<SerializedImportOrderCache>) => {
        handleResponse(response.data);
      })
      .catch((e) => {
        enqueueSnackbar(fiveZeroZeroErrorMessage);
        throw e;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const patchImportCacheMappings: () => Promise<void> = async () => {
    const existMappings = () => !!Object
      .values(state.mappings)
      .filter((maybe) => !!maybe)
      .length;

    if (state.mappings && !existMappings()) return;

    const requestPath = `/api/v3/orders/imports/${state.importId}/mappings`;
    const response: AxiosResponse<SerializedImportOrderCache> = await axios.patch(
      requestPath,
      { mappings: state.mappings }
    );

    handleResponse(response.data);
  };

  useEffect(() => {
    getMappings();
  }, []);

  useEffect(() => {
    if (state.skipMapping) return;

    getImportCache();
  }, [state.importId, state.skipMapping]);

  useEffect(() => {
    setDefaultMappings();
  }, [state.selectedRuleId]);

  const value = useMemo(
    () => ({
      ...state,
      setStep,
      setSelectedRule,
      setSelectedFile,
      setSelectedKeys,
      postImports,
      patchImportCacheMappings,
      setImportId,
      setMappingRules,
      setSampleValues,
      setErrorValues,
      setAttributesForImport,
      setMappings,
      setCsvColumns,
      setIsLoading,
      setFile,
      setSelectedRuleId,
      setPlaceSaveCondition,
      isDefaultMappings,
      setUsePlaceComplement,
      setPrioritizePlaces,
      setSkipMapping,
    }),
    [state]
  );

  return (
    <ImportContext.Provider value={value}>{children}</ImportContext.Provider>
  );
});

ImportProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ImportContext;
