import { LicenseEntity } from 'src/entities/licenseEntity';
import { PlanningsOperationEntity } from 'src/entities/PlanningsOperation.entity';
import {
  PlanningsOperationDeliveryByDeliveryIdEntity,
  PlanningsOperationEntitiesWithStatsByDeliveryIdEntity
} from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsOperationEntityWithStatsEntity } from 'src/entities/PlanningsOperationEntityWithStats.entity';

import { PlanningsOperationDelivery } from './PlanningsOperationGroup.model';

export class Operations {
  readonly licenseEntity: LicenseEntity;

  readonly entities: PlanningsOperationEntity[];

  constructor(licenseEntity: LicenseEntity, entities: PlanningsOperationEntity[]) {
    this.licenseEntity = licenseEntity;
    this.entities = entities;
  }

  createPlanningsOperationDeliveryByDeliveryIdEntity(options?: {
    doNotSort?: boolean;
    separateAction?: boolean;
  }): PlanningsOperationDeliveryByDeliveryIdEntity {
    const groupedEntity = this.groupAndSortEntitiesByDeliveryIdWithVolumeAndWeightIdx(options);
    const ret: PlanningsOperationDeliveryByDeliveryIdEntity = {};
    Object.keys(groupedEntity).forEach((key) => {
      ret[key] = new PlanningsOperationDelivery(groupedEntity[key], this.licenseEntity?.delivery_list_grouping);
    });
    return ret;
  }

  groupAndSortEntitiesByDeliveryIdWithVolumeAndWeightIdx(options?: {
    doNotSort?: boolean;
    separateAction?: boolean;
  }): PlanningsOperationEntitiesWithStatsByDeliveryIdEntity {
    const groupedEntities: PlanningsOperationEntitiesWithStatsByDeliveryIdEntity = {};

    this.entities.forEach((entity: PlanningsOperationEntity) => {
      const { shiftTruckId } = entity;
      const entityWithVolumeAndWeight = entity as PlanningsOperationEntityWithStatsEntity;

      if (!groupedEntities[shiftTruckId]) {
        groupedEntities[shiftTruckId] = [entityWithVolumeAndWeight];
      } else {
        groupedEntities[shiftTruckId].push(entityWithVolumeAndWeight);
      }
    });

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const shiftTruckId in groupedEntities) {
      const groupedEntity: PlanningsOperationEntityWithStatsEntity[] = groupedEntities[shiftTruckId];

      if (!options?.doNotSort) {
        groupedEntity.sort((a, b) => {
          const aArrival = new Date(a.arrivalAt).getTime();
          const bArrival = new Date(b.arrivalAt).getTime();
          return aArrival - bArrival;
        });
      }

      const loadOrderIdsInOperation = groupedEntity.filter((it) => it.action === '積').map((it) => it.orderId);
      const unloadOrderIdsInOperation = groupedEntity.filter((it) => it.action === '降').map((it) => it.orderId);

      const initialStockOrderIds = unloadOrderIdsInOperation.filter((id) => !loadOrderIdsInOperation.includes(id));
      const initialStockOperations = initialStockOrderIds.map((id) => groupedEntity.find((e) => e.orderId === id));

      const initialWeightG: number = initialStockOperations
        .map((it) => it.itemTotalWeightForCalculation)
        .filter((maybe) => maybe)
        .reduce((prev, current) => prev + current, 0);
      const initialVolumeMm3: number = initialStockOperations
        .map((it) => it.itemTotalVolumeMm3)
        .filter((maybe) => maybe)
        .reduce((prev, current) => prev + current, 0);

      let currentVolumeSum = initialVolumeMm3;
      let currentWeightSum = initialWeightG;

      groupedEntity.forEach((entity) => {
        currentVolumeSum += entity.operationVolumeMm3 || 0;
        currentWeightSum += entity.operationWeightG;

        entity.currentVolumeMm3 = currentVolumeSum;
        entity.currentWeightG = currentWeightSum;
      });

      this.incrementIdxIfLatLngChanged(groupedEntity, options);
    }

    return groupedEntities;
  }

  // eslint-disable-next-line class-methods-use-this
  private incrementIdxIfLatLngChanged(
    entities: PlanningsOperationEntityWithStatsEntity[],
    options?: {
      doNotSort?: boolean;
      separateAction?: boolean;
    }
  ): void {
    let prevLatLng: string | null = null;
    let placeEntity: PlanningsOperationEntityWithStatsEntity;
    let idx = 0;

    entities.forEach((entity) => {
      const { latitude, longitude, action } = entity;
      const latLng = options?.separateAction ? `${latitude},${longitude}-${action}` : `${latitude},${longitude}`;
      if (latLng !== prevLatLng) {
        idx += 1;
        placeEntity = entity;
      }

      prevLatLng = latLng;
      entity.idx = idx;
    });

    if (this.licenseEntity?.delivery_list_grouping === 'groupByName') {
      idx = 0;
      let prevName: string;
      entities.forEach((entity) => {
        const name = entity.action === '積' ? entity.loadingName : entity.unloadingName;
        if (name !== prevName) {
          idx += 1;
        }
        prevName = name;
        entity.idx = idx;
      });
    }
  }
}
