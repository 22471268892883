import { BarChart } from '@mui/x-charts';

interface BarChartProps {
  barchartAxis: string[];
  barchartData: number[];
}

export function KpiBarChart(props: BarChartProps) {
  const { barchartAxis, barchartData } = props;
  if (barchartAxis.length === 0) return null;
  if (barchartData.length === 0) return null;

  return (
    <BarChart
      xAxis={[
        {
          id: 'barCategories',
          data: barchartAxis,
          scaleType: 'band',
        },
      ]}
      series={[
        {
          data: barchartData,
        },
      ]}
    />
  );
}
