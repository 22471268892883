import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Paper, Stack, TextField, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler, useForm } from 'react-hook-form';
import LicenseContext from 'src/contexts/LicenseContext';
import { EditCompanyEntity } from 'src/entities/EditCompany.entity';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required('会社名を入力してください').max(255, '会社名は255文字以内で入力してください'),
  address: yup.string().optional().nullable().max(255, '住所は255文字以内で入力してください'),
  phone_number: yup.string().optional().nullable().max(255, '電話番号は255文字以内で入力してください'),
});

const CompanyPresenter: FC = memo(() => {
  const { enqueueSnackbar } = useSnackbar();
  const licenseContext = useContext(LicenseContext);

  const [isLoading, setIsLoading] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<EditCompanyEntity>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      id: licenseContext.config?.company_id,
      name: licenseContext.config?.company_name,
      address: licenseContext.config?.address,
      phone_number: licenseContext.config?.phone_number,
    });
  }, [licenseContext.config, reset]);

  const buttonOnClick: SubmitHandler<EditCompanyEntity> = async (data: EditCompanyEntity) => {
    const companyRequestPath = '/api/v2/company';

    setIsLoading(true);
    try {
      await axios.patch(companyRequestPath, data);
      enqueueSnackbar('更新が完了しました');
    } catch (e) {
      const error = e as unknown as AxiosError<{ messages: string[]; }>;
      enqueueSnackbar(error.response.data.messages.join(', '), { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>会社情報</title>
      </Helmet>
      <Box p={2}>
        <Paper>
          <Stack component="form" noValidate spacing={3} divider={<Divider />} p={3}>
            <Stack>
              <Typography variant="h3" pb={2}>会社情報</Typography>
              <Stack direction="column" gap={2} justifyItems="center" m={5}>
                <Stack>
                  <TextField
                    type="text"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="会社名"
                    id="name"
                    {...register('name')}
                    error={'name' in errors}
                    helperText={errors.name?.message}
                  />
                </Stack>
                <Stack>
                  <TextField
                    type="text"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="住所"
                    id="address"
                    {...register('address')}
                    error={'address' in errors}
                    helperText={errors.address?.message}
                  />
                </Stack>
                <Stack>
                  <TextField
                    type="text"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="電話番号"
                    id="phone_number"
                    {...register('phone_number')}
                    error={'phone_number' in errors}
                    helperText={errors.phone_number?.message}
                  />
                </Stack>
                <Stack mt={5}>
                  {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                  <LoadingButton loading={isLoading} variant="contained" size="large" onClick={handleSubmit(buttonOnClick)}>
                    保存する
                  </LoadingButton>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Box>
    </>
  );
});

export default CompanyPresenter;
