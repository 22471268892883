import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { PlanningSetting } from 'src/entities/PlanningHistory.entity';

const getIndex = () => async () => {
  const requestPath = '/api/v4/planning_settings';
  const response: AxiosResponse<PlanningSetting[]> = await axios.get(requestPath);

  return response.data;
};

export const useQueryPlanningSettings = (enabled: boolean) => useQuery(
  ['useQueryPlanningSettings'],
  getIndex(),
  {
    staleTime: Infinity,
    enabled,
  }
);
