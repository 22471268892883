import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';

const getLastOperationStatusUpdated = (queryClient: QueryClient, startOn: string, endOn: string, shiftTruckIds: number[]) => async () => {
  if (shiftTruckIds.length === 0) return null;

  const res = await axios.get<ApiResponse>(`/api/v3/plannings/${startOn}/operatios/last_status_updated?end_on=${endOn}&shift_truck_ids=${shiftTruckIds.join(',')}`);
  return res.data.data as unknown as string;
};

export const useQueryLastOperationStatusUpdated = (startOn: string, endOn:string, shiftTruckIds: number[]) => {
  const queryClient = useQueryClient();
  return useQuery(['lastOperationStatusUpdated', startOn, endOn, shiftTruckIds], getLastOperationStatusUpdated(queryClient, startOn, endOn, shiftTruckIds), {
    staleTime: Infinity,
    cacheTime: 5 * 60 * 1000,
    refetchInterval: 3 * 60 * 1000
  });
};
