import { Avatar, Checkbox, Paper, Stack, Typography, useTheme } from '@mui/material';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { PlanningsOperationEntityWithStatsEntity } from 'src/entities/PlanningsOperationEntityWithStats.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PlanningsOperationPlace } from 'src/models/PlanningsOperationGroup.model';
import { SelectedStatusVo } from 'src/vo/SelectedStatus.vo';

import PlanningMapOperationsPresenter from './PlanningMapOperations.presenter';

type Props = {
  deliveryId: number;
  place: PlanningsOperationPlace;
  loadCapacityWeightG: number;
  loadCapacityVolumeMm3: number;
  selectedOrderIds: number[];
  addSelectedOrderIds: (orderIds: number[]) => void;
  removeSelectedOrderIds: (orderIds: number[]) => void;
  updateDisplayOrderId: (orderId: number) => void;
  isLoading: boolean;
  forceSelect: boolean;
  forceUnselect: boolean;
  updateSelectedStatus: (idx: number, deliveryId: number, status: SelectedStatusVo) => void;
  addTransferFromDeliveryId: (id: number) => void;
  removeTransferFromDeliveryId: (id: number) => void;
  truckEntity: PlanningsTruckEntity;
  driverColor: string;
}

const PlanningMapPositionPresenter: FC<Props> = memo((
  {
    deliveryId,
    place,
    loadCapacityWeightG,
    loadCapacityVolumeMm3,
    selectedOrderIds,
    addSelectedOrderIds,
    removeSelectedOrderIds,
    updateDisplayOrderId,
    isLoading,
    forceSelect,
    forceUnselect,
    updateSelectedStatus,
    addTransferFromDeliveryId,
    removeTransferFromDeliveryId,
    truckEntity,
    driverColor,
  }
) => {
  const theme = useTheme();

  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [arrivalAt, setArrivalAt] = useState<string>('');
  const [departureAt, setDepartureAt] = useState<string>('');
  const [operations, setOperations] = useState<PlanningsOperationEntityWithStatsEntity[]>([]);
  const [allOrderIds, setAllOrderIds] = useState<number[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<SelectedStatusVo>('none');

  const selectableOrderIds = useCallback(() => allOrderIds.filter((orderId) => !operations.find((ops) => ops.orderId === orderId).fixed), [allOrderIds, operations]);

  const checkboxOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_PLACE_DIALOG, button_name: `車両チェックボックス ${selectedStatus === 'every' ? 'OFF' : 'ON'}` });

    if (selectedStatus === 'every') {
      removeSelectedOrderIds(allOrderIds);

      return;
    }

    addSelectedOrderIds(selectableOrderIds());
  };

  useEffect(() => {
    if (!place) return;
    if (!deliveryId) return;

    updateSelectedStatus(place.seriesIndex, deliveryId, selectedStatus);
  }, [selectedStatus, place, deliveryId, updateSelectedStatus]);

  useEffect(() => {
    if (selectedStatus === 'none') {
      removeTransferFromDeliveryId(deliveryId);

      return;
    }

    addTransferFromDeliveryId(deliveryId);
  }, [selectedStatus]);

  useEffect(() => {
    if (allOrderIds.every((it) => selectedOrderIds.includes(it))) {
      setSelectedStatus('every');

      return;
    }

    if (allOrderIds.some((it) => selectedOrderIds.includes(it))) {
      setSelectedStatus('some');

      return;
    }

    setSelectedStatus('none');
  }, [selectedOrderIds, allOrderIds]);

  useEffect(() => {
    if (!forceSelect) return;

    addSelectedOrderIds(selectableOrderIds());
  }, [addSelectedOrderIds, forceSelect, selectableOrderIds]);

  useEffect(() => {
    if (!forceUnselect) return;

    removeSelectedOrderIds(allOrderIds);
  }, [allOrderIds, forceUnselect, removeSelectedOrderIds]);

  useEffect(() => {
    if (!place) return;

    setName(place.name);
    setAddress(place.address);
    setArrivalAt(
      datetimeDecorator.toHourMinutes(
        new Date(
          place.arrivalAt
        )
      )
    );
    setDepartureAt(
      datetimeDecorator.toHourMinutes(
        new Date(
          place.departureAt
        )
      )
    );
    setOperations(place.allOperations());
  }, [place]);

  useEffect(() => {
    setAllOrderIds(
      operations.map((it) => it.orderId)
    );
  }, [operations]);

  return (
    <Paper
      square
    >
      <Stack
        p={1}
        pr={0}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          onClick={checkboxOnClick}
          style={{
            cursor: 'pointer'
          }}
        >
          <Checkbox
            indeterminate={selectedStatus === 'some'}
            checked={selectedStatus === 'every'}
            onClick={checkboxOnClick}
            disabled={isLoading}
            size="small"
            sx={{
              m: 0,
              p: 0.3,
            }}
          />
          <Avatar style={{
            backgroundColor: driverColor,
            width: theme.spacing(2),
            height: theme.spacing(2),
            fontSize: '10px'
          }}
          >
            {place.seriesIndex}
          </Avatar>
          <Typography>
            {truckEntity.licensePlateValue}
          </Typography>
          <Typography>
            {
              [
                arrivalAt,
                departureAt
              ].join(' - ')
            }
          </Typography>
          <Typography>
            {name}
          </Typography>
          <Typography>
            {address}
          </Typography>
        </Stack>
        <Stack>
          <PlanningMapOperationsPresenter
            loadCapacityWeightG={loadCapacityWeightG}
            loadCapacityVolumeMm3={loadCapacityVolumeMm3}
            selectedOrderIds={selectedOrderIds}
            addSelectedOrderIds={addSelectedOrderIds}
            removeSelectedOrderIds={removeSelectedOrderIds}
            updateDisplayOrderId={updateDisplayOrderId}
            planningOperations={operations}
            isLoading={isLoading}
          />
        </Stack>
      </Stack>
    </Paper>
  );
});

export default PlanningMapPositionPresenter;
