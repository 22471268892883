import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { Box, Button } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-premium';
import { FC, memo } from 'react';

import { AdminSideNav } from '../SideNav';

export const AdminLicensesToolbar: FC<{
  openAppendDialog: () => void;
}> = memo(({ openAppendDialog }) => (
  <GridToolbarContainer
    sx={{ width: '100%' }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
        flexGrow={1}
      >
        <Button
          startIcon={<AddCircleOutlinedIcon />}
          onClick={openAppendDialog}
        >
          追加
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
        flexGrow={1}
      >
        <AdminSideNav />
      </Box>
    </Box>
  </GridToolbarContainer>
));
