import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { AchievementEntity } from 'src/entities/Achievement.entity';

export const path = (on: string) => `/api/v4/achievements/${on}`;
export const key = (on: string) => ['useV4AchievementQuery', on];

export const useV4AchievementQuery = (on: string | undefined | null) => useQuery(
  key(on),
  async () => {
    const response = await axios.get<AchievementEntity[]>(path(on));

    return response.data;
  },
  {
    enabled: !!on,
    staleTime: Infinity
  }
);
