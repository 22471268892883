import {
  Box,
  Tabs,
  Tab,
  Tooltip,
  TextField,
  Stack,
  Paper,
  Backdrop,
  CircularProgress,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryKpiSummaries } from 'src/hooks/useQueryKpiSummaries';
import { datetimeUtil } from 'src/utils/datetime.util';

import LicenseContext from '../../contexts/LicenseContext';

import { a11yProps, CustomTabPanel } from './CustomTabPanel';
import { KpiBarChart } from './KpiBarChart';

const MonthlySummaries: React.FC = memo(() => {
  const licenseContext = useContext(LicenseContext);

  const [endDate, setEndDate] = useState(new Date());
  const [selectedTab, setSelectedTab] = useState(0);
  const [minDate, setMinDate] = useState(new Date());

  const kpiSummaries = useQueryKpiSummaries(endDate, false);
  const kpiSummariesWithCo2Emissions = useQueryKpiSummaries(endDate, true);

  useEffect(() => {
    const d = kpiSummaries.data?.minDate || new Date();
    setMinDate(d);
  }, [kpiSummaries.data?.minDate]);

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  }, []);

  const handleEndDateChange = useCallback((date: Date) => {
    if (datetimeUtil.invalidDate(date)) return;
    setEndDate(date);
  }, []);

  const tabSelectBoxMemo = useMemo(() => (
    <Paper>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="売上" {...a11yProps(0)} />
          <Tab label="案件・発注" {...a11yProps(1)} />
          {
            licenseContext?.config?.use_algorithm_planning ? (
              <Tab label="CO2排出量" {...a11yProps(2)} />
            ) : (
              <Tooltip
                title={(
                  <Typography>
                    CO2排出量は、自動配車プランのみご利用可能です。
                    <br />
                    自動配車を利用したい場合はセールス (sales@logpose.co.jp) へご連絡ください。
                  </Typography>
                )} arrow
              >
                <span>
                  <Tab disabled label="CO2排出量" {...a11yProps(2)} />
                </span>
              </Tooltip>
            )
          }
        </Tabs>
      </Box>
    </Paper>
  ), [handleTabChange, licenseContext?.config, selectedTab]);

  const dateSelectBoxMemo = useMemo(() => (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <DatePicker
        onChange={(date: Date) => handleEndDateChange(date)}
        value={endDate}
        inputFormat="yyyy/MM"
        views={['year', 'month']}
        // views={['month', 'year']}
        minDate={minDate}
        maxDate={new Date()}
        label="終了月"
        renderInput={(params) => (
          <Tooltip
            title="終了月を変更する"
            arrow
          >
            <TextField
              {...params}
              size="small"
              sx={{
                width: 155
              }}
              type="date"
            />
          </Tooltip>
        )}
      />
    </Box>
  ), [endDate, handleEndDateChange, minDate]);

  const tabHeaderMemo = useMemo(() => (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={0}
    >
      {tabSelectBoxMemo}
      {dateSelectBoxMemo}
    </Stack>
  ), [tabSelectBoxMemo, dateSelectBoxMemo]);

  const tabPanelMemo = useMemo(() => (
    <Paper sx={{ marginTop: 1, height: '30vh', position: 'relative' }}>
      <CustomTabPanel value={selectedTab} index={0}>
        <Backdrop
          sx={{ color: '#fff', position: 'absolute' }}
          open={kpiSummaries.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <KpiBarChart
          barchartAxis={kpiSummaries.data?.months || []}
          barchartData={kpiSummaries.data?.sales || []}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <Backdrop
          sx={{ color: '#fff', position: 'absolute' }}
          open={kpiSummaries.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <KpiBarChart
          barchartAxis={kpiSummaries.data?.months || []}
          barchartData={kpiSummaries.data?.orders || []}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={2}>
        <Backdrop
          sx={{ color: '#fff', position: 'absolute' }}
          open={kpiSummariesWithCo2Emissions.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <KpiBarChart
          barchartAxis={kpiSummaries.data?.months || []}
          barchartData={kpiSummariesWithCo2Emissions.data?.co2emissions || []}
        />
      </CustomTabPanel>
    </Paper>
  ), [kpiSummaries.data?.months, kpiSummaries.data?.orders, kpiSummaries.data?.sales, kpiSummaries.isLoading, kpiSummariesWithCo2Emissions.data?.co2emissions, kpiSummariesWithCo2Emissions.isLoading, selectedTab]);

  return (
    <Box sx={{ width: '100%' }}>
      {tabHeaderMemo}
      {tabPanelMemo}
    </Box>
  );
});

export default MonthlySummaries;
