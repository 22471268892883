import { Button } from '@mui/material';
import { FC, memo } from 'react';
import { CompanyEntity } from 'src/entities/admin/companyEntity';

import { SwitchCompanies } from './SwitchCompanies';

type Props = {
  companies: CompanyEntity[];
  navigateToDashboard: (isLogo: boolean) => void;
  hideShowAllSelectMenuItem: boolean;
  hideThisComponent: boolean;
}

const AppBarLogoComponent: FC<Props> = memo((
  {
    companies,
    navigateToDashboard,
    hideShowAllSelectMenuItem,
    hideThisComponent,
  }
) => (
  <>
    <Button
      onClick={
        () => {
          navigateToDashboard(true);
        }
      }
    >
      <img src="/main-logo.png" alt="logo" style={{ width: '100px' }} />
    </Button>
    <SwitchCompanies
      companies={companies}
      hideShowAllSelectMenuItem={hideShowAllSelectMenuItem}
      hideThisComponent={hideThisComponent}
    />
  </>
));

export default AppBarLogoComponent;
