import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { SubscriptionEntity, SubscriptionRequestEntity } from 'src/entities/admin/subscriptionEntity';

const baseUrl = (companyId: number | string) => `/api/admin/v1/companies/${companyId}/subscriptions/`;

const getSubscriptions = (queryClient: QueryClient, companyId: number | string, page: number, pageSize: number) => async () => {
  const res = await axios.get<ApiResponse>(`${baseUrl(companyId)}?page=${page + 1}&per=${pageSize}`);
  const data = res.data.data as unknown as SubscriptionEntity[];
  const total = res.data.total_pages_count;
  return { data, total };
};

export const useQuerySubscriptions = (companyId: number | string, page: number, pageSize: number) => {
  const queryClient = useQueryClient();
  return useQuery(['subscriptions', { companyId, page, pageSize }], getSubscriptions(queryClient, companyId, page, pageSize), {
    staleTime: Infinity
  });
};

const getSubscription = (companyId: number | string, id: number) => async () => {
  const res = await axios.get<ApiResponse>(`${baseUrl(companyId)}${id}`);
  return res.data as unknown as SubscriptionEntity;
};

export const useQuerySubscription = (companyId: number | string, id: number) => {
  const queryClient = useQueryClient();
  return useQuery(['subscriptions', { companyId, id }], getSubscription(companyId, id), {
    staleTime: Infinity,
    enabled: Boolean(id)
  });
};

export const useMutationSubscription = () => {
  const queryClient = useQueryClient();
  return {
    addSubscription: useMutation(
      (subscription: SubscriptionRequestEntity) => axios.post(baseUrl(subscription.company_id), subscription),
      {
        onSuccess: () => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['subscriptions']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['companies']);
        }
      }
    ),
    updateSubscription: useMutation(
      (subscription: SubscriptionEntity) => axios.put(`${baseUrl(subscription.company_id)}${subscription.id}`, subscription),
      {
        onSuccess: () => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['subscriptions']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['companies']);
        }
      }
    ),
    deleteSubscription: useMutation(
      (subscription: SubscriptionEntity) => axios.delete(`${baseUrl(subscription.company_id)}${subscription.id}`),
      {
        onSuccess: () => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['subscriptions']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['companies']);
        }
      }
    ),
  };
};
