import { Box } from '@mui/material';
import { FC, memo } from 'react';
import { StepNumber } from 'src/entities/import';

const grayout = {
  filter: 'grayscale(100%)'
};

const Header: FC<{
  step: StepNumber;
}> = memo(({ step }) => (
  <Box
    paddingTop="1.5em"
    paddingBottom="3.5em"
    sx={{
      backgroundColor: 'rgba(248, 248, 248, 1)',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '600px',
      fontSize: '12px',
      color: '#44A8E6'
    }}
  >
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '500px'
      }}
    >
      <Box
        sx={{
          backgroundColor: '#E2E4E6',
          position: 'absolute',
          top: '50%',
          left: '0',
          transform: 'translateY(-50%)',
          height: '4px',
          width: '100%'
        }}
      />
      <Box
        sx={{
          backgroundColor: '#44A8E6',
          position: 'absolute',
          top: '50%',
          left: '0',
          transform: 'translateY(-50%)',
          height: '4px',
          // eslint-disable-next-line no-nested-ternary
          width:
          // eslint-disable-next-line no-nested-ternary
            step === 1 ? '0%' : step === 2 ? '33%' : step === 3 ? '66%' : '100%'
        }}
      />
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '500px'
        }}
      >
        <Box
          textAlign="center"
          position="relative"
          style={step >= 1 ? null : grayout}
        >
          <img src="/static/images/import1.png" alt="import1" height={48} />
          <Box
            sx={{
              position: 'absolute',
              top: '110%',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '14em'
            }}
          >
            アップロード
          </Box>
        </Box>
        <Box
          textAlign="center"
          position="relative"
          style={step >= 2 ? null : grayout}
        >
          <img src="/static/images/import2.png" alt="import2" height={48} />
          <Box
            sx={{
              position: 'absolute',
              top: '110%',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '14em'
            }}
          >
            対応付け
          </Box>
        </Box>
        <Box
          textAlign="center"
          position="relative"
          style={step >= 3 ? null : grayout}
        >
          <img src="/static/images/import3.png" alt="import3" height={48} />
          <Box
            sx={{
              position: 'absolute',
              top: '110%',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '14em'
            }}
          >
            データ修正
          </Box>
        </Box>
        <Box
          textAlign="center"
          position="relative"
          style={step >= 4 ? null : grayout}
        >
          <img src="/static/images/import4.png" alt="import4" height={48} />
          <Box
            sx={{
              position: 'absolute',
              top: '110%',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '14em'
            }}
          >
            インポート
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
));

export default Header;
