import { QueryClient, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns-tz';
import { ApiResponse } from 'src/constants/apiResponse';
import { OrderEntity, OrderRequestEntity } from 'src/entities/orderEntity';
import { OrderSplitEntity } from 'src/entities/orderSplitEntity';

const getOrders = (queryClient: QueryClient, startOn: Date, page: number) => async () => {
  const pathParam = format(startOn, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' });
  const res = await axios.get<ApiResponse>(`/api/v2/orders?with_data=1&start_at_range_start_on=${pathParam}`);
  const list = res.data.data;
  return list.map((entity: OrderEntity) => {
    queryClient.setQueryData(['orders', { id: entity.id }], entity);
    return entity;
  });
};

const getOrder = (id: number) => async () => {
  const requestPath = `/api/v2/orders/${id}`;
  const res = await axios.get<OrderEntity>(requestPath);
  const entity = res.data;
  return entity;
};

export const useQueryOrders = (startOn: Date, page: number) => {
  const queryClient = useQueryClient();
  return useQuery(['orders', { page, startOn }], getOrders(queryClient, startOn, page), {
    staleTime: Infinity
  });
};

export const useQueryOrder = (id: number) => useQuery(['orders', { id }], getOrder(id), {
    staleTime: Infinity,
    enabled: Boolean(id)
  });

export const useMutationOrder = () => {
  const queryClient = useQueryClient();
  return {
    addOrder: useMutation(
      (order: OrderRequestEntity) => axios.post('/api/v2/orders', { order }),
      {
        onSuccess: () => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['orders']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['dashboards']);
        },
        onError: (error: AxiosError<{data: string[]}>) => error.response.data
      }
    ),
    updateOrder: useMutation(
      (order: OrderEntity) => axios.put(`/api/v2/orders/${order.id}`, { order }),
      {
        onSuccess: (res) => {
          const orders = res.data as OrderEntity;
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['dashboards']);
          return queryClient.invalidateQueries(['orders']);
        },
        onError: (error: AxiosError<{data: string[]}>) => error.response.data
      }
    ),
    deleteOrder: useMutation(
      (id: number) => axios.delete(`/api/v2/orders/${id}`),
      {
        onSuccess: () => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['orders']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['dashboards']);
        },
        onError: (error: AxiosError<{data: string[]}>) => error.response.data
      }
    ),
    deleteOrders: useMutation(
      (ids: number[]) => axios.delete('/api/v2/orders/bulk', { data: { ids } }),
      {
        onSuccess: (res) => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['orders']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['dashboards']);
        },
        onError: (error: AxiosError<{data: string[]}>) => error.response.data
      }
    ),
    restoreSplittedOrder: useMutation(
      (id: number) => axios.delete(`/api/v4/master/orders/${id}/order_split`),
      {
        onSuccess: (res) => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['orders']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['dashboards']);
        },
        onError: (error: AxiosError<{data: string[]}>) => error.response.data
      }
    ),
    restoreSplittedOrders: useMutation(
      (dates: { startOn: string, endOn: string }) => axios.post(`/api/v4/master/delete_all/order_splits?start_on=${dates.startOn}&end_on=${dates.endOn}`),
      {
        onSuccess: (res) => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['orders']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['dashboards']);
        },
        onError: (error: AxiosError<{data: string[]}>) => error.response.data
      }
    ),
    deleteOperations: useMutation(
      (id: number) => axios.delete(`/api/v2/orders/${id}/order_operations`)
    ),
    splitOrder: useMutation(
      (entity: OrderSplitEntity) => axios.post(`/api/v2/orders/${entity.order_id}/split`, { data: entity.data }),
      {
        onSuccess: () => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['orders']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['dashboards']);
        },
        onError: (error: AxiosError<{data: string[]}>) => error.response.data
      }
    ),
    operationBulkResetting: useMutation(
      (orderIds: number[]) => axios.post('/api/v3/orders/order_operations/bulk/resetting', { order_ids: orderIds }),
      {
        onSuccess: () => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['orders']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['dashboards']);
        }
      }
    ),
  };
};

const fetchUnallocatedOrderIds = (queryClient: QueryClient) => async ({ pageParam = 1, queryKey }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const startOn = queryKey[1] as string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const endOn = queryKey[2] as string;
  const requestPath = `/api/v2/orders?with_data=1&start_at_range_start_on=${startOn}&start_at_range_end_on=${endOn}&page=${pageParam}&not_planed=true`;

  const response: AxiosResponse<{
    ids: number[],
    total_pages_count: number;
    data: OrderEntity[];
  }> = await axios.get(requestPath).then((res) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const list = res.data.data as OrderEntity[];
    list.map((entity: OrderEntity) => {
      queryClient.setQueryData(['orders', { id: entity.id }], entity);
      return entity;
    });
    return res;
  });
  return response.data;
};

const fetchOrderIds = (queryClient: QueryClient) => async ({ pageParam = 1, queryKey }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const startOn = queryKey[1] as string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const endOn = queryKey[2] as string;
  const requestPath = `/api/v2/orders?with_data=1&start_at_range_start_on=${startOn}&start_at_range_end_on=${endOn}&page=${pageParam}`;

  const response: AxiosResponse<{
    ids: number[],
    total_pages_count: number;
    data: OrderEntity[];
  }> = await axios.get(requestPath).then((res) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const list = res.data.data as OrderEntity[];
    list.map((entity: OrderEntity) => {
      queryClient.setQueryData(['orders', { id: entity.id }], entity);
      return entity;
    });
    return res;
  });
  return response.data;
};

export const useInfiniteQueryUnallocatedOrders = (startDate, endDate) => {
  const queryClient = useQueryClient();
  return useInfiniteQuery(
    ['orders', startDate, endDate],
    fetchUnallocatedOrderIds(queryClient),
    {
      enabled: !!startDate && !!endDate,
      staleTime: Infinity,
      getNextPageParam: (lastPage, allPages) => (allPages.length < lastPage.total_pages_count ? allPages.length + 1 : undefined),
    }
  );
};

export const useInfiniteQueryOrders = (startDate, endDate) => {
  const queryClient = useQueryClient();
  return useInfiniteQuery(
    ['orders', startDate, endDate],
    fetchOrderIds(queryClient),
    {
      enabled: !!startDate && !!endDate,
      staleTime: Infinity,
      getNextPageParam: (lastPage, allPages) => (allPages.length < lastPage.total_pages_count ? allPages.length + 1 : undefined),
    }
  );
};
