import { Box, Tabs, Tab, Tooltip, TextField, Stack, Paper, LinearProgress } from '@mui/material';
import { DataGridPremium, GridColDef, jaJP } from '@mui/x-data-grid-premium';
import { DatePicker } from '@mui/x-date-pickers';
import { addDays, differenceInDays } from 'date-fns';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryKpiDetails } from 'src/hooks/useQueryKpiDetails';
import { datetimeUtil } from 'src/utils/datetime.util';

import LicenseContext from '../../contexts/LicenseContext';

import { a11yProps, CustomTabPanel } from './CustomTabPanel';

const DetailSummaries: React.FC = memo(() => {
  const licenseContext = useContext(LicenseContext);

  const [startDate, setStartDate] = useState(addDays(new Date(), -30));
  const [minDate, setMinDate] = useState(addDays(new Date(), -30));
  const [maxDate, setMaxDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);

  const kpiDetails = useQueryKpiDetails(startDate, endDate, false);
  const kpiDetailsWithCo2Emissions = useQueryKpiDetails(startDate, endDate, true);

  useEffect(() => {
    const d = kpiDetails.data?.minDate || new Date();
    setMinDate(d);
  }, [kpiDetails.data?.minDate]);

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  }, []);

  const handleStartDateChange = useCallback((date: Date) => {
    if (datetimeUtil.invalidDate(date)) return;
    setStartDate(date);
    const diffDays = differenceInDays(endDate, date);
    if (diffDays < 0 || diffDays > 31) {
      setEndDate(addDays(date, 31));
    }
  }, [endDate]);

  const handleEndDateChange = useCallback((date: Date) => {
    if (datetimeUtil.invalidDate(date)) return;
    setEndDate(date);
    const diffDays = differenceInDays(date, startDate);
    if (diffDays < 0 || diffDays > 31) {
      setStartDate(addDays(date, -31));
    }
  }, [startDate]);

  const CommonColumns: GridColDef[] = useMemo(() => (
    [
      { field: 'sales', headerName: '売上', flex: 1, type: 'number', },
      { field: 'cost', headerName: '原価', flex: 1, type: 'number', },
      { field: 'grossProfit', headerName: '粗利益', flex: 1, type: 'number', },
      {
        field: 'grossProfitMargin',
        headerName: '粗利益率',
        flex: 1,
        type: 'number',
        valueFormatter: ({ value }: { value: number }) => `${value} %`,
      },
      { field: 'orderCount', headerName: '案件数', flex: 1, type: 'number', },
      {
        field: 'co2emissions',
        headerName: 'CO2排出量',
        flex: 1,
        type: 'number',
        valueFormatter: ({ value }: { value: number }) => {
          if (licenseContext?.config?.use_algorithm_planning) {
            return value > 0 ? `${value} t-CO2` : '-';
          }

          return '自動配車プランのみ';
        },
      },
      {
        field: 'distanceKm',
        headerName: '距離',
        flex: 1,
        type: 'number',
        valueFormatter: ({ value }: { value: number }) => {
          if (licenseContext?.config?.use_algorithm_planning) {
            return value > 0 ? `${value} km` : '-';
          }

          return '自動配車プランのみ';
        },
      },
      {
        field: 'loadingWeightRate',
        headerName: '重量積載率',
        flex: 1,
        type: 'number',
        valueFormatter: ({ value }: { value: number }) => (value > 0 ? `${value} %` : '-')
      },
      {
        field: 'loadingVolumeRate',
        headerName: '体積積載率',
        flex: 1,
        type: 'number',
        valueFormatter: ({ value }: { value: number }) => (value > 0 ? `${value} %` : '-')
      },
      { field: 'sumItemCount', headerName: '数量', flex: 1, type: 'number', },
      {
        field: 'sumItemWeightKg',
        headerName: '重量',
        flex: 1,
        type: 'number',
        valueFormatter: ({ value }: { value: number }) => (value > 0 ? `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} kg` : '-')
      },
      {
        field: 'sumItemVolumeM3',
        headerName: '体積',
        flex: 1,
        type: 'number',
        valueFormatter: ({ value }: { value: number }) => (value > 0 ? `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} m3` : '-')
      },
    ]
  ), [licenseContext?.config]);

  const DriverColumns: GridColDef[] = useMemo(() => (
    [
      ...[{ field: 'name', headerName: '氏名', flex: 2, type: 'string', }],
      ...CommonColumns
    ]
  ), [CommonColumns]);

  const ShipperColumns: GridColDef[] = useMemo(() => (
    [
      ...[{ field: 'name', headerName: '荷主名', flex: 2, type: 'string', }],
      ...CommonColumns,
    ]
  ), [CommonColumns]);

  const TruckColumns: GridColDef[] = useMemo(() => (
    [
      ...[{ field: 'name', headerName: '車番', flex: 2, type: 'string', }],
      ...CommonColumns,
    ]
  ), [CommonColumns]);

  const driverRows = useMemo(() => (
    kpiDetailsWithCo2Emissions?.data?.drivers || kpiDetails.data?.drivers || []
  ), [kpiDetails.data?.drivers, kpiDetailsWithCo2Emissions?.data?.drivers]);

  const shipperRows = useMemo(() => (
    kpiDetailsWithCo2Emissions.data?.shippers || kpiDetails.data?.shippers || []
  ), [kpiDetails.data?.shippers, kpiDetailsWithCo2Emissions.data?.shippers]);

  const truckRows = useMemo(() => (
    kpiDetailsWithCo2Emissions.data?.trucks || kpiDetails.data?.trucks || []
  ), [kpiDetails.data?.trucks, kpiDetailsWithCo2Emissions.data?.trucks]);

  useEffect(() => {
    setLoading(kpiDetails.isLoading || kpiDetailsWithCo2Emissions.isLoading);
  }, [kpiDetails.isLoading, kpiDetailsWithCo2Emissions.isLoading]);

  const tabSelectBoxMemo = useMemo(() => (
    <Paper>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="ドライバー" {...a11yProps(0)} />
          <Tab label="荷主" {...a11yProps(1)} />
          <Tab label="車両" {...a11yProps(2)} />
        </Tabs>
      </Box>
    </Paper>
  ), [handleTabChange, selectedTab]);

  const startDatePickerMemo = useMemo(() => (
    <DatePicker
      onChange={(date: Date) => handleStartDateChange(date)}
      value={startDate}
      inputFormat="yyyy/MM/dd"
      minDate={minDate}
      maxDate={maxDate}
      label="集計開始日"
      renderInput={(params) => (
        <Tooltip
          title="集計開始日を変更する"
          arrow
        >
          <TextField
            {...params}
            size="small"
            sx={{
              width: 155
            }}
          />
        </Tooltip>
      )}
    />
  ), [handleStartDateChange, maxDate, minDate, startDate]);

  const endDatePickerMemo = useMemo(() => (
    <DatePicker
      onChange={(date: Date) => handleEndDateChange(date)}
      value={endDate}
      inputFormat="yyyy/MM/dd"
      minDate={minDate}
      maxDate={maxDate}
      label="集計終了日"
      renderInput={(params) => (
        <Tooltip
          title="集計終了日を変更する"
          arrow
        >
          <TextField
            {...params}
            size="small"
            sx={{
              width: 155
            }}
          />
        </Tooltip>
      )}
    />
  ), [endDate, handleEndDateChange, maxDate, minDate]);

  const dateSelectBoxMemo = useMemo(() => (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      {startDatePickerMemo}
      {endDatePickerMemo}
    </Box>
  ), [endDatePickerMemo, startDatePickerMemo]);

  const tabHeaderMemo = useMemo(() => (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={0}
    >
      {tabSelectBoxMemo}
      {dateSelectBoxMemo}
    </Stack>
  ), [tabSelectBoxMemo, dateSelectBoxMemo]);

  const tabPanelMemo = useMemo(() => (
    <Paper sx={{ marginTop: 1, height: '50vh' }}>
      <CustomTabPanel value={selectedTab} index={0}>
        <DataGridPremium
          rows={driverRows}
          columns={DriverColumns}
          pagination
          pageSize={20}
          disableRowGrouping
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          loading={loading}
          components={{
            LoadingOverlay: LinearProgress,
          }}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <DataGridPremium
          rows={shipperRows}
          columns={ShipperColumns}
          pagination
          pageSize={20}
          disableRowGrouping
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          loading={loading}
          components={{
            LoadingOverlay: LinearProgress,
          }}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={2}>
        <DataGridPremium
          rows={truckRows}
          columns={TruckColumns}
          pagination
          pageSize={20}
          disableRowGrouping
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          loading={loading}
          components={{
            LoadingOverlay: LinearProgress,
          }}
        />
      </CustomTabPanel>
    </Paper>
  ), [DriverColumns, ShipperColumns, TruckColumns, driverRows, loading, selectedTab, shipperRows, truckRows]);

  return (
    <Box sx={{ width: '100%' }}>
      {tabHeaderMemo}
      {tabPanelMemo}
    </Box>
  );
});

export default DetailSummaries;
