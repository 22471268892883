import {
  Box, Stack
} from '@mui/material';
import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';

import DetailSummaries from './DetailSummaries';
import MonthlySummaries from './MonthlySummaries';

const KpiDashboardPresenter: FC = memo(() => (
  <>
    <Helmet>
      <title>実績参照</title>
    </Helmet>
    <Box p={2}>
      <Stack sx={{ marginTop: 1 }}>
        <MonthlySummaries />
      </Stack>
      <Stack sx={{ marginTop: 2 }}>
        <DetailSummaries />
      </Stack>
    </Box>
  </>
));

export default KpiDashboardPresenter;
