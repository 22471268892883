import { EstimationEntity } from 'src/entities/Estimation.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';

import convertToEntity from './convertToEntity';

type Args = {
  planningsDeliveryEntities: PlanningsDeliveryEntity[],
  planningsDriverEntities: PlanningsDriverEntity[],
  planningsTruckEntities: PlanningsTruckEntity[],
  defaultDriverCostYenPerSeconds: number,
  defaultTruckFuelCostYenPerMm: number,
  defaultTruckInsuranceFeeYenPerDay: number,
  defaultTruckRepairFeeYenPerDay: number,
  defaultTruckExpresswayFeeYenPerDay: number,
  expectedProfitRate: number,
  expectedFeeYenByKm: number,
  expectedFeeYenByHour: number,
  expectedFeeYenByItemCount: number,
  expectedFeeYenByItemWeightKg: number,
  expectedFeeYenByItemVolumeM3: number,
}

const convertToEntities: (
  args: Args
) => EstimationEntity[] | undefined = (
  args
) => {
  const {
    planningsDeliveryEntities,
    planningsDriverEntities,
    planningsTruckEntities,
    defaultDriverCostYenPerSeconds,
    defaultTruckFuelCostYenPerMm,
    defaultTruckInsuranceFeeYenPerDay,
    defaultTruckRepairFeeYenPerDay,
    defaultTruckExpresswayFeeYenPerDay,
    expectedProfitRate,
    expectedFeeYenByKm,
    expectedFeeYenByHour,
    expectedFeeYenByItemCount,
    expectedFeeYenByItemWeightKg,
    expectedFeeYenByItemVolumeM3,
  } = args;

  return planningsDeliveryEntities
    .flatMap((planningsDeliveryEntity: PlanningsDeliveryEntity) => planningsDeliveryEntity
      .distanceMmBurdenPerShippers.map((it) => ({
        planningsDeliveryEntity,
        shipperName: it.shipperName,
        distanceMmByShipper: it.distanceMm,
        orderId: it.orderId
      })))
    .map((it) => {
      const { shipperName, distanceMmByShipper, orderId, planningsDeliveryEntity } = it;
      const planningsDriverEntity = planningsDriverEntities.find((e) => e.id === planningsDeliveryEntity.driverId);
      const planningsTruckEntity = planningsTruckEntities.find((e) => e.id === planningsDeliveryEntity.truckId);

      return convertToEntity(
        {
          shipperName,
          distanceMmByShipper,
          orderId,
          planningsDeliveryEntity,
          planningsDriverEntity,
          planningsTruckEntity,
          defaultDriverCostYenPerSeconds,
          defaultTruckFuelCostYenPerMm,
          defaultTruckInsuranceFeeYenPerDay,
          defaultTruckRepairFeeYenPerDay,
          defaultTruckExpresswayFeeYenPerDay,
          expectedProfitRate,
          expectedFeeYenByKm,
          expectedFeeYenByHour,
          expectedFeeYenByItemCount,
          expectedFeeYenByItemWeightKg,
          expectedFeeYenByItemVolumeM3,
        }
      );
    });
};

export default convertToEntities;
