import Leaflet from 'leaflet';
import { FC, memo, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Marker, Tooltip } from 'react-leaflet';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import {
  PlanningMapUnallocatedOrderPositionEntity
} from 'src/entities/import/PlanningMapUnallocatedOrderPosition.entity';
import { OrderEntity } from 'src/entities/orderEntity';

import PlanningsMapOrderMarkerTooltipContentsPresenter from './PlanningsMapOrderMarkerTooltipContents.presenter';

type Props = {
  entity: PlanningMapUnallocatedOrderPositionEntity;
  selectedIds: number[];
  addSelectedId: (id: number) => void;
  removeSelectedId: (id: number) => void;
  orderEntityMap: Map<number, OrderEntity>;
}

const PlanningMapOrderMarkerPresenter: FC<Props> = memo((
  {
    entity,
    selectedIds,
    addSelectedId,
    removeSelectedId,
    orderEntityMap,
  }
) => {
  const icon = new Leaflet.Icon({
    iconUrl: '/static/images/markers/red.png',
    iconSize: [24, 42],
    iconAnchor: [12, 42],
    tooltipAnchor: [-12, -42],
  });

  const [isSelected, setIsSelected] = useState(false);
  const [opacity, setOpacity] = useState<number>(0.5);

  useEffect(() => {
    if (!entity) return;
    if (!selectedIds) return;

    setIsSelected(
      entity.ids.some((id) => selectedIds.includes(id))
    );
  }, [selectedIds, entity]);

  useEffect(() => {
    if (isSelected) {
      setOpacity(1);
    } else {
      setOpacity(0.5);
    }
  }, [isSelected]);

  const gaEvent = (onOff: 'ON' | 'OFF') => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_MAP, button_name: `案件ピン ${onOff}`, label: entity.ids });
  };

  const onClick = () => {
    gaEvent('ON');
    entity.ids.map((id) => {
      addSelectedId(id);
      return id;
    });
  };

  const onContextmenu = () => {
    gaEvent('OFF');
    entity.ids.map((id) => {
      removeSelectedId(id);
      return id;
    });
  };

  const eventHandlers = {
    click: () => {
      onClick();
    },
    contextmenu: () => {
      onContextmenu();
    }
  };

  return (
    <Marker
      position={entity.position}
      icon={icon}
      opacity={opacity}
      eventHandlers={eventHandlers}
    >
      <Tooltip
        offset={
          [24, 21]
        }
      >
        <PlanningsMapOrderMarkerTooltipContentsPresenter
          orderIds={entity.ids}
          position={entity.position}
          orderEntityMap={orderEntityMap}
        />
      </Tooltip>
    </Marker>
  );
});

export default PlanningMapOrderMarkerPresenter;
