import axios, { AxiosResponse } from 'axios';
import PropTypes from 'prop-types';
import {
  createContext,
  Context,
  FC,
  ReactNode,
  memo,
  useReducer,
  useEffect,
  useMemo
} from 'react';
import { AdminEntity, AdminRequestEntity } from 'src/entities/admin/adminEntity';

type ContextEntity = {
  initialized: boolean;
  authenticated: boolean;
  loading: boolean;
  entity?: AdminEntity;
};

type Initialize = {
  type: 'INITIALIZE';
  authenticated: boolean;
};

type Login = {
  type: 'LOGIN';
  value: AdminEntity;
};

type Logout = {
  type: 'LOGOUT';
};

type Loading = {
  type: 'LOADING';
};

type Loaded = {
  type: 'LOADED';
};

type SetEntity = {
  type: 'SET_ENTITY',
  value: AdminEntity;
}

type Action = Initialize | Login | Logout | Loading | Loaded | SetEntity;

type ContextValueEntity = ContextEntity & {
  login: (payload: AdminRequestEntity) => Promise<void>;
  logout: () => Promise<void>;
};

const initialState: ContextEntity = {
  initialized: false,
  authenticated: false,
  loading: false,
};

const handlers: Record<
  string,
  (state: ContextEntity, action: Action) => ContextEntity
> = {
  INITIALIZE: (
    state: ContextEntity,
    { authenticated }: Initialize
  ): ContextEntity => ({
    ...state,
    authenticated,
    initialized: true,
    loading: false
  }),
  SET_ENTITY: (state: ContextEntity, { value }: SetEntity): ContextEntity => ({
    ...state,
    entity: value
  }),
  LOGIN: (state: ContextEntity, { value }: Login): ContextEntity => ({
    ...state,
    authenticated: true,
    loading: false,
    entity: value
  }),
  LOGOUT: (state: ContextEntity): ContextEntity => ({
    ...state,
    authenticated: false,
    loading: false,
    entity: undefined
  }),
  LOADING: (state: ContextEntity): ContextEntity => ({
    ...state,
    loading: true
  }),
  LOADED: (state: ContextEntity): ContextEntity => ({
    ...state,
    loading: false
  })
};

const reducer = (state: ContextEntity, action: Action): ContextEntity => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AdminContext: Context<ContextValueEntity> = createContext<ContextValueEntity>({
    ...initialState,
    login: (_payload: AdminRequestEntity) => Promise.resolve(),
    logout: () => Promise.resolve()
});

export const AdminProvider: FC<{ children: ReactNode }> = memo((props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const requestPath = '/api/admin/v1/session';

  useEffect(() => {
    axios
      .get(requestPath)
      .then((response: AxiosResponse<AdminEntity>) => {
        dispatch({
          type: 'INITIALIZE',
          authenticated: true
        });
        dispatch({
          type: 'SET_ENTITY',
          value: response.data
        });
      })
      .catch((e) => {
        if (axios.isAxiosError(e) && e.response && e.response.status < 500) {
          dispatch({
            type: 'INITIALIZE',
            authenticated: false
          });
        } else {
          throw e;
        }
      });
  }, [window.location.pathname]);

  const login = async ({
    email,
    password
  }: AdminRequestEntity): Promise<void> => {
    dispatch({
      type: 'LOADING'
    });

    try {
      const response: AxiosResponse<AdminEntity> = await axios.post(requestPath, {
        email,
        password
      });
      dispatch({
        type: 'LOGIN',
        value: response.data
      });
    } catch (e) {
      if (axios.isAxiosError(e) && e.response && e.response.status < 500) {
        dispatch({
          type: 'LOADED'
        });
      }
      throw e;
    }
  };

  const logout = async (): Promise<void> => {
    dispatch({
      type: 'LOADING'
    });
    try {
      await axios.delete(requestPath);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response && e.response.status < 500) {
        dispatch({
          type: 'LOADED'
        });
      }
      throw e;
    }
  };

  const value = useMemo(
    () => ({
      ...state,
      login,
      logout
    }),
    [state]
  );

  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
});

AdminProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AdminContext;
