import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, Stack, TextField } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ConfirmDialog } from 'src/components/ConfirmDialog';
import { fiveZeroZeroErrorMessage } from 'src/constants/messages';
import { CompanyConnectionRequestEntity } from 'src/entities/CompanyConnectionEntity';
import { useMutationCompanyConnection } from 'src/hooks/useQueryComapnyConnections';
import * as yup from 'yup';

const schema = yup.object({
  email: yup
    .string()
    .email('正しい形式で入力してください')
    .required('メールアドレスを入力してください')
});

interface Props {
  dialogIsOpen: boolean;
  setDialogIsOpen: (isOpen: boolean) => void;
}

export const ConnectDialog: FC<Props> = memo((props: Props) => {
  const { dialogIsOpen, setDialogIsOpen } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { addConnection } = useMutationCompanyConnection();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState<boolean>(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState<string>('');
  const [requestData, setRequestData] = useState<CompanyConnectionRequestEntity>(null);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<(CompanyConnectionRequestEntity)>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    reset();
    setErrorMessage('');
  }, [dialogIsOpen, reset]);

  const confirmDialogHandleOk = useCallback(() => {
    addConnection.mutate(
      requestData,
      {
        onSuccess: () => {
          enqueueSnackbar('連携依頼メールを送信しました。');
          setDialogIsOpen(false);
        },
        onError: (error: unknown) => {
          if (axios.isAxiosError(error)) {
            const axiosError = error as unknown as AxiosError<{ message: string }>;
            setErrorMessage(axiosError.response.data.message || fiveZeroZeroErrorMessage);
          } else {
            throw error;
          }
        },
        onSettled: () => {
          setConfirmDialogIsOpen(false);
        }
      }
    );
  }, [addConnection, enqueueSnackbar, requestData, setDialogIsOpen]);

  const confirmDialogHandleCancel = useCallback(() => {
    setConfirmDialogIsOpen(false);
  }, []);

  const onSubmitConnect: SubmitHandler<CompanyConnectionRequestEntity> = (
    data
  ) => {
    setRequestData(data);
    setConfirmDialogIsOpen(true);
    setConfirmDialogMessage('連携依頼メールを送信します。よろしいですか？');
  };

  const confirmDialogMmoe = useMemo(() => (
    <ConfirmDialog
      open={confirmDialogIsOpen}
      message={confirmDialogMessage}
      handleOk={confirmDialogHandleOk}
      handleCancel={confirmDialogHandleCancel}
    />
  ), [confirmDialogHandleCancel, confirmDialogHandleOk, confirmDialogIsOpen, confirmDialogMessage]);

  return (
    <>
      <Dialog open={dialogIsOpen} maxWidth="md" fullWidth onClose={() => setDialogIsOpen(false)}>
        <DialogTitle>
          事業所連携
        </DialogTitle>
        <DialogContent>
          <Stack component="form" noValidate spacing={2}>
            <DialogContentText>
              連携する依頼する事業所の責任者のメールアドレスを入力してください。
            </DialogContentText>
            <FormControl error={!!errorMessage}>
              <TextField
                required
                margin="dense"
                id="email"
                label="メールアドレス"
                type="text"
                fullWidth
                variant="standard"
                {...register('email')}
                error={('email' in errors) || !!errorMessage}
                helperText={errors.email?.message}
                onChange={() => setErrorMessage('')}
              />
              <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogIsOpen(false)}>キャンセル</Button>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Button onClick={handleSubmit(onSubmitConnect)} variant="contained">
            メールを送る
          </Button>
        </DialogActions>
      </Dialog>
      {confirmDialogMmoe}
    </>
  );
});
