import { differenceInMinutes, max, subSeconds } from 'date-fns';
import { OrderEntity } from 'src/entities/orderEntity';

import { OrderOperationEntity } from '../entities/orderOperationEntity';
import { PositionEntity } from '../entities/PositionEntity';

export class OrderFactory {
  entity: OrderEntity;

  constructor(entity: OrderEntity) {
    this.entity = entity;
  }

  code(): string | undefined {
    if (!this.entity.code) return undefined;

    return this.entity.code;
  }

  shipperName(): string | undefined {
    if (!this.entity.shipper_name) return undefined;

    return this.entity.shipper_name;
  }

  shipperEmailAddress(): string | undefined {
    if (!this.entity.shipper_email_address) return undefined;

    return this.entity.shipper_email_address;
  }

  shipperPhoneNumber(): string | undefined {
    if (!this.entity.shipper_phone_number) return undefined;

    return this.entity.shipper_phone_number;
  }

  driverName(): string | undefined {
    if (!this.entity.driver_name) return undefined;

    return this.entity.driver_name;
  }

  loadingOperation(): OrderOperationEntity | undefined {
    const maybe = this.entity.order_operations.find((operation) => operation.action === '積');

    if (!maybe) return undefined;

    return maybe;
  }

  loadingArrivalAt(): Date | undefined {
    const operation = this.loadingOperation();

    if (!operation) return undefined;

    return new Date(operation.arrival_at);
  }

  loadingName(): string {
    return this.entity.loading_name;
  }

  loadingAddress(): string {
    return this.entity.loading_address;
  }

  orderLoadingSpecifiedDatetimesAttributes(): {
    start_at: string;
    end_at: string;
  }[] | undefined {
    const orderLoadingSpecifiedDatetimesAttributes = this.entity.order_loading_specified_datetimes_attributes;

    if (!orderLoadingSpecifiedDatetimesAttributes) return undefined;

    return orderLoadingSpecifiedDatetimesAttributes;
  }

  loadingStartAtOne(): Date | undefined {
    const idx = 0;

    if (!this.orderLoadingSpecifiedDatetimesAttributes()) return undefined;

    const maybe = this.orderLoadingSpecifiedDatetimesAttributes()[idx];

    if (!maybe) return undefined;

    return new Date(maybe.start_at);
  }

  loadingEndAtOne(): Date | undefined {
    const idx = 0;

    if (!this.orderLoadingSpecifiedDatetimesAttributes()) return undefined;

    const maybe = this.orderLoadingSpecifiedDatetimesAttributes()[idx];

    if (!maybe) return undefined;

    return new Date(maybe.end_at);
  }

  loadingStartAtTwo(): Date | undefined {
    const idx = 1;

    if (!this.orderLoadingSpecifiedDatetimesAttributes()) return undefined;

    const maybe = this.orderLoadingSpecifiedDatetimesAttributes()[idx];

    if (!maybe) return undefined;

    return new Date(maybe.start_at);
  }

  loadingEndAtTwo(): Date {
    const idx = 1;

    if (!this.orderLoadingSpecifiedDatetimesAttributes()) return undefined;

    const maybe = this.orderLoadingSpecifiedDatetimesAttributes()[idx];

    if (!maybe) return undefined;

    return new Date(maybe.end_at);
  }

  loadingStartAtThree(): Date | undefined {
    const idx = 2;

    if (!this.orderLoadingSpecifiedDatetimesAttributes()) return undefined;

    const maybe = this.orderLoadingSpecifiedDatetimesAttributes()[idx];

    if (!maybe) return undefined;

    return new Date(maybe.start_at);
  }

  loadingEndAtThree(): Date {
    const idx = 2;

    if (!this.orderLoadingSpecifiedDatetimesAttributes()) return undefined;

    const maybe = this.orderLoadingSpecifiedDatetimesAttributes()[idx];

    if (!maybe) return undefined;

    return new Date(maybe.end_at);
  }

  loadingStayingSeconds(): number | undefined {
    if (this.entity.loading_staying_seconds) return undefined;

    return this.entity.loading_staying_seconds;
  }

  loadingStayingMinutes(): number | undefined {
    if (!this.loadingStayingSeconds()) return undefined;

    return this.loadingStayingSeconds() / 60;
  }

  loadingRequiredArrivalAt(): Date {
    const dates: Date[] = this.entity.order_loading_specified_datetimes_attributes.map((specifiedDatetime) => new Date(specifiedDatetime.end_at));
    const latest = max(dates);

    return subSeconds(latest, this.loadingStayingSeconds() || 0);
  }

  minutesDifferenceBetweenLoadingRequiredArrivalAtAndArrivalAt(): number | undefined {
    if (!this.loadingOperation()) return undefined;

    return differenceInMinutes(this.loadingRequiredArrivalAt(), this.loadingArrivalAt());
  }

  loadingWaitingDurationMinutes(): number | undefined {
    if (!this.loadingOperation()) return undefined;

    return this.loadingOperation().waiting_duration_seconds / 60;
  }

  loadingOperationStartAt(): Date | undefined {
    if (!this.loadingOperation()) return undefined;

    return new Date(this.loadingOperation().operation_start_at);
  }

  loadingOperationDurationMinutes(): number | undefined {
    if (!this.loadingOperation()) return undefined;

    return this.loadingOperation().operation_duration_seconds / 60;
  }

  loadingDepartureAt(): Date | undefined {
    if (!this.loadingOperation()) return undefined;

    return new Date(this.loadingOperation().departure_at);
  }

  unloadingOperation(): OrderOperationEntity | undefined {
    const maybe = this.entity.order_operations.find((operation) => operation.action === '降');

    if (!maybe) return undefined;

    return maybe;
  }

  unloadingArrivalAt(): Date | undefined {
    const operation = this.unloadingOperation();

    if (!operation) return undefined;

    return new Date(operation.arrival_at);
  }

  unloadingName(): string {
    return this.entity.unloading_name;
  }

  unloadingAddress(): string {
    return this.entity.unloading_address;
  }

  orderUnloadingSpecifiedDatetimesAttributes(): {
    start_at: string;
    end_at: string;
  }[] | undefined {
    const orderUnloadingSpecifiedDatetimesAttributes = this.entity.order_unloading_specified_datetimes_attributes;

    if (!orderUnloadingSpecifiedDatetimesAttributes) return undefined;

    return orderUnloadingSpecifiedDatetimesAttributes;
  }

  unloadingStartAtOne(): Date | undefined {
    const idx = 0;

    if (!this.orderUnloadingSpecifiedDatetimesAttributes()) return undefined;

    const maybe = this.orderUnloadingSpecifiedDatetimesAttributes()[idx];

    if (!maybe) return undefined;

    return new Date(maybe.start_at);
  }

  unloadingEndAtOne(): Date | undefined {
    const idx = 0;

    if (!this.orderUnloadingSpecifiedDatetimesAttributes()) return undefined;

    const maybe = this.orderUnloadingSpecifiedDatetimesAttributes()[idx];

    if (!maybe) return undefined;

    return new Date(maybe.end_at);
  }

  unloadingStartAtTwo(): Date | undefined {
    const idx = 1;

    if (!this.orderUnloadingSpecifiedDatetimesAttributes()) return undefined;

    const maybe = this.orderUnloadingSpecifiedDatetimesAttributes()[idx];

    if (!maybe) return undefined;

    return new Date(maybe.start_at);
  }

  unloadingEndAtTwo(): Date {
    const idx = 1;

    if (!this.orderUnloadingSpecifiedDatetimesAttributes()) return undefined;

    const maybe = this.orderUnloadingSpecifiedDatetimesAttributes()[idx];

    if (!maybe) return undefined;

    return new Date(maybe.end_at);
  }

  unloadingStartAtThree(): Date | undefined {
    const idx = 2;

    if (!this.orderUnloadingSpecifiedDatetimesAttributes()) return undefined;

    const maybe = this.orderUnloadingSpecifiedDatetimesAttributes()[idx];

    if (!maybe) return undefined;

    return new Date(maybe.start_at);
  }

  unloadingEndAtThree(): Date {
    const idx = 2;

    if (!this.orderUnloadingSpecifiedDatetimesAttributes()) return undefined;

    const maybe = this.orderUnloadingSpecifiedDatetimesAttributes()[idx];

    if (!maybe) return undefined;

    return new Date(maybe.end_at);
  }

  unloadingStayingSeconds(): number | undefined {
    if (this.entity.unloading_staying_seconds) return undefined;

    return this.entity.unloading_staying_seconds;
  }

  unloadingStayingMinutes(): number | undefined {
    if (!this.unloadingStayingSeconds()) return undefined;

    return this.unloadingStayingSeconds() / 60;
  }

  unloadingRequiredArrivalAt(): Date {
    const dates: Date[] = this.entity.order_unloading_specified_datetimes_attributes.map((specifiedDatetime) => new Date(specifiedDatetime.end_at));
    const latest = max(dates);

    return subSeconds(latest, this.unloadingStayingSeconds() || 0);
  }

  minutesDifferenceBetweenUnloadingRequiredArrivalAtAndArrivalAt(): number | undefined {
    if (!this.unloadingOperation()) return undefined;

    return differenceInMinutes(this.unloadingRequiredArrivalAt(), this.unloadingArrivalAt());
  }

  unloadingWaitingDurationMinutes(): number | undefined {
    if (!this.unloadingOperation()) return undefined;

    return this.unloadingOperation().waiting_duration_seconds / 60;
  }

  unloadingOperationStartAt(): Date | undefined {
    if (!this.unloadingOperation()) return undefined;

    return new Date(this.unloadingOperation().operation_start_at);
  }

  unloadingOperationDurationMinutes(): number | undefined {
    if (!this.unloadingOperation()) return undefined;

    return this.unloadingOperation().operation_duration_seconds / 60;
  }

  unloadingDepartureAt(): Date | undefined {
    if (!this.unloadingOperation()) return undefined;

    return new Date(this.unloadingOperation().departure_at);
  }

  designatedTruckCarModels(): string[] | undefined {
    if (!this.entity.designated_truck_car_models.length) return undefined;

    return this.entity.designated_truck_car_models;
  }

  designatedTruckFeatures(): string[] | undefined {
    if (!this.entity.designated_truck_features.length) return undefined;

    return this.entity.designated_truck_features;
  }

  designatedTruckFloorSpecifications(): string[] | undefined {
    if (!this.entity.designated_truck_floor_specifications.length) return undefined;

    return this.entity.designated_truck_floor_specifications;
  }

  designatedTruckLoadingPlatformHeights(): string[] | undefined {
    if (!this.entity.designated_truck_floor_specifications.length) return undefined;

    return this.entity.designated_truck_floor_specifications;
  }

  designatedTruckLoadingPlatformLengths(): string[] | undefined {
    if (!this.entity.designated_truck_loading_platform_lengths.length) return undefined;

    return this.entity.designated_truck_loading_platform_lengths;
  }

  designatedTruckLoadingPlatformWidths(): string[] | undefined {
    if (!this.entity.designated_truck_loading_platform_widths.length) return undefined;

    return this.entity.designated_truck_loading_platform_widths;
  }

  designatedTruckKlasses(): string[] | undefined {
    if (!this.entity.designated_truck_klasses.length) return undefined;

    return this.entity.designated_truck_klasses;
  }

  itemCount(): number {
    return this.entity.item_count;
  }

  itemTotalWeightKg(): number {
    return this.entity.item_total_weight_kg;
  }

  itemTotalVolumeM3(): number | undefined {
    if (!this.entity.item_total_volume_m3) return undefined;

    return this.entity.item_total_volume_m3;
  }

  itemKlass(): string | undefined {
    if (!this.entity.item_klass) return undefined;

    return this.entity.item_klass;
  }

  itemName(): string | undefined {
    if (!this.entity.item_name) return undefined;

    return this.entity.item_name;
  }

  itemPackingStyle(): string | undefined {
    if (!this.entity.item_packing_style) return undefined;

    return this.entity.item_packing_style;
  }

  itemCanBeMixed(): boolean {
    return !!this.entity.item_can_be_mixed;
  }

  itemHandlingOfCargoStyle(): string | undefined {
    if (!this.entity.item_handling_of_cargo_style) return undefined;

    return this.entity.item_handling_of_cargo_style;
  }

  chargeBasicFeeYen(): number | undefined {
    if (!this.entity.charge_basic_fee_yen) return undefined;

    return this.entity.charge_basic_fee_yen;
  }

  chargeLoadingFeeYen(): number | undefined {
    if (!this.entity.charge_loading_fee_yen) return undefined;

    return this.entity.charge_loading_fee_yen;
  }

  chargeHighwayFeeYen(): number | undefined {
    if (!this.entity.charge_highway_fee_yen) return undefined;

    return this.entity.charge_highway_fee_yen;
  }

  chargeWaitingTimeFeeYen(): number | undefined {
    if (!this.entity.charge_waiting_time_fee_yen) return undefined;

    return this.entity.charge_waiting_time_fee_yen;
  }

  chargeUnloadingFeeYen(): number | undefined {
    if (!this.entity.charge_unloading_fee_yen) return undefined;

    return this.entity.charge_unloading_fee_yen;
  }

  chargeExpensesFeeYen(): number | undefined {
    if (!this.entity.charge_expenses_fee_yen) return undefined;

    return this.entity.charge_expenses_fee_yen;
  }

  chargeAncillaryFeeYen(): number | undefined {
    if (!this.entity.charge_ancillary_fee_yen) return undefined;

    return this.entity.charge_ancillary_fee_yen;
  }

  totalFeeYen(): number | undefined {
    const fees = [
      this.chargeBasicFeeYen(),
      this.chargeLoadingFeeYen(),
      this.chargeHighwayFeeYen(),
      this.chargeWaitingTimeFeeYen(),
      this.chargeUnloadingFeeYen(),
      this.chargeExpensesFeeYen(),
      this.chargeAncillaryFeeYen(),
    ].filter((maybe) => maybe);

    if (!fees.length) return undefined;

    return fees.reduce((prev, current) => prev + current, 0);
  }

  chargeAncillaryContent(): string | undefined {
    if (!this.entity.charge_ancillary_content) return undefined;

    return this.entity.charge_ancillary_content;
  }

  memo(): string | undefined {
    if (!this.entity.memo) return undefined;

    return this.entity.memo;
  }

  loadingPositions(): [number, number] {
    return [this.entity.loading_lat, this.entity.loading_lng];
  }

  unloadingPositions(): [number, number] {
    return [this.entity.unloading_lat, this.entity.unloading_lng];
  }

  positions(): [number, number][] {
    return [
      this.loadingPositions(),
      this.unloadingPositions()
    ];
  }

  allocated(): boolean {
    return !!this.entity.order_operations?.length;
  }

  searchKw(): string {
    return [
      this.entity.shipper_name,
      this.entity.shipper_email_address,
      this.entity.shipper_phone_number,
      this.entity.code,
      this.entity.memo,
      this.entity.designated_truck_car_models.join(' '),
      this.entity.designated_truck_features.join(' '),
      this.entity.designated_truck_floor_specifications.join(' '),
      this.entity.designated_truck_loading_platform_heights.join(' '),
      this.entity.designated_truck_loading_platform_lengths.join(' '),
      this.entity.designated_truck_loading_platform_widths.join(' '),
      this.entity.designated_truck_klasses.join(' '),
      this.entity.item_handling_of_cargo_style,
      this.entity.item_klass,
      this.entity.item_name,
      this.entity.item_packing_style,
      this.entity.loading_name,
      this.entity.loading_address,
      this.entity.unloading_name,
      this.entity.unloading_address,
    ].filter((maybe) => !!maybe).join(' ');
  }

  polyline(): [PositionEntity, PositionEntity] {
    return [
      [this.entity.loading_lat, this.entity.loading_lng],
      [this.entity.unloading_lat, this.entity.unloading_lng],
    ];
  }
}
