import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';

const requestPath = (startOn: string, endOn: string) => `/api/v3/plannings/${startOn}/trucks?end_on=${endOn}`;

const getAll = (startOn: string, endOn: string) => async () => {
  const response: AxiosResponse<PlanningsTruckEntity[]> = await axios.get(requestPath(startOn, endOn));

  return response.data;
};

export const useQueryPlanningsTrucks = (startOn: string, endOn: string) => useQuery(
  ['useQueryPlanningsTrucks', { startOn, endOn }],
  getAll(startOn, endOn),
  {
    staleTime: Infinity,
    enabled: Boolean(startOn) && Boolean(endOn)
  }
);
