import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { AsyncJobEntity } from 'src/entities/AsyncJob.entity';

const getAll = () => async () => {
  const requestPath = '/api/v3/async_jobs';
  const axiosResponse = await axios.get<AsyncJobEntity[]>(requestPath);

  return axiosResponse.data;
};

const refreshSeconds = 1000 * 3;// 3秒

export const useAsyncJobsRequest = (authenticated: boolean, enabled: boolean) => useQuery(
  ['useAsyncJobsRequest'],
  getAll(),
  {
    refetchInterval: refreshSeconds,
    retry: 0,
    enabled: authenticated && enabled,
  }
);
