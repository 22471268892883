import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { PastOperationAndDriverIdEntity } from 'src/entities/PastOperationAndDriverId.entity';

const requestPath = (startOn: string) => `/api/v3/plannings/past_order_operations/${startOn}`;

const getRecords: (startOn: string) => () => Promise<PastOperationAndDriverIdEntity[]> = (startOn) => async () => {
  const response: AxiosResponse<PastOperationAndDriverIdEntity[]> = await axios.get(requestPath(startOn));

  return response.data;
};

export const usePastOperationsQuery = (startOn: string) => useQuery(
    ['usePastOperationsQuery', { startOn }],
    getRecords(startOn),
    {
      staleTime: Infinity,
      enabled: !!startOn
    }
  );
