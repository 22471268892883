import { differenceInDays } from 'date-fns';
import { FC, memo, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FourZeroFourComponent from 'src/components/FourZeroFourComponent';
import LicenseContext from 'src/contexts/LicenseContext';
import { DateModel } from 'src/models/DateModel';

import Component from './Timeline/Component';

const Timeline: FC = memo(() => {
  const { startOn, endOn } = useParams();
  const navigate = useNavigate();
  const context = useContext(LicenseContext);

  const start = new Date(startOn);
  const end = new Date(endOn);
  const [unit, setUnit] = useState<string | undefined>(undefined);
  const [customInputFields, setCustomInputFields] = useState<string[]>([]);

  useEffect(() => {
    if (!context.config?.on) return;
    if (!context.config?.unit) return;

    setUnit(context.config.unit);
    setCustomInputFields(context.config.custom_input_fields);
    if (startOn) {
      const paramsOnModel = new DateModel(startOn);
      let paramsendDateModel = new DateModel(endOn);
      const dateDiff = differenceInDays(new Date(endOn), new Date(startOn));
      if (dateDiff < 0 || dateDiff > 2) {
        paramsendDateModel = new DateModel(startOn);
      }

      if (paramsOnModel.isValid()) {
        navigate(`/timeline/${paramsOnModel.toString()}/${paramsendDateModel.toString()}`);
        return;
      }
    }

    const configOnModel = new DateModel(context.config.on);

    if (configOnModel.isValid()) {
      navigate(`/timeline/${configOnModel.toString()}/${configOnModel.toString()}`);
      return;
    }

    navigate('/404');
  }, [startOn, endOn, context.config, navigate]);

  if (!startOn) return <FourZeroFourComponent />;

  return <Component startDate={start} endDate={end} unit={unit} customInputFields={customInputFields} />;
});

export default Timeline;
