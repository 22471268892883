import { KeyboardDoubleArrowDown, KeyboardDoubleArrowRight, SkipPrevious, SkipNext, Pause } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table, TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { UpdateDeliveryEntity } from 'src/entities/Delivery.entity';
import { DriverEntity } from 'src/entities/Driver.entity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { SelectedShiftCell } from 'src/entities/SelectedShiftCell.entity';
import { TruckEntity } from 'src/entities/Truck.entity';
import { useMutationDeliveries } from 'src/hooks/useDeliveries.query';

import BodyRowsPresenter from './BodyRows.presenter';
import { EditProps, EditPropsBase, RequestEntity } from './EditDialog.presenter';
import HeaderCellsPresenter from './HeaderCells.presenter';

type Props = {
  daysOfWeek: Date[];
  truckData: TruckEntity[];
  driverData: DriverEntity[];
  groupData: PlanningsGroupEntity[];
  selectedGroup: PlanningsGroupEntity | undefined;
  updateSelectedGroup: (entity: PlanningsGroupEntity | undefined) => void;
  isLoading: boolean;
  updateIsLoading: (bool: boolean) => void;
  updateEditProps: (props: EditProps) => void;
  defaultStartAt: string;
  defaultEndAt: string;
  defaultWorkingAvailableDurationHours: number | undefined;
  selectedShiftCell: SelectedShiftCell[];
  updateSelectedShiftCell: (cell: SelectedShiftCell[], checked: boolean) => void;
  updateStartEndOn: (week: number) => void;
}

const TablePresenter: FC<Props> = memo((
  {
    daysOfWeek,
    truckData,
    driverData,
    groupData,
    selectedGroup,
    updateSelectedGroup,
    isLoading,
    updateIsLoading,
    updateEditProps,
    defaultStartAt,
    defaultEndAt,
    defaultWorkingAvailableDurationHours,
    selectedShiftCell,
    updateSelectedShiftCell,
    updateStartEndOn,
  }
) => {
  const [expandAll, setExpandAll] = useState(false);
  const theme = useTheme();
  const { bulkUpdate } = useMutationDeliveries();

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: SelectChangeEvent<number>) => {
    const selectedIndex = event.target.value as number;
    const newValue = selectedIndex === 0 ? undefined : groupData[selectedIndex - 1];
    updateSelectedGroup(newValue);
  };

  const [deliveryExistingCells, setDeliveryExistingCells] = useState<SelectedShiftCell[]>([]);

  const appendDeliveryExistingCells = useCallback((cells: SelectedShiftCell[]) => {
    setDeliveryExistingCells((prev) => {
      const notIncludes = cells.filter((cell) => !prev.some((it) => it.driver_id === cell.driver_id && it.date === cell.date));
      return [...prev, ...notIncludes];
    });
  }, []);

  const deleteRequestOnClick = useCallback((shiftCell: SelectedShiftCell[]) => {
    const exists = shiftCell.some((cell) => deliveryExistingCells.some((it) => cell.date === it.date && cell.driver_id === it.driver_id));
    // eslint-disable-next-line no-alert
    if (exists && !window.confirm('既に配車した荷物は、未割り当てに戻りますがよろしいですか？')) return;

    const entity: UpdateDeliveryEntity = {
      method: 'delete',
      targets: shiftCell,
    };
    updateIsLoading(true);
    bulkUpdate.mutateAsync(entity)
      .catch((e: AxiosError<{ message: string; }>) => {
        if (e.response?.data?.message) {
          enqueueSnackbar(e.response.data.message);
        } else {
          enqueueSnackbar('エラーが発生しました。');
        }
      }).finally(() => {
        setDeliveryExistingCells([]);
        queryClient.invalidateQueries(['useDeliveriesQuery']).finally(() => {
          updateEditProps(undefined);
          updateIsLoading(false);
        });
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, queryClient, updateEditProps, updateIsLoading, deliveryExistingCells]);

  const createRequestButtonOnClick: (requestEntities: RequestEntity[], shiftCell: SelectedShiftCell[]) => void = useCallback((requestEntities, shiftCell) => {
    const exists = shiftCell.some((cell) => deliveryExistingCells.some((it) => cell.date === it.date && cell.driver_id === it.driver_id));
    // eslint-disable-next-line no-alert
    if (exists && !window.confirm('シフトを変更します。よろしいですか？')) return;

    const entity: UpdateDeliveryEntity = {
      method: 'update',
      targets: shiftCell,
      times: requestEntities.map((it) => ({
        start_at: it.startAt,
        end_at: it.endAt,
        working_available_duration_seconds: it.workingAvailableDurationHours * 60 * 60,
        truck_id: it.truckId,
      }))
    };

    updateIsLoading(true);
    bulkUpdate.mutateAsync(entity)
      .catch((e: AxiosError<{ message: string }>) => {
        if (e.response?.data?.message) {
          enqueueSnackbar(e.response.data.message);
        } else {
          enqueueSnackbar('エラーが発生しました。');
        }
      }).finally(() => {
        setDeliveryExistingCells([]);
        queryClient.invalidateQueries(['useDeliveriesQuery']).finally(() => {
          updateEditProps(undefined);
          updateIsLoading(false);
        });
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, queryClient, updateEditProps, updateIsLoading, deliveryExistingCells]);

  const [dialogTitle, setDialogTitle] = useState<string>('');

  useEffect(() => {
    setDialogTitle(`チェックした${selectedShiftCell.length}件の勤務を一括編集`);
  }, [selectedShiftCell]);

  const updateEditPropsBase = useCallback((props: EditPropsBase) => {
    if (props) {
      updateEditProps({ ...props, ...{ deleteRequestOnClick, createRequestButtonOnClick, deliveryExistingCells } });
    } else {
      updateEditProps(undefined);
    }
  }, [createRequestButtonOnClick, deleteRequestOnClick, deliveryExistingCells, updateEditProps]);

  const onClickEditCheckedShifts = useCallback(() => {
    if (selectedShiftCell.length === 0) return;

    const props: EditPropsBase = {
      dialogTitle,
      defaultRequestEntities: [{
        startAt: defaultStartAt,
        endAt: defaultEndAt,
        workingAvailableDurationHours: defaultWorkingAvailableDurationHours,
      }],
      truckData,
      date: null,
      defaultStartAt,
      defaultEndAt,
      defaultWorkingAvailableDurationHours,
      shiftCell: selectedShiftCell,
    };
    updateEditPropsBase(props);
  }, [defaultEndAt, defaultStartAt, defaultWorkingAvailableDurationHours, dialogTitle, selectedShiftCell, truckData, updateEditPropsBase]);

  return (
    <Stack p={2}>
      <Stack direction="row" gap={2}>
        <Stack direction="row" gap={0}>
          <LoadingButton
            startIcon={<Pause />}
            loading={isLoading}
            onClick={() => updateStartEndOn(0)}
          >
            もとの週に戻る
          </LoadingButton>
          <LoadingButton
            startIcon={<SkipPrevious />}
            loading={isLoading}
            onClick={() => updateStartEndOn(-1)}
          >
            先週
          </LoadingButton>
          <LoadingButton
            startIcon={<SkipNext />}
            loading={isLoading}
            onClick={() => updateStartEndOn(1)}
          >
            来週
          </LoadingButton>
        </Stack>
        <Stack>
          <FormControl
            size="small"
            fullWidth
          >
            <InputLabel>表示するグループ</InputLabel>
            <Select value={selectedGroup ? groupData.indexOf(selectedGroup) + 1 : 0} onChange={handleChange}>
              <MenuItem value={0}>すべて表示する</MenuItem>
              {groupData?.map((group, index) => (
                <MenuItem key={group.name} value={index + 1}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <TableContainer
        sx={{ maxHeight: `calc(100vh - ${theme.spacing(15)})` }}
        component={Paper}
      >
        <Table
          stickyHeader
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ position: 'sticky', left: 0, zIndex: 3 }}>
                <Stack direction="row" alignItems="center" justifyContent="flex-start">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    sx={{ width: '30px' }}
                    onClick={() => setExpandAll(!expandAll)}
                  >
                    {expandAll ? <KeyboardDoubleArrowDown /> : <KeyboardDoubleArrowRight />}
                  </IconButton>
                  <Button variant="contained" onClick={onClickEditCheckedShifts} sx={{ wordBreak: 'keep-all' }}>
                    チェックした勤務を編集する
                  </Button>
                </Stack>
              </TableCell>
              <HeaderCellsPresenter
                driverDate={driverData}
                daysOfWeek={daysOfWeek}
                truckData={truckData}
                selectedGroup={selectedGroup}
                updateEditPropsBase={updateEditPropsBase}
                isLoading={isLoading}
                defaultStartAt={defaultStartAt}
                defaultEndAt={defaultEndAt}
                defaultWorkingAvailableDurationHours={defaultWorkingAvailableDurationHours}
                selectedShiftCell={selectedShiftCell}
                updateSelectedShiftCell={updateSelectedShiftCell}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            <BodyRowsPresenter
              daysOfWeek={daysOfWeek}
              driverData={driverData}
              truckData={truckData}
              selectedGroup={selectedGroup}
              isLoading={isLoading}
              expandAll={expandAll}
              updateEditPropsBase={updateEditPropsBase}
              defaultStartAt={defaultStartAt}
              defaultEndAt={defaultEndAt}
              defaultWorkingAvailableDurationHours={defaultWorkingAvailableDurationHours}
              selectedShiftCell={selectedShiftCell}
              updateSelectedShiftCell={updateSelectedShiftCell}
              appendDeliveryExistingCells={appendDeliveryExistingCells}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
});

export default TablePresenter;
