import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Button, Dialog, IconButton, Skeleton, TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { Stack } from '@mui/system';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { addDays } from 'date-fns';
import { format } from 'date-fns-tz';
import ja from 'date-fns/locale/ja';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useState, useEffect } from 'react';
import PlansContext from 'src/contexts/PlansContext';
import { DriverShiftRequestEntity } from 'src/entities/driverShiftEntity';
import { useMutationDriverShiftBulk, useQueryDriverShifts } from 'src/hooks/useQueryDriverShifts';

import DriverShiftPresenter from './DriverShiftPresenter';
import ShiftsTableEditDialog from './ShiftsTableEditDialog';
import ShiftsTableSummaryTableRow from './ShiftsTableSummaryTableRow';

type Props = {
  driverId: number,
  weekStartAt: Date,
  expandAll: boolean
}

const ShiftsTableBodyTableRow: FC<Props> = memo(({ driverId, weekStartAt, expandAll }) => {
  const context = useContext(PlansContext);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const buttonOnClick = () => {
    setIsOpen(!isOpen);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const { createDriverShiftBulk, deleteDriverShiftBulk } = useMutationDriverShiftBulk();

  const createDriverShiftBulkAsync = async (shift: DriverShiftRequestEntity) => {
    await createDriverShiftBulk.mutateAsync(shift);
  };

  const deleteDriverShiftBulkAsync = async (shift: DriverShiftRequestEntity) => {
    await deleteDriverShiftBulk.mutateAsync(shift);
  };

  const resetRelatedQueries = () => {
    // eslint-disable-next-line no-void
    void queryClient.resetQueries({
      predicate: (query) => query.queryKey[0] === 'shifts' || query.queryKey[0] === 'unallocatedOrders' || query.queryKey[0] === 'shift' || query.queryKey[0] === 'order',
    });
  };

  const createRequest = (startTime: string, endTime: string, workingAvailableDuration: number) => {
    context.updateIsLoading(true);

    const shift: DriverShiftRequestEntity = {
      driver_id: driverId,
      on: format(weekStartAt, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' }),
      start_at: startTime,
      end_at: endTime,
      workingAvailableDurationHours: workingAvailableDuration
    };

    createDriverShiftBulkAsync(shift)
      .catch((error: AxiosError) => {
        console.log('error');
        console.log(error);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        error.response.data.map((str) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          enqueueSnackbar(str);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return str;
        });
      })
      .finally(() => {
        setIsOpen(false);
        context.updateIsLoading(false);
        resetRelatedQueries();
        context.reset();
      });
  };

  const deleteRequest = () => {
    const on = format(weekStartAt, 'yyyy-MM-dd', { locale: ja });
    const requestPath = `/api/v2/drivers/${driverId}/shifts/${on}`;

    const shift: DriverShiftRequestEntity = {
      driver_id: driverId,
      on,
      start_at: null,
      end_at: null,
      workingAvailableDurationHours: null
    };

    context.updateIsLoading(true);
    deleteDriverShiftBulkAsync(shift).finally(() => {
      setIsOpen(false);
      context.updateIsLoading(false);
      resetRelatedQueries();
      context.reset();
    });
  };

  const [openWorkSummary, setOpenWorkSummary] = useState(false);

  useEffect(() => { setOpenWorkSummary(expandAll); }, [expandAll]);

  const driverShiftsQuery = useQueryDriverShifts(driverId, weekStartAt);

  if (driverShiftsQuery.isLoading) {
    return <Skeleton width="100%" variant="text" sx={{ fontSize: '1rem' }} />;
  }

  if (driverShiftsQuery.isError) {
    return <>Error</>;
  }

  const driverShifts = driverShiftsQuery.data;

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <Stack direction="row" alignItems="left" justifyContent="flex-start">
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{ width: '30px' }}
              onClick={() => setOpenWorkSummary(!openWorkSummary)}
            >
              {openWorkSummary ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
            <Button
              fullWidth
              onClick={buttonOnClick}
            >
              {driverShifts.driver.name}
            </Button>
          </Stack>
        </TableCell>
        {
          driverShifts.shifts.map((shift, idx) => (
            <TableCell align="center">
              <DriverShiftPresenter driver={driverShifts.driver} shift={shift} on={format(addDays(weekStartAt, idx), 'yyyy-MM-dd', { locale: ja })} />
            </TableCell>
          ))
        }
        <Dialog
          open={isOpen}
          onClose={onClose}
          maxWidth="xs"
          fullWidth
        >
          <ShiftsTableEditDialog
            isLoading={context.isLoading}
            title={`${driverShifts.driver.name}さんの勤務計画を一括編集する`}
            deleteButtonOnClick={deleteRequest}
            saveButtonOnClick={createRequest}
          />
        </Dialog>
      </TableRow>
      <ShiftsTableSummaryTableRow
        driverShifts={driverShifts}
        openWorkSummary={openWorkSummary}
      />
    </>
  );
});

export default ShiftsTableBodyTableRow;
