import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import { GridColumnResizeParams } from '@mui/x-data-grid-premium';
import { SavedColumnWidth } from 'src/entities/SavedColumnWidth';

const getSavedColumnWidths: (localStorageItemName: string) => SavedColumnWidth[] = (localStorageItemName) => {
  const columnWidth = localStorage.getItem(localStorageItemName);

  if (columnWidth) {
    const columns = JSON.parse(columnWidth) as SavedColumnWidth[];
    return columns;
  }

  return [];
};

const setSavedColumnWidth = (prefix: string, params: GridColumnResizeParams) => {
  const storageKey = `${prefix}-width-${params.colDef.field}`;
  localStorage.setItem(storageKey, JSON.stringify(params.colDef.width));
};

const getSavedColumnWidth = (prefix: string, field: string) => {
  const storageKey = `${prefix}-width-${field}`;
  const width = localStorage.getItem(storageKey);

  if (width) {
    return parseInt(width, 10);
  }

  return 0;
};

const setSavedColumnVisibilities = (prefix: string, newModel: GridColumnVisibilityModel) => {
  const storageKey = `${prefix}-visibilities`;

  localStorage.setItem(storageKey, JSON.stringify(newModel));
};

const getSavedColumnVisibilities: (prefix: string) => GridColumnVisibilityModel = (prefix) => {
  const storageKey = `${prefix}-visibilities`;

  const columnVisibility = localStorage.getItem(storageKey);

  if (columnVisibility) {
    const columns = JSON.parse(columnVisibility) as GridColumnVisibilityModel;
    return columns;
  }

  return {};
};

export const dataGridUtil = {
  getSavedColumnWidth,
  setSavedColumnWidth,
  getSavedColumnVisibilities,
  setSavedColumnVisibilities,
};
