import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const requestPath = '/api/v3/async_jobs/bulk_update_request';

export const useAsyncJobMutation = () => ({
  postBulkUpdateRequest: useMutation(
    (ids: number[]) => axios.post(requestPath, { async_job_ids: ids })
  )
});
