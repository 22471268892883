import { Box, Button } from '@mui/material';
import { FC, memo } from 'react';

const Footer: FC<{
  showFormatDownloadButton?: boolean;
  handleDownloadFormatButton?: () => void;
  handleBackButton?: () => void;
  handleNextButton?: () => void;
  handleCompleteButton?: () => void;
  disabled?: boolean;
  nextButtonText?: string;
}> = memo(
  ({
    showFormatDownloadButton,
    handleDownloadFormatButton,
    handleBackButton,
    handleNextButton,
    handleCompleteButton,
     nextButtonText,
     disabled
  }) => (
    <Box
      paddingX={1}
      paddingY={3}
      sx={{
        backgroundColor: 'rgba(248, 248, 248, 1)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          visibility: showFormatDownloadButton ? 'visible' : 'hidden'
        }}
      >
        <Button
          size="small"
          onClick={() => {
            handleDownloadFormatButton();
          }}
        >
          フォーマットダウンロード
        </Button>
      </Box>
      <Box>
        {handleBackButton ? (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            sx={{
              marginRight: '1em'
            }}
            onClick={() => {
              handleBackButton();
            }}
          >
            戻る
          </Button>
        ) : null}
        {handleNextButton ? (
          <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              handleNextButton();
            }}
          >
            { nextButtonText || '次へ' }
          </Button>
        ) : null}
        {handleCompleteButton ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              handleCompleteButton();
            }}
          >
            完了
          </Button>
        ) : null}
      </Box>
    </Box>
  )
);

export default Footer;
