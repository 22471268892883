import { GridColDef } from '@mui/x-data-grid';
import { GridValueFormatterParams, GridValueSetterParams } from '@mui/x-data-grid-premium';
import numberDecorator from 'src/decorators/number.decorator';
import { EstimationEntity } from 'src/entities/Estimation.entity';
import numberUtil from 'src/utils/number.util';

const costReserveColumns: GridColDef[] = [
  {
    field: 'deliveryName',
    headerName: '勤務計画',
    type: 'string',
  },
  {
    field: 'totalOrderOperationDistanceMm',
    headerName: '車庫帰着を除く合計距離',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromMmToKm(params.row.totalOrderOperationDistanceMm),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedKm(
      params.value,
      ''
    )
  },
  {
    field: 'totalDistanceMm',
    headerName: '合計距離',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromMmToKm(params.row.totalOrderOperationDistanceMm + params.row.arrivalOperationDistanceMm),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedKm(
      params.value,
      ''
    )
  },
  {
    field: 'orderOperationSeconds',
    headerName: '車庫帰着を除く時間',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromSecondsToMinutes(params.row.orderOperationSeconds),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedUnit(
      params.value,
      '分',
      1,
      ''
    )
  },
  {
    field: 'totalSeconds',
    headerName: '合計時間',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromSecondsToMinutes(params.row.orderOperationSeconds + params.row.arrivalOperationSeconds),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedUnit(
      params.value,
      '分',
      1,
      ''
    )
  },
  {
    field: 'driverCostYenPerSeconds',
    headerName: '🖊️時給',
    type: 'number',
    editable: true,
    valueGetter: (params: { row: EstimationEntity }) => params.row.driverCostYenPerSeconds * 3600,
    valueSetter: (params: GridValueSetterParams<EstimationEntity, number>) => ({
      ...params.row,
      driverCostYenPerSeconds: params.value / 3600
    }),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'fuelCostPerMm',
    headerName: '🖊️燃料費',
    type: 'number',
    editable: true,
    valueGetter: (params: { row: EstimationEntity }) => params.row.fuelCostPerMm * 1000000,
    valueSetter: (params: GridValueSetterParams<EstimationEntity, number>) => ({
      ...params.row,
      fuelCostPerMm: params.value / 1000000
    }),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'insuranceFeeYenPerDay',
    headerName: '🖊️保険料',
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'repairCostYenPerDay',
    headerName: '🖊️修繕費',
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'expresswayFeeYenPerShift',
    headerName: '🖊️高速代',
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'expectedProfitRate',
    headerName: '🖊️粗利益率',
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedRate(
      params.value / 100,
      ''
    ),
  },
  {
    field: 'feeExcludeArrivalOperation',
    headerName: '運賃（車庫帰着を除く）',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => {
      if (!params.row.expectedProfitRate) return undefined;

      const seconds = params.row.orderOperationSeconds;
      const distanceMm = params.row.totalOrderOperationDistanceMm;

      const totalDriverCostYenPerSeconds = seconds * params.row.driverCostYenPerSeconds;
      const totalFuelConstPerMm = distanceMm * params.row.fuelCostPerMm;

      const costYen = [totalDriverCostYenPerSeconds,
        totalFuelConstPerMm,
        params.row.insuranceFeeYenPerDay,
        params.row.repairCostYenPerDay,
        params.row.expresswayFeeYenPerShift]
        .filter((maybe) => maybe)
        .reduce((prev, current) => prev + current, 0);

      const profits = costYen * (params.row.expectedProfitRate / 100);

      return costYen + profits;
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value,
    ),
  },
  {
    field: 'fee',
    headerName: '運賃',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => {
      if (!params.row.expectedProfitRate) return undefined;

      const seconds = params.row.orderOperationSeconds + params.row.arrivalOperationSeconds;
      const distanceMm = params.row.totalOrderOperationDistanceMm + params.row.arrivalOperationDistanceMm;

      const totalDriverCostYenPerSeconds = seconds * params.row.driverCostYenPerSeconds;
      const totalFuelConstPerMm = distanceMm * params.row.fuelCostPerMm;

      const costYen = [totalDriverCostYenPerSeconds,
        totalFuelConstPerMm,
        params.row.insuranceFeeYenPerDay,
        params.row.repairCostYenPerDay,
        params.row.expresswayFeeYenPerShift]
        .filter((maybe) => maybe)
        .reduce((prev, current) => prev + current, 0);

      const profits = costYen * (params.row.expectedProfitRate / 100);

      return costYen + profits;
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value,
    ),
  },
];

export default costReserveColumns;
