export const ALL_ML_SOURCE_TYPES = ['LOG_ドライバーアプリ', 'DoCoMAP', 'データテック'];
export type MLSourceTypes = (typeof ALL_ML_SOURCE_TYPES)[number];

type CompanyEntityBase = {
  name: string; // 企業名
  address: string; // 住所
  phone_number: string; // 電話番号
  request_algorithm_host?: string; // リクエストするアルゴリズムのURL
  subscription_kind_ids: number[]; // サブスクリプション種別ID
  default_preset?: string;
  ml_source_types: number[];
};

export type CompanyRequestEntity = CompanyEntityBase;
export type CompanyEntity = CompanyEntityBase & { id: number };
