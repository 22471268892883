/* eslint-disable @typescript-eslint/no-misused-promises */
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Link, Paper, Stack, TextField, Typography } from '@mui/material';
import { FC, memo, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import LogoComponent from 'src/components/LogoComponent';
import LicenseContext from 'src/contexts/LicenseContext';
import { LicenseRequestEntity } from 'src/entities/licenseEntity';
import * as yup from 'yup';

import PasswordResetDialog from './PasswordResetDialog';
import RegisterDialog from './RegisterDialog';

const schema = yup.object({
  email: yup
    .string()
    .email('正しい形式で入力してください')
    .required('メールアドレスを入力してください'),
  password: yup.string().required('パスワードを入力してください')
});

const V2Login: FC = memo(() => {
  const licenseContext = useContext(LicenseContext);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LicenseRequestEntity>({
    resolver: yupResolver(schema)
  });

  const [registerDialogOpened, setRegisterDialogOpend] = useState(false);
  const [passwordResetDialogOpened, setPasswordResetDialogOpened] = useState(false);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const [showResetConfirmMessage, setResetShowConfirmMessage] = useState(false);
  const [showConfirmedMessage, setShowConfirmedMessage] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('confirmed') && searchParams.get('confirmed') === 'confirmed') {
      setShowConfirmedMessage(true);
    }
  }, [searchParams]);

  const onSubmit: SubmitHandler<LicenseRequestEntity> = (
    entity: LicenseRequestEntity
  ): void => {
    licenseContext.login(entity);
  };

  const onClickResetPassword = () => {
    setShowConfirmMessage(false);
    setShowConfirmedMessage(false);
    setPasswordResetDialogOpened(true);
  };
  return (
    <>
      <Helmet>
        <title>ログイン</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <RegisterDialog
          registerDialogOpened={registerDialogOpened}
          setRegisterDialogOpened={setRegisterDialogOpend}
          setShowConfirmMessage={setShowConfirmMessage}
        />
        <PasswordResetDialog
          passwordResetDialogOpened={passwordResetDialogOpened}
          setPasswordResetDialogOpened={setPasswordResetDialogOpened}
          setResetShowConfirmMessage={setResetShowConfirmMessage}
        />
        <Paper
          sx={{
            py: 2,
            px: 4,
            minWidth: 380
          }}
        >
          <Typography textAlign="center">
            <LogoComponent width={196} />
          </Typography>
          {showConfirmedMessage && (
            <Typography variant="h4" style={{ color: '#00f' }} mt={5} mb={2}>
              会員登録が完了しました。
              <br />
              ログインしてご利用ください。
            </Typography>
          )}
          {showConfirmMessage && (
            <Typography variant="h4" style={{ color: '#00f' }} mt={5}>
              ご登録のメールアドレスに会員登録完了のURLをお送りいたしました。
              <br />
              URLをクリックして登録手続きを完了ください。
            </Typography>
          )}
          {showResetConfirmMessage && (
            <Typography variant="h4" style={{ color: '#00f' }} mt={5}>
              ご登録のメールアドレスにパスワードリセットのURLをお送りいたしました。
              <br />
              URLをクリックして手続きを完了ください。
            </Typography>
          )}
          <Stack component="form" noValidate spacing={2}>
            <TextField
              required
              margin="dense"
              id="email"
              label="メールアドレス"
              type="text"
              fullWidth
              variant="standard"
              {...register('email')}
              error={'email' in errors}
              helperText={errors.email?.message}
            />
            <TextField
              required
              margin="dense"
              id="password"
              label="パスワード"
              type="password"
              fullWidth
              variant="standard"
              {...register('password')}
              error={'password' in errors}
              helperText={errors.password?.message}
            />
            <LoadingButton
              loading={licenseContext.loading}
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleSubmit(onSubmit)}
            >
              ログインする
            </LoadingButton>
            <Stack alignItems="center">
              <Link href="#" onClick={() => { onClickResetPassword(); }}>
                <Typography>
                  パスワードを忘れた場合
                </Typography>
              </Link>
            </Stack>
          </Stack>
        </Paper>
      </Box>
    </>
  );
});

export default V2Login;
