import { useQueries, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ShiftEntity } from 'src/entities/shiftEntity';

const getShift = (id: number) => async () => {
  const requestPath = `/api/v2/shifts/${id}`;
  const res = await axios.get<ShiftEntity>(requestPath);
  const entity = res.data;
  return entity;
};

export const useQueryShiftsByIds = (ids: number[]) => {
  const queryClient = useQueryClient();
  return useQueries({
    queries: ids.map((id) => ({
      queryKey: ['shifts', { id }],
      queryFn: getShift(id),
      staleTime: Infinity,
      enabled: Boolean(id)
    })),
  });
};
