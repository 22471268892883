import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { GarageEntity } from 'src/entities/Garage.entity';

const requestPathBase = '/api/v3/garages';
const queryPrefix = 'useGaragesQuery';

const getAll = (queryClient: QueryClient) => async () => {
  const requestPath = requestPathBase;
  const axiosResponse = await axios.get<GarageEntity[]>(requestPath);
  const entities = axiosResponse.data;

  return entities.map((it) => {
    queryClient.setQueryData([queryPrefix, { id: it.id }], it);

    return it;
  });
};

const getRecord = (id: number) => async () => {
  const requestPath = `${requestPathBase}/${id}`;
  const axiosResponse = await axios.get<GarageEntity>(requestPath);

  return axiosResponse.data;
};

export const useGaragesQuery = () => {
  const queryClient = useQueryClient();

  return useQuery(
    [queryPrefix],
    getAll(queryClient),
    {
      staleTime: Infinity
    }
  );
};

export const useGarageQuery = (id: number) => useQuery(
  [queryPrefix, { id }],
  getRecord(id),
  {
    staleTime: Infinity,
    enabled: Boolean(id)
  }
);
