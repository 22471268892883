import { Button, Dialog } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { format } from 'date-fns-tz';
import ja from 'date-fns/locale/ja';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useState } from 'react';
import PlansContext from 'src/contexts/PlansContext';
import { DriverShiftRequestEntity } from 'src/entities/driverShiftEntity';
import { useMutationShiftBulk } from 'src/hooks/useQueryDriverShifts';

import ShiftsTableEditDialog from './ShiftsTableEditDialog';

type Props = {
  at: Date;
}

const ShiftsTableHeaderTableCell: FC<Props> = memo(({ at }) => {
  const context = useContext(PlansContext);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const buttonOnClick = () => {
    setIsOpen(!isOpen);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const { createShiftsBulk, deleteShiftsBulk } = useMutationShiftBulk();

  const createShiftsBulkAsync = async (data: DriverShiftRequestEntity) => {
    await createShiftsBulk.mutateAsync(data);
  };

  const deleteShiftsBulkAsync = async (date: string) => {
    await deleteShiftsBulk.mutateAsync(date);
  };

  const resetRelatedQueries = () => {
    // eslint-disable-next-line no-void
    void queryClient.resetQueries({
      predicate: (query) => query.queryKey[0] === 'shifts' || query.queryKey[0] === 'unallocatedOrders' || query.queryKey[0] === 'shift' || query.queryKey[0] === 'order',
    });
  };

  const deleteRequest = () => {
    const shiftDay = format(
      at,
      'yyyy-MM-dd',
      { locale: ja }
    );
    context.updateIsLoading(true);
    deleteShiftsBulkAsync(shiftDay).finally(() => {
      setIsOpen(false);
      context.updateIsLoading(false);
      resetRelatedQueries();
      context.reset();
    });
  };

  const createRequest = (startTime: string, endTime: string, workingAvailableDurationHours: number) => {
    const shiftDay = format(
      at,
      'yyyy-MM-dd',
      { locale: ja }
    );

    const data: DriverShiftRequestEntity = {
      on: shiftDay,
      start_at: startTime,
      end_at: endTime,
      workingAvailableDurationHours
    };

    context.updateIsLoading(true);
    createShiftsBulkAsync(data)
      .catch((error: AxiosError) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        error.response.data.map((str) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          enqueueSnackbar(str);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return str;
        });
      })
      .finally(() => {
        setIsOpen(false);
        context.updateIsLoading(false);
        resetRelatedQueries();
        context.reset();
      });
  };

  return (
    <>
      <Button
        fullWidth
        onClick={buttonOnClick}
      >
        {format(at, 'MM/dd(E)', { locale: ja })}
      </Button>
      <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
      >
        <ShiftsTableEditDialog
          isLoading={context.isLoading}
          title={`${format(at, 'MM/dd(E)', { locale: ja })}を一括編集する`}
          deleteButtonOnClick={deleteRequest}
          saveButtonOnClick={createRequest}
        />
      </Dialog>
    </>
  );
});

export default ShiftsTableHeaderTableCell;
