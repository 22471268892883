import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns-tz';
import { ApiResponse } from 'src/constants/apiResponse';
import { KpiDetails } from 'src/entities/KpiDetails';

const BASE_URL = '/api/v3/kpis/details';

const getKpiDetails = (queryClient: QueryClient, startDate: string, endDate: string, withCo2emissions: boolean) => async () => {
  const res = await axios.get<ApiResponse>(`${BASE_URL}?startDate=${startDate}&endDate=${endDate}&withCo2emissions=${withCo2emissions ? 'true' : 'false'}`);
  return res.data.data as unknown as KpiDetails;
};

export const useQueryKpiDetails = (startDate: Date, endDate: Date, withCo2emissions: boolean) => {
  const queryClient = useQueryClient();
  const startDateString = format(startDate, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' });
  const endDateString = format(endDate, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' });
  return useQuery(['KpiDetails', { startDateString, endDateString, withCo2emissions }], getKpiDetails(queryClient, startDateString, endDateString, withCo2emissions), {
    staleTime: Infinity
  });
};
