import {
  Box, Divider,
  Paper,
  Stack
} from '@mui/material';
import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';

import Calendar from './Calendar';

const DashboardPresenter: FC = memo(() => (
  <>
    <Helmet>
      <title>ダッシュボード</title>
    </Helmet>
    <Box p={2}>
      <Paper>
        <Stack divider={<Divider />} p={3}>
          <Calendar />
        </Stack>
      </Paper>
    </Box>
  </>
));

export default DashboardPresenter;
