import PropTypes from 'prop-types';
import {
  Context,
  createContext,
  FC,
  memo,
  ReactNode, useEffect,
  useMemo,
  useReducer
} from 'react';
import { OrderModel } from 'src/models/OrderModel';
import { ShiftModel } from 'src/models/ShiftModel';

export type HowToPlanningType = '最適化' | '平準化';
export type PlanningPriorityType = '指定なし' | '近隣優先' | '遠方優先';

type ContextEntity = {
  on: string;
  isLoading: boolean;
  shiftSearchKw: string;
  shiftIds: number[];
  selectedShiftIds: number[];
  filteredShiftIds: number[];
  orderSearchKw: string;
  orderIds: number[];
  selectedOrderIds: number[];
  filteredOrderIds: number[];
  howToPlanning: HowToPlanningType;
  planningPriority: PlanningPriorityType;
  availabilityOfExpressway: boolean;
  isForcePlanning: boolean;
  ignoreWeightConstraints: boolean;
  ignoreTimeConstraints: boolean;
  orders: OrderModel[];
  shifts: ShiftModel[];
};
type SetIsForcePlanning = {
  type: 'SET_IS_FORCE_PLANNING',
  value: boolean
}
type SetIgnoreWeightConstraints = {
  type: 'SET_IGNORE_WEIGHT_CONSTRAINTS',
  value: boolean
}
type SetIgnoreTimeConstraints = {
  type: 'SET_IGNORE_TIME_CONSTRAINTS',
  value: boolean
}
type SetAvailabilityOfExpressway = {
  type: 'SET_AVAILABILITY_OF_EXPRESSWAY',
  value: boolean
}
type SetHowToPlanning = {
  type: 'SET_HOW_TO_PLANNING',
  value: HowToPlanningType
}
type SetPlanningPriority = {
  type: 'SET_PLANNING_PRIORITY',
  value: PlanningPriorityType
}
type SetOn = {
  type: 'SET_ON';
  value: string;
};
type SetInitialState = {
  type: 'SET_INITIAL_STATE';
};
type SetIsLoading = {
  type: 'SET_IS_LOADING',
  value: boolean
}
type SetShiftSearchKw = {
  type: 'SET_SHIFT_SEARCH_KW';
  value: string;
};
type SetShifts = {
  type: 'SET_SHIFTS';
  values: ShiftModel[];
};
type SetShiftIds = {
  type: 'SET_SHIFT_IDS';
  values: number[];
};
type SetSelectedShiftIds = {
  type: 'SET_SELECTED_SHIFT_IDS';
  values: number[];
};
type SetFilteredShiftIds = {
  type: 'SET_FILTERED_SHIFT_IDS';
  values: number[];
};
type SetOrderPage = {
  type: 'SET_ORDER_PAGE';
  value: number;
};
type SetOrderSearchKw = {
  type: 'SET_ORDER_SEARCH_KW';
  value: string;
};
type SetOrders = {
  type: 'SET_ORDERS';
  values: OrderModel[];
};
type SetOrderIds = {
  type: 'SET_ORDER_IDS';
  values: number[];
};
type SetSelectedOrderIds = {
  type: 'SET_SELECTED_ORDER_IDS';
  values: number[];
};
type SetFilteredOrderIds = {
  type: 'SET_FILTERED_ORDER_IDS';
  values: number[];
};

type Action =
  | SetIsForcePlanning
  | SetIgnoreWeightConstraints
  | SetIgnoreTimeConstraints
  | SetAvailabilityOfExpressway
  | SetHowToPlanning
  | SetPlanningPriority
  | SetOn
  | SetInitialState
  | SetIsLoading
  | SetShiftSearchKw
  | SetShifts
  | SetShiftIds
  | SetSelectedShiftIds
  | SetFilteredShiftIds
  | SetOrderPage
  | SetOrderSearchKw
  | SetOrders
  | SetOrderIds
  | SetSelectedOrderIds
  | SetFilteredOrderIds;

type ContextValueEntity = ContextEntity & {
  reset: () => void;
  updateOn: (on: string) => void;
  updateIsLoading: (value: boolean) => void;

  updateShiftSearchKw: (value: string) => void;
  addShiftIds: (shiftIds: number[]) => void;
  removeShiftIds: (shiftIds: number[]) => void;
  addSelectedShiftIds: (shiftIds: number[]) => void;
  removeSelectedShiftIds: (shiftIds: number[]) => void;

  updateOrderSearchKw: (value: string) => void;
  addOrderIds: (orderIds: number[]) => void;
  removeOrderIds: (orderIds: number[]) => void;
  addSelectedOrderIds: (orderIds: number[]) => void;
  removeSelectedOrderIds: (orderIds: number[]) => void;

  selectedOrderIdsEvery: () => boolean;
  selectedOrderIdsSome: () => boolean;
  resetFilteredSelectedOrderIds: () => void;
  selectFilteredOrderIds: () => void;

  setHowToPlanning: (value: HowToPlanningType) => void;
  setPlanningPriority: (value: PlanningPriorityType) => void;
  setAvailabilityOfExpressway: (value: boolean) => void;
  setIsForcePlanning: (value: boolean) => void;
  setIgnoreWeightConstraints: (value: boolean) => void;
  setIgnoreTimeConstraints: (value: boolean) => void;

  setOrders: (values: OrderModel[]) => void;
  setShifts: (values: ShiftModel[]) => void;
};

const initialState: ContextEntity = {
  on: '',
  isLoading: false,
  shiftSearchKw: '',
  shiftIds: [],
  selectedShiftIds: [],
  filteredShiftIds: [],
  orderSearchKw: '',
  orderIds: [],
  selectedOrderIds: [],
  filteredOrderIds: [],
  howToPlanning: '最適化',
  planningPriority: '指定なし',
  availabilityOfExpressway: false,
  isForcePlanning: false,
  ignoreWeightConstraints: false,
  ignoreTimeConstraints: false,
  orders: [],
  shifts: []
};

const handlers: Record<
  string,
  (state: ContextEntity, action: Action) => ContextEntity
> = {
  SET_INITIAL_STATE: (state: ContextEntity): ContextEntity => ({
    ...initialState,
    on: state.on
  }),
  SET_ON: (state: ContextEntity, { value }: SetOn): ContextEntity => ({
    ...initialState,
    on: value
  }),
  SET_IS_LOADING: (state: ContextEntity, { value }: SetIsLoading): ContextEntity => ({
    ...state,
    isLoading: value
  }),
  SET_SHIFT_SEARCH_KW: (
    state: ContextEntity,
    { value }: SetShiftSearchKw
  ): ContextEntity => ({
    ...state,
    shiftSearchKw: value
  }),
  SET_SHIFT_IDS: (
    state: ContextEntity,
    { values }: SetShiftIds
  ): ContextEntity => ({
    ...state,
    shiftIds: values
  }),
  SET_SELECTED_SHIFT_IDS: (
    state: ContextEntity,
    { values }: SetSelectedShiftIds
  ): ContextEntity => ({
    ...state,
    selectedShiftIds: values
  }),
  SET_FILTERED_SHIFT_IDS: (
    state: ContextEntity,
    { values }: SetFilteredShiftIds
  ): ContextEntity => ({
    ...state,
    filteredShiftIds: values
  }),
  SET_ORDER_IDS: (
    state: ContextEntity,
    { values }: SetOrderIds
  ): ContextEntity => ({
    ...state,
    orderIds: values
  }),
  SET_ORDER_SEARCH_KW: (state: ContextEntity, { value }: SetOrderSearchKw): ContextEntity => ({
    ...state,
    orderSearchKw: value
  }),
  SET_SELECTED_ORDER_IDS: (
    state: ContextEntity,
    { values }: SetSelectedOrderIds
  ): ContextEntity => ({
    ...state,
    selectedOrderIds: values
  }),
  SET_FILTERED_ORDER_IDS: (
    state: ContextEntity,
    { values }: SetFilteredOrderIds
  ): ContextEntity => ({
    ...state,
    filteredOrderIds: values
  }),
  SET_HOW_TO_PLANNING: (
    state: ContextEntity,
    { value }: SetHowToPlanning
  ): ContextEntity => ({
    ...state,
    howToPlanning: value
  }),
  SET_PLANNING_PRIORITY: (
    state: ContextEntity,
    { value }: SetPlanningPriority
  ): ContextEntity => ({
    ...state,
    planningPriority: value
  }),
  SET_AVAILABILITY_OF_EXPRESSWAY: (
    state: ContextEntity,
    { value }: SetAvailabilityOfExpressway
  ): ContextEntity => ({
    ...state,
    availabilityOfExpressway: value
  }),
  SET_IS_FORCE_PLANNING: (
    state: ContextEntity,
    { value }: SetIsForcePlanning
  ): ContextEntity => ({
    ...state,
    isForcePlanning: value
  }),
  SET_IGNORE_WEIGHT_CONSTRAINTS: (
    state: ContextEntity,
    { value }: SetIgnoreWeightConstraints
  ): ContextEntity => ({
    ...state,
    ignoreWeightConstraints: value
  }),
  SET_IGNORE_TIME_CONSTRAINTS: (
    state: ContextEntity,
    { value }: SetIgnoreTimeConstraints
  ): ContextEntity => ({
    ...state,
    ignoreTimeConstraints: value
  }),
  SET_ORDERS: (
    state: ContextEntity,
    { values } : SetOrders
  ): ContextEntity => ({
    ...state,
    orders: values
  }),
  SET_SHIFTS: (
    state: ContextEntity,
    { values } : SetShifts
  ): ContextEntity => ({
    ...state,
    shifts: values
  })
};

const reducer: (state: ContextEntity, action: Action) => ContextEntity = (
  state,
  action
) => (handlers[action.type] ? handlers[action.type](state, action) : state);

/* eslint-disable @typescript-eslint/no-empty-function */
const PlansContext: Context<ContextValueEntity> = createContext<ContextValueEntity>({
  ...initialState,
  addOrderIds(orderIds: number[]): void {},
  updateIsLoading(isLoading: boolean): void {},
  addSelectedOrderIds(orderIds: number[]): void {},
  addSelectedShiftIds(shiftIds: number[]): void {},
  addShiftIds(shiftIds: number[]): void {},
  removeOrderIds(orderIds: number[]): void {},
  removeSelectedOrderIds(orderIds: number[]): void {},
  removeSelectedShiftIds(shiftIds: number[]): void {},
  removeShiftIds(shiftIds: number[]): void {},
  reset(): void {},
  resetFilteredSelectedOrderIds(): void {},
  selectFilteredOrderIds(): void {},
  selectedOrderIdsEvery(): boolean { return false; },
  selectedOrderIdsSome(): boolean { return false; },
  updateOn(on: string): void {},
  updateOrderSearchKw(value: string): void {},
  updateShiftSearchKw(value: string): void {},
  setHowToPlanning(value: HowToPlanningType): void {},
  setPlanningPriority(value: PlanningPriorityType): void {},
  setAvailabilityOfExpressway(value: boolean): void {},
  setIsForcePlanning(value: boolean): void {},
  setIgnoreWeightConstraints(value: boolean): void {},
  setIgnoreTimeConstraints(value: boolean): void {},
  setOrders(values: OrderModel[]): void {},
  setShifts(values: ShiftModel[]): void {}
});
/* eslint-enable @typescript-eslint/no-empty-function */

export const PlansProvider: FC<{ children: ReactNode }> = memo((props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateIsLoading = (value: boolean) => {
    dispatch({
      type: 'SET_IS_LOADING',
      value
    });
  };

  const addSelectedOrderIds = (orderIds: number[]) => {
    const excludeExisting = orderIds.filter((id) => !state.selectedOrderIds.includes(id));

    dispatch({
      type: 'SET_SELECTED_ORDER_IDS',
      values: [...excludeExisting, ...state.selectedOrderIds]
    });
  };

  const addSelectedShiftIds = (shiftIds: number[]) => {
    const excludeExisting = shiftIds.filter((id) => !state.selectedShiftIds.includes(id));

    dispatch({
      type: 'SET_SELECTED_SHIFT_IDS',
      values: [...excludeExisting, ...state.selectedShiftIds]
    });
  };

  const addOrderIds = (orderIds: number[]) => {
    dispatch({
      type: 'SET_ORDER_IDS',
      values: Array.from(new Set([...orderIds, ...state.orderIds]))
    });
  };

  const removeOrderIds = (orderIds: number[]) => {
    const excludeExisting = state.orderIds.filter((id) => !orderIds.includes(id));

    dispatch({
      type: 'SET_ORDER_IDS',
      values: excludeExisting
    });
  };

  const removeSelectedOrderIds = (orderIds: number[]) => {
    const excludeExisting = state.selectedOrderIds.filter(
      (id) => !orderIds.includes(id)
    );

    dispatch({
      type: 'SET_SELECTED_ORDER_IDS',
      values: excludeExisting
    });
  };

  const removeSelectedShiftIds = (shiftIds: number[]) => {
    const excludeExisting = state.selectedShiftIds.filter(
      (id) => !shiftIds.includes(id)
    );

    dispatch({
      type: 'SET_SELECTED_SHIFT_IDS',
      values: excludeExisting
    });
  };

  const addShiftIds = (shiftIds: number[]) => {
    dispatch({
      type: 'SET_SHIFT_IDS',
      values: Array.from(new Set([...shiftIds, ...state.shiftIds]))
    });
  };

  const removeShiftIds = (shiftIds: number[]) => {
    const excludeExisting = state.shiftIds.filter((id) => !shiftIds.includes(id));

    dispatch({
      type: 'SET_SHIFT_IDS',
      values: excludeExisting
    });
  };

  const reset = () => {
    dispatch({
      type: 'SET_INITIAL_STATE'
    });
  };

  const updateOn = (on: string) => {
    dispatch({
      type: 'SET_ON',
      value: on
    });
  };

  const updateShiftSearchKw: (value: string) => void = (value) => {
    dispatch({
      type: 'SET_SHIFT_SEARCH_KW',
      value
    });
  };

  const filteredSelectedOrderIds = state.selectedOrderIds.filter((id) => state.filteredOrderIds.includes(id));

  const selectedOrderIdsEvery: () => boolean = () => {
    if (state.selectedOrderIds.length === 0) return false;

    return filteredSelectedOrderIds.length === state.filteredOrderIds.length;
  };

  const selectedOrderIdsSome: () => boolean = () => {
    if (state.selectedOrderIds.length === 0) return false;

    return filteredSelectedOrderIds.length !== state.filteredOrderIds.length;
  };

  const resetFilteredSelectedOrderIds = () => {
    dispatch({
      type: 'SET_SELECTED_ORDER_IDS',
      values: []
    });
  };

  const selectFilteredOrderIds = () => {
    dispatch({
      type: 'SET_SELECTED_ORDER_IDS',
      values: Array.from(new Set([...state.orderIds.filter((id) => state.filteredOrderIds.includes(id)), ...state.selectedOrderIds]))
    });
  };

  const updateOrderSearchKw = (value: string) => {
    dispatch({
      type: 'SET_ORDER_SEARCH_KW',
      value
    });
  };

  const setHowToPlanning = (value: HowToPlanningType) => {
    dispatch({
      type: 'SET_HOW_TO_PLANNING',
      value
    });
  };

  const setPlanningPriority = (value: PlanningPriorityType) => {
    dispatch({
      type: 'SET_PLANNING_PRIORITY',
      value
    });
  };

  const setAvailabilityOfExpressway = (value: boolean) => {
    dispatch({
      type: 'SET_AVAILABILITY_OF_EXPRESSWAY',
      value
    });
  };

  const setIsForcePlanning = (value: boolean) => {
    dispatch({
      type: 'SET_IS_FORCE_PLANNING',
      value
    });
  };

  const setIgnoreWeightConstraints = (value: boolean) => {
    dispatch({
      type: 'SET_IGNORE_WEIGHT_CONSTRAINTS',
      value
    });
  };

  const setIgnoreTimeConstraints = (value: boolean) => {
    dispatch({
      type: 'SET_IGNORE_TIME_CONSTRAINTS',
      value
    });
  };

  const setOrders = (values: OrderModel[]) => {
    dispatch({
      type: 'SET_ORDERS',
      values
    });
  };

  const setShifts = (values: ShiftModel[]) => {
    dispatch({
      type: 'SET_SHIFTS',
      values
    });
  };

  useEffect(() => {
    if (!state.isForcePlanning) {
      dispatch({
        type: 'SET_IGNORE_WEIGHT_CONSTRAINTS',
        value: false
      });
    }
  }, [state.isForcePlanning]);

  const value: ContextValueEntity = useMemo(
    () => ({
      reset,
      updateIsLoading,
      updateOn,
      updateShiftSearchKw,
      addShiftIds,
      removeShiftIds,
      addSelectedShiftIds,
      removeSelectedShiftIds,
      updateOrderSearchKw,
      addOrderIds,
      removeOrderIds,
      addSelectedOrderIds,
      removeSelectedOrderIds,
      selectedOrderIdsEvery,
      selectedOrderIdsSome,
      resetFilteredSelectedOrderIds,
      selectFilteredOrderIds,
      setHowToPlanning,
      setPlanningPriority,
      setAvailabilityOfExpressway,
      setIsForcePlanning,
      setIgnoreWeightConstraints,
      setIgnoreTimeConstraints,
      setOrders,
      setShifts,
      ...state
    }),
    [state]
  );

  return (
    <PlansContext.Provider
      value={value}
    >
      {children}
    </PlansContext.Provider>
  );
});

PlansProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default PlansContext;
