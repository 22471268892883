import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import SystemUpdateAltRoundedIcon from '@mui/icons-material/SystemUpdateAltRounded';
import { Box, Button, Dialog, DialogActions, DialogTitle, Tooltip } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector
} from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, memo, useContext, useEffect, useRef, useState } from 'react';
import { fiveZeroZeroErrorMessage } from 'src/constants/messages';
import LicenseContext from 'src/contexts/LicenseContext';
import { queryKey } from 'src/hooks/usePlaces.request';

import MyGridToolbarFilterButton from '../common/MyGridToolbarFilterButton';

const CustomToolbar: FC<{
  toggleDialogIsOpen: () => void;
  onClickDeletePlaces: () => void;
  setIsLoading: (prevState: boolean) => boolean;
  canDeleteAll: boolean;
  deleteAll: (settledFnk: () => void) => void;
  handleDownloadCSV: () => void;
}> = memo((
  {
    toggleDialogIsOpen,
    onClickDeletePlaces,
    setIsLoading,
    canDeleteAll,
    deleteAll,
    handleDownloadCSV,
  }
  ) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const licenseContext = useContext(LicenseContext);

  const [importFile, setImportFile] = useState<File | undefined>(undefined);
  const importButtonOnClick = (event: ChangeEvent<HTMLInputElement>) => {
    setImportFile(event.target.files[0]);
  };

  const [deleteHandlerDialogIsOpen, setDeleteHandlerDialogIsOpen] = useState<boolean>(false);

  const deleteHandlerDialogOnClose = () => {
    setDeleteHandlerDialogIsOpen(false);
  };

  const deleteBtnOnClick = () => {
    if (canDeleteAll) {
      setDeleteHandlerDialogIsOpen(true);

      return;
    }

    onClickDeletePlaces();
    setDeleteHandlerDialogIsOpen(false);
  };

  useEffect(() => {
    if (!importFile) return;

    const requestPath = '/api/v2/places/import';

    const data = new FormData();
    data.append('file', importFile);

    setIsLoading(true);
    enqueueSnackbar('インポートを開始します');

    axios
      .post(requestPath, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(
        (response: AxiosResponse<{ severity: string; message: string }>) => {
          enqueueSnackbar(response.data.message);
        }
      )
      .catch((e) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (e.response.status === 400) {
          enqueueSnackbar(
            '取り込めない地点情報がありました、データをご確認ください。'
          );
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
          const blob = new Blob([e.response.data], {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            type: e.response.data.type
          });
          saveAs(blob, 'errors.txt');
        } else {
          enqueueSnackbar(fiveZeroZeroErrorMessage);
        }
      })
      .finally(() => {
        setImportFile(undefined);
        setIsLoading(false);
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries([queryKey]);
        if (importRef) {
          importRef.current.value = '';
        }
      });
  }, [enqueueSnackbar, importFile, queryClient, setIsLoading]);

  const importRef = useRef<HTMLInputElement>(null);

  const [disableImport, setDisableImport] = useState<boolean>(false);
  const [importTooltip, setImportTooltip] = useState<string>('');

  useEffect(() => {
    if (licenseContext?.config?.selected_company_id === 0) {
      setDisableImport(true);
      setImportTooltip('インポート対象の事業所を選択してください');
    } else {
      setDisableImport(false);
      setImportTooltip('');
    }
  }, [licenseContext?.config]);

  return (
    <>
      <GridToolbarContainer
        sx={{
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}
            flexGrow={1}
          >
            <GridToolbarColumnsButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
            <MyGridToolbarFilterButton />
            <GridToolbarDensitySelector nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
            <Tooltip title={importTooltip}>
              <Button
                startIcon={<CloudUploadRoundedIcon />}
                sx={{
                  backgroundColor: '#fff'
                }}
                disabled={disableImport}
              >
                インポート
                <input
                  type="file"
                  onChange={importButtonOnClick}
                  ref={importRef}
                  accept="text/csv"
                  style={{
                    opacity: 0,
                    appearance: 'none',
                    position: 'absolute',
                    width: '100%',
                    cursor: 'pointer'
                  }}
                />
              </Button>
            </Tooltip>
            <Button
              startIcon={<SystemUpdateAltRoundedIcon />}
              onClick={handleDownloadCSV}
              sx={{ textTransform: 'none' }}
            >
              エクスポート
            </Button>
            <Button
              startIcon={<AddCircleOutlinedIcon />}
              onClick={toggleDialogIsOpen}
            >
              地点追加
            </Button>
            <Button
              startIcon={<DeleteForeverOutlinedIcon />}
              onClick={deleteBtnOnClick}
            >
              地点削除
            </Button>
          </Box>
        </Box>
      </GridToolbarContainer>
      <Dialog
        open={deleteHandlerDialogIsOpen}
        onClose={deleteHandlerDialogOnClose}
      >
        <DialogTitle>
          削除する対象を選択してください
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            startIcon={<DeleteForeverRoundedIcon />}
            onClick={() => {
              deleteAll(deleteHandlerDialogOnClose);
            }}
          >
            すべて削除する
          </Button>
          <Button
            onClick={() => {
              onClickDeletePlaces();
              setDeleteHandlerDialogIsOpen(false);
            }}
            variant="outlined"
            startIcon={<DeleteSweepRoundedIcon />}
          >
            選択したものを削除する
          </Button>
          <Button
            onClick={deleteHandlerDialogOnClose}
            startIcon={<HighlightOffRoundedIcon />}
          >
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default CustomToolbar;
