import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import {
  PlanningMapUnallocatedOrderPositionEntity
} from 'src/entities/import/PlanningMapUnallocatedOrderPosition.entity';

const getPlanningMapUnallocatedOrderPositions = (startOn: string, endOn: string) => async () => {
  const requestPath = `/api/v3/plannings/${startOn}/orders/positions?end_on=${endOn}`;
  const response: AxiosResponse<PlanningMapUnallocatedOrderPositionEntity[]> = await axios.get(requestPath);

  return response.data;
};

export const useQueryPlanningMapUnallocatedOrderPositions = (startOn: string, endOn: string) => useQuery(
  ['planningMapUnallocatedOrderPositions', { startOn, endOn }],
  getPlanningMapUnallocatedOrderPositions(startOn, endOn),
  {
    staleTime: Infinity,
    enabled: [startOn, endOn].every((maybe) => maybe)
  }
);
