import { FC, memo, useState, useEffect } from 'react';
import OrderSplitForm from 'src/components/V2OrderSplitForm';
import { OrderSplitEntity } from 'src/entities/orderSplitEntity';
import { useQueryOrder } from 'src/hooks/useQueryOrders';

type Props = {
  orderId: undefined | number;
  startOn: string;
  endOn: string;
  onClose: () => void;
  onSubmit: () => void;
}

const OrderSplitFormPresenter: FC<Props> = memo((
  {
    orderId,
    startOn,
    endOn,
    onClose,
    onSubmit
  }
) => {
  const { data } = useQueryOrder(orderId);
  const [entity, setEntity] = useState<OrderSplitEntity | undefined>(undefined);

  useEffect(() => {
    if (!data) return;

    setEntity({
        order_id: data.id,
        original_item_count: data.item_count,
        original_item_total_weight_kg: data.item_total_weight_kg,
        original_item_total_volume_m3: data.item_total_volume_m3,
        is_item_total_volume_m3_required: data.item_total_volume_m3 !== undefined
    });
  }, [data]);

  if (!entity) return null;

  return (
    <OrderSplitForm
      onClose={onClose}
      createAfterCallbackFnc={onSubmit}
      entity={entity}
    />
  );
});

export default OrderSplitFormPresenter;
