import { FC, memo, useContext } from 'react';
import LicenseContext from 'src/contexts/LicenseContext';
import { DisplayOperationRowEntity } from 'src/entities/DisplayOperationRow.entity';
import { OperationDiff } from 'src/entities/OperationDirection.entity';
import { useOperationDirectionsRequestQuery } from 'src/hooks/useOperationDirections.request.query';
import { ShiftWithCycleModel } from 'src/models/ShiftModel';

import LoadingComponent from './LoadingComponent';

export type PresenterProps = {
  shiftWithCycleModel: ShiftWithCycleModel;
  action: '積' | '降' | null;
  unit: 'kg' | '才';
  operations: DisplayOperationRowEntity[];
  isYC: boolean;
  operationDiffs: OperationDiff;
  shiftWithCycleModels: ShiftWithCycleModel[];
}

type Props = {
  shiftIds: number[];
  presenter: FC<PresenterProps>;
  action: '積' | '降' | null;
}

const OperationDirectionsComponent: FC<Props> = memo(({ action, shiftIds, presenter: Presenter }) => {
  const licenseContext = useContext(LicenseContext);
  const { data, isError, isLoading } = useOperationDirectionsRequestQuery(shiftIds);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (isError) {
    return <>Error</>;
  }

  const shiftEntities = data?.shifts || [];
  const operations: DisplayOperationRowEntity[] = data?.operations || [];
  const shiftWithCycleModels = shiftEntities.map((entity) => new ShiftWithCycleModel(entity));

  const isYC = data.isYC || false;
  const operationDiffs = data?.operationDiffs || {};

  return (
    <>
      {
        shiftWithCycleModels.map((shiftWithCycleModel, idx) => {
          if (!shiftWithCycleModel) return <div />;

          return (
            <Presenter
              key={['OperationDirection', shiftWithCycleModel.entity.id].join('-')}
              shiftWithCycleModel={shiftWithCycleModel}
              action={action}
              unit={licenseContext?.config?.unit}
              operations={operations}
              isYC={isYC}
              operationDiffs={operationDiffs}
              shiftWithCycleModels={shiftWithCycleModels}
            />
          );
        })
      }
    </>
  );
});

export default OperationDirectionsComponent;
