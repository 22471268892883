import { Divider, Stack } from '@mui/material';
import { FC, memo, useContext, useEffect, useMemo, useState } from 'react';
import LicenseContext from 'src/contexts/LicenseContext';
import { GarageEntity } from 'src/entities/Garage.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsOperationEntity } from 'src/entities/PlanningsOperation.entity';
import {
  PlanningsOperationDeliveryByDeliveryIdEntity,
  PlanningsOperationEntitiesWithStatsByDeliveryIdEntity
} from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PlanningsWorkingStatisticEntity } from 'src/entities/PlanningsWorkingStatistic.entity';
import { SelectedCycle } from 'src/entities/SelectedCycle.entity';
import { TransferRequestEntity } from 'src/entities/transferRequestEntity';
import { Operations } from 'src/models/Operations';
import { PlanningsOperationDelivery, PlanningsOperationPlace } from 'src/models/PlanningsOperationGroup.model';

import { AllocateHistoryEntity } from '../../../entities/AllocateHistory.entity';
import { PastOperationAndDriverIdEntity } from '../../../entities/PastOperationAndDriverId.entity';

import PlanningsDeliveryPresenter from './PlanningsDelivery.presenter';

type Props = {
  garage: GarageEntity;
  truck: PlanningsTruckEntity;
  driversOnTheTruck: PlanningsDriverEntity[];
  deliveriesOnTheTruck: PlanningsDeliveryEntity[];
  operationEntities: PlanningsOperationEntity[];
  updateDisplayOrderId: (orderId: number) => void;
  deliveryEntities: PlanningsDeliveryEntity[];
  driverEntities: PlanningsDriverEntity[];
  truckEntities: PlanningsTruckEntity[];
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => void;
  openTransferDialog: (entity: TransferRequestEntity) => void;
  isLoading: boolean;
  updateWorkingStatisticses: (entity: PlanningsWorkingStatisticEntity) => void;
  currentHistoryVersion: string | undefined;
  updateEditDriverId: (driverId: number) => void;
  pastOperationsData: undefined | PastOperationAndDriverIdEntity[];
  latestAllocateHistory: AllocateHistoryEntity | undefined;
  expandAll: boolean;
  requestSingleAlgorithmPlanning: (deliveryId: number, orderOperationIdsForSort: number[], deleteOrderIdsFromOperations: number[], orderOperationCycleIndexes?: { [key: number]: number }) => void;
  startOn: string;
  endOn: string;
  selectedCycleIndexes: SelectedCycle[];
  updateSelectedCycleIndexes: (deliveryId: number, cycleIndexes: number[]) => void;
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
  resetEditPlaces: () => void;
  updateEditPlaces: (deliveryId: number, cycleIndex: number, places: PlanningsOperationPlace[]) => void;
  addEmptyCycle: (deliveryId: number) => void;
  removeEmptyCycle: (deliveryId: number, cycleIndex: number) => void;
  addDeliveryElementRef: (deliveryId: number, element: HTMLElement) => void;
}

const PlanningsDeliveriesPresenter: FC<Props> = memo((
  {
    garage,
    truck,
    driversOnTheTruck,
    deliveriesOnTheTruck,
    operationEntities,
    updateDisplayOrderId,
    deliveryEntities,
    driverEntities,
    truckEntities,
    mutateDeleteOrdersOperations,
    openTransferDialog,
    isLoading,
    updateWorkingStatisticses,
    currentHistoryVersion,
    updateEditDriverId,
    pastOperationsData,
    latestAllocateHistory,
    expandAll,
    requestSingleAlgorithmPlanning,
    startOn,
    endOn,
    selectedCycleIndexes,
    updateSelectedCycleIndexes,
    planningsOperationDeliveryByDeliveryIdEntity,
    resetEditPlaces,
    updateEditPlaces,
    addEmptyCycle,
    removeEmptyCycle,
    addDeliveryElementRef,
  }
) => {
  const licenseContext = useContext(LicenseContext);
  const [planningsOperationEntitiesWithStatsByDeliveryIdEntity, setPlanningsOperationEntitiesWithStatsByDeliveryIdEntity] = useState<PlanningsOperationEntitiesWithStatsByDeliveryIdEntity>({});
  const [beforePlanningsOperationEntitiesWithStatsByDeliveryIdEntity, setBeforePlanningsOperationEntitiesWithStatsByDeliveryIdEntity] = useState<PlanningsOperationEntitiesWithStatsByDeliveryIdEntity>({});
  const [beforePlanningsOperationDeliveryByDeliveryIdEntity, setBeforePlanningsOperationDeliveryByDeliveryIdEntity] = useState<PlanningsOperationDeliveryByDeliveryIdEntity>({});

  useEffect(() => {
    if (!operationEntities) return;
    if (!licenseContext.config) return;

    const model = new Operations(licenseContext.config, operationEntities);

    setPlanningsOperationEntitiesWithStatsByDeliveryIdEntity(
      model.groupAndSortEntitiesByDeliveryIdWithVolumeAndWeightIdx()
    );
  }, [licenseContext.config, operationEntities]);

  useEffect(() => {
    if (!latestAllocateHistory) return;
    if (!licenseContext.config) return;

    const entities: PlanningsOperationEntity[] = latestAllocateHistory.deliveryEntities.flatMap((it) => it.operations);
    const model = new Operations(licenseContext.config, entities);

    setBeforePlanningsOperationEntitiesWithStatsByDeliveryIdEntity(
      model.groupAndSortEntitiesByDeliveryIdWithVolumeAndWeightIdx()
    );
    const tmp = model.createPlanningsOperationDeliveryByDeliveryIdEntity();
    const entity = deliveryEntities.reduce((acc, delivery) => {
      acc[delivery.id] = tmp[delivery.id] ?? PlanningsOperationDelivery.empty(licenseContext.config?.delivery_list_grouping);
      return acc;
    }, {});
    setBeforePlanningsOperationDeliveryByDeliveryIdEntity(entity);
  }, [deliveryEntities, latestAllocateHistory, licenseContext.config]);

  const emptyArray = useMemo(() => ([]), []);

  if (!planningsOperationDeliveryByDeliveryIdEntity) {
    return null;
  }

  return (
    <Stack divider={<Divider />}>
      {deliveriesOnTheTruck.map((delivery) => {
        const selectedDeliveryIds = selectedCycleIndexes.map((it) => it.deliveryId);
        let selected = false;
        if ([delivery, selectedDeliveryIds].every((maybe) => maybe)) {
          selected = selectedDeliveryIds.includes(delivery.id);
        }

        const beforeOperationEntityWithStatsEntitiesDefault = latestAllocateHistory ? emptyArray : null;
        const operationEntityWithStatsEntities = planningsOperationEntitiesWithStatsByDeliveryIdEntity[delivery.id] || emptyArray;
        const beforeOperationEntityWithStatsEntities = beforePlanningsOperationEntitiesWithStatsByDeliveryIdEntity[delivery.id] || beforeOperationEntityWithStatsEntitiesDefault;
        const planningsOperationDelivery = planningsOperationDeliveryByDeliveryIdEntity[delivery.id];
        const beforePlanningsOperationDelivery = beforePlanningsOperationDeliveryByDeliveryIdEntity[delivery.id];

        return (
          <PlanningsDeliveryPresenter
            key={[
                'PlanningsDeliveriesPresenter',
                'PlanningsDeliveryPresenter',
                delivery.id
              ].join('-')}
            garage={garage}
            truck={truck}
            driver={driversOnTheTruck.find((it) => it.id === delivery.driverId)}
            delivery={delivery}
            operationEntityWithStatsEntities={operationEntityWithStatsEntities}
            selected={selected}
            updateDisplayOrderId={updateDisplayOrderId}
            deliveryEntities={deliveryEntities}
            driverEntities={driverEntities}
            truckEntities={truckEntities}
            mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
            openTransferDialog={openTransferDialog}
            isLoading={isLoading}
            updateWorkingStatisticses={updateWorkingStatisticses}
            currentHistoryVersion={currentHistoryVersion}
            updateEditDriverId={updateEditDriverId}
            pastOperationsData={pastOperationsData}
            beforeOperationEntityWithStatsEntities={beforeOperationEntityWithStatsEntities}
            expandAll={expandAll}
            planningsOperationDelivery={planningsOperationDelivery}
            beforePlanningsOperationDelivery={beforePlanningsOperationDelivery}
            requestSingleAlgorithmPlanning={requestSingleAlgorithmPlanning}
            startOn={startOn}
            endOn={endOn}
            planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
            selectedCycleIndexes={selectedCycleIndexes}
            updateSelectedCycleIndexes={updateSelectedCycleIndexes}
            resetEditPlaces={resetEditPlaces}
            updateEditPlaces={updateEditPlaces}
            addEmptyCycle={addEmptyCycle}
            removeEmptyCycle={removeEmptyCycle}
            addDeliveryElementRef={addDeliveryElementRef}
          />
        );
      })}
    </Stack>
  );
});

export default PlanningsDeliveriesPresenter;
