import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';

const requestPath = (startOn: string, endOn: string) => `/api/v3/plannings/${startOn}/drivers?end_on=${endOn}`;

const getAll = (startOn: string, endOn: string) => async () => {
  const response: AxiosResponse<PlanningsDriverEntity[]> = await axios.get(requestPath(startOn, endOn));

  return response.data;
};
export const useQueryPlanningsDrivers = (startOn: string, endOn: string) => useQuery(
  ['useQueryPlanningsDrivers', { startOn, endOn }],
  getAll(startOn, endOn),
  {
    staleTime: Infinity,
    enabled: Boolean(startOn) && Boolean(endOn)
  }
);
