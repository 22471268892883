import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import Leaflet, { LeafletEventHandlerFnMap } from 'leaflet';
import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet';

type Props = {
  dialogIsOpen: boolean,
  closeDialog: () => void;
  loading: boolean;
  lat: string;
  lng: string;
  updateLatLng: (lat: string, lng: string) => void;
  isOrder?: boolean;
}

const CoordinationEditDialog: FC<Props> = memo(({
  dialogIsOpen,
  closeDialog,
  loading,
  lat,
  lng,
  updateLatLng,
  isOrder,
}) => {
  const initializeCenter:[number, number] = [35.6812144014183, 139.7672018734606];
  const markerRef = useRef<Leaflet.Marker>(null);

  const [position, setPosition] = useState<[number, number]>(null);
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');

  const icon = new Leaflet.Icon({
    iconUrl: '/static/images/markers/red.png',
    iconSize: [24, 42],
    iconAnchor: [12, 42],
    tooltipAnchor: [-12, -42],
  });

  const onClickClose = () => {
    closeDialog();
  };

  // ある程度の長さで切りたいので toFixed しているが、
  // toFixedすると7桁に揃えるため末尾に0が残ってしまうことがあるので、
  // 再度Numberにして末尾の0を削除する。
  const latlngToString = (num: number) => Number(num.toFixed(7)).toString();

  const onClickSave = () => {
    const marker = markerRef.current;
    updateLatLng(
      latlngToString(marker.getLatLng().lat),
      latlngToString(marker.getLatLng().lng),
    );
  };

  const resetPosition = () => {
    setLatitude(lat);
    setLongitude(lng);
  };

  const markerEventHandler: LeafletEventHandlerFnMap = {
    dragend: (e) => {
      const marker = markerRef.current;
      setLatitude(latlngToString(marker.getLatLng().lat));
      setLongitude(latlngToString(marker.getLatLng().lng));
    },
  };

  function ChangeMapCenter({ pos }) {
    const mapCenter = useMap();
    mapCenter.panTo(pos as [number, number]);

    return null;
  }

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteText = e.clipboardData.getData('text/plain');
    if (pasteText.includes(',')) {
      const values = pasteText.split(',', 2).map((value) => value.trim());
      setLatitude(values[0]);
      setLongitude(values[1]);
    }
  };

  useEffect(() => {
    if (!(lat && lng)) return;

    setPosition([Number(lat), Number(lng)]);
    setLatitude(lat);
    setLongitude(lng);
  }, [lat, lng]);

  useEffect(() => {
    if (!latitude || !longitude) return;

    const pos: [number, number] = [Number(latitude), Number(longitude)];
    setPosition(pos);
  }, [latitude, longitude]);

  const titleLayer = useMemo(() => (
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
  ), []);

  return (
    <Dialog open={dialogIsOpen} maxWidth="md" fullWidth onClose={closeDialog}>
      <DialogTitle>
        マーカーを移動するか、緯度経度の数値を入力して調整してください
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" gap={1}>
          <TextField
            margin="normal"
            type="number"
            variant="standard"
            label="緯度"
            id="latitude"
            name="latitude"
            value={latitude}
            onChange={(e) => setLatitude(e.target.value)}
            onPaste={onPaste}
          />
          <TextField
            margin="normal"
            type="number"
            variant="standard"
            label="経度"
            id="longitude"
            name="longitude"
            value={longitude}
            onChange={(e) => setLongitude(e.target.value)}
            onPaste={onPaste}
          />
          <Button onClick={resetPosition}>戻す</Button>
        </Stack>
        <MapContainer
          style={{ height: '50vh' }}
          center={initializeCenter}
          zoom={17}
          scrollWheelZoom={false}
        >
          {titleLayer}
          <ChangeMapCenter pos={position} />
          <Marker
            draggable
            position={position}
            icon={icon}
            ref={markerRef}
            eventHandlers={markerEventHandler}
          />
        </MapContainer>
      </DialogContent>
      <DialogActions>
        {isOrder && (
          <Stack>
            <Typography variant="body1">同じ住所の同一日付の案件、同じ住所の地点マスタにも反映されます。</Typography>
          </Stack>
        )}
        <Button onClick={onClickClose} disabled={loading}>キャンセル</Button>
        <Button onClick={onClickSave} variant="contained" disabled={loading}>
          保存する
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default CoordinationEditDialog;
