import { Snackbar, SnackbarContent } from '@mui/material';
import { FC, memo, ReactNode, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import LicenseContext from 'src/contexts/LicenseContext';

type Props = {
  children: ReactNode;
};

const WithoutAppBarLayout: FC<Props> = memo((
  {
    children
  }
) => {
  const licenseContext = useContext(LicenseContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!licenseContext.initialized) return;
    if (licenseContext.authenticated) return;

    navigate('/login');
  }, [licenseContext.initialized, licenseContext.authenticated]);

  return (
    <>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={licenseContext.forceDisabled}
        key="DuplicatingSnackbar"
      >
        <SnackbarContent
          message={` 複製処理を実行中です（残り${licenseContext.pendingCount ? licenseContext.pendingCount.toLocaleString() : '-'}件）。複製中はデータの更新処理はできません。`}
        />
      </Snackbar>
    </>
  );
});

export default WithoutAppBarLayout;
