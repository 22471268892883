import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { RoleEntity } from 'src/entities/Role.entity';

const baseUrl = '/api/admin/v1/roles';

const getRoles = (queryClient: QueryClient) => async () => {
  const res = await axios.get<ApiResponse>(baseUrl);
  return res.data.data as unknown as RoleEntity[];
};

export const useQueryRoles = () => {
  const queryClient = useQueryClient();
  return useQuery(['roles'], getRoles(queryClient), {
    staleTime: Infinity
  });
};
