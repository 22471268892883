import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { LicenseEntity } from 'src/entities/licenseEntity';

const get = () => async () => {
  const requestPath = '/api/v2/session';
  const axiosResponse = await axios.get<LicenseEntity>(requestPath);

  return axiosResponse.data;
};
const refreshSeconds = 1000 * 60;// 1分

export const useSessionRequest = (authenticated: boolean) => useQuery(
  ['useSessionRequest'],
  get(),
  {
    refetchInterval: refreshSeconds,
    retry: 0,
    enabled: authenticated,
  }
);
