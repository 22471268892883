import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { AllowedIpAddressEntity } from 'src/entities/admin/AllowedIpAddress.entity';

const requestPathBuilder = (companyId: number, id: number | null) => [
    `/api/admin/v1/companies/${companyId}/allowed_ip_addresses`,
    id
  ].filter((maybe) => maybe).join('/');

const getRecords = (companyId: number) => async () => {
  const requestPath = requestPathBuilder(companyId, null);

  const response: AxiosResponse<AllowedIpAddressEntity[]> = await axios.get(requestPath);

  return response.data;
};

export const useAllowedConnectionsQuery = (companyId: number) => useQuery(
  ['useAllowedConnectionsQuery'],
  getRecords(companyId),
  {
    staleTime: Infinity
  }
);

export const useMutationAllowedConnection = () => {
  const queryClient = useQueryClient();

  return {
    add: useMutation(
      ({ companyId, value }: Omit<AllowedIpAddressEntity, 'id'>) => axios.post(requestPathBuilder(companyId, null), {
        allowed_ip_address: {
          value
        }
      }),
      {
        onSuccess: () => queryClient.invalidateQueries(['useAllowedConnectionsQuery'])
      }
    ),
    destroy: useMutation(
      ({ companyId, id }: Omit<AllowedIpAddressEntity, 'ipAddress'>) => axios.delete(requestPathBuilder(companyId, id)),
      {
        onSuccess: () => queryClient.invalidateQueries(['useAllowedConnectionsQuery']),
      }
    )
  };
};
