import { format } from 'date-fns-tz';
import ja from 'date-fns/locale/ja';

const toDayAndHourMinutes = (maybe: Date | undefined) => {
  if (!maybe) return undefined;

  return format(maybe, 'dd日 HH:mm', { timeZone: 'Asia/Tokyo' });
};
const toHourMinutes = (maybe: Date | undefined) => {
  if (!maybe) return undefined;

  return format(maybe, 'HH:mm', { timeZone: 'Asia/Tokyo' });
};
const toHourMinuteSeconds = (maybe: Date | undefined) => {
  if (!maybe) return undefined;

  return format(maybe, 'HH:mm:ss', { timeZone: 'Asia/Tokyo' });
};
const toMmDd = (maybe: Date) => {
  if (!maybe) return '';

  return format(maybe, 'MM/dd', { locale: ja });
};

const toYyyyMmDdHhMm: (at: Date) => string = (at: Date) => format(at, 'yyyy-MM-dd HH:mm', { timeZone: 'Asia/Tokyo' });
const toYyyyMmDd: (at: Date) => string = (at: Date) => format(at, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' });
const toYyyy: (at: Date) => string = (at: Date) => format(at, 'yyyy', { timeZone: 'Asia/Tokyo' });
const toYyyyMm: (at: Date) => string = (at: Date) => format(at, 'yyyy年MM月', { timeZone: 'Asia/Tokyo' });

const toDayAndDayOfTheWeek = (at: Date) => format(at, 'MM/dd(E)', { locale: ja });

const toMonth = (maybe: Date | undefined) => {
  if (!maybe) return '';

  return [
    format(maybe, 'M', { timeZone: 'Asia/Tokyo' }),
    '月'
  ].join('');
};

const fmtIso8601 = (maybe: Date | undefined) => {
  if (!maybe) return '';

  return format(maybe, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", { timeZone: 'Asia/Tokyo' });
};

const toDayOfWeek = (maybe: Date | undefined) => {
  if (!maybe) return '';

  return format(maybe, 'E', { locale: ja });
};

const datetimeDecorator = {
  toDayAndHourMinutes,
  toHourMinutes,
  toMmDd,
  toYyyyMmDdHhMm,
  toYyyyMmDd,
  toDayAndDayOfTheWeek,
  toMonth,
  fmtIso8601,
  toHourMinuteSeconds,
  toDayOfWeek,
  toYyyyMm,
  toYyyy,
};

export default datetimeDecorator;
