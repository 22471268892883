import { LeafletMouseEvent, MarkerCluster } from 'leaflet';
import { FC, memo, useMemo } from 'react';
import ReactGA from 'react-ga4';
import MarkerClusterGroup from 'src/components/MarkerClusterGroup';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import {
  PlanningMapUnallocatedOrderPositionEntity
} from 'src/entities/import/PlanningMapUnallocatedOrderPosition.entity';
import { OrderEntity } from 'src/entities/orderEntity';

import PlanningMapOrderMarkerPresenter from './PlanningMapOrderMarker.presenter';

type Props = {
  entities: PlanningMapUnallocatedOrderPositionEntity[];
  selectedIds: number[];
  addSelectedId: (id: number) => void;
  removeSelectedId: (id: number) => void;
  orderEntityMap: Map<number, OrderEntity>;
  displayKinds: string[];
}

const PlanningMapOrderMarkersPresenter: FC<Props> = memo((
  {
    entities,
    selectedIds,
    addSelectedId,
    removeSelectedId,
    orderEntityMap,
    displayKinds,
  }
) => {
  const gaEvent = (onOff: 'ON' | 'OFF') => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_MAP, button_name: `案件クラスター ${onOff}` });
  };

  const onClick = (ev: LeafletMouseEvent) => {
    gaEvent('ON');
    const cluster = ev.propagatedFrom as MarkerCluster;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
    cluster.getAllChildMarkers().map((marker) => marker.options.eventHandlers.click());
  };

  const onContextMenu = (ev: LeafletMouseEvent) => {
    gaEvent('OFF');
    const cluster = ev.propagatedFrom as MarkerCluster;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
    cluster.getAllChildMarkers().map((marker) => marker.options.eventHandlers.contextmenu());
  };

  const displayEntities = useMemo(() => {
    if (entities.length === 0) return [];
    if (!orderEntityMap) return [];

    const showLoad = displayKinds.includes('積地');
    const showUnload = displayKinds.includes('納品地');
    if (!showLoad && !showUnload) return [];
    if (showLoad && showUnload) return entities;

    return entities.map((entity) => {
      const orders = entity.ids.map((orderId) => orderEntityMap.get(orderId)).filter((it) => it);
      const filtered = orders.filter((it) => {
        const lat = showLoad ? it.loading_lat : it.unloading_lat;
        const lng = showLoad ? it.loading_lng : it.unloading_lng;
        return lat === Number(entity.position[0]) && lng === Number(entity.position[1]);
      });
      return { ids: filtered.map((it) => it.id), position: entity.position };
    }).filter((it) => it.ids.length > 0);
  }, [displayKinds, entities, orderEntityMap]);

  return (
    <MarkerClusterGroup
      animate={false}
      animateAddingMarkers={false}
      chunkedLoading
      maxClusterRadius={(zoom: number) => 100 / zoom}
      zoomToBoundsOnClick={false}
      onClick={onClick}
      onContextMenu={onContextMenu}
    >
      {displayEntities.map((entity) => (
        <PlanningMapOrderMarkerPresenter
          key={[
            'PlanningMapOrderMarkersPresenter',
            ...entity.position,
            'PlanningMapOrderMarkerPresenter',
            ...entity.ids
          ].join('-')}
          entity={entity}
          selectedIds={selectedIds}
          addSelectedId={addSelectedId}
          removeSelectedId={removeSelectedId}
          orderEntityMap={orderEntityMap}
        />
      ))}
    </MarkerClusterGroup>
  );
});

export default PlanningMapOrderMarkersPresenter;
