import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { Box, Button, Typography } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-premium';
import { FC, memo } from 'react';
import { CompanyEntity } from 'src/entities/admin/companyEntity';

import { AdminSideNav } from '../SideNav';

export const AdminSubscriptionsToolbar: FC<{
  openAppendDialog: () => void;
  company: CompanyEntity
}> = memo(({ openAppendDialog, company }) => (
  <GridToolbarContainer
    sx={{ width: '100%' }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
        flexGrow={1}
      >
        <Typography variant="h4" margin={1}>
          {company.name}
        </Typography>
        <Button
          startIcon={<AddCircleOutlinedIcon />}
          onClick={openAppendDialog}
        >
          サブスクリプション追加
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
        flexGrow={1}
      >
        <AdminSideNav />
      </Box>
    </Box>
  </GridToolbarContainer>
));
