import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { Dialog, DialogActions, Button, DialogTitle } from '@mui/material';
import React, { FC, memo } from 'react';

type Props = {
  open: boolean;
  handleRestoreAll: () => void;
  handleRestoreSelected: () => void;
  handleCancel: () => void;
}

const RestoreSplittedOrdersConfirmDialog: FC<Props> = memo(({
  open,
  handleRestoreAll,
  handleRestoreSelected,
  handleCancel
}) => {
  const onClickRestoreAll = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    handleRestoreAll();
  };

  const onClickRestoreSelected = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    handleRestoreSelected();
  };

  const onClickCancel = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    handleCancel();
  };

  const onClose = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Dialog
      open={open}
      // sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="md"
      onClose={onClose}
    >
      <DialogTitle>
        復元する案件を選択してください。
      </DialogTitle>
      <DialogActions>
        <Button
          color="error"
          variant="outlined"
          startIcon={<DeleteForeverRoundedIcon />}
          onClick={onClickRestoreAll}
        >
          分割した案件をすべて復元する
        </Button>
        <Button
          onClick={onClickRestoreSelected}
          variant="outlined"
          startIcon={<DeleteSweepRoundedIcon />}
        >
          選択した案件のみ復元する
        </Button>
        <Button
          onClick={onClickCancel}
          startIcon={<HighlightOffRoundedIcon />}
        >
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default RestoreSplittedOrdersConfirmDialog;
