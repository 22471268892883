import { FC, memo } from 'react';

const MatchingPage: FC = memo(() => {
  const redirectTo = `${window.location.href.replace(/\/$/, '')}/`;

  window.location.href = redirectTo;

  return null;
});

export default MatchingPage;
