import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { SearchConditionRequestEntity } from 'src/entities/SearchCondition.request.entity';

const bulkCreationPath = (companyId: number) => `/api/v3/companies/${companyId}/not_allocated_order_search_conditions/bulk_creation`;
const deletePath = (conditionId: number) => `/api/v3/not_allocated_order_search_conditions/${conditionId}`;
export const useNotAllocatedOrderSearchCondition = () => ({
  bulkCreation: useMutation((dto: { companyId: number, conditions: SearchConditionRequestEntity[] }) => axios.post(bulkCreationPath(dto.companyId), { conditions: dto.conditions.map((it) => ({
      ...it,
      searchConditions: JSON.stringify(it.searchConditions),
    })) })),
  destroy: useMutation((conditionId: number) => axios.delete(deletePath(conditionId)))
});
