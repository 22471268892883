import { GridColDef } from '@mui/x-data-grid';
import { GridValueFormatterParams } from '@mui/x-data-grid-premium';
import numberDecorator from 'src/decorators/number.decorator';
import { EstimationEntity } from 'src/entities/Estimation.entity';
import numberUtil from 'src/utils/number.util';

const costWeightAndOthersColumns: GridColDef[] = [
  {
    field: 'deliveryName',
    headerName: '勤務計画',
    type: 'string',
  },
  {
    field: 'totalItemCount',
    headerName: '数量',
    description: '宵積みは0.5としてカウントされます',
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedUnit(
      params.value,
      '個',
      10,
      ''
    )
  },
  {
    field: 'totalItemWeightGram',
    headerName: '重量',
    description: '宵積みは半分がカウントされます',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromGramToKg(params.row.totalItemWeightGram),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedUnit(
      params.value,
      'kg',
      10,
      ''
    )
  },
  {
    field: 'totalItemVolumeMm3',
    headerName: '体積',
    description: '宵積みは半分がカウントされます',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromMm3ToM3(params.row.totalItemVolumeMm3),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedUnit(
      params.value,
      'm3',
      10,
      ''
    )
  },
  {
    field: 'expectedFeeYenByItemCount',
    headerName: '🖊️1個あたりの運賃',
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'expectedFeeYenByItemWeightKg',
    headerName: '🖊️1kgあたりの運賃',
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'expectedFeeYenByItemVolumeM3',
    headerName: '🖊️1m3あたりの運賃',
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'feeByCount',
    headerName: '運賃（数量）',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => {
      if (!params.row.expectedFeeYenByItemCount) return undefined;

      return params.row.totalItemCount * params.row.expectedFeeYenByItemCount;
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value,
    ),
  },
  {
    field: 'feeByWeight',
    headerName: '運賃（重量）',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => {
      if (!params.row.expectedFeeYenByItemWeightKg) return undefined;

      return params.row.totalItemWeightGram * (params.row.expectedFeeYenByItemWeightKg / 1000);
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value,
    ),
  },
  {
    field: 'feeByVolume',
    headerName: '運賃（体積）',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => {
      if (!params.row.expectedFeeYenByItemVolumeM3) return undefined;

      return params.row.totalItemVolumeMm3 * (params.row.expectedFeeYenByItemVolumeM3 / 1000000000);
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value,
    ),
  },
  {
    field: 'fee',
    headerName: '運賃',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => {
      const count = (() => {
        if (!params.row.expectedFeeYenByItemCount) return undefined;

        return params.row.totalItemCount * params.row.expectedFeeYenByItemCount;
      })();

      const weight = (() => {
        if (!params.row.expectedFeeYenByItemWeightKg) return undefined;

        return params.row.totalItemWeightGram * (params.row.expectedFeeYenByItemWeightKg / 1000);
      })();

      const volume = (() => {
        if (!params.row.expectedFeeYenByItemVolumeM3) return undefined;

        return params.row.totalItemVolumeMm3 * (params.row.expectedFeeYenByItemVolumeM3 / 1000000000);
      })();

      const fares = [
        count,
        weight,
        volume
      ].filter((maybe) => maybe);

      return fares.reduce((prev, current) => prev + current, 0) / fares.length;
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value,
    ),
  },
];

export default costWeightAndOthersColumns;
