import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded';
import {
  FormControl, IconButton,
  InputAdornment,
  InputLabel, LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField
} from '@mui/material';
import { DataGridPremium, jaJP } from '@mui/x-data-grid-premium';
import { FC, memo, useEffect, useState } from 'react';
import { EstimationEntity } from 'src/entities/Estimation.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { EstimationMethodVo } from 'src/vo/EstimationMethod.vo';

import convertToEntities from '../datagridColumns/convertToEntities';
import costDistanceColumns from '../datagridColumns/costDistance.columns';
import costHourColumns from '../datagridColumns/costHour.columns';
import costReserveColumns from '../datagridColumns/costReserve.columns';
import costStrictColumns from '../datagridColumns/costStrict.columns';
import costStrictByShipperColumns from '../datagridColumns/costStrictByShipper.columns';
import costWeightAndOthersColumns from '../datagridColumns/costWeightAndOthersColumns';
import rowsByDeliveryRows from '../datagridColumns/rowsByDelivery.rows';
import rowsByDeliveryAndShippersRows from '../datagridColumns/rowsByDeliveryAndShippers.rows';
import CustomToolbar from '../presenters/CustomToolbar';

type Props = {
  planningsDeliveryEntities: PlanningsDeliveryEntity[];
  planningsDriverEntities: PlanningsDriverEntity[];
  planningsTruckEntities: PlanningsTruckEntity[];
  defaultDriverCostYenPerSeconds: number;
  defaultTruckFuelCostYenPerMm: number;
  defaultTruckInsuranceFeeYenPerDay: number;
  defaultTruckRepairFeeYenPerDay: number;
  defaultTruckExpresswayFeeYenPerDay: number;
  presetEstimationMethod: EstimationMethodVo
  estimationMethods: EstimationMethodVo[];
  onClose: () => void;
}

const EstimationByDeliveriesComponent: FC<Props> = memo((
  {
    planningsDeliveryEntities,
    planningsDriverEntities,
    planningsTruckEntities,
    defaultDriverCostYenPerSeconds,
    defaultTruckFuelCostYenPerMm,
    defaultTruckInsuranceFeeYenPerDay,
    defaultTruckRepairFeeYenPerDay,
    defaultTruckExpresswayFeeYenPerDay,
    presetEstimationMethod,
    estimationMethods,
    onClose,
  }
) => {
  const [rows, setRows] = useState<EstimationEntity[]>([]);
  const [estimationMethod, setEstimationMethod] = useState<EstimationMethodVo | undefined>(undefined);
  const [expectedProfitRate, setExpectedProfitRate] = useState<number>(0);
  const [expectedFeeYenByKm, setExpectedFeeYenByKm] = useState<number>(0);
  const [expectedFeeYenByHour, setExpectedFeeYenByHour] = useState<number>(0);
  const [expectedFeeYenByItemCount, setExpectedFeeYenByItemCount] = useState<number>(0);
  const [expectedFeeYenByItemWeightKg, setExpectedFeeYenByItemWeightKg] = useState<number>(0);
  const [expectedFeeYenByItemVolumeM3, setExpectedFeeYenByItemVolumeM3] = useState<number>(0);

  const expectedProfitRateOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const modified = Number(event.target.value);
      if (modified > 100) {
        setExpectedProfitRate(100);
        return;
      }

      if (modified <= 0) {
        setExpectedProfitRate(0);
        return;
      }

      setExpectedProfitRate(modified);
    } else {
      setExpectedProfitRate(0);
    }
  };

  const estimationMethodOnChange = (event: SelectChangeEvent) => {
    setEstimationMethod(event.target.value as EstimationMethodVo);
  };

  const expectedFeeYenByItemCountOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const modified = Number(event.target.value);

      if (modified <= 0) {
        setExpectedFeeYenByItemCount(0);
        return;
      }

      setExpectedFeeYenByItemCount(modified);
    } else {
      setExpectedFeeYenByItemCount(0);
    }
  };

  const expectedFeeYenByItemWeightKgOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const modified = Number(event.target.value);

      if (modified <= 0) {
        setExpectedFeeYenByItemWeightKg(0);
        return;
      }

      setExpectedFeeYenByItemWeightKg(modified);
    } else {
      setExpectedFeeYenByItemWeightKg(0);
    }
  };

  const expectedFeeYenByItemVolumeM3OnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const modified = Number(event.target.value);

      if (modified <= 0) {
        setExpectedFeeYenByItemVolumeM3(0);
        return;
      }

      setExpectedFeeYenByItemVolumeM3(modified);
    } else {
      setExpectedFeeYenByItemVolumeM3(0);
    }
  };

  const expectedFeeYenByKmOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const modified = Number(event.target.value);

      if (modified <= 0) {
        setExpectedFeeYenByKm(0);
        return;
      }

      setExpectedFeeYenByKm(modified);
    } else {
      setExpectedFeeYenByKm(0);
    }
  };

  const expectedFeeYenByHourOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const modified = Number(event.target.value);

      if (modified <= 0) {
        setExpectedFeeYenByHour(0);
        return;
      }

      setExpectedFeeYenByHour(modified);
    } else {
      setExpectedFeeYenByHour(0);
    }
  };

  const columns = (() => {
    switch (estimationMethod) {
      case '原価積み上げ式':
        return costReserveColumns;
      case '荷主価格式-距離制':
        return costDistanceColumns;
      case '荷主価格式-時間制':
        return costHourColumns;
      case '荷主価格式-個建て':
        return costWeightAndOthersColumns;
      case '荷主価格式-厳密計算':
        return costStrictColumns;
      case '荷主価格式-厳密計算-荷主別':
        return costStrictByShipperColumns;
      default:
        return [];
    }
  })();

  useEffect(() => {
    if (!estimationMethod) {
      setRows([]);
      return;
    }

    const entities: EstimationEntity[] = convertToEntities(
      {
        planningsDeliveryEntities,
        planningsDriverEntities,
        planningsTruckEntities,
        defaultDriverCostYenPerSeconds,
        defaultTruckFuelCostYenPerMm,
        defaultTruckInsuranceFeeYenPerDay,
        defaultTruckRepairFeeYenPerDay,
        defaultTruckExpresswayFeeYenPerDay,
        expectedProfitRate,
        expectedFeeYenByKm,
        expectedFeeYenByHour,
        expectedFeeYenByItemCount,
        expectedFeeYenByItemWeightKg,
        expectedFeeYenByItemVolumeM3,
      }
    );

    if (estimationMethod !== '荷主価格式-厳密計算-荷主別') {
      setRows(
        rowsByDeliveryRows(entities)
      );
      return;
    }

    setRows(
      rowsByDeliveryAndShippersRows(entities)
    );
  }, [
    estimationMethod,
    planningsDeliveryEntities,
    planningsDriverEntities,
    planningsTruckEntities,
    defaultDriverCostYenPerSeconds,
    defaultTruckFuelCostYenPerMm,
    defaultTruckInsuranceFeeYenPerDay,
    defaultTruckRepairFeeYenPerDay,
    defaultTruckExpresswayFeeYenPerDay,
    expectedProfitRate,
    expectedFeeYenByKm,
    expectedFeeYenByHour,
    expectedFeeYenByItemCount,
    expectedFeeYenByItemWeightKg,
    expectedFeeYenByItemVolumeM3
  ]);

  useEffect(() => {
    if (!presetEstimationMethod) {
      setEstimationMethod(undefined);
    } else {
      setEstimationMethod(presetEstimationMethod);
    }
  }, [presetEstimationMethod]);

  useEffect(() => {
    if (!expectedProfitRate) return;

    localStorage.setItem(['logpose', 'log', 'estimation', 'expectedProfitRate'].join('-'), `${expectedProfitRate}`);
  }, [expectedProfitRate]);

  useEffect(() => {
    if (!expectedFeeYenByKm) return;

    localStorage.setItem(['logpose', 'log', 'estimation', 'expectedFeeYenByKm'].join('-'), `${expectedFeeYenByKm}`);
  }, [expectedFeeYenByKm]);

  useEffect(() => {
    if (!expectedFeeYenByHour) return;

    localStorage.setItem(['logpose', 'log', 'estimation', 'expectedFeeYenByHour'].join('-'), `${expectedFeeYenByHour}`);
  }, [expectedFeeYenByHour]);

  useEffect(() => {
    if (!expectedFeeYenByItemCount) return;

    localStorage.setItem(['logpose', 'log', 'estimation', 'expectedFeeYenByItemCount'].join('-'), `${expectedFeeYenByItemCount}`);
  }, [expectedFeeYenByItemCount]);

  useEffect(() => {
    if (!expectedFeeYenByItemWeightKg) return;

    localStorage.setItem(['logpose', 'log', 'estimation', 'expectedFeeYenByItemWeightKg'].join('-'), `${expectedFeeYenByItemWeightKg}`);
  }, [expectedFeeYenByItemWeightKg]);

  useEffect(() => {
    if (!expectedFeeYenByItemVolumeM3) return;

    localStorage.setItem(['logpose', 'log', 'estimation', 'expectedFeeYenByItemVolumeM3'].join('-'), `${expectedFeeYenByItemVolumeM3}`);
  }, [expectedFeeYenByItemVolumeM3]);

  useEffect(() => {
    const val = localStorage.getItem(['logpose', 'log', 'estimation', 'expectedProfitRate'].join('-'));

    if (!val) return;

    setExpectedProfitRate(Number(val));
  }, []);

  useEffect(() => {
    const val = localStorage.getItem(['logpose', 'log', 'estimation', 'expectedFeeYenByKm'].join('-'));

    if (!val) return;

    setExpectedFeeYenByKm(Number(val));
  }, []);

  useEffect(() => {
    const val = localStorage.getItem(['logpose', 'log', 'estimation', 'expectedFeeYenByHour'].join('-'));

    if (!val) return;

    setExpectedFeeYenByHour(Number(val));
  }, []);

  useEffect(() => {
    const val = localStorage.getItem(['logpose', 'log', 'estimation', 'expectedFeeYenByItemCount'].join('-'));

    if (!val) return;

    setExpectedFeeYenByItemCount(Number(val));
  }, []);

  useEffect(() => {
    const val = localStorage.getItem(['logpose', 'log', 'estimation', 'expectedFeeYenByItemWeightKg'].join('-'));

    if (!val) return;

    setExpectedFeeYenByItemWeightKg(Number(val));
  }, []);

  useEffect(() => {
    const val = localStorage.getItem(['logpose', 'log', 'estimation', 'expectedFeeYenByItemVolumeM3'].join('-'));

    if (!val) return;

    setExpectedFeeYenByItemVolumeM3(Number(val));
  }, []);

  return (
    <Stack spacing={1}>
      <Stack direction="row" gap={1}>
        <Stack
          flexGrow={1}
        >
          <FormControl>
            <InputLabel id="estimation-method-label">
              見積り方法
            </InputLabel>
            <Select
              labelId="estimation-method-label"
              id="estimation-method"
              value={estimationMethod || presetEstimationMethod}
              label="見積り方法"
              onChange={estimationMethodOnChange}
            >
              {estimationMethods.map((it) => (
                <MenuItem
                  key={
                    [
                      'EstimationByDeliveriesComponent',
                      'MenuItem',
                      it
                    ].join('-')
                  }
                  value={it}
                  selected={estimationMethod === it}
                >
                  {it}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <IconButton
          onClick={onClose}
        >
          <CloseFullscreenRoundedIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" gap={1}>
        <TextField
          type="number"
          value={expectedProfitRate || ''}
          onChange={expectedProfitRateOnChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="想定粗利益率"
        />
        <TextField
          type="number"
          value={expectedFeeYenByKm || ''}
          onChange={expectedFeeYenByKmOnChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">円</InputAdornment>,
          }}
          label="１kmあたりの運賃"
        />
        <TextField
          type="number"
          value={expectedFeeYenByHour || ''}
          onChange={expectedFeeYenByHourOnChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">円</InputAdornment>,
          }}
          label="１時間あたりの運賃"
        />
        <TextField
          type="number"
          value={expectedFeeYenByItemCount || ''}
          onChange={expectedFeeYenByItemCountOnChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">円</InputAdornment>,
          }}
          label="個数あたりの運賃"
        />
        <TextField
          type="number"
          value={expectedFeeYenByItemWeightKg || ''}
          onChange={expectedFeeYenByItemWeightKgOnChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">円</InputAdornment>,
          }}
          label="kgあたりの運賃"
        />
        <TextField
          type="number"
          value={expectedFeeYenByItemVolumeM3 || ''}
          onChange={expectedFeeYenByItemVolumeM3OnChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">円</InputAdornment>,
          }}
          label="立米あたりの運賃"
        />
      </Stack>
      <Stack>
        <Paper>
          <DataGridPremium
            autoHeight
            rows={rows}
            columns={columns}
            pagination
            pageSize={30}
            components={{
              LoadingOverlay: LinearProgress,
              Toolbar: CustomToolbar,
            }}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            disableSelectionOnClick
            initialState={
              {
                pinnedColumns: {
                  right: [
                    'driverCostYenPerSeconds',
                    'fuelCostPerMm',
                    'insuranceFeeYenPerDay',
                    'repairCostYenPerDay',
                    'expresswayFeeYenPerShift',
                    'expectedProfitRate',
                    'expectedFeeYenByKm',
                    'expectedFeeYenByHour',
                    'expectedFeeYenByItemCount',
                    'expectedFeeYenByItemWeightKg',
                    'expectedFeeYenByItemVolumeM3',
                    'feeByCount',
                    'feeByWeight',
                    'feeByVolume',
                    'feeExcludeArrivalOperation',
                    'fee'
                  ]
                }
              }
            }
          />
        </Paper>
      </Stack>
    </Stack>
  );
});

export default EstimationByDeliveriesComponent;
