import { OrderOperationEntity } from '../entities/orderOperationEntity';

import { OrderModel } from './OrderModel';

export type OrderOperationWithOrder = OrderOperationModel & {
  order: OrderModel;
  placeName: string;
  address: string;
  lat: number;
  lng: number;
  i?: number;
  currentWeightKg?: number;
  currentVolumeM3?: number;
  isLoadKgExceeded?: boolean;
  actionJa: '集荷' | '納品';
  shipperName: string;
};

export class OrderOperationModel {
  id: number;

  shiftId: number;

  orderId: number;

  arrivalAt: Date; // 出発日時

  departureAt: Date; // 到着日時

  drivingDurationSeconds: number; // 運転時間

  operationDurationSeconds: number; // 作業時間

  operationStartAt: Date; // 作業開始日時

  waitingDurationSeconds: number; // 待機時間

  action: '積' | '降'; // 積・降

  drivingDistanceMm: number; // 前地点からの走行距離

  constructor(entity: OrderOperationEntity) {
    this.id = entity.id;
    this.orderId = entity.order_id;
    this.shiftId = entity.shift_id;
    this.arrivalAt = new Date(entity.arrival_at);
    this.departureAt = new Date(entity.departure_at);
    this.drivingDurationSeconds = entity.driving_duration_seconds;
    this.operationDurationSeconds = entity.operation_duration_seconds;
    this.operationStartAt = new Date(entity.operation_start_at);
    this.waitingDurationSeconds = entity.waiting_duration_seconds;
    this.action = entity.action;
    this.drivingDistanceMm = entity.driving_distance_mm;
  }

  merge(orders: OrderModel[]): OrderOperationWithOrder {
    const order: OrderModel = orders.find(({ id }) => this.orderId === id);
    const placeName: string = this.action === '積' ? order.loadingName : order.unloadingName;
    const address: string = this.action === '積' ? order.loadingAddress : order.unloadingAddress;
    const lat: number = this.action === '積' ? order.loadingLat : order.unloadingLat;
    const lng: number = this.action === '積' ? order.loadingLng : order.unloadingLng;
    const actionJa: '集荷' | '納品' = this.action === '積' ? '集荷' : '納品';
    const { shipperName } = order;

    return {
      ...this,
      order,
      placeName,
      address,
      lat,
      lng,
      actionJa,
      shipperName
    };
  }
}
