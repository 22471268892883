import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { GroupEntity, GroupRequestEntity } from 'src/entities/groupEntity';

const getGroups = (queryClient: QueryClient) => async () => {
  const res = await axios.get<ApiResponse>('/api/v2/groups?with_data=1');
  const list = res.data.data;
  return list.map((entity: GroupEntity) => {
    queryClient.setQueryData(['groups', { id: entity.id }], entity);
    return entity;
  });
};

const getGroup = (id: number) => async () => {
  const res = await axios.get<ApiResponse>(`/api/v2/groups/${id}`);
  const entity = JSON.parse(res.data as unknown as string) as GroupEntity;
  return entity;
};

export const useQueryGroups = () => {
  const queryClient = useQueryClient();
  return useQuery(['groups'], getGroups(queryClient), {
    staleTime: Infinity
  });
};

export const useQueryGroup = (id: number) => {
  const queryClient = useQueryClient();
  return useQuery(['groups', { id }], getGroup(id), {
    staleTime: Infinity,
    enabled: Boolean(id)
  });
};

export const useMutationGroup = () => {
  const queryClient = useQueryClient();
  return {
    addGroup: useMutation(
      (group: GroupRequestEntity) => axios.post('/api/v2/groups', group),
      {
        onSuccess: () => queryClient.invalidateQueries(['groups']),
      }
    ),
    updateGroup: useMutation(
      (group: GroupEntity) => axios.put(`/api/v2/groups/${group.id}`, group),
      {
        onSuccess: () => queryClient.invalidateQueries(['groups']),
      }
    ),
    deleteGroup: useMutation(
      (id: number) => axios.delete(`/api/v2/groups/${id}`),
      {
        onSuccess: () => queryClient.invalidateQueries(['groups']),
      }
    ),
  };
};
