import { QueryKey } from '@tanstack/query-core/src/types';
import { QueryFunctionContext, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { PlaceEntity, PlaceRequestEntity } from 'src/entities/placeEntity';

const buildRequestPath: (page: number, withData: boolean) => string = (
  page,
  withData,
) => [`/api/v2/places?page=${page}`, withData ? 'with_data=1' : null].filter((maybe) => !!maybe).join('&');

const fetchPlaces: (ctx: QueryFunctionContext<
  QueryKey,
  number
  >) => Promise<{
  ids: number[];
  total_pages_count: number;
  data: PlaceEntity[];
}> = async ({ pageParam = 1 }) => {
  const requestPath = buildRequestPath(pageParam, true);

  const response: AxiosResponse<{
    ids: number[],
    total_pages_count: number,
    data: PlaceEntity[],
  }> = await axios.get(requestPath);
  return response.data;
};

export const useQueryPlaces = (enabled: boolean) => useInfiniteQuery(
    ['places'],
    fetchPlaces,
    {
      enabled,
      staleTime: Infinity,
      getNextPageParam: (lastPage, allPages) => (allPages.length < lastPage.total_pages_count ? allPages.length + 1 : undefined),
    }
  );

export const useMutationPlace = () => {
  const queryClient = useQueryClient();
  return {
    addPlace: useMutation(
      (place: PlaceRequestEntity) => axios.post('/api/v2/places', place),
      {
        onSuccess: () => queryClient.invalidateQueries(['places']),
      }
    ),
    updatePlace: useMutation(
      (place: PlaceEntity) => axios.put(`/api/v2/places/${place.id}`, place),
      {
        onSuccess: () => queryClient.invalidateQueries(['places']),
      }
    ),
    deletePlaces: useMutation(
      (ids: number[]) => axios.delete('/api/v2/places/bulk', { data: { ids } }),
      {
        onSuccess: () => queryClient.invalidateQueries(['places']),
      }
    )
  };
};
