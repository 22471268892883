import { Divider, Stack } from '@mui/material';
import { FC, memo } from 'react';
import { OrderEntity } from 'src/entities/orderEntity';
import { PositionEntity } from 'src/entities/PositionEntity';

import PlanningsMapOrderMarkerTooltipContentPresenter from './PlanningsMapOrderMarkerTooltipContent.presenter';

type Props = {
  position: PositionEntity;
  orderIds: number[];
  orderEntityMap: Map<number, OrderEntity>;
}

const PlanningsMapOrderMarkerTooltipContentsPresenter: FC<Props> = memo((
  {
    position,
    orderIds,
    orderEntityMap
  }
) => (
  <Stack spacing={1} divider={<Divider />}>
    {orderIds.map((orderId) => (
      <PlanningsMapOrderMarkerTooltipContentPresenter
        key={[
          'PlanningsMapOrderMarkerTooltipContentsPresenter',
          'PlanningsMapOrderMarkerTooltipContentPresenter',
          orderId,
          position,
        ].join('-')}
        orderId={orderId}
        position={position}
        orderEntityMap={orderEntityMap}
      />
    ))}
  </Stack>
));

export default PlanningsMapOrderMarkerTooltipContentsPresenter;
