import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { GarageEntity } from 'src/entities/garageEntity';

export const Columns: (openEditDialog: (entity: GarageEntity) => void) => GridColDef[] = (openEdit) => [
    {
      field: 'edit',
      headerName: '編集',
      disableExport: true,
      renderCell: (params: { row: GarageEntity }) => (
        <IconButton onClick={() => openEdit(params.row)}>
          <EditOutlinedIcon />
        </IconButton>
      ),
      flex: 2,
      sortable: false,
      filterable: false,
    },
    {
      field: 'company_name',
      headerName: '事業所',
      flex: 10,
    },
    {
      field: 'name',
      headerName: '車庫',
      flex: 10,
    },
    {
      field: 'address',
      headerName: '住所',
      flex: 20,
    }
  ];
