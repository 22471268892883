import { GridColDef } from '@mui/x-data-grid';
import { GridValueFormatterParams } from '@mui/x-data-grid-premium';
import numberDecorator from 'src/decorators/number.decorator';
import { EstimationEntity } from 'src/entities/Estimation.entity';
import numberUtil from 'src/utils/number.util';

const costHourColumns: GridColDef[] = [
  {
    field: 'deliveryName',
    headerName: '勤務計画',
    type: 'string',
  },
  {
    field: 'orderOperationSeconds',
    headerName: '車庫帰着を除く時間',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromSecondsToMinutes(params.row.orderOperationSeconds),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedUnit(
      params.value,
      '分',
      1,
      ''
    )
  },
  {
    field: 'totalSeconds',
    headerName: '合計時間',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromSecondsToMinutes(params.row.orderOperationSeconds + params.row.arrivalOperationSeconds),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedUnit(
      params.value,
      '分',
      1,
      ''
    )
  },
  {
    field: 'expectedFeeYenByHour',
    headerName: '🖊️1時間あたり運賃',
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'feeExcludeArrivalOperation',
    headerName: '運賃（車庫帰着を除く）',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => {
      if (!params.row.expectedFeeYenByHour) return undefined;

      const seconds = params.row.orderOperationSeconds;

      return seconds * (params.row.expectedFeeYenByHour / 3600);
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value,
    ),
  },
  {
    field: 'fee',
    headerName: '運賃',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => {
      if (!params.row.expectedFeeYenByKm) return undefined;

      const seconds = params.row.orderOperationSeconds + params.row.arrivalOperationSeconds;

      return seconds * (params.row.expectedFeeYenByHour / 3600);
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value,
    ),
  },
];

export default costHourColumns;
