import MapIcon from '@mui/icons-material/Map';
import { Chip, colors, IconButton, Stack, Tooltip } from '@mui/material';
import { GridCheckIcon, GridCloseIcon, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-premium';
import { format } from 'date-fns-tz';
import numberDecorator from 'src/decorators/number.decorator';
import { OrderResponseEntity } from 'src/entities/Order.response.entity';
import arrayUtil from 'src/utils/array.util';
import numberUtil from 'src/utils/number.util';

import { dataGridUtil } from '../../utils/dataGrid.util';

export const Columns: (
  openLoadingMapDialog: (orderEntity: OrderResponseEntity) => void,
  openUnloadingMapDialog: (orderEntity: OrderResponseEntity) => void,
) => GridColDef[] = (
  openLoadingMapDialog,
  openUnloadingMapDialog,
) => {
  const getWidth = (name: string, width: number) => {
    const maybeWidth: number = dataGridUtil.getSavedColumnWidth('order-columns', name);

    return maybeWidth || width;
  };
  const parsedOrderLoadingSpecifiedTimes = (data: OrderResponseEntity) => {
    const maybe: {
      start_at: string | null;
      end_at: string | null;
    }[] = JSON.parse(data.order_loading_specified_json) as {
      start_at: string | null;
      end_at: string | null;
    }[];

    return maybe.filter((d) => !!d.start_at && !!d.end_at)
                .sort((a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime());
  };
  const parsedOrderUnloadingSpecifiedTimes = (data: OrderResponseEntity) => {
    const maybe: {
      start_at: string | null;
      end_at: string | null;
    }[] = JSON.parse(data.order_unloading_specified_json) as {
      start_at: string | null;
      end_at: string | null;
    }[];

    return maybe.filter((d) => !!d.start_at && !!d.end_at)
                .sort((a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime());
  };

  return [
    {
      field: 'company_name',
      disableExport: true,
      headerName: '事業所',
      width: 200,
      filterable: false,
    },
    {
      field: 'drivers_name',
      headerName: '割当',
      width: 100,
      renderCell: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_operations_count) return <Chip size="small" color="error" label="未割当" />;
        if (!params.row.drivers_json) return <Chip size="small" color="error" label="未割当" />;

        const maybeDrivers = JSON.parse(params.row.drivers_json) as {
          id: number | null;
          name: string |null ;
        }[];

        const drivers: { id: number; name: string }[] = maybeDrivers.filter((d) => !!d.id && !!d.name);
        const maybeFirstDriver = drivers[0];

        if (!maybeFirstDriver) return <Chip size="small" color="error" label="未割当" />;

        return <Chip size="small" color="success" label={maybeFirstDriver.name} />;
      },
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_operations_count) return '未割当';
        if (!params.row.drivers_json) return '未割当';

        const maybeDrivers = JSON.parse(params.row.drivers_json) as {
          id: number | null;
          name: string |null ;
        }[];

        const drivers: { id: number; name: string }[] = maybeDrivers.filter((d) => !!d.id && !!d.name);
        const maybeFirstDriver = drivers[0];

        if (!maybeFirstDriver) return '未割当';

        return maybeFirstDriver.name;
      }
    },
    {
      field: 'encoded_id',
      headerName: 'ID',
    },
    {
      field: 'code',
      headerName: '注文コード',
      width: 200,
    },
    {
      field: 'shipper_name',
      headerName: '荷主名',
      width: 200
    },
    {
      field: 'loading_name',
      headerName: '積地',
      width: 300
    },
    {
      field: 'loading_address',
      headerName: '積地住所',
      width: 500
    },
    {
      field: 'loading_position',
      headerName: '積地座標',
      disableExport: true,
      width: 250,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (params.row.loading_latitude && params.row.loading_longitude) {
          return `${params.row.loading_latitude}, ${params.row.loading_longitude}`;
        }
        return '';
      },
      renderCell: (params: { row: OrderResponseEntity }) => {
        if (params.row.loading_latitude && params.row.loading_longitude) {
          return (
            <Stack direction="row" alignContent="center" alignItems="center">
              <Tooltip title="位置を調整します">
                <IconButton onClick={() => openLoadingMapDialog(params.row)}>
                  <MapIcon />
                </IconButton>
              </Tooltip>
              {[params.row.loading_latitude, params.row.loading_longitude].join(', ')}
            </Stack>
          );
        }
        return '';
      }
    },
    {
      field: 'loading_latitude',
      headerName: '積地緯度',
      filterable: false,
      sortable: false,
    },
    {
      field: 'loading_longitude',
      headerName: '積地経度',
      filterable: false,
      sortable: false,
    },
    {
      field: 'order_loading_specified_datetimes_start_date',
      headerName: '積日',
      width: 200,
      type: 'date',
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_loading_specified_json) { return ''; }
        const parsed = parsedOrderLoadingSpecifiedTimes(params.row);

        if (parsed?.length < 1) { return ''; }

        const target = parsed[0];

        return format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' });
      },
    },
    {
      field: 'loading_start_at1',
      headerName: '積地受付',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_loading_specified_json) { return ''; }
        const parsed = parsedOrderLoadingSpecifiedTimes(params.row);

        if (parsed?.length < 1) { return ''; }

        const target = parsed[0];

        return format(new Date(target.start_at), 'HH:mm', { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'loading_end_at1',
      headerName: '積地締切',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_loading_specified_json) { return ''; }
        const parsed = parsedOrderLoadingSpecifiedTimes(params.row);

        if (parsed?.length < 1) { return ''; }

        const target = parsed[0];

        const fmt = format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' }) === format(new Date(target.end_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' })
          ? 'HH:mm'
          : 'yyyy/MM/dd HH:mm';

        return format(new Date(target.end_at), fmt, { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'loading_start_at2',
      headerName: '積地受付②',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_loading_specified_json) { return ''; }
        const parsed = parsedOrderLoadingSpecifiedTimes(params.row);

        if (parsed?.length < 2) { return ''; }

        const target = parsed[1];

        return format(new Date(target.start_at), 'HH:mm', { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'loading_end_at2',
      headerName: '積地締切②',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_loading_specified_json) { return ''; }
        const parsed = parsedOrderLoadingSpecifiedTimes(params.row);

        if (parsed?.length < 2) { return ''; }

        const target = parsed[1];

        const fmt = format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' }) === format(new Date(target.end_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' })
          ? 'HH:mm'
          : 'yyyy/MM/dd HH:mm';

        return format(new Date(target.end_at), fmt, { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'loading_start_at3',
      headerName: '積地受付③',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_loading_specified_json) { return ''; }
        const parsed = parsedOrderLoadingSpecifiedTimes(params.row);

        if (parsed?.length < 3) { return ''; }

        const target = parsed[2];

        return format(new Date(target.start_at), 'HH:mm', { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'loading_end_at3',
      headerName: '積地締切③',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_loading_specified_json) { return ''; }
        const parsed = parsedOrderLoadingSpecifiedTimes(params.row);

        if (parsed?.length < 3) { return ''; }

        const target = parsed[2];

        const fmt = format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' }) === format(new Date(target.end_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' })
          ? 'HH:mm'
          : 'yyyy/MM/dd HH:mm';

        return format(new Date(target.end_at), fmt, { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'loading_staying_minutes',
      headerName: '積地作業分数',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.loading_staying_seconds) return '';
        return params.row.loading_staying_seconds / 60;
      }
    },
    {
      field: 'unloading_name',
      headerName: '降地名称',
      width: 300
    },
    {
      field: 'unloading_address',
      headerName: '降地住所',
      width: 500
    },
    {
      field: 'unloading_position',
      headerName: '降地座標',
      disableExport: true,
      width: 250,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (params.row.unloading_latitude && params.row.unloading_longitude) {
          return `${params.row.unloading_latitude}, ${params.row.unloading_longitude}`;
        }
        return '';
      },
      renderCell: (params: { row: OrderResponseEntity }) => {
        if (params.row.unloading_latitude && params.row.unloading_longitude) {
          return (
            <Stack direction="row" alignContent="center" alignItems="center">
              <Tooltip title="位置を調整します">
                <IconButton onClick={() => openUnloadingMapDialog(params.row)}>
                  <MapIcon />
                </IconButton>
              </Tooltip>
              {[params.row.unloading_latitude, params.row.unloading_longitude].join(', ')}
            </Stack>
          );
        }
        return '';
      }
    },
    {
      field: 'unloading_latitude',
      headerName: '降地緯度',
      filterable: false,
      sortable: false,
    },
    {
      field: 'unloading_longitude',
      headerName: '降地経度',
      filterable: false,
      sortable: false,
    },
    {
      field: 'order_unloading_specified_datetimes_start_date',
      headerName: '降日',
      width: 200,
      type: 'date',
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_unloading_specified_json) { return ''; }
        const parsed = parsedOrderUnloadingSpecifiedTimes(params.row);

        if (parsed?.length < 1) { return ''; }

        const target = parsed[0];

        return format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' });
      },
    },
    {
      field: 'unloading_start_at',
      headerName: '降地受付',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_unloading_specified_json) { return ''; }
        const parsed = parsedOrderUnloadingSpecifiedTimes(params.row);

        if (parsed?.length < 1) { return ''; }

        const target = parsed[0];

        return format(new Date(target.start_at), 'HH:mm', { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'unloading_end_at',
      headerName: '降地締切',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_unloading_specified_json) { return ''; }
        const parsed = parsedOrderUnloadingSpecifiedTimes(params.row);

        if (parsed?.length < 1) { return ''; }

        const target = parsed[0];

        const fmt = format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' }) === format(new Date(target.end_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' })
          ? 'HH:mm'
          : 'yyyy/MM/dd HH:mm';

        return format(new Date(target.end_at), fmt, { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'unloading_start_at2',
      headerName: '降地受付②',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_unloading_specified_json) { return ''; }
        const parsed = parsedOrderUnloadingSpecifiedTimes(params.row);

        if (parsed?.length < 2) { return ''; }

        const target = parsed[1];

        return format(new Date(target.start_at), 'HH:mm', { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'unloading_end_at2',
      headerName: '降地締切②',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_unloading_specified_json) { return ''; }
        const parsed = parsedOrderUnloadingSpecifiedTimes(params.row);

        if (parsed?.length < 2) { return ''; }

        const target = parsed[1];

        const fmt = format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' }) === format(new Date(target.end_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' })
          ? 'HH:mm'
          : 'yyyy/MM/dd HH:mm';

        return format(new Date(target.end_at), fmt, { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'unloading_start_at3',
      headerName: '降地受付③',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_unloading_specified_json) { return ''; }
        const parsed = parsedOrderUnloadingSpecifiedTimes(params.row);

        if (parsed?.length < 3) { return ''; }

        const target = parsed[2];

        return format(new Date(target.start_at), 'HH:mm', { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'unloading_end_at3',
      headerName: '降地締切③',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_unloading_specified_json) { return ''; }
        const parsed = parsedOrderUnloadingSpecifiedTimes(params.row);

        if (parsed?.length < 3) { return ''; }

        const target = parsed[2];

        const fmt = format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' }) === format(new Date(target.end_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' })
          ? 'HH:mm'
          : 'yyyy/MM/dd HH:mm';

        return format(new Date(target.end_at), fmt, { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'unloading_staying_minutes',
      headerName: '降地作業分数',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.unloading_staying_seconds) return '';
        return params.row.unloading_staying_seconds / 60;
      }
    },
    {
      field: 'item_count',
      headerName: '数量',
      width: 80,
      type: 'number'
    },
    {
      field: 'item_packing_style',
      headerName: '荷姿',
      width: 80,
      type: 'number'
    },
    {
      field: 'item_total_weight_kg',
      headerName: '総重量',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.item_total_weight_for_calculation && params.row.item_total_weight_for_calculation !== 0) return '';

        return numberUtil.convertFromGramToKg(params.row.item_total_weight_for_calculation);
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (!params.value && params.value !== 0) return '';
        if (params.value === 0) return '0kg';

        return numberDecorator.toRoundedUnit(params.value, 'kg', 1, '');
      }
    },
    {
      field: 'item_total_volume_m3',
      headerName: '総体積',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.item_total_volume_mm3) return '';

        return numberUtil.convertFromMm3ToM3(params.row.item_total_volume_mm3);
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (!params.value) return '';

        return numberDecorator.toRoundedUnit(params.value, 'm³', 10, '');
      }
    },
    {
      field: 'item_name',
      headerName: '品名',
      width: 80
    },
    { field: 'item_klass', headerName: '輸送区分', width: 80 },
    { field: 'item_handling_of_cargo_style', headerName: '荷扱い', width: 80 },
    {
      field: 'item_can_be_mixed',
      headerName: '混載可否',
      valueGetter: (params: { row: OrderResponseEntity }) => (params.row.item_can_be_mixed ? '可' : '不可'),
      renderCell: (params: { row: OrderResponseEntity }) => (
        params.row.item_can_be_mixed
          ? <GridCheckIcon style={{ color: colors.grey[500] }} /> : <GridCloseIcon style={{ color: colors.grey[500] }} />
      ),
      width: 80,
      type: 'boolean'
    },
    {
      field: 'designated_truck_klasses',
      headerName: '車両タイプ',
      width: 80,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => { // CSVに出力する場合
        if (!params.value || params.value.length < 2) { return params.value.join(','); }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.designated_truck_klasses) { return ''; }
        return params.row.designated_truck_klasses.join(',');
      }
    },
    {
      field: 'designated_truck_car_models',
      headerName: '指定車種',
      width: 80,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => { // CSVに出力する場合
        if (!params.value || params.value.length < 2) { return params.value.join(','); }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.designated_truck_car_models) { return ''; }
        return params.row.designated_truck_car_models.join(',');
      }
    },
    {
      field: 'designated_truck_loading_platform_heights',
      headerName: '荷台高',
      width: 80,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => { // CSVに出力する場合
        if (!params.value || params.value.length < 2) { return params.value.join(','); }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.designated_truck_loading_platform_heights) { return ''; }
        return params.row.designated_truck_loading_platform_heights.join(',');
      }
    },
    {
      field: 'designated_truck_loading_platform_widths',
      headerName: '荷台幅',
      width: 80,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => { // CSVに出力する場合
        if (!params.value || params.value.length < 2) { return params.value.join(','); }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.designated_truck_loading_platform_widths) { return ''; }
        return params.row.designated_truck_loading_platform_widths.join(',');
      }
    },
    {
      field: 'designated_truck_loading_platform_lengths',
      headerName: '荷台長',
      width: 80,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => { // CSVに出力する場合
        if (!params.value || params.value.length < 2) { return params.value.join(','); }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.designated_truck_loading_platform_lengths) { return ''; }
        return params.row.designated_truck_loading_platform_lengths.join(',');
      }
    },
    {
      field: 'designated_truck_floor_specifications',
      headerName: '床仕様',
      width: 80,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => { // CSVに出力する場合
        if (!params.value || params.value.length < 2) { return params.value.join(','); }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.designated_truck_floor_specifications) { return ''; }
        return params.row.designated_truck_floor_specifications.join(',');
      }
    },
    {
      field: 'designated_truck_features',
      headerName: '指定装置・特徴',
      width: 80,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => { // CSVに出力する場合
        if (!params.value || params.value.length < 2) { return params.value.join(','); }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.designated_truck_features) { return ''; }
        return params.row.designated_truck_features.join(',');
      }
    },
    {
      field: 'charge_basic_fee_yen',
      headerName: '基本運賃',
      width: 80,
      type: 'number'
    },
    {
      field: 'charge_highway_fee_yen',
      headerName: '高速代金',
      width: 80,
      type: 'number'
    },
    {
      field: 'charge_loading_fee_yen',
      headerName: '積込料金',
      width: 80,
      type: 'number'
    },
    {
      field: 'charge_ancillary_fee_yen',
      headerName: '付帯作業料金',
      width: 80,
      type: 'number'
    },
    {
      field: 'charge_waiting_time_fee_yen',
      headerName: '待機料金',
      width: 80,
      type: 'number'
    },
    {
      field: 'charge_unloading_fee_yen',
      headerName: '取卸料',
      width: 80,
      type: 'number'
    },
    {
      field: 'charge_expenses_fee_yen',
      headerName: '諸経費',
      width: 80,
      type: 'number'
    },
    {
      field: 'charge_ancillary_content',
      headerName: '付帯作業備考',
      renderCell: (params: { row: OrderResponseEntity }) => (
        <Tooltip title={params.row.charge_ancillary_content}>
          <span>
            {params.row.charge_ancillary_content}
          </span>
        </Tooltip>
      ),
      width: 200
    },
    {
      field: 'memo',
      headerName: '備考',
      width: 300,
      renderCell: (params: { row: OrderResponseEntity }) => {
        if (!params.row.memo) return '';

        const val = params.row.memo
          .trim()
          .split('\n')
          .flatMap((str) => arrayUtil.sliceByNumber(str.split(''), 20).map((s) => (
            <li
              style={{
                listStyle: 'none'
              }}
            >
              {s}
            </li>
          )));

        return (
          <ul
            style={{
              padding: 0
            }}
          >
            {val}
          </ul>
        );
      }
    },
    {
      field: 'allowed_trucks_license_plate_value',
      headerName: '指定トラック',
      width: 200,
      valueGetter: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.allowed_trucks_json) { return ''; }

        const maybeTrucks = JSON.parse(params.row.allowed_trucks_json) as {
          id: number | null;
          name: string | null;
        }[];

        const trucks = maybeTrucks.filter((d) => !!d.id && !!d.name);

        if (trucks.length < 1) { return ''; }

        return trucks.map((obj) => obj.name).join(',');
      },
    },
    {
      field: 'denied_drivers_name',
      headerName: 'NGドライバー',
      width: 200,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.denied_drivers_json) return '';

        const maybeDrivers = JSON.parse(params.row.denied_drivers_json) as {
          id: number | null;
          name: string | null;
        }[];

        const drivers = maybeDrivers.filter((d) => !!d.id && !!d.name);

        return drivers.map((obj) => obj.name).join(',');
      },
    }
  ].map((it) => ({
    ...it,
    width: getWidth(it.field, it.width || 50)
  }));
};
