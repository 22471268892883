import { Box, Tooltip } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid';
import { FC, memo } from 'react';
import MyGridToolbarFilterButton from 'src/pages/common/MyGridToolbarFilterButton';

const CustomToolbar: FC<{
  toggleDialogIsOpen: () => void;
}> = memo(() => (
  <GridToolbarContainer
    sx={{
      width: '100%'
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
        flexGrow={1}
      >
        <GridToolbarColumnsButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
        <MyGridToolbarFilterButton />
        <GridToolbarDensitySelector nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
        <Tooltip title="時間の単位は秒・距離の単位はミリ、時給・燃料費は、それぞれ秒・ミリの単位に合わせて出力されます。">
          <GridToolbarExport />
        </Tooltip>
      </Box>
    </Box>
  </GridToolbarContainer>
));

export default CustomToolbar;
