import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LicenseRegisterRequestEntity } from 'src/entities/licenseEntity';
import * as yup from 'yup';

const schema = yup.object({
  company_name: yup.string().required('会社名を入力してください'),
  email: yup
    .string()
    .email('正しい形式で入力してください')
    .required('メールアドレスを入力してください'),
  password: yup
    .string()
    .required('パスワードを入力してください')
    .min(10, '10文字以上入力してください')
});

const RegisterDialog: FC<{
  registerDialogOpened: boolean,
  setRegisterDialogOpened: (bool: boolean) => void,
  setShowConfirmMessage: (bool: boolean) => void,
}> = memo(({
  registerDialogOpened,
  setRegisterDialogOpened,
  setShowConfirmMessage,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { register, handleSubmit, formState: { errors } } = useForm<LicenseRegisterRequestEntity>({
    resolver: yupResolver(schema)
  });

  const closeDialog = () => {
    setRegisterDialogOpened(false);
  };

  const onSubmit: SubmitHandler<LicenseRegisterRequestEntity> = async (
    data: LicenseRegisterRequestEntity
  ): Promise<void> => {
    setIsLoading(true);
    setErrorMessages([]);
    setShowConfirmMessage(false);

    try {
      await axios.post('/api/v2/accounts/register', data);
      setShowConfirmMessage(true);
      setRegisterDialogOpened(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e as unknown as AxiosError<{ message: string[] }>;
        // enqueueSnackbar(axiosError.response.data.message.join(' '));
        setErrorMessages(axiosError.response.data.message);
      } else {
        enqueueSnackbar('エラーが発生しました');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={registerDialogOpened}
      onClose={closeDialog}
      maxWidth="md"
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3>新規会員登録</h3>
        <IconButton onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {errorMessages.map((message: string) => (
        <Typography variant="h5" style={{ color: '#f00' }} pl={2}>
          {message}
        </Typography>
      ))}
      <DialogContent>
        <TextField
          required
          margin="dense"
          id="company_name"
          label="会社名"
          type="text"
          fullWidth
          variant="standard"
          {...register('company_name')}
          error={'company_name' in errors}
          helperText={errors.company_name?.message}
        />
        <TextField
          required
          margin="dense"
          id="email"
          label="メールアドレス"
          type="text"
          fullWidth
          variant="standard"
          {...register('email')}
          error={'email' in errors}
          helperText={errors.email?.message}
        />
        <TextField
          required
          margin="dense"
          id="password"
          label="パスワード"
          type="password"
          fullWidth
          variant="standard"
          {...register('password')}
          error={'password' in errors}
          helperText={errors.password?.message}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={isLoading}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSubmit(onSubmit)}
        >
          アカウントを作成する
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});

export default RegisterDialog;
