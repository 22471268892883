import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, InputAdornment, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, memo, useCallback, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import LicenseContext from 'src/contexts/LicenseContext';
import { AccountLicenseEntity, AccountLicenseRequestEntity } from 'src/entities/AccountEntity';
import { RoleEntity } from 'src/entities/Role.entity';
import { useMutationAccounts } from 'src/hooks/useQueryAccounts';
import * as yup from 'yup';

const schema = yup.object({
  id: yup.number(),
  email: yup
    .string()
    .email('正しい形式で入力してください')
    .required('メールアドレスを入力してください'),
  password: yup
    .string()
    .test(
      'len',
      '10文字以上入力してください',
      (val: string) => {
        if (val === undefined) return true;
        return val.length === 0 || val.length >= 10;
      }
    ),
  license_profile: yup.object().shape({
    branch_name: yup.string().optional().nullable().max(255, '支店名は255文字以内で入力してください'),
    person_name: yup.string().optional().nullable().max(255, '氏名は255文字以内で入力してください'),
    person_phone_number: yup.string().optional().nullable().max(255, '電話番号は255文字以内で入力してください')
  }),
  role: yup.object().shape({
    id: yup.mixed().oneOf([1, 2]).required('権限を選択してください')
  }),
});

interface Props {
  dialogIsOpen: boolean;
  setDialogIsOpen: (isOpen: boolean) => void;
  entity: AccountLicenseEntity;
  roles: RoleEntity[];
}

export const EditDialog: FC<Props> = memo((props: Props) => {
  const { dialogIsOpen, setDialogIsOpen, entity, roles } = props;

  const licenseContext = useContext(LicenseContext);
  const { enqueueSnackbar } = useSnackbar();
  const { addLicense, updateLicense } = useMutationAccounts();
  const [isEdit, setIsEdit] = useState(false);
  const [roleValue, setRoleValue] = useState<string>('');
  const [canEditEmail, setCanEditEmail] = useState<boolean>(false);
  const [sameCampany, setSameCampany] = useState<boolean>(false);
  const [showPasswordField, setShowPasswordField] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [canEditRole, setCanEditRole] = useState<boolean>(false);

  useEffect(() => {
    setCanEditEmail(!entity || entity.id === licenseContext.config?.id);
    setSameCampany(!entity || (entity.company.id === licenseContext.config?.company_id));
  }, [entity, licenseContext.config]);

  useEffect(() => {
    setShowPasswordField(entity && entity.id === licenseContext.config?.id);
  }, [entity, licenseContext.config?.id]);

  useEffect(() => {
    setCanEditRole(licenseContext.config?.role?.name === 'admin');
  }, [licenseContext.config?.role?.name]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const {
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<(AccountLicenseEntity | AccountLicenseRequestEntity)>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    reset(entity || {});
    setIsEdit(!!entity);
    setRoleValue(entity?.role?.id?.toString() || '');
  }, [dialogIsOpen, entity, reset]);

  const onSubmitAdd: SubmitHandler<AccountLicenseEntity | AccountLicenseRequestEntity> = async (
    data
  ): Promise<void> => {
    try {
      if ('id' in data) {
        await updateLicense.mutateAsync(data);
      } else {
        await addLicense.mutateAsync(data);
      }
      setDialogIsOpen(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as unknown as AxiosError<{ messages: string[] }>;
        enqueueSnackbar(axiosError.response.data.messages.join(' '));
      } else {
        throw error;
      }
    }
  };

  const onChangeAccountRole = useCallback((event: ChangeEvent<HTMLInputElement>, value: string) => {
    setValue('role.id', Number(value));
    setRoleValue(value);
  }, [setValue]);

  return (
    <Dialog open={dialogIsOpen} maxWidth="md" fullWidth onClose={() => setDialogIsOpen(false)}>
      <DialogTitle>
        ユーザー
        {isEdit ? '編集' : '追加'}
      </DialogTitle>
      <DialogContent>
        <Stack component="form" noValidate spacing={2}>
          <TextField
            required
            disabled={!canEditEmail}
            margin="dense"
            id="email"
            label="メールアドレス"
            type="text"
            fullWidth
            variant="standard"
            {...register('email')}
            error={'email' in errors}
            helperText={errors.email?.message}
          />
          <TextField
            margin="dense"
            disabled={!sameCampany}
            id="person_name"
            label="氏名"
            fullWidth
            variant="standard"
            {...register('license_profile.person_name')}
            error={errors?.license_profile?.person_name !== undefined}
            helperText={errors.license_profile?.person_name?.message}
          />
          <TextField
            margin="dense"
            disabled={!sameCampany}
            id="branch_name"
            label="支店名"
            fullWidth
            variant="standard"
            {...register('license_profile.branch_name')}
            error={errors?.license_profile?.branch_name !== undefined}
            helperText={errors.license_profile?.branch_name?.message}
          />
          <TextField
            margin="dense"
            disabled={!sameCampany}
            id="phone_number"
            label="電話番号"
            fullWidth
            variant="standard"
            {...register('license_profile.phone_number')}
            error={errors?.license_profile?.phone_number !== undefined}
            helperText={errors.license_profile?.phone_number?.message}
          />
          {showPasswordField && (
            <TextField
              required
              margin="dense"
              id="password"
              label={isEdit ? 'パスワード (変更する場合は入力してください)' : 'パスワード'}
              type={showPassword ? 'text' : 'password'}
              fullWidth
              variant="standard"
              {...register('password')}
              error={'password' in errors}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          )}
          <FormControl error={'role' in errors}>
            <FormLabel>権限</FormLabel>
            <FormHelperText>{errors.role?.id?.message}</FormHelperText>
            <RadioGroup
              row
              id="role.id"
              name="role.id"
              value={roleValue}
              onChange={(event, value) => onChangeAccountRole(event, value)}
            >
              {roles.map((role) => (
                <FormControlLabel key={`role-${role.id}`} value={`${role.id}`} control={<Radio />} label={role.name_ja} disabled={!canEditRole} />
              ))}
            </RadioGroup>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogIsOpen(false)}>キャンセル</Button>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Button onClick={handleSubmit(onSubmitAdd)} variant="contained">
          保存する
        </Button>
      </DialogActions>
    </Dialog>
  );
});
