import { EstimationEntity } from 'src/entities/Estimation.entity';
import arrayUtil from 'src/utils/array.util';

const rowsByDeliveryAndShippersRows: (entities: EstimationEntity[]) => EstimationEntity[] = (entities) => {
  const deliveryIds = arrayUtil.uniq<number>(entities.map((it) => it.deliveryId));

  return deliveryIds
    .flatMap((id) => {
      const estimationsOnDelivery = entities.filter(({ deliveryId }) => deliveryId === id);

      const totalShipperDistanceMm = estimationsOnDelivery
        .map(({ distanceMmByShipper }) => distanceMmByShipper)
        .filter((maybe) => maybe)
        .reduce((prev, current) => prev + current, 0);

      return estimationsOnDelivery.map((entity) => {
        const distanceProportionRate = entity.distanceMmByShipper / totalShipperDistanceMm;
        const weightProportionRate = entity.totalWeightGramByShipper / entity.totalItemWeightGram;
        const volumeProportionRate = entity.totalVolumeMm3ByShipper / entity.totalItemVolumeMm3;

        const totalOrderOperationDistanceMm = entity.totalOrderOperationDistanceMm * distanceProportionRate;
        const arrivalOperationDistanceMm = entity.arrivalOperationDistanceMm * distanceProportionRate;
        const totalItemWeightGram = entity.totalItemWeightGram * weightProportionRate;
        const totalItemVolumeMm3 = entity.totalItemVolumeMm3 * volumeProportionRate;

        return {
          ...entity,
          ...{
            totalOrderOperationDistanceMm,
            arrivalOperationDistanceMm,
            totalItemWeightGram,
            totalItemVolumeMm3
          }
        };
      });
    });
};

export default rowsByDeliveryAndShippersRows;
