import { yupResolver } from '@hookform/resolvers/yup';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Paper, Stack, TextField } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { FC, memo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import LoadingComponent from 'src/components/LoadingComponent';
import { AllowedIpAddressEntity } from 'src/entities/admin/AllowedIpAddress.entity';
import {
  useAllowedConnectionsQuery,
  useMutationAllowedConnection
} from 'src/hooks/admin/useAllowedConnections.query';
import * as yup from 'yup';

import { AdminLicensesToolbar } from './Toolbar';

const schema = yup.object({
  value: yup.string().required('必須です'),
});

const AdminAllowedIpAddresses: FC = memo(() => {
  const { companyId } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, isError } = useAllowedConnectionsQuery(Number(companyId));

  const { add, destroy } = useMutationAllowedConnection();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<(Omit<AllowedIpAddressEntity, 'id'>)>({
    resolver: yupResolver(schema)
  });

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const toggleDialogIsOpen = () => {
    reset({});
    setDialogIsOpen(!dialogIsOpen);
  };

  const onSubmitAdd: SubmitHandler<Omit<AllowedIpAddressEntity, 'id' | 'companyId'>> = async (
    d
  ): Promise<void> => {
    try {
      await add.mutateAsync({
        ...d,
        companyId: Number(companyId)
      });
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, quotes,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument
      error.response.data.map((str) => enqueueSnackbar(str));
    } finally {
      setDialogIsOpen(false);
    }
  };

  const onSubmitRemove: SubmitHandler<AllowedIpAddressEntity> = async (
    d
  ): Promise<void> => {
    if (window.confirm('本当に削除してもよいですか？')) {
      await destroy.mutateAsync(d);
    }
    setDialogIsOpen(false);
  };

  const openAppendDialog = () => {
    reset();
    setDialogIsOpen(true);
  };
  const Columns: GridColDef[] = [
    { field: 'id', headerName: 'id' },
    {
      field: 'value',
      headerName: 'IPアドレス',
      width: 400,
    },
    {
      field: 'delete',
      headerName: '削除',
      renderCell: (params: { row: AllowedIpAddressEntity }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        <IconButton onClick={() => onSubmitRemove(params.row)}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
      )
    }
  ];

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (isError) {
    return <>Error</>;
  }

  return (
    <>
      <Helmet>
        <title>ADMIN | 接続許可IPアドレス設定</title>
      </Helmet>
      <Box width="100%" height="100%" p={2}>
        <Dialog open={dialogIsOpen} maxWidth="md" fullWidth onClose={toggleDialogIsOpen}>
          <DialogTitle>
            接続許可IPアドレス
          </DialogTitle>
          <DialogContent>
            <Stack component="form" noValidate spacing={2}>
              <TextField
                required
                margin="dense"
                id="value"
                label="IP アドレス"
                type="text"
                fullWidth
                variant="standard"
                {...register('value')}
                error={'value' in errors}
                helperText={errors.value?.message}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialogIsOpen}>キャンセル</Button>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Button onClick={handleSubmit(onSubmitAdd)} variant="contained">
              保存する
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          width="100%"
          height="100%"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Box width="100%" height="100%" flexGrow={1}>
            <Paper
              style={{
                width: '100%',
                height: '100%'
              }}
            >
              <DataGridPremium
                columns={Columns}
                rows={data}
                pagination
                paginationMode="server"
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: AdminLicensesToolbar
                }}
                componentsProps={{
                  toolbar: { openAppendDialog },
                }}
              />
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default AdminAllowedIpAddresses;
