import AltRouteRoundedIcon from '@mui/icons-material/AltRouteRounded';
import ExploreRoundedIcon from '@mui/icons-material/ExploreRounded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { FC, memo } from 'react';

type Props = {
  printAllOperationDirectionsOnClick: () => void;
  printAllTruckDirectionsOnClick: () => void;
  printUnloadTruckDirectionsOnClick: () => void;
  printPickingListOnClick: () => void;
  downloadPlanCsvOnClick: () => void;
}

const SelectPrintPresenter: FC<Props> = memo((
  {
    printAllOperationDirectionsOnClick,
    printAllTruckDirectionsOnClick,
    printUnloadTruckDirectionsOnClick,
    printPickingListOnClick,
    downloadPlanCsvOnClick
  }
) => (
  <Stack p={2} spacing={1}>
    <Stack>
      <Typography>
        出力形式を選択してください
      </Typography>
    </Stack>
    <Stack divider={<Divider />} spacing={1}>
      <Stack>
        <Button
          onClick={printAllOperationDirectionsOnClick}
          startIcon={<ExploreRoundedIcon />}
          endIcon={<PictureAsPdfRoundedIcon />}
        >
          配送指示書
        </Button>
      </Stack>
      <Stack>
        <Button
          onClick={printAllTruckDirectionsOnClick}
          startIcon={<LocalShippingRoundedIcon />}
          endIcon={<PictureAsPdfRoundedIcon />}
        >
          すべての配車表
        </Button>
        <Button
          onClick={printUnloadTruckDirectionsOnClick}
          startIcon={<LocalShippingRoundedIcon />}
          endIcon={<PictureAsPdfRoundedIcon />}
        >
          納品のみの配車書
        </Button>
      </Stack>
      <Stack>
        <Button
          onClick={printPickingListOnClick}
          startIcon={<ListAltRoundedIcon />}
          endIcon={<PictureAsPdfRoundedIcon />}
        >
          ピッキングリスト
        </Button>
      </Stack>
      <Stack>
        <Button
          onClick={downloadPlanCsvOnClick}
          startIcon={<AltRouteRoundedIcon />}
          endIcon={<FileDownloadIcon />}
        >
          配車CSVダウンロード
        </Button>
      </Stack>
    </Stack>
  </Stack>
  ));

export default SelectPrintPresenter;
