import { yupResolver } from '@hookform/resolvers/yup';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, LinearProgress, Paper, Radio, RadioGroup, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { addMonths } from 'date-fns';
import { format } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ConfirmDialog } from 'src/components/ConfirmDialog';
import LoadingComponent from 'src/components/LoadingComponent';
import { SubscriptionEntity, SubscriptionRequestEntity, SubscriptionStatus } from 'src/entities/admin/subscriptionEntity';
import { SubscriptionKindEntity } from 'src/entities/SubscriptionKind.entity';
import { useQueryCompany } from 'src/hooks/admin/useQueryCompanies';
import { useQuerySubscriptionKinds } from 'src/hooks/admin/useQuerySubscriptionKinds';
import { useMutationSubscription, useQuerySubscriptions } from 'src/hooks/admin/useQuerySubscriptions';
import * as yup from 'yup';

import { AdminSubscriptionsToolbar } from './Toolbar';

const schema = yup.object({
  subscription_kind_id: yup.number().required('必須です'),
  status: yup.string().required('必須です'),
  trial_from: yup.date(),
  trial_until: yup.date(),
  active_from: yup.date().nullable().transform((value: Date, originalValue: string) => (String(originalValue) === '' ? null : value)),
  active_until: yup.date().nullable().transform((value: Date, originalValue: string) => (String(originalValue) === '' ? null : value))
});

const AdminSubscriptionsPresenter: FC = memo(() => {
  const { companyId } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const [subscriptionKindId, setSubscriptionKindId] = useState<number>(1);
  const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus>('trial');

  const [page, setPage] = useState(0);
  const [pageSise, setPageSize] = useState(25);
  const [subscriptions, setSubscriptions] = useState<SubscriptionEntity[]>([]);
  const [subscriptionKinds, setSubscriptionKinds] = useState<SubscriptionKindEntity[]>([]);
  const { data: companyData, isLoading: companyDataIsLoading, isError: companyDataIsError } = useQueryCompany(companyId);
  const { data: subscriptionData, isLoading: subscriptionDataIsLoading, isError: subscriptionDataIsError } = useQuerySubscriptions(companyId, page, pageSise);
  const { data: subscriptionKindData, isLoading: subscriptionKindDataIsLoading, isError: subscriptionKindDataIsError } = useQuerySubscriptionKinds();

  const { addSubscription, updateSubscription, deleteSubscription } = useMutationSubscription();

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<(SubscriptionRequestEntity | SubscriptionEntity)>({
    resolver: yupResolver(schema)
  });

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const toggleDialogIsOpen = () => {
    reset({});
    setDialogIsOpen(!dialogIsOpen);
  };

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const onSubmitAdd: SubmitHandler<SubscriptionRequestEntity | SubscriptionEntity> = async (
    data
  ): Promise<void> => {
    data.company_id = companyId;
    data.subscription_kind_id = subscriptionKindId;
    data.status = subscriptionStatus;

    try {
      if ('id' in data) {
        await updateSubscription.mutateAsync(data);
      } else {
        await addSubscription.mutateAsync(data);
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, quotes,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument
      error.response.data.map((str) => enqueueSnackbar(str));
    } finally {
      setDialogIsOpen(false);
    }
  };

  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState<boolean>(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState<string>('');
  const [entityForRemove, setEntityForRemove] = useState<SubscriptionEntity>(null);

  const onSubmitRemove: SubmitHandler<SubscriptionEntity> = (
    data
  ) => {
    setConfirmDialogMessage('削除してもよいですか？');
    setEntityForRemove(data);
    setConfirmDialogIsOpen(true);
  };

  const confirmDialogHandleOk = useCallback(() => {
    deleteSubscription.mutate(entityForRemove);
    setDialogIsOpen(false);
  }, [deleteSubscription, entityForRemove]);

  const confirmDialogHandleCancel = useCallback(() => {
    setDialogIsOpen(false);
  }, []);

  const confirmDialogMmoe = useMemo(() => (
    <ConfirmDialog
      open={confirmDialogIsOpen}
      message={confirmDialogMessage}
      handleOk={confirmDialogHandleOk}
      handleCancel={confirmDialogHandleCancel}
    />
  ), [confirmDialogHandleCancel, confirmDialogHandleOk, confirmDialogIsOpen, confirmDialogMessage]);

  const convertYYYYMMDD = (value: Date) => format(value, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' });

  const openAppendDialog = () => {
    reset({
      subscription_kind_id: subscriptionKindId,
      status: 'trial',
      trial_from: convertYYYYMMDD(new Date()),
      trial_until: convertYYYYMMDD(addMonths(new Date(), 2))
    });
    setSubscriptionKindId(subscriptionKinds[0].id);
    setSubscriptionStatus('trial');
    setIsEdit(false);
    setDialogIsOpen(true);
  };

  const openEditDialog: SubmitHandler<SubscriptionEntity> = async (
    data
    // eslint-disable-next-line @typescript-eslint/require-await
  ): Promise<void> => {
    reset({
      ...data,
      trial_from: convertYYYYMMDD(new Date(data.trial_from)),
      trial_until: convertYYYYMMDD(new Date(data.trial_until)),
      active_from: data.active_from ? convertYYYYMMDD(new Date(data.active_from)) : null,
      active_until: data.active_until ? convertYYYYMMDD(new Date(data.active_until)) : null,
    });
    setSubscriptionKindId(data.subscription_kind_id);
    setSubscriptionStatus(data.status);
    setIsEdit(true);
    setDialogIsOpen(true);
  };

  const onChangeKindValue = (event: SelectChangeEvent) => {
    setSubscriptionKindId(Number(event.target.value));
  };

  const onChangeStatusValue = (event: SelectChangeEvent) => {
    setSubscriptionStatus(event.target.value as SubscriptionStatus);
  };

  const Columns: GridColDef[] = [
    { field: 'id', headerName: 'id', flex: 1 },
    {
      field: 'kind',
      headerName: '種類',
      flex: 3,
      valueGetter: (params: { row: SubscriptionEntity }) => subscriptionKinds.find((it) => it.id === params.row.subscription_kind_id)?.name_ja
    },
    {
      field: 'status',
      headerName: 'ステータス',
      flex: 2,
      valueGetter: (params: {row: SubscriptionEntity }) => {
        switch (params.row.status) {
          case 'trial':
            return 'トライアル';
          case 'active':
            return '本契約';
          case 'cancelled':
            return 'キャンセル';
          default:
            return '-';
        }
      }
    },
    {
      field: 'trial_from',
      headerName: 'トライアル開始',
      flex: 2,
      valueFormatter: ({ value }) => (value ? convertYYYYMMDD(new Date(String(value))) : '')
    },
    {
      field: 'trial_until',
      headerName: 'トライアル終了',
      flex: 2,
      valueFormatter: ({ value }) => (value ? convertYYYYMMDD(new Date(String(value))) : '')
    },
    {
      field: 'active_from',
      headerName: '本契約開始',
      flex: 2,
      valueFormatter: ({ value }) => (value ? convertYYYYMMDD(new Date(String(value))) : '')
    },
    {
      field: 'active_until',
      headerName: '本契約終了',
      flex: 2,
      valueFormatter: ({ value }) => (value ? convertYYYYMMDD(new Date(String(value))) : '')
    },
    {
      field: 'token',
      headerName: '認証トークン',
      flex: 2,
    },
    {
      field: 'edit',
      headerName: '編集',
      flex: 1,
      renderCell: (params: { row: SubscriptionEntity }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        <IconButton onClick={() => openEditDialog(params.row)}>
          <EditOutlinedIcon />
        </IconButton>
      )
    },
    {
      field: 'delete',
      headerName: '削除',
      flex: 1,
      renderCell: (params: { row: SubscriptionEntity }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        <IconButton onClick={() => onSubmitRemove(params.row)}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
      )
    }
  ];

  useEffect(() => {
    if (!subscriptionData || !subscriptionKindData) return;

    setSubscriptions(subscriptionData.data);
    setSubscriptionKinds(subscriptionKindData.data);
  }, [subscriptionData, subscriptionKindData]);

  if (subscriptionDataIsLoading || companyDataIsLoading || subscriptionKindDataIsLoading) {
    return <LoadingComponent />;
  }

  if (subscriptionDataIsError || companyDataIsError || subscriptionKindDataIsError) {
    return <>Error</>;
  }

  return (
    <>
      <Helmet>
        <title>ADMIN | サブスクリプション設定</title>
      </Helmet>
      <Box width="100%" height="100%" p={2}>
        {confirmDialogMmoe}
        <Dialog open={dialogIsOpen} maxWidth="md" fullWidth onClose={toggleDialogIsOpen}>
          <DialogTitle>
            サブスクリプション
            {isEdit ? '編集' : '追加'}
          </DialogTitle>
          <DialogContent>
            <Stack component="form" noValidate spacing={2}>
              <FormControl>
                <FormLabel id="radio-kind-label">種類</FormLabel>
                <RadioGroup
                  row
                  value={subscriptionKindId}
                  onChange={onChangeKindValue}
                >
                  {subscriptionKinds.map((it) => (
                    <FormControlLabel value={it.id} control={<Radio disabled={isEdit} />} label={it.name_ja} />
                  ))}
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel id="radio-status-label">ステータス</FormLabel>
                <RadioGroup
                  row
                  value={subscriptionStatus}
                  onChange={onChangeStatusValue}
                >
                  <FormControlLabel value="trial" control={<Radio />} label="トライアル" />
                  <FormControlLabel value="active" control={<Radio />} label="本契約" />
                  <FormControlLabel value="cancelled" control={<Radio />} label="キャンセル" />
                </RadioGroup>
              </FormControl>
              <Stack direction="row" spacing={2} alignItems="flex-end" justifyContent="center">
                <TextField
                  id="trial_from"
                  margin="dense"
                  label="トライアル開始日"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  fullWidth
                  variant="standard"
                  {...register('trial_from')}
                  error={'trial_from' in errors}
                  // value={getValues('trial_from')}
                />
                <TextField
                  id="trial_until"
                  margin="dense"
                  label="トライアル終了日"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  fullWidth
                  variant="standard"
                  {...register('trial_until')}
                  error={'trial_until' in errors}
                  // value={getValues('trial_until')}
                />
                <TextField
                  id="active_from"
                  margin="dense"
                  label="本契約開始日"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  fullWidth
                  variant="standard"
                  {...register('active_from')}
                  error={'active_from' in errors}
                  // value={getValues('active_from')}
                />
                <TextField
                  id="active_until"
                  margin="dense"
                  label="本契約終了日"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  fullWidth
                  variant="standard"
                  {...register('active_until')}
                  error={'active_until' in errors}
                  // value={getValues('active_until')}
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialogIsOpen}>キャンセル</Button>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Button onClick={handleSubmit(onSubmitAdd)} variant="contained">
              保存する
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          width="100%"
          height="100%"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Box width="100%" height="100%" flexGrow={1}>
            <Paper
              style={{
                width: '100%',
                height: '100%'
              }}
            >
              <DataGridPremium
                columns={Columns}
                rows={subscriptions}
                rowCount={subscriptionData.total}
                pagination
                paginationMode="server"
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: AdminSubscriptionsToolbar
                }}
                componentsProps={{
                  toolbar: { openAppendDialog, company: companyData },
                }}
                onPageChange={(val) => { setPage(val); }}
                onPageSizeChange={(val) => { setPageSize(val); }}
                page={page}
                pageSize={pageSise}
              />
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default AdminSubscriptionsPresenter;
