export type Color = '#222222' |
  '#555555' |
  '#777777' |
  '#AAAAAA' |
  '#CCCCCC' |
  '#00CED1' |
  '#00BFFF' |
  '#87CEFA' |
  '#87CEEB' |
  '#ADD8E6' |
  '#B0C4DE' |
  '#6495ED' |
  '#4169E1' |
  '#0000FF' |
  '#1E90FF' |
  '#40E0D0' |
  '#66CDAA' |
  '#20B2AA' |
  '#2E8B57' |
  '#3CB371' |
  '#8FBC8F' |
  '#32CD32' |
  '#228B22' |
  '#008000' |
  '#006400' |
  '#9ACD32' |
  '#F0E68C' |
  '#DAA520' |
  '#B8860B' |
  '#FFD700' |
  '#FFA500' |
  '#FF8C00' |
  '#FF7F50' |
  '#FF6347' |
  '#FF4500' |
  '#FF0000' |
  '#DC143C' |
  '#FF69B4' |
  '#000000' |
  '#A52A2A' |
  '#800000';

export class ColorModel {
  static colors: Color[] = [
    '#222222',
    '#555555',
    '#777777',
    '#AAAAAA',
    '#CCCCCC',
    '#00CED1',
    '#00BFFF',
    '#87CEFA',
    '#87CEEB',
    '#ADD8E6',
    '#B0C4DE',
    '#6495ED',
    '#4169E1',
    '#0000FF',
    '#1E90FF',
    '#40E0D0',
    '#66CDAA',
    '#20B2AA',
    '#2E8B57',
    '#3CB371',
    '#8FBC8F',
    '#32CD32',
    '#228B22',
    '#008000',
    '#006400',
    '#9ACD32',
    '#F0E68C',
    '#DAA520',
    '#B8860B',
    '#FFD700',
    '#FFA500',
    '#FF8C00',
    '#FF7F50',
    '#FF6347',
    '#FF4500',
    '#FF0000',
    '#DC143C',
    '#FF69B4',
    '#000000',
    '#A52A2A',
    '#800000',
  ];

  static build(idx: number): Color {
    return ColorModel.colors[(idx - 1) % ColorModel.colors.length];
  }
}
