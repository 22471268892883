import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import App from 'src/App';
import ScrollTop from 'src/hooks/useScrollTop';
import 'nprogress/nprogress.css';
import * as serviceWorker from 'src/serviceWorker';

// npm run buildの際にconsole.log()を何もしないメソッドにすり替え
if (process.env.NODE_ENV !== 'development') {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = () => {};
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <ScrollTop />
      <App />
    </BrowserRouter>
  </HelmetProvider>
);

serviceWorker.unregister();
