import { KeyboardDoubleArrowDown, KeyboardDoubleArrowRight } from '@mui/icons-material';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { addDays, startOfWeek } from 'date-fns';
import { format } from 'date-fns-tz';
import ja from 'date-fns/locale/ja';
import * as React from 'react';
import { FC, memo, useState } from 'react';
import { useQueryDriverIds } from 'src/hooks/useQueryDrivers';

import LoadingComponent from '../components/LoadingComponent';

import ShiftsTableBodyTableRow from './ShiftsTableBodyTableRow';
import ShiftsTableHeaderTableCell from './ShiftsTableHeaderTableCell';

type Props = {
  startOn: string;
}

const ShiftsTablePresenter: FC<Props> = memo(({ startOn }) => {
  const startAt = new Date(startOn);
  const weekStartAt = startOfWeek(startAt);
  const [expandAll, setExpandAll] = useState(false);
  const theme = useTheme();

  const driverIds = useQueryDriverIds();

  if (!startOn) return <LoadingComponent />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!driverIds) return <LoadingComponent />;

  return (
    <Stack mt={3} p={2}>
      <TableContainer
        sx={{ maxHeight: `calc(100vh - ${theme.spacing(12)})` }}
        component={Paper}
      >
        <Table
          stickyHeader
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Stack direction="row" alignItems="center" justifyContent="flex-start">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    sx={{ width: '30px' }}
                    onClick={() => setExpandAll(!expandAll)}
                  >
                    {expandAll ? <KeyboardDoubleArrowDown /> : <KeyboardDoubleArrowRight />}
                  </IconButton>
                  <Typography width="100%" variant="button" align="center">ドライバー名</Typography>
                </Stack>
              </TableCell>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              {[...new Array(7)].map((_, idx) => (
                <TableCell align="center" key={format(addDays(weekStartAt, idx), 'MM/dd', { locale: ja })}>
                  <ShiftsTableHeaderTableCell at={addDays(weekStartAt, idx)} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {driverIds && driverIds.data && driverIds.data.map((driverId) => (
              <ShiftsTableBodyTableRow
                driverId={driverId}
                weekStartAt={weekStartAt}
                key={['driverId', driverId].join('-')}
                expandAll={expandAll}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
});

export default ShiftsTablePresenter;
