import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { GroupRequestEntity } from 'src/entities/Group.request.entity';

const requestPath: (id?: number) => string = (id) => {
  const base = '/api/v4/master/groups';

  if (!id) return base;

  return `${base}/${id}`;
};

export const useGroupMutation = () => ({
  add: useMutation(
    (entity: GroupRequestEntity) => axios.post(requestPath(), entity),
  ),
  update: useMutation(
    (entity: GroupRequestEntity & { id: number }) => axios.put(requestPath(entity.id), entity),
  ),
});
