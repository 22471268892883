const toYen: (maybe: (number | undefined)) => (string) = (maybe) => {
  if (!maybe) return '';

  const intl: Intl.NumberFormat = new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' });

  return intl.format(Math.round(maybe));
};

const toMinutes: (maybe: (number | undefined), prefix?: string) => (string) = (maybe, prefix) => {
  if (!maybe) return '';

  if (prefix) {
    return [
      prefix,
      `${Math.round(maybe).toLocaleString()} 分`
    ].join(' ');
  }

  return `${Math.round(maybe).toLocaleString()} 分`;
};

const toRoundedRate: (maybe: number | undefined, prefix?: string) => string = (maybe, prefix?: string) => {
  if (!maybe) return '';

  if (prefix === undefined) {
    return `約 ${Math.round(maybe * 100).toLocaleString()}%`;
  }

  return [
    prefix, ' ',
    Math.round(maybe * 100).toLocaleString(),
    '%'
  ].join('');
};

const toRoundedKm: (maybe: number | undefined, prefix?: string) => string = (maybe, prefix) => {
  if (!maybe) return '';

  if (prefix === undefined) {
    return `約 ${Math.round(maybe).toLocaleString()} km`;
  }

  return [
    prefix,
    Math.round(maybe).toLocaleString(),
    'km'
  ].join(' ');
};

const toRoundedUnit: (maybe: number | undefined, unit: string, afterTheDecimalPoint?: number, prefix?: string) => string = (
  maybe,
  unit,
  afterTheDecimalPoint,
  prefix
) => {
  if (!maybe) return '';
  const modifiedPrefix = (prefix === undefined)
    ? '約'
    : prefix;

  if (!afterTheDecimalPoint) {
    return [
      modifiedPrefix,
      Math.round(maybe).toLocaleString(),
      unit
    ].filter((str) => !!str).join(' ');
  }

  const divider = 10 ** afterTheDecimalPoint;

  return [
    modifiedPrefix,
    (Math.round(maybe * divider) / divider).toLocaleString(),
    unit
  ].filter((str) => !!str).join(' ');
};

const convertGramToTons: (maybe: (number | undefined), decimals?: number) => string = (maybe, decimals) => {
  if (!maybe && maybe !== 0) return '';
  const multiply = decimals ? decimals * 10 : 1;

  return [
    (Math.round((maybe * multiply) / 1000 / 1000) / multiply).toLocaleString(),
    't'
  ].join('');
};

const convertGramToKg: (maybe: (number | undefined), decimals?: number, unit?: boolean) => string = (maybe, decimals, unit) => {
  if (!maybe) return '';
  const multiply = decimals ? decimals * 10 : 1;

  return [
    (Math.round((maybe * multiply) / 1000) / multiply).toLocaleString(),
    (unit || unit === undefined) ? 'kg' : ''
  ].join('');
};

const convertMm3ToM3: (maybe: (number | undefined), decimals?: number, unit?: boolean) => string = (maybe, decimals, unit) => {
  if (!maybe) return '';
  const multiply = decimals ? decimals * 10 : 1;

  return [
    (Math.round((maybe * multiply) / 1000000000) / multiply).toLocaleString(),
    (unit || unit === undefined) ? 'm3' : ''
  ].join('');
};

const convertSecondsToMinutes: (maybe: number | undefined) => string = (maybe) => {
  if (!maybe) return '';

  return [
    Math.round(maybe / 60).toLocaleString(),
    '分'
  ].join('');
};

const convertToHoursAndMinutes: (maybeMinutes: number | undefined) => string = (maybeMinutes) => {
  if (!maybeMinutes) return '';

  const hours = Math.floor(maybeMinutes / 60);
  const minutes = Math.floor(maybeMinutes % 60);

  if ([hours, minutes].every((maybe) => maybe)) {
    return [
      `${hours}時間`,
      `${minutes}分`
    ].join('');
  }

  if (!minutes) {
    return `${hours}時間`;
  }

  return `${minutes}分`;
};

const toRoundedAndK = (maybe: number | undefined, suffix?: string) => {
  if (!maybe && maybe !== 0) {
    return [
      '-',
      suffix
    ].filter((it) => it).join('');
  }

  const overThousands = maybe >= 1000;
  const rounded = overThousands ? `${(Math.round((maybe * 10) / 1000) / 10).toLocaleString()}K` : (Math.round(maybe * 10) / 10).toLocaleString();

  return [
    rounded,
    suffix
  ].filter((it) => it).join('');
};

const round = (value: number, ndigits: number) => {
  const n = 10 ** ndigits;
  return Math.round(value * n) / n;
};

const formatNumber = (value: number, fractionDigits = 0) => new Intl.NumberFormat('en-US', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(value);

const numberDecorator = {
  toYen,
  toMinutes,
  toRoundedRate,
  toRoundedKm,
  toRoundedUnit,
  convertGramToTons,
  convertGramToKg,
  convertMm3ToM3,
  convertToHoursAndMinutes,
  convertSecondsToMinutes,
  toRoundedAndK,
  round,
  formatNumber,
};

export default numberDecorator;
