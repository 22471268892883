import { FC, memo } from 'react';
import OrderForm from 'src/components/V2OrderForm';
import { useQueryOrder } from 'src/hooks/useQueryOrders';

type Props = {
  dialogIsOpen: boolean;
  dialogOnClose: () => void;
  orderId: undefined | number;
  startOn: string;
  endOn: string;
  onClose: () => void;
  onClickSplit?: () => void;
}

const OrderFormPresenter: FC<Props> = memo((
  {
    dialogIsOpen,
    dialogOnClose,
    orderId,
    startOn,
    endOn,
    onClose,
    onClickSplit
  }
) => {
  const { data, isLoading } = useQueryOrder(orderId);

  const isEdit = !!orderId;

  if (isEdit && isLoading) return null;

  return (
    <OrderForm
      dialogIsOpen={dialogIsOpen}
      dialogOnClose={dialogOnClose}
      startOn={startOn}
      onClose={onClose}
      onClickSplit={onClickSplit}
      createAfterCallbackFnc={onClose}
      entity={data}
    />
  );
});

export default OrderFormPresenter;
