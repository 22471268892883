import { EstimationEntity } from 'src/entities/Estimation.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import arrayUtil from 'src/utils/array.util';
import { datetimeUtil } from 'src/utils/datetime.util';

import datetimeDecorator from '../../../decorators/datetime.decorator';

type Args = {
  shipperName: string,
  distanceMmByShipper: number,
  orderId: number,
  planningsDeliveryEntity: PlanningsDeliveryEntity,
  planningsDriverEntity: PlanningsDriverEntity,
  planningsTruckEntity: PlanningsTruckEntity,
  defaultDriverCostYenPerSeconds: number,
  defaultTruckFuelCostYenPerMm: number,
  defaultTruckInsuranceFeeYenPerDay: number,
  defaultTruckRepairFeeYenPerDay: number,
  defaultTruckExpresswayFeeYenPerDay: number,
  expectedProfitRate: number,
  expectedFeeYenByKm: number,
  expectedFeeYenByHour: number,
  expectedFeeYenByItemCount: number,
  expectedFeeYenByItemWeightKg: number,
  expectedFeeYenByItemVolumeM3: number,
}

const convertToEntity: (
  args: Args
) => EstimationEntity | undefined = (args) => {
  const {
    shipperName,
    distanceMmByShipper,
    orderId,
    planningsDeliveryEntity,
    planningsDriverEntity,
    planningsTruckEntity,
    defaultDriverCostYenPerSeconds,
    defaultTruckFuelCostYenPerMm,
    defaultTruckInsuranceFeeYenPerDay,
    defaultTruckRepairFeeYenPerDay,
    defaultTruckExpresswayFeeYenPerDay,
    expectedProfitRate,
    expectedFeeYenByKm,
    expectedFeeYenByHour,
    expectedFeeYenByItemCount,
    expectedFeeYenByItemWeightKg,
    expectedFeeYenByItemVolumeM3,
  } = args;

  if ([!planningsDeliveryEntity.workEndAt, !planningsDeliveryEntity.workEndAt, !planningsDeliveryEntity.operations.length].every((b) => b)) return undefined;

  const totalOrderOperationDistanceMm = planningsDeliveryEntity
    .operations
    .map((it) => it.drivingDistanceMm)
    .filter((maybe) => maybe)
    .reduce((prev, current) => prev + current, 0);
  const arrivalOperationDistanceMm = planningsDeliveryEntity.arrivalDistanceMm || 0;

  const departureAt = new Date(planningsDeliveryEntity.workStartAt);
  const operationMaxDepartureAt: Date = arrayUtil.maxAt(
    planningsDeliveryEntity
      .operations
      .map((it) => it.departureAt)
      .filter((maybe) => maybe)
      .map((it) => new Date(it))
  );
  const arrivalAt = new Date(planningsDeliveryEntity.workEndAt);

  const orderOperationSeconds = datetimeUtil.diffSeconds(departureAt, operationMaxDepartureAt);
  const arrivalOperationSeconds = datetimeUtil.diffSeconds(operationMaxDepartureAt, arrivalAt);

  const totalItemCount = planningsDeliveryEntity
    .operations
    .map((it) => it.itemCount)
    .filter((maybe) => maybe)
    .reduce((prev, current) => prev + current, 0) / 2;
  const totalItemWeightGram = planningsDeliveryEntity
    .operations
    .map((it) => it.itemTotalWeightForCalculation)
    .filter((maybe) => maybe)
    .reduce((prev, current) => prev + current, 0) / 2;
  const totalItemVolumeMm3 = planningsDeliveryEntity
    .operations
    .map((it) => it.itemTotalVolumeMm3)
    .filter((maybe) => maybe)
    .reduce((prev, current) => prev + current, 0) / 2;

  const driverCostYenPerSeconds = planningsDriverEntity.driverCostYenPerHours
    ? planningsDriverEntity.driverCostYenPerHours / (3600)
    : (defaultDriverCostYenPerSeconds || 0);
  const fuelCostPerMm = planningsTruckEntity.truckFuelCostYenPerKm
    ? planningsTruckEntity.truckFuelCostYenPerKm / 1000000
    : (defaultTruckFuelCostYenPerMm || 0);

  const insuranceFeeYenPerDay = planningsTruckEntity.truckInsuranceFeeYenPerDay || defaultTruckInsuranceFeeYenPerDay || 0;
  const repairCostYenPerDay = planningsTruckEntity.truckRepairCostYenPerDay || defaultTruckRepairFeeYenPerDay || 0;
  const expresswayFeeYenPerShift = planningsTruckEntity.truckExpresswayFeeYenPerShift || defaultTruckExpresswayFeeYenPerDay || 0;

  const workStartAt = new Date(planningsDeliveryEntity.workStartAt);
  const workEndAt = new Date(planningsDeliveryEntity.workEndAt);

  const workTime = [
    datetimeDecorator.toHourMinutes(workStartAt),
    datetimeDecorator.toHourMinutes(workEndAt),
  ].join(' ~ ');

  const deliveryName = [
    planningsTruckEntity.licensePlateValue,
    planningsDriverEntity.name,
    workTime
  ].join(' ');

  const totalWeightGramByShipper = planningsDeliveryEntity
    .operations
    .filter((it) => it.shipperName === shipperName)
    .map((it) => it.itemTotalWeightForCalculation)
    .filter((maybe) => maybe)
    .reduce((prev, current) => prev + current, 0);
  const totalVolumeMm3ByShipper = planningsDeliveryEntity
    .operations
    .filter((it) => it.shipperName === shipperName)
    .map((it) => it.itemTotalVolumeMm3)
    .filter((maybe) => maybe)
    .reduce((prev, current) => prev + current, 0);

  const entity: EstimationEntity = {
    id: [
      'delivery',
      planningsDeliveryEntity.id,
      'order',
      orderId
    ].join('-'),
    deliveryName,
    totalWeightGramByShipper,
    arrivalOperationDistanceMm,
    arrivalOperationSeconds,
    totalVolumeMm3ByShipper,
    deliveryId: planningsDeliveryEntity.id,
    distanceMmByShipper,
    driverCostYenPerSeconds,
    driverName: planningsDriverEntity.name,
    expectedFeeYenByItemCount,
    expectedFeeYenByItemVolumeM3,
    expectedFeeYenByItemWeightKg,
    expectedFeeYenByKm,
    expectedFeeYenByHour,
    expectedProfitRate,
    expresswayFeeYenPerShift,
    fuelCostPerMm,
    insuranceFeeYenPerDay,
    orderOperationSeconds,
    repairCostYenPerDay,
    shipperName,
    totalItemCount,
    totalItemVolumeMm3,
    totalItemWeightGram,
    totalOrderOperationDistanceMm,
    truckLicensePlateValue: planningsTruckEntity.licensePlateValue,
    orderId
  };

  return entity;
};

export default convertToEntity;
