import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { TruckEntity, TruckRequestEntity } from 'src/entities/truckEntity';

export const useMutationTruck = () => {
  const queryClient = useQueryClient();
  return {
    addTruck: useMutation(
      (trucks: TruckRequestEntity) => axios.post('/api/v2/trucks', trucks),
      {
        onSuccess: () => queryClient.invalidateQueries(['trucks']),
      }
    ),
    updateTruck: useMutation(
      (trucks: TruckEntity) => axios.put(`/api/v2/trucks/${trucks.id}`, trucks),
      {
        onSuccess: (res) => queryClient.invalidateQueries(['trucks']),
      }
    ),
    deleteTrucks: useMutation(
      (ids: number[]) => axios.delete('/api/v2/trucks/bulk', { data: { ids } }),
      {
        onSuccess: () => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['trucks']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['drivers']);
        },
      }
    ),
  };
};
