/* eslint-disable @typescript-eslint/no-misused-promises */
import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogActions, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useEffect } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import LicenseContext from 'src/contexts/LicenseContext';
import { AreaEntity, AreaRequestEntity } from 'src/entities/areaEntity';
import { useAreaMutation } from 'src/hooks/useArea.mutation';
import { queryKey } from 'src/hooks/useAreas.request';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required('必須です'),
  areaAddressesAttributes: yup.array().of(
    yup.object().shape({
      address: yup.string().required('必須です'),
    })
  ).test({
    message: '1つ以上設定してください',
    test: (arr) => arr.length >= 1,
  }),
});

type Props = {
  open: boolean;
  onClose: () => void;
  entity?: AreaEntity;
};

const FormDialog: FC<Props> = memo((
  {
    open,
    onClose,
    entity,
  }
) => {
  const licenseContext = useContext(LicenseContext);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { add, update } = useAreaMutation();

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AreaEntity | AreaRequestEntity>({
    resolver: yupResolver(schema)
  });

  const { fields, append, remove } = useFieldArray({ control, name: 'areaAddressesAttributes' });

  const addArea = (data: AreaRequestEntity) => {
    let success = true;

    add.mutate(data, {
      onSuccess: () => {
        enqueueSnackbar('エリアを追加しました', { variant: 'success' });
      },
      onError: (e: AxiosError<{errors: string[]}>) => {
        enqueueSnackbar(`エリアの追加に失敗しました。${e.response.data.errors.join(', ')}`, { variant: 'error' });
        success = false;
      },
      onSettled: () => {
        queryClient
          .invalidateQueries([queryKey])
          .finally(() => {
            if (success) onClose();
          });
      }
    });
  };

  const updateArea = (data: AreaEntity) => {
    const addressIds = data.areaAddressesAttributes.map(({ id }) => id).filter((it) => it);
    entity.areaAddressesAttributes.forEach((it) => {
      if (!addressIds.includes(it.id)) {
        data.areaAddressesAttributes.push({ ...it, delete: true });
      }
    });

    let success = true;
    update.mutate(data, {
      onSuccess: () => {
        enqueueSnackbar('エリアを更新しました', { variant: 'success' });
      },
      onError: (e: AxiosError<{errors: string[]}>) => {
        enqueueSnackbar(`エリアの更新に失敗しました。${e.response.data.errors.join(', ')}`, { variant: 'error' });
        success = false;
      },
      onSettled: () => {
        queryClient
          .invalidateQueries([queryKey])
          .finally(() => {
            if (success) onClose();
          });
      }
    });
  };

  const onSubmitForm: SubmitHandler<AreaRequestEntity | AreaEntity> = (
    data: AreaEntity
  ): void => {
    if (entity) {
      updateArea(data);
    } else {
      addArea(data);
    }
  };

  const haveSelectableCompanies = licenseContext?.config?.selectable_companies?.length > 1;
  const isNew = !entity;

  useEffect(() => {
    if (entity) {
      if (!entity.areaAddressesAttributes || entity.areaAddressesAttributes.length === 0) {
        entity.areaAddressesAttributes = [{ address: '' }];
      }
      reset(entity);
      return;
    }

    reset({ companyId: licenseContext?.config?.company_id, areaAddressesAttributes: [{ address: '' }] });
  }, [entity, licenseContext?.config?.company_id, reset]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      sx={{
        mt: 8
      }}
    >
      <Stack
        p={2}
      >
        {[haveSelectableCompanies, isNew].every((bool) => bool) && (
          <Stack
            mb={2}
          >
            <FormControl
              fullWidth
              size="small"
              sx={{
                mt: 1
              }}
            >
              <Controller
                control={control}
                name="companyId"
                render={({ field }) => (
                  <>
                    <InputLabel>事業所</InputLabel>
                    <Select
                      id="companyId"
                      margin="dense"
                      label="事業所"
                      type="number"
                      fullWidth
                      variant="standard"
                      {...field}
                    >
                      {licenseContext?.config?.selectable_companies?.map((company) => (
                        <MenuItem value={company.id} key={company.id}>{company.name}</MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
              <FormHelperText error={'companyId' in errors}>{errors.companyId?.message}</FormHelperText>
            </FormControl>
          </Stack>
        )}
        <Stack>
          <Typography
            variant="h4"
            sx={{
              mb: 1
            }}
          >
            エリア名
          </Typography>
          <TextField
            margin="dense"
            type="text"
            fullWidth
            size="small"
            variant="standard"
            id="name"
            {...register('name')}
            error={'name' in errors}
            helperText={errors.name?.message}
            required
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
        <Stack
          sx={{
            pl: 3,
          }}
        >
          <Stack
            direction="row"
          >
            <Typography
              variant="h4"
              sx={{
                pt: 1,
                mb: 1
              }}
            >
              市区町村名
            </Typography>
          </Stack>
          {fields.map((field, index) => (
            <Stack
              direction="row"
              gap={1}
              alignItems="baseline"
              key={['addresses', field, index].join('-')}
            >
              <IconButton
                disabled={fields.length < 2}
                onClick={() => remove(index)}
              >
                <RemoveCircleOutlineOutlined />
              </IconButton>
              <TextField
                margin="dense"
                type="test"
                fullWidth
                size="small"
                variant="standard"
                id={`addresses[${index}].address`}
                {...register(`areaAddressesAttributes.${index}.address`)}
                error={!!(index === 0 && errors.areaAddressesAttributes?.message) || !!errors.areaAddressesAttributes?.[index]?.address}
                helperText={(index === 0 && errors.areaAddressesAttributes?.message) || errors.areaAddressesAttributes?.[index]?.address?.message}
                required
              />
            </Stack>
          ))}
        </Stack>
      </Stack>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          ml: 8,
        }}
      >
        <Stack direction="row" spacing={2}>
          <IconButton
            onClick={() => append({ areaId: entity?.id || null, address: '' })}
          >
            <AddCircleOutlineOutlined />
            <Typography>
              市区町村追加
            </Typography>
          </IconButton>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button
            onClick={onClose}
            color="primary"
          >
            キャンセル
          </Button>
          <Button
            onClick={handleSubmit(onSubmitForm)}
            variant="contained"
            color="primary"
          >
            保存する
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
});
export default FormDialog;
