import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton, Stack, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { GridColDef } from '@mui/x-data-grid';
import { DriverEntity } from 'src/entities/driverEntity';
import { dataGridUtil } from 'src/utils/dataGrid.util';

export const Columns: (
  openEditDialog?: (truckEntity: DriverEntity) => void
) => GridColDef[] = (
  openEditDialog
) => {
  const getWidth = (name: string, width: number) => {
    const maybeWidth: number = dataGridUtil.getSavedColumnWidth('driver-columns', name);

    return maybeWidth || width;
  };

  return [
    {
      field: 'edit',
      headerName: '編集',
      disableExport: true,
      renderCell: (params: { row: DriverEntity }) => (
        <IconButton onClick={() => openEditDialog(params.row)}>
          <EditOutlinedIcon />
        </IconButton>
      ),
      filterable: false,
      sortable: false,
    },
    {
      field: 'company_name',
      headerName: '事業所',
      width: getWidth('company_name', 300),
    },
    {
      field: 'name',
      headerName: '名前',
      width: getWidth('name', 280),
      renderCell: (params: { row: DriverEntity }) => (
        <Stack direction="row">
          <Stack
            borderLeft={20}
            borderColor={params.row.color}
            spacing={0.5}
            marginRight={0.5}
          >
            &nbsp;
          </Stack>
          <Typography>
            {params.row.name}
          </Typography>
        </Stack>
      )
    },
    {
      field: 'default_truck_license_plate_value',
      headerName: 'トラック',
      width: getWidth('default_truck_license_plate_value', 300),
      valueGetter: (params: { row: DriverEntity }) => params.row.truck_name || '',
    },
    {
      field: 'shuttle_limit',
      headerName: 'ピストン上限回数',
      width: getWidth('shuttle_limit', 150),
      type: 'number',
    },
    {
      field: 'orders_limit',
      headerName: '案件数上限',
      width: getWidth('orders_limit', 100),
      type: 'number',
    },
    {
      field: 'driver_cost_yen_per_hours',
      headerName: '時給',
      width: getWidth('driver_cost_yen_per_hours', 70),
      type: 'number',
    },
    {
      field: 'phone_number',
      headerName: '電話番号',
      width: getWidth('phone_number', 170)
    },
    {
      field: 'email_address',
      headerName: 'メールアドレス',
      width: getWidth('email_address', 400),
      renderCell: (params: { row: DriverEntity }) => {
        if (params.row.email_address === null || params.row.email_address === '') return '';

        let activationState = <CloseIcon sx={{ color: red[500] }} />;
        if (params.row.activation_state === 'active') {
          activationState = <DoneIcon color="success" />;
        }

        return (
          <>
            {activationState}
            <Typography variant="body1" paddingLeft={1}>
              {params.row.email_address}
            </Typography>
          </>
        );
      }
    },
    {
      field: 'sort_order',
      headerName: '並び順',
      width: getWidth('sort_order', 100),
      type: 'number',
    },
  ];
};
