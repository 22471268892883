import {
  Box, Paper, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography
} from '@mui/material';
import { format } from 'date-fns-tz';
import { FC, memo, useContext, useEffect, useRef, useState } from 'react';
import OrderForm from 'src/components/V2OrderForm';
import { ErrorValue } from 'src/entities/import';
import { OrderRequestEntity } from 'src/entities/orderEntity';
import useImport from 'src/hooks/useImport';

import LicenseContext from '../../contexts/LicenseContext';

import ErrorTableRow from './ErrorTableRow';
import Footer from './Footer';
import Header from './Header';

const Validation: FC = memo(() => {
  const importContext = useImport();
  const licenseContext = useContext(LicenseContext);

  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [editRowIdx, setEditRowIdx] = useState<number>(-1);
  const updateEditRowIdx = (num: number) => {
    setEditRowIdx(num);
  };

  const [entity, setEntity] = useState<undefined | OrderRequestEntity>(undefined);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const openDialog = (requestEntity: OrderRequestEntity) => {
    setDialogIsOpen(true);
    setEntity(requestEntity);
  };
  const dialogOnClose = () => {
    setDialogIsOpen(false);
    setEntity(undefined);
  };

  const handleWindowResize = () => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [ref]);

  useEffect(() => {
    if (!importContext) return;
    if (importContext.isLoading) return;

    if (importContext?.errorValues && importContext.errorValues.length) return;

    importContext.setStep(4);
  }, [importContext]);

  useEffect(() => {
    if (!importContext) return;
    if (!importContext.errorValues) return;
    if (!importContext.errorValues.length) return;

    const maybeError: undefined | ErrorValue = importContext.errorValues.find((errorValue) => errorValue.idx === editRowIdx);

    if (!maybeError) return;

    setEntity(maybeError.value);
  }, [editRowIdx]);

  const customInputFields: string[] = licenseContext?.config?.custom_input_fields || [];

  const columns: { [key: string]: string } = {
    code: '注文コード',
    shipper_name: '荷主名',
    loading_name: '積地',
    loading_address: '積地住所',
    loading_start_at: '積日受付',
    loading_end_at: '積日締切',
    loading_staying_minutes: '積地作業分数',
    unloading_name: '降地名称',
    unloading_address: '降地住所',
    unloading_start_at: '降日受付',
    unloading_end_at: '降日締切',
    unloading_staying_minutes: '降地作業分数',
    item_count: '数量',
    item_packing_style: '荷姿',
    item_total_weight_kg: '総重量',
    item_total_volume_m3: '総体積',
    item_name: '品名',
    item_klass: '輸送区分',
    item_handling_of_cargo_style: '荷扱い',
    item_can_be_mixed: '混載可',
    designated_truck_klasses: '車両タイプ',
    designated_truck_car_models: '指定車種',
    designated_truck_loading_platform_heights: '荷台高',
    designated_truck_loading_platform_widths: '荷台幅',
    designated_truck_loading_platform_lengths: '荷台長',
    designated_truck_floor_specifications: '床仕様',
    designated_truck_features: '指定装置・特徴',
    charge_basic_fee_yen: '基本運賃',
    charge_highway_fee_yen: '高速代金',
    charge_loading_fee_yen: '積込料金',
    charge_ancillary_fee_yen: '付帯作業料金',
    charge_waiting_time_fee_yen: '待機料金',
    charge_unloading_fee_yen: '取卸料',
    charge_expenses_fee_yen: '諸経費',
    charge_ancillary_content: '付帯作業備考',
    memo: '備考',
    allowed_truck_ids: '許可トラック',
    denied_driver_ids: 'NGドライバー',
    order_loading_specified_datetimes: '積日',
    order_unloading_specified_datetimes: '降日',
  };

  const createAfterCallbackFnc = () => {
    const newErrorValues = importContext
      .errorValues
      .filter((errorValue) => errorValue.idx !== editRowIdx);

    importContext.setErrorValues(newErrorValues);
  };

  console.log(importContext);

  return (
    <Box
      ref={ref}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Header step={3} />
      </Box>
      <Stack px={2} pt={1}>
        <Typography>
          {importContext.total}
          件のうち、
          {importContext.errorValues?.length}
          件が取り込めません。
        </Typography>
        <Typography>
          修正する場合は、この画面で修正・登録してください。
        </Typography>
      </Stack>
      <Box
        flexGrow={1}
        paddingX={2}
        paddingY={2}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
        component="form"
      >
        <TableContainer component={Paper} sx={{ height: `${height - 280}px` }}>
          <Table sx={{ minWidth: 650 }} stickyHeader size="small">
            <TableHead>
              <TableRow
                sx={{
                  whiteSpace: 'nowrap'
                }}
              >
                <TableCell>
                  エラー
                </TableCell>
                {
                  [
                    ...Object.values(columns),
                    ...customInputFields
                  ]
                    .map((column, idx) => (
                      <TableCell
                        key={[
                          idx
                        ].join('-')}
                      >
                        {column}
                      </TableCell>
                    ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {importContext.errorValues.map((errorValue: ErrorValue) => (
                <ErrorTableRow
                  errorValue={errorValue}
                  columns={columns}
                  updateEditRowIdx={updateEditRowIdx}
                  openDialog={openDialog}
                  customInputFields={customInputFields}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <Footer
          handleBackButton={() => {
            importContext.setStep(2);
          }}
          handleNextButton={() => {
            importContext.setStep(4);
          }}
          nextButtonText={`${importContext.errorValues?.length}件を無視してインポートする`}
        />
      </Box>
      {entity && (
        <OrderForm
          dialogIsOpen={dialogIsOpen}
          dialogOnClose={dialogOnClose}
          onClose={dialogOnClose}
          startOn={format(new Date(), 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' })}
          entity={entity}
          createAfterCallbackFnc={createAfterCallbackFnc}
        />
      )}
    </Box>
  );
});

export default Validation;
