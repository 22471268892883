import { DayCellContentArg, EventContentArg, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import ControlPointDuplicateRoundedIcon from '@mui/icons-material/ControlPointDuplicateRounded';
import DifferenceRoundedIcon from '@mui/icons-material/DifferenceRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button, Checkbox,
  Dialog, DialogActions, DialogContent,
  DialogTitle, Divider, FormControlLabel,
  IconButton,
  LinearProgress, Skeleton, TextField,
  Typography,
  useTheme
} from '@mui/material';
import { Stack } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useQueryClient } from '@tanstack/react-query';
import {
  addDays,
  addMonths, addYears,
  eachDayOfInterval,
  format,
  startOfMonth,
  subMonths
} from 'date-fns';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { appBarHeight } from 'src/constants/layout';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import LicenseContext from 'src/contexts/LicenseContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import numberDecorator from 'src/decorators/number.decorator';
import { PlanDuplicationRequestEntity } from 'src/entities/PlanDuplication.request.entity';
import { usePlanDuplicationRequestMutation } from 'src/hooks/usePlanDuplication.request.mutation';
import { useV4AchievementQuery } from 'src/hooks/useV4Achievement.query';
import { datetimeUtil } from 'src/utils/datetime.util';
import numberUtil from 'src/utils/number.util';
import { DayOfWeekVo } from 'src/vo/DayOfWeek.vo';

const renderEventContent = (eventContent: EventContentArg) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const budget = eventContent.event.extendedProps.budget as number;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const achievement = eventContent.event.extendedProps.achievement as number;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const unit = eventContent.event.extendedProps.unit as string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const bgColor = eventContent.event.extendedProps.bgColor as string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const barColor = eventContent.event.extendedProps.barColor as string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const color = eventContent.event.extendedProps.color as string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isLoading = eventContent.event.extendedProps.isLoading as boolean;

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0.5}
      sx={{
        width: '100%'
      }}
    >
      <Stack
        sx={{
          width: '100%',
          position: 'relative',
        }}
      >
        {
          isLoading ? (
            <Skeleton
              variant="rectangular"
              height={18}
            />
          ) : (
            <LinearProgress
              variant="determinate"
              value={achievement ? (achievement / budget) * 100 : 0}
              sx={{
                height: 18,
                borderRadius: 0,
                backgroundColor: bgColor,
                '& .MuiLinearProgress-bar': {
                  backgroundColor: barColor,
                }
              }}
            />
          )
        }
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            left: 3,
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block'
            }
          }}
        >
          <Typography
            variant="caption"
            color={color}
          >
            {eventContent.event.title}
          </Typography>
        </Stack>
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            right: 3,
            display: {
              xs: 'none',
              sm: 'block'
            }
          }}
        >
          { !!budget && (
            <Typography
              variant="caption"
              color={color}
            >
              {
                [
                  achievement === 0 ? `${achievement}${unit}` : `${numberDecorator.toRoundedUnit(achievement, unit, 2, '')}`,
                  `${numberDecorator.toRoundedUnit(budget, unit, 2, '')}`
                ].join('/')
              }
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const moreLinkContent = () => (
  <Typography
    variant="caption"
  >
    全指標を表示
  </Typography>
  );

const V2Dashboard: FC = memo(() => {
  const CURRENT_MONTH_KEY = 'dashboardCurretMonth';
  const queryClient = useQueryClient();

  const licenseContext = useContext(LicenseContext);

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const calendarRef = useRef<FullCalendar>(null!);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [planningOn, setPlanningOn] = useState<string>('');
  const [dayOfWeekOrSpecifiedDay, setDayOfWeekOrSpecifiedDay] = useState<'dayOfWeek' | 'specifiedDay'>('dayOfWeek');
  const [specifiedAt, setSpecifiedAt] = useState<Date | null>(null);
  const [copyFromAt, setCopyFromAt] = useState<Date | null>(null);
  const [maximumRepeatableAt, setMaximumRepeatableAt] = useState<Date | null>(null);
  const [repeatableAt, setRepeatableAt] = useState<Date | null>(null);
  const [copyToAts, setCopyToAts] = useState<Date[]>([]);
  const [canDupRequest, setCanDupRequest] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState<Date | null>(null);
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<DayOfWeekVo[]>([]);
  const [selectMonthsDialogIsOpen, setSelectMonthsDialogIsOpen] = useState<boolean>(false);
  const [events, setEvents] = useState<EventInput[]>([]);
  const [monthsOfTheYear, setMonthsOfTheYear] = useState<Date[]>([]);

  const updateMonthsOfTheYear = (year: number) => {
    const difference = year - monthsOfTheYear[0].getFullYear();
    const newMonthsOfTheYear = monthsOfTheYear.map((it) => addYears(it, difference));

    setMonthsOfTheYear(newMonthsOfTheYear);
  };

  const { create } = usePlanDuplicationRequestMutation();
  const { data, isLoading: queryIsLoading } = useV4AchievementQuery(
    currentMonth ? datetimeDecorator.toYyyyMmDd(currentMonth) : null
  );

  const buttonText = {
    month: '月',
    day: '日',
  };

  const fontColor = theme.colors.alpha.black[70];

  const customButtons = {
    myTodayButton: {
      text: '今月',
      click: () => {
        if (isLoading) return;
        ReactGA.event('click', { screen_name: SCREEN_NAMES.DASHBOARD, button_name: '前後月移動', label: '今月' });
        const d = new Date();
        const calendarApi = calendarRef.current.getApi();
        setCurrentMonth(startOfMonth(d));
        calendarApi.gotoDate(startOfMonth(d));
      }
    },
    myPrevButton: {
      text: '先月',
      click: () => {
        if (isLoading) return;
        ReactGA.event('click', { screen_name: SCREEN_NAMES.DASHBOARD, button_name: '前後月移動', label: '先月' });
        const calendarApi = calendarRef.current.getApi();
        const currentDate = calendarApi.getDate();
        const prevMonth = subMonths(currentDate, 1);
        setCurrentMonth(startOfMonth(prevMonth));
        calendarApi.gotoDate(startOfMonth(prevMonth));
      }
    },
    myNextButton: {
      text: '次月',
      click: () => {
        if (isLoading) return;
        ReactGA.event('click', { screen_name: SCREEN_NAMES.DASHBOARD, button_name: '前後月移動', label: '次月' });
        const calendarApi = calendarRef.current.getApi();
        const currentDate = calendarApi.getDate();
        const nextMonth = addMonths(currentDate, 1);
        setCurrentMonth(startOfMonth(nextMonth));
        calendarApi.gotoDate(startOfMonth(nextMonth));
      }
    },
    displayCalendarButton: {
      text: '月を選択',
      click: () => {
        if (isLoading) return;
        ReactGA.event('open', { screen_name: SCREEN_NAMES.DASHBOARD_MONTH_SELECT });
        setSelectMonthsDialogIsOpen(true);
      }
    },
  };

  const headerToolbar = {
    start: 'displayCalendarButton',
    center: 'title',
    end: 'myPrevButton,myTodayButton,myNextButton'
  };

  const selectDaysOfWeekOnClick = (dayOfWeek: DayOfWeekVo) => {
    if (dayOfWeekOrSpecifiedDay !== 'dayOfWeek') return;

    if (selectedDaysOfWeek.includes(dayOfWeek)) {
      setSelectedDaysOfWeek(selectedDaysOfWeek.filter((d) => d !== dayOfWeek));
    } else {
      setSelectedDaysOfWeek([...selectedDaysOfWeek, dayOfWeek]);
    }
  };

  const updateRepeatableAt = (at: Date | null) => {
    if (!at) {
      setRepeatableAt(null);
      return;
    }

    if (maximumRepeatableAt < at) return;

    setRepeatableAt(at);
  };

  const specifiedDayOnChange = (at: Date | null) => {
    if (datetimeDecorator.toYyyyMmDd(at) === planningOn) {
      enqueueSnackbar('複製元の日付と同じ日付は指定できません。', {
        variant: 'error'
      });
      return;
    }

    setSpecifiedAt(at);
  };

  const displayDaysOfWeek: DayOfWeekVo[] = [
    '日',
    '月',
    '火',
    '水',
    '木',
    '金',
    '土'
  ];

  const duplicationButtonOnClick = () => {
    if (!canDupRequest) return;

    const gaEventValue = {
      screen_name: SCREEN_NAMES.DASHBOARD_COPY_PLANS,
      button_name: 'copy',
      label: dayOfWeekOrSpecifiedDay === 'dayOfWeek' ? 'week' : 'date',
      ...(dayOfWeekOrSpecifiedDay === 'dayOfWeek'
        ? { week: selectedDaysOfWeek.join(','), until: datetimeDecorator.toYyyyMmDd(repeatableAt) }
        : { date: datetimeDecorator.toYyyyMmDd(specifiedAt) })
    };
    ReactGA.event('executeDuplication', gaEventValue);

    setIsLoading(true);

    const requestEntity: PlanDuplicationRequestEntity = {
      planning_on: planningOn,
      specific_days: copyToAts.map((it) => datetimeDecorator.toYyyyMmDd(it)),
      reset_key: JSON.stringify(['useV4AchievementQuery'])
    };

    enqueueSnackbar('複製を開始します');

    create.mutate(requestEntity, {
      onSuccess: () => {
        licenseContext.updateForceDisabled(true);
      },
      onSettled: () => {
        setPlanningOn('');
        setIsLoading(false);
      }
    });
  };

  const dayCellContent = (dayCellContentArg: DayCellContentArg) => {
    const holidayName = datetimeUtil.holidayName(dayCellContentArg.date);
    const replaced = dayCellContentArg.dayNumberText.replace('日', '');
    const startOn = datetimeDecorator.toYyyyMmDd(dayCellContentArg.date);
    const isSaturdayDay = [6].includes(dayCellContentArg.dow);
    const isSundayDay = [0].includes(dayCellContentArg.dow);
    const showAvatar = dayCellContentArg.isToday;
    const bgColor = (() => {
      if (dayCellContentArg.isToday) return theme.colors.primary.light;

      return 'inherit';
    })();
    const color: string = (() => {
      if (isSaturdayDay) return theme.colors.primary.main;
      if (isSundayDay || holidayName) return theme.colors.error.main;

      return fontColor;
    })();

    const calendarDateOnClick = () => {
      ReactGA.event('click', { screen_name: SCREEN_NAMES.DASHBOARD, button_name: 'date', label: startOn });
      navigate(`/plans/${startOn}/${startOn}`);
    };

    const copyToOnClick = () => {
      ReactGA.event('open', { screen_name: SCREEN_NAMES.DASHBOARD_COPY_PLANS, label: startOn });
      setPlanningOn(startOn);
    };

    if (!replaced) return null;

    return (
      <Stack
        sx={{
          width: '100%',
          position: 'relative',
          textAlign: 'center',
        }}
      >
        <Stack
          flexGrow={1}
        >
          <Button
            onClick={calendarDateOnClick}
            sx={{
              color: theme.colors.alpha.black[100],
              padding: 0.2,
            }}
            style={{
              borderRadius: 0
            }}
            size="small"
          >
            {
              showAvatar ? (
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    bgcolor: bgColor,
                  }}
                >
                  {replaced}
                </Avatar>
              ) : (
                <Typography
                  p={0}
                  color={color}
                  fontWeight="bold"
                >
                  {replaced}
                </Typography>
              )
            }
          </Button>
        </Stack>
        <Stack
          sx={{
            position: 'absolute',
            top: 2,
            right: 0,
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block'
            }
          }}
        >
          <IconButton
            onClick={copyToOnClick}
            size="small"
          >
            <DifferenceRoundedIcon
              sx={{
                color: fontColor
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
    );
  };

  const fullCalendarStyles = {
    '@media screen and (max-height: 800px)': {
      '& .fc-toolbar-title': {
        fontSize: '16px',
      },
      '& .fc-direction-ltr .fc-daygrid-event.fc-event-end,': {
        fontSize: '11px',
      },
      '& .fc .fc-toolbar.fc-header-toolbar': {
        marginBottom: '1px',
      },
    },
    '& .fc-col-header-cell': {
      background: theme.colors.secondary.lighter,
    },
    '& .fc-h-event': {
      background: 'inherit',
      border: 0,
    },
    '& .fc-daygrid-day-top': {
      display: 'block',
      height: 'auto',
    },
    '& .fc-daygrid-day-top a': {
      padding: 0,
      height: '20px'
    },
    '& .fc .fc-daygrid-day.fc-day-today': {
      backgroundColor: 'inherit'
    },
    '& .fc .fc-button-primary': {
      backgroundColor: 'inherit',
      border: 0,
      color: theme.colors.primary.main
    },
    '& .fc .fc-button-primary:not(:disabled):active': {
      backgroundColor: 'inherit',
      border: 0,
      color: theme.colors.primary.main
    },
    '& .fc .fc-button-primary:focus': {
      boxShadow: 'none',
      backgroundColor: 'inherit',
      border: 0,
      color: theme.colors.primary.main
    },
    '& .fc .fc-button-primary:focus-within': {
      boxShadow: 'none',
      backgroundColor: 'inherit',
      border: 0,
      color: theme.colors.primary.main
    },
    '& .fc .fc-button-primary:hover': {
      boxShadow: 'none',
      backgroundColor: theme.colors.primary.lighter,
      border: 0,
      color: theme.colors.primary.main
    },
    '& .fc .fc-button-primary:focus-visible': {
      boxShadow: 'none',
      backgroundColor: theme.colors.primary.lighter,
      border: 0,
      color: theme.colors.primary.main,
      outline: 'none'
    },
    '& .fc .fc-button-primary:not(:disabled):active:focus': {
      boxShadow: 'none',
      backgroundColor: theme.colors.primary.lighter,
      border: 0,
      color: theme.colors.primary.main,
      outline: 'none'
    },
    '& .fc-popover-body .MuiStack-root': {
      display: 'block'
    }
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    const newEvents: EventInput[] = data.flatMap((it) => {
      const ordersAchievement = {
        id: `ordersAchievement-${it.loadOn}T00:00:00Z`,
        title: '案件数',
        start: `${it.loadOn}T00:00:00Z`,
        extendedProps: {
          budget: it.totalOrdersCount,
          achievement: it.allocatedOrdersCount,
          unit: '件',
          bgColor: it.totalOrdersCount ? theme.colors.primary.light : theme.colors.secondary.lighter,
          barColor: it.totalOrdersCount ? theme.colors.primary.dark : theme.colors.secondary.lighter,
          color: it.totalOrdersCount ? theme.colors.alpha.white[100] : theme.colors.secondary.main,
        }
      };
      const totalWeightG = it.loadWeightG + it.notAllocatedWeightG;
      let weightUnit = 'kg';
      let divider = 1000;
      if ((totalWeightG / 1000 / 1000) > 1) {
        weightUnit = 't';
        divider *= 1000;
      }
      const weightAchievement = {
        id: `weightAchievement-${it.loadOn}T01:00:00Z`,
        title: '総重量',
        start: `${it.loadOn}T01:00:00Z`,
        extendedProps: {
          budget: totalWeightG / divider,
          achievement: it.loadWeightG / divider,
          unit: weightUnit,
          bgColor: totalWeightG ? theme.colors.success.light : theme.colors.secondary.lighter,
          barColor: totalWeightG ? theme.colors.success.dark : theme.colors.secondary.lighter,
          color: totalWeightG ? theme.colors.alpha.white[100] : theme.colors.secondary.main,
        }
      };
      const totalVolumeMm3 = it.loadVolumeMm3 + it.notAllocatedVolumeMm3;
      const volumeAchievement = {
        id: `volumeAchievement-${it.loadOn}T02:00:00Z`,
        title: '総体積',
        start: `${it.loadOn}T02:00:00Z`,
        extendedProps: {
          budget: numberUtil.convertFromMm3ToM3(totalVolumeMm3),
          achievement: numberUtil.convertFromMm3ToM3(it.loadVolumeMm3),
          unit: 'm3',
          bgColor: totalVolumeMm3 ? theme.colors.success.light : theme.colors.secondary.lighter,
          barColor: totalVolumeMm3 ? theme.colors.success.dark : theme.colors.secondary.lighter,
          color: totalVolumeMm3 ? theme.colors.alpha.white[100] : theme.colors.secondary.main,
        }
      };
      const deliveryAchievement = {
        id: `deliveryAchievement-${it.loadOn}T03:00:00Z`,
        title: '車両数',
        start: `${it.loadOn}T03:00:00Z`,
        extendedProps: {
          budget: it.totalDeliveriesCount,
          achievement: it.allocatedDeliveriesCount,
          unit: '台',
          bgColor: it.totalDeliveriesCount ? theme.colors.warning.main : theme.colors.secondary.lighter,
          barColor: it.totalDeliveriesCount ? theme.colors.warning.dark : theme.colors.secondary.lighter,
          color: it.totalDeliveriesCount ? theme.colors.alpha.white[100] : theme.colors.secondary.main,
        }
      };

      return [
        ordersAchievement,
        weightAchievement,
        volumeAchievement,
        deliveryAchievement
      ];
    });

    setEvents(newEvents);
  }, [data, theme]);

  useEffect(() => {
    setCanDupRequest(copyToAts.length > 0);
  }, [copyToAts]);

  useEffect(() => {
    if (!planningOn) {
      setCopyToAts([]);
      return;
    }

    if (dayOfWeekOrSpecifiedDay === 'specifiedDay') {
      if (!specifiedAt) {
        setCopyToAts([]);
        return;
      }

      setCopyToAts([specifiedAt]);
      return;
    }

    if (dayOfWeekOrSpecifiedDay === 'dayOfWeek') {
      if (selectedDaysOfWeek.length === 0 && !repeatableAt) {
        setCopyToAts([]);
        return;
      }

      if ([copyFromAt, repeatableAt].some((it) => it === null)) {
        setCopyToAts([]);
        return;
      }
      if (copyFromAt > repeatableAt) {
        setCopyToAts([]);
        return;
      }

      const copyDaysOfInterval = eachDayOfInterval({
        start: copyFromAt,
        end: repeatableAt
      });

      const selectedDaysOfWeekIdxes = selectedDaysOfWeek.map((dayOfWeek) => displayDaysOfWeek.indexOf(dayOfWeek));

      setCopyToAts(
        copyDaysOfInterval.filter((it) => selectedDaysOfWeekIdxes.includes(it.getDay()))
      );
    }
  }, [planningOn, selectedDaysOfWeek, specifiedAt, repeatableAt, dayOfWeekOrSpecifiedDay, copyFromAt]);

  useEffect(() => {
    const month = sessionStorage.getItem(CURRENT_MONTH_KEY);
    const at = month ? new Date(month) : new Date();

    queryClient.invalidateQueries(['useV4AchievementQuery']).finally(() => {
      setCurrentMonth(at);
      calendarRef.current.getApi().gotoDate(at);
    });
  }, []);

  useEffect(() => {
    if (!planningOn) {
      setMaximumRepeatableAt(null);
      setCopyFromAt(null);
      return;
    }

    const planningAt = new Date(planningOn);

    setMaximumRepeatableAt(
      addDays(planningAt, 30)
    );
    setCopyFromAt(
      addDays(planningAt, 1)
    );
  }, [planningOn]);

  useEffect(() => {
    setRepeatableAt(maximumRepeatableAt);
  }, [maximumRepeatableAt]);

  useEffect(() => {
    if (!currentMonth) return;

    sessionStorage.setItem(CURRENT_MONTH_KEY, format(currentMonth, 'yyyy-MM-dd'));

    const days = Array.from({ length: 12 }, (_, index) => index + 1)
      .map((it) => {
        const year = format(currentMonth, 'yyyy');
        const month = `0${it}`.slice(-2);
        const beginningOfMonth = new Date(`${year}-${month}-01`);

        return beginningOfMonth;
      });

    setMonthsOfTheYear(days);
  }, [currentMonth]);

  useEffect(() => {
    const notExistsCurrentMonth = currentMonth === null;

    if (notExistsCurrentMonth) {
      setIsLoading(true);
      return;
    }

    const queryIsLoadingNow = queryIsLoading;

    if (queryIsLoadingNow) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
  }, [currentMonth, queryIsLoading]);

  const copyDialogOnClose = () => {
    if (isLoading) return;

    ReactGA.event('close', { screen_name: SCREEN_NAMES.DASHBOARD_COPY_PLANS });
    setPlanningOn('');
  };

  const selectMonthsDialogOnClose = () => {
    ReactGA.event('close', { screen_name: SCREEN_NAMES.DASHBOARD_MONTH_SELECT });
    setSelectMonthsDialogIsOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>ダッシュボード</title>
      </Helmet>
      <Box
        sx={{
          px: 2,
          '@media screen and (min-height: 800px)': {
            p: 2,
          },
          height: `calc(100vh - ${appBarHeight}px)`,
          backgroundColor: '#fff',
          ...fullCalendarStyles,
        }}
      >
        <FullCalendar
          initialDate={currentMonth}
          ref={calendarRef}
          events={events}
          eventColor="none"
          plugins={[dayGridPlugin]}
          firstDay={1}
          fixedWeekCount={false}
          initialView="dayGridMonth"
          height="100%"
          locale="ja"
          dayMaxEventRows
          navLinks={false}
          buttonText={buttonText}
          customButtons={customButtons}
          headerToolbar={headerToolbar}
          eventContent={renderEventContent}
          editable={false}
          dayCellContent={dayCellContent}
          moreLinkContent={moreLinkContent}
          moreLinkHint={() => ''}
          closeHint=""
        />
        <Dialog
          open={!!planningOn}
          onClose={copyDialogOnClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            <Typography
              variant="h5"
            >
              {planningOn}
              {planningOn && [
                '(',
                datetimeDecorator.toDayOfWeek(new Date(planningOn)),
                ')'
              ].join('')}
              の配送計画・未割り当て案件を複製する
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={1}
              divider={<Divider />}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormControlLabel
                    control={(
                      <Checkbox
                        size="small"
                      />
                    )}
                    label="曜日指定"
                    checked={dayOfWeekOrSpecifiedDay === 'dayOfWeek'}
                    onClick={() => {
                      setDayOfWeekOrSpecifiedDay('dayOfWeek');
                    }}
                  />
                  {
                    displayDaysOfWeek.map((dayOfWeek) => (
                      <FormControlLabel
                        key={[
                          planningOn,
                          dayOfWeek,
                          'displayDaysOfWeek'
                        ].join('-')}
                        control={(
                          <Checkbox
                            size="small"
                            disabled={dayOfWeekOrSpecifiedDay !== 'dayOfWeek'}
                          />
                        )}
                        label={dayOfWeek}
                        checked={selectedDaysOfWeek.includes(dayOfWeek)}
                        onClick={() => {
                          selectDaysOfWeekOnClick(dayOfWeek);
                        }}
                      />
                    ))
                  }
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >

                  <FormControlLabel
                    control={(
                      <Checkbox
                        size="small"
                      />
                    )}
                    label="日付指定"
                    checked={dayOfWeekOrSpecifiedDay === 'specifiedDay'}
                    onClick={() => {
                      setDayOfWeekOrSpecifiedDay('specifiedDay');
                    }}
                  />
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <DatePicker
                      label="日付指定"
                      value={specifiedAt}
                      onChange={specifiedDayOnChange}
                      renderInput={(params) => <TextField {...params} size="small" sx={{ width: '170px' }} />}
                    />
                  </Stack>
                </Stack>
              </Stack>
              {
                (dayOfWeekOrSpecifiedDay === 'dayOfWeek') && copyFromAt && maximumRepeatableAt && repeatableAt && (
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Stack>
                      <Typography
                        variant="h5"
                      >
                        以下の条件で繰り返し登録する
                      </Typography>
                      <Typography variant="caption">
                        繰り返し登録は最大30日先まで可能です。
                        <br />
                        コピー先に既存で計画がある場合、計画・案件は削除されて、上書き保存されます。
                        <br />
                        日を跨ぐ案件のコピーは対応していません。
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-end"
                      spacing={2}
                    >
                      <DatePicker
                        label="日付指定"
                        value={repeatableAt}
                        onChange={updateRepeatableAt}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="standard"
                            sx={{
                              width: '130px',
                              mr: 4
                            }}
                          />
                        )}
                        minDate={copyFromAt}
                        maxDate={maximumRepeatableAt}
                      />
                      <Typography>
                        まで繰り返し登録する
                      </Typography>
                    </Stack>
                  </Stack>
                )
              }
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              fullWidth
              disabled={!canDupRequest}
              loading={isLoading}
              variant="contained"
              startIcon={<ControlPointDuplicateRoundedIcon />}
              size="large"
              onClick={() => {
                duplicationButtonOnClick();
              }}
            >
              複製する
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={selectMonthsDialogIsOpen}
          onClose={selectMonthsDialogOnClose}
          hideBackdrop
          sx={{
            '& .MuiDialog-container': {
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }
          }}
        >
          <DialogTitle>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={2}
            >
              <Button
                startIcon={<KeyboardDoubleArrowLeftRoundedIcon />}
                onClick={() => {
                  if (!monthsOfTheYear[0]) return;
                  ReactGA.event('click', { screen_name: SCREEN_NAMES.DASHBOARD_MONTH_SELECT, button_name: '年を選択', label: '前年' });
                  updateMonthsOfTheYear(monthsOfTheYear[0].getFullYear() - 1);
                }}
              >
                {!!monthsOfTheYear[0] && monthsOfTheYear[0].getFullYear() - 1}
              </Button>
              <Button
                endIcon={<KeyboardDoubleArrowRightRoundedIcon />}
                onClick={() => {
                  if (!monthsOfTheYear[0]) return;
                  ReactGA.event('click', { screen_name: SCREEN_NAMES.DASHBOARD_MONTH_SELECT, button_name: '年を選択', label: '次年' });

                  updateMonthsOfTheYear(monthsOfTheYear[0].getFullYear() + 1);
                }}
              >
                {!!monthsOfTheYear[0] && monthsOfTheYear[0].getFullYear() + 1}
              </Button>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack
              divider={<Divider />}
            >
              {
                monthsOfTheYear.map((it) => (
                  <Button
                    key={[
                        'selectMonthsDialog',
                        it.toLocaleString()
                      ].join('-')}
                    onClick={() => {
                      ReactGA.event('click', { screen_name: SCREEN_NAMES.DASHBOARD_MONTH_SELECT, button_name: '月を選択', label: datetimeDecorator.toYyyyMm(it) });
                      setCurrentMonth(it);
                        calendarRef.current.getApi().gotoDate(it);
                        setSelectMonthsDialogIsOpen(false);
                      }}
                    sx={{
                      borderRadius: 0,
                    }}
                    endIcon={<NavigateNextRoundedIcon />}
                  >
                    {datetimeDecorator.toYyyyMm(it)}
                  </Button>
                  ))
              }
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              onClick={() => {
                setSelectMonthsDialogIsOpen(false);
              }}
              startIcon={<HighlightOffRoundedIcon />}
            >
              閉じる
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
});

export default V2Dashboard;
