import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { AccountEntity, AccountLicenseEntity, AccountLicenseRequestEntity } from 'src/entities/AccountEntity';

const getAccounts = (queryClient: QueryClient) => async () => {
  const res = await axios.get<ApiResponse>('/api/v2/licenses');
  return res.data.data as unknown as AccountEntity;
};

export const useQueryAccounts = () => {
  const queryClient = useQueryClient();
  return useQuery(['accounts'], getAccounts(queryClient), {
    staleTime: Infinity
  });
};

export const useMutationAccounts = () => {
  const queryClient = useQueryClient();
  return {
    addLicense: useMutation(
      (license: AccountLicenseRequestEntity) => axios.post('/api/v2/licenses', license),
      {
        onSuccess: () => queryClient.invalidateQueries(['accounts']),
      }
    ),
    updateLicense: useMutation(
      (license: AccountLicenseEntity) => axios.put(`/api/v2/licenses/${license.id}`, license),
      {
        onSuccess: () => queryClient.invalidateQueries(['accounts']),
      }
    ),
    deleteLicense: useMutation(
      (id: number) => axios.delete(`/api/v2/licenses/${id}`),
      {
        onSuccess: () => queryClient.invalidateQueries(['accounts']),
      }
    ),
  };
};
