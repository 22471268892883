import * as yup from 'yup';

const specifiedTime = yup.object().shape({
  start_at: yup.string(),
  end_at: yup.string(),
});

export const schema = yup.object({
  name: yup.string().required('必須です'),
  address: yup.string().required('必須です'),
  staying_minutes: yup.number().positive().integer().nullable()
    .transform((val) => (val ? Number(val) : null)),
  designated_truck_car_models: yup.array().of(yup.string()).nullable(),
  designated_truck_features: yup.array().of(yup.string()).nullable(),
  designated_truck_floor_specifications: yup.array().of(yup.string()).nullable(),
  designated_truck_loading_platform_heights: yup.array().of(yup.string()).nullable(),
  designated_truck_loading_platform_lengths: yup.array().of(yup.string()).nullable(),
  designated_truck_loading_platform_widths: yup.array().of(yup.string()).nullable(),
  designated_truck_klasses: yup.array().of(yup.string()).nullable(),
  place_specified_times_attributes: yup.array().of(specifiedTime),
  shipper_name: yup.string().nullable(),
  shipper_phone_number: yup.string().nullable(),
  shipper_email_address: yup.string().nullable(),
});
