import { ThemeProvider } from '@mui/material';
import React, { memo, useState } from 'react';

import { themeCreator } from './base';

export const ThemeContext = React.createContext(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  (themeName: string): void => {}
);

const ThemeProviderWrapper: React.FC<{ children: React.ReactNode }> = memo(
  (props) => {
    const curThemeName = localStorage.getItem('appTheme') || 'LogTheme';
    const [themeName, _setThemeName] = useState(curThemeName);
    const theme = themeCreator(themeName);
    // eslint-disable-next-line no-shadow,react/jsx-no-constructed-context-values
    const setThemeName = (themeName: string): void => {
      localStorage.setItem('appTheme', themeName);
      _setThemeName(themeName);
    };

    return (
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    );
  }
);

export default ThemeProviderWrapper;
