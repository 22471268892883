import { PlanningsOperationEntityWithStatsEntity } from 'src/entities/PlanningsOperationEntityWithStats.entity';

export class PositionModel {
  entity: PlanningsOperationEntityWithStatsEntity;

  constructor(entity: PlanningsOperationEntityWithStatsEntity) {
    this.entity = entity;
  }

  isOutsideSpecifiedTime() {
    const placeArrivalAt = this.entity.timeWindow.place.arrivalAt;
    const placeDepartureAt = this.entity.timeWindow.place.departureAt;
    const maxEndAt = this.entity.action === '積' ? this.entity.maxLoadingEndAt : this.entity.maxUnloadingEndAt;
    const minStartAt = this.entity.action === '積' ? this.entity.minLoadingStartAt : this.entity.minUnloadingStartAt;
    return new Date(maxEndAt).getTime() - new Date(placeArrivalAt).getTime() < 0
      || new Date(placeDepartureAt).getTime() - new Date(minStartAt).getTime() < 0;
  }
}
