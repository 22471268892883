import { Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { FC, memo, ReactNode } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

interface ScrollbarProps {
  className?: string;
  children?: ReactNode;
  style?: React.CSSProperties;
}

const Scrollbar: FC<ScrollbarProps> = memo(
  ({ className, children, style, ...rest }) => {
    const theme = useTheme();

    return (
      <Scrollbars
        autoHide
        style={style}
        renderThumbVertical={() => (
          <Box
            sx={{
              width: 5,
              background: `${theme.colors.alpha.black[10]}`,
              borderRadius: `${theme.general.borderRadiusLg}`,
              transition: `${theme.transitions.create(['background'])}`,

              '&:hover': {
                background: `${theme.colors.alpha.black[30]}`
              }
            }}
          />
        )}
        {...rest}
      >
        {children}
      </Scrollbars>
    );
  }
);

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Scrollbar;
