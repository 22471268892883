import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import { Stack, Typography } from '@mui/material';
import { FC, memo, useEffect, useState } from 'react';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import numberDecorator from 'src/decorators/number.decorator';
import { OrderEntity } from 'src/entities/orderEntity';
import { PositionEntity } from 'src/entities/PositionEntity';
import { OrderFactory } from 'src/models/OrderFactory';
import arrayUtil from 'src/utils/array.util';

type Props = {
  orderId: number;
  position: PositionEntity;
  orderEntityMap: Map<number, OrderEntity>;
}

const PlanningsMapOrderMarkerTooltipContentPresenter: FC<Props> = memo((
  {
    orderId,
    position,
    orderEntityMap
  }
) => {
  const data = orderEntityMap.get(orderId);
  const [model, setModel] = useState<OrderFactory | undefined>(undefined);

  useEffect(() => {
    if (!data) return;

    setModel(
      new OrderFactory(data)
    );
  }, [data]);

  const [loadingStartAt, setLoadingStartAt] = useState<string>('');
  useEffect(() => {
    if (!model) return;

    const min = arrayUtil.minAt(
      [
        model.loadingStartAtOne(),
        model.loadingStartAtTwo(),
        model.loadingStartAtThree()
      ].filter((maybe) => maybe)
    );

    setLoadingStartAt(
      datetimeDecorator.toDayAndHourMinutes(min)
    );
  }, [model]);

  const [loadingEndAt, setLoadingEndAt] = useState<string>('');

  useEffect(() => {
    if (!model) return;

    const max = arrayUtil.maxAt(
      [
        model.loadingEndAtOne(),
        model.loadingEndAtTwo(),
        model.loadingEndAtThree()
      ].filter((maybe) => maybe)
    );

    setLoadingEndAt(
      datetimeDecorator.toDayAndHourMinutes(max)
    );
  }, [model]);

  const [unloadingStartAt, setUnloadingStartAt] = useState<string>('');
  useEffect(() => {
    if (!model) return;

    const min = arrayUtil.minAt(
      [
        model.unloadingStartAtOne(),
        model.unloadingStartAtTwo(),
        model.unloadingStartAtThree()
      ].filter((maybe) => maybe)
    );

    setUnloadingStartAt(
      datetimeDecorator.toDayAndHourMinutes(min)
    );
  }, [model]);

  const [unloadingEndAt, setUnloadingEndAt] = useState<string>('');
  useEffect(() => {
    if (!model) return;

    const max = arrayUtil.maxAt(
      [
        model.unloadingEndAtOne(),
        model.unloadingEndAtTwo(),
        model.unloadingEndAtThree()
      ].filter((maybe) => maybe)
    );

    setUnloadingEndAt(
      datetimeDecorator.toDayAndHourMinutes(max)
    );
  }, [model]);

  if (!data || !model) return null;

  const action = [data.loading_lat, data.loading_lng].join('-') === position.join('-') ? '積' : '降';

  return (
    <Stack>
      <Stack>
        <Typography>
          {model.shipperName()}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={0.5}>
        <Typography variant="caption">
          {numberDecorator.toRoundedUnit(
            model.itemTotalWeightKg(),
            'kg',
            1,
          )}
        </Typography>
        <Typography variant="caption">
          {numberDecorator.toRoundedUnit(
            model.itemTotalVolumeM3(),
            'm3',
            10,
            ''
          )}
        </Typography>
        <Typography variant="caption">
          {numberDecorator.toYen(
            model.totalFeeYen()
          )}
        </Typography>
      </Stack>
      <Stack spacing={0.5}>
        <Stack
          sx={{
            opacity: action === '積' ? 1 : 0.5
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={0.5}
          >
            <ArrowCircleUpRoundedIcon color="success" fontSize="small" />
            <Typography>
              {model.loadingName()}
            </Typography>
          </Stack>
          <Stack>
            <Typography>
              {
                [
                  loadingStartAt,
                  loadingEndAt,
                ].join(' - ')
              }
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            opacity: action === '降' ? 1 : 0.5
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={0.5}
          >
            <ArrowCircleDownRoundedIcon color="error" fontSize="small" />
            <Typography>
              {model.unloadingName()}
            </Typography>
          </Stack>
          <Stack>
            <Typography>
              {
                [
                  unloadingStartAt,
                  unloadingEndAt,
                ].join(' - ')
              }
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
});

export default PlanningsMapOrderMarkerTooltipContentPresenter;
