import { Button, Checkbox, Stack, Typography } from '@mui/material';
import { FC, memo, useCallback, useEffect, useState, MouseEvent, useMemo } from 'react';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import numberDecorator from 'src/decorators/number.decorator';
import { DeliveryEntity } from 'src/entities/Delivery.entity';
import { DriverEntity } from 'src/entities/Driver.entity';
import { SelectedShiftCell } from 'src/entities/SelectedShiftCell.entity';
import { TruckEntity } from 'src/entities/Truck.entity';
import { useDeliveriesQuery } from 'src/hooks/useDeliveries.query';
import numberUtil from 'src/utils/number.util';

import { EditPropsBase, RequestEntity } from './EditDialog.presenter';

type Props = {
  dayOfWeek: Date;
  truckData: TruckEntity[];
  driver: DriverEntity;
  isLoading: boolean;
  updateEditPropsBase: (props: EditPropsBase) => void;
  defaultStartAt: string;
  defaultEndAt: string;
  defaultWorkingAvailableDurationHours: number | undefined;
  checked: boolean;
  updateSelectedShiftCell: (cell: SelectedShiftCell[], checked: boolean) => void;
  appendDeliveryExistingCells: (cells: SelectedShiftCell[]) => void;
}

const BodyCellPresenter: FC<Props> = memo((
  {
    dayOfWeek,
    truckData,
    driver,
    isLoading,
    updateEditPropsBase,
    defaultStartAt,
    defaultEndAt,
    defaultWorkingAvailableDurationHours,
    checked,
    updateSelectedShiftCell,
    appendDeliveryExistingCells,
  }
) => {
  const { data, isFetching: deliveryIsFetching } = useDeliveriesQuery(datetimeDecorator.toYyyyMmDd(dayOfWeek));

  useEffect(() => {
    if (!data) return;

    appendDeliveryExistingCells(data.map((it) => ({ date: dayOfWeek, driver_id: it.driverId }) as SelectedShiftCell));
  }, [appendDeliveryExistingCells, data, dayOfWeek, deliveryIsFetching]);

  const [currentDeliveries, setCurrentDeliveries] = useState<DeliveryEntity[]>([]);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [defaultRequestEntities, setDefaultRequestEntities] = useState<RequestEntity[]>([]);

  const onClickCheckbox = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    updateSelectedShiftCell([{ driver_id: driver.id, date: dayOfWeek }], !checked);
  }, [checked, dayOfWeek, driver.id, updateSelectedShiftCell]);

  const buttonOnClick = useCallback(() => {
    const props: EditPropsBase = {
      dialogTitle,
      defaultRequestEntities,
      driver,
      truckData,
      date: dayOfWeek,
      defaultStartAt,
      defaultEndAt,
      defaultWorkingAvailableDurationHours,
      shiftCell: [{ driver_id: driver.id, date: dayOfWeek }]
    };

    updateEditPropsBase(props);
  }, [dayOfWeek, defaultEndAt, defaultRequestEntities, defaultStartAt, defaultWorkingAvailableDurationHours, dialogTitle, driver, truckData, updateEditPropsBase]);

  useEffect(() => {
    if (!data) {
      setCurrentDeliveries([]);
      return;
    }

    const sortedDeliveries = [...data.filter((it) => it.driverId === driver.id)].sort((a, b) => {
      const aStart = new Date(a.startAt).getTime();
      const bStart = new Date(b.startAt).getTime();
      return aStart - bStart;
    });

    setCurrentDeliveries(sortedDeliveries);
  }, [data, driver.id]);

  useEffect(() => {
    if (!currentDeliveries) return;
    if (!driver) return;

    if (!currentDeliveries.length) {
      setDefaultRequestEntities(
        [
          {
            startAt: defaultStartAt,
            endAt: defaultEndAt,
            workingAvailableDurationHours: defaultWorkingAvailableDurationHours,
            truckId: driver.defaultTruckId,
          }
        ]
      );
      return;
    }

    const sortedDeliveries = [...currentDeliveries].sort((a, b) => {
      const aStart = new Date(a.startAt).getTime();
      const bStart = new Date(b.startAt).getTime();
      return aStart - bStart;
    });

    setDefaultRequestEntities(
      sortedDeliveries.map((it) => ({
        id: it.id,
        startAt: datetimeDecorator.toHourMinutes(new Date(it.startAt)),
        endAt: datetimeDecorator.toHourMinutes(new Date(it.endAt)),
        workingAvailableDurationHours: numberUtil.convertFromSecondsToHours(it.workingAvailableDurationSeconds),
        truckId: it.truckId,
      }))
    );
  }, [currentDeliveries, driver, defaultStartAt, defaultEndAt, defaultWorkingAvailableDurationHours]);

  useEffect(() => {
    if (!driver) return;
    if (!dayOfWeek) return;

    setDialogTitle([
      datetimeDecorator.toMmDd(dayOfWeek),
      driver.name
    ].join(' '));
  }, [driver, dayOfWeek]);

  const newButton = useMemo(() => (driver.isActive ? '新規作成' : '-'), [driver]);

  const textContentsMemo = useMemo(() => (
    currentDeliveries.length ? currentDeliveries.map((it) => (
      <Stack
        key={
          [
            'BodyCellPresenter',
            'Stack',
            it.id
          ].join('-')
        }
      >
        <Typography sx={{ wordBreak: 'keep-all' }}>
          {
            [
              datetimeDecorator.toHourMinutes(new Date(it.startAt)),
              datetimeDecorator.toHourMinutes(new Date(it.endAt)),
            ].join(' - ')
          }
          {
            it.workingAvailableDurationSeconds && (
              [
                '(',
                numberDecorator.convertToHoursAndMinutes(
                  numberUtil.convertFromSecondsToMinutes(it.workingAvailableDurationSeconds)
                ),
                ')'
              ].join('')
            )
          }
        </Typography>
      </Stack>
    )) : newButton
  ), [currentDeliveries, newButton]);

  const contentsMemo = useMemo(() => (
    <Stack direction="row" alignItems="center" justifyContent="center">
      {driver.isActive && (
        <Checkbox
          checked={checked}
          onClick={onClickCheckbox}
        />
      )}
      <Button
        style={{
            display: 'block'
          }}
        size="small"
        color={currentDeliveries.length ? 'primary' : 'secondary'}
        onClick={buttonOnClick}
        disabled={isLoading || !driver.isActive}
      >
        {textContentsMemo}
      </Button>
    </Stack>
  ), [buttonOnClick, checked, currentDeliveries, driver, isLoading, onClickCheckbox, textContentsMemo]);

  return contentsMemo;
});

export default BodyCellPresenter;
