import { EstimationEntity } from 'src/entities/Estimation.entity';
import arrayUtil from 'src/utils/array.util';

const rowsByDeliveryRows: (entities: EstimationEntity[]) => EstimationEntity[] = (entities) => {
  const deliveryIds = arrayUtil.uniq<number>(entities.map((it) => it.deliveryId));

  return deliveryIds
    .map((it) => entities.find((entity) => entity.deliveryId === it));
};

export default rowsByDeliveryRows;
