import { FC, memo, useEffect, useState } from 'react';
import { EditPlaceEntity } from 'src/entities/EditPlace.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PositionEntity } from 'src/entities/PositionEntity';
import { PlanningsOperationDelivery, PlanningsOperationPlace } from 'src/models/PlanningsOperationGroup.model';
import { SelectedStatusVo } from 'src/vo/SelectedStatus.vo';

import PlanningMapPositionsPresenter from './PlanningMapPositions.presenter';

type Props = {
  deliveryEntity: PlanningsDeliveryEntity;
  position: PositionEntity;
  selectedOrderIds: number[];
  addSelectedOrderIds: (orderIds: number[]) => void;
  removeSelectedOrderIds: (orderIds: number[]) => void;
  updateDisplayOrderId: (orderId: number) => void;
  isLoading: boolean;
  forceSelect: boolean;
  forceUnselect: boolean;
  updateSelectedStatus: (idx: number, deliveryId: number, status: SelectedStatusVo) => void;
  addTransferFromDeliveryId: (id: number) => void;
  removeTransferFromDeliveryId: (id: number) => void;
  truckEntities: PlanningsTruckEntity[];
  editPlaces: EditPlaceEntity[];
  planningsOperationDelivery: PlanningsOperationDelivery;
}

const PlanningMapDeliveryPresenter: FC<Props> = memo((
  {
    deliveryEntity,
    position,
    selectedOrderIds,
    addSelectedOrderIds,
    removeSelectedOrderIds,
    updateDisplayOrderId,
    isLoading,
    forceSelect,
    forceUnselect,
    updateSelectedStatus,
    addTransferFromDeliveryId,
    removeTransferFromDeliveryId,
    truckEntities,
    editPlaces,
    planningsOperationDelivery,
  }
) => {
  const [truckEntity, setTruckEntity] = useState<PlanningsTruckEntity | undefined>(undefined);
  const [places, setPlaces] = useState<PlanningsOperationPlace[]>([]);
  const [loadCapacityWeightG, setLoadCapacityWeightG] = useState<number>(0);
  const [loadCapacityVolumeMm3, setLoadCapacityVolumeMm3] = useState<number>(0);

  useEffect(() => {
    if (!truckEntities) return;

    setTruckEntity(
      truckEntities.find((e) => e.id === deliveryEntity.truckId)
    );
  }, [deliveryEntity.truckId, truckEntities]);

  useEffect(() => {
    if (!truckEntity) return;

    setLoadCapacityWeightG(
      truckEntity.maximumLoadingCapacityWeightForCalculation
    );
    setLoadCapacityVolumeMm3(
      truckEntity.loadingPlatformVolumeMm3 || 0
    );
  }, [truckEntity]);

  useEffect(() => {
    if (!deliveryEntity || !planningsOperationDelivery) return;

    setPlaces(
      planningsOperationDelivery.cycles.flatMap((cycle) => {
        if (editPlaces) {
          const editPlace = editPlaces.find((it) => it.deliveryId === deliveryEntity.id && it.cycleIndex === cycle.cycleIndex);
          if (editPlace) {
            return editPlace.places;
          }
        }
        return cycle.places;
      }).filter((it) => [it.latitude, it.longitude].join('-') === position.join('-'))
    );
  }, [deliveryEntity, editPlaces, planningsOperationDelivery, position]);

  return (
    <PlanningMapPositionsPresenter
      places={places}
      deliveryId={deliveryEntity.id}
      loadCapacityWeightG={loadCapacityWeightG}
      loadCapacityVolumeMm3={loadCapacityVolumeMm3}
      selectedOrderIds={selectedOrderIds}
      addSelectedOrderIds={addSelectedOrderIds}
      removeSelectedOrderIds={removeSelectedOrderIds}
      updateDisplayOrderId={updateDisplayOrderId}
      isLoading={isLoading}
      forceSelect={forceSelect}
      forceUnselect={forceUnselect}
      updateSelectedStatus={updateSelectedStatus}
      addTransferFromDeliveryId={addTransferFromDeliveryId}
      removeTransferFromDeliveryId={removeTransferFromDeliveryId}
      truckEntity={truckEntity}
      driverColor={deliveryEntity.driverColor}
    />
  );
});

export default PlanningMapDeliveryPresenter;
