import { PositionEntity } from 'src/entities/PositionEntity';

const findCenter: (positions: PositionEntity[]) => PositionEntity = (positions: PositionEntity[]) => {
  const lats = positions.map((position) => position[0]);
  const lngs = positions.map((position) => position[1]);

  return [
    (Math.min(...lats) + Math.max(...lats)) / 2,
    (Math.min(...lngs) + Math.max(...lngs)) / 2
  ];
};

const geoCodeUtil = {
  findCenter
};

export default geoCodeUtil;
