import { Box, Paper } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ConfirmDialog } from 'src/components/ConfirmDialog';
import LoadingComponent from 'src/components/LoadingComponent';
import LicenseContext from 'src/contexts/LicenseContext';
import { AccountEntity, AccountLicenseEntity, AccountSubscriptionEntity } from 'src/entities/AccountEntity';
import { RoleEntity } from 'src/entities/Role.entity';
import { SubscriptionKindEntity } from 'src/entities/SubscriptionKind.entity';
import { useMutationAccounts, useQueryAccounts } from 'src/hooks/useQueryAccounts';
import { useQueryCompanyConnections } from 'src/hooks/useQueryComapnyConnections';

import { AccountList } from './AccountList';
import { ConnectDialog } from './ConnectDialog';
import { ConnectedCompanyList } from './ConnectedCompanyList';
import { EditDialog } from './EditDialog';
import { SubscriptionList } from './SubscriptionList';

const AccountsPresenter: FC = memo(() => {
  const { enqueueSnackbar } = useSnackbar();
  const licenseContext = useContext(LicenseContext);

  const { data: accountData, isLoading: accountIsLoading } = useQueryAccounts();
  const { data: connectedCompaniesData, isLoading: connectedCompaniesIsLoading } = useQueryCompanyConnections();
  const { deleteLicense } = useMutationAccounts();

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [connectDialogIsOpen, setConnectDialogIsOpen] = useState(false);
  const [editEntity, setEditEntity] = useState<AccountLicenseEntity>(null);
  const [account, setAccount] = useState<AccountEntity>(null);
  const [roles, setRoles] = useState<RoleEntity[]>([]);
  const [subscriptionKinds, setSubscriptionKinds] = useState<SubscriptionKindEntity[]>([]);
  const [subscriptions, setSubscriptions] = useState<AccountSubscriptionEntity[]>([]);

  useEffect(() => {
    if (!accountData) return;

    setAccount(accountData);
    setRoles(accountData.roles);
    const kinds = accountData.subscription_kinds.filter((it) => it.name !== 'docomap');
    setSubscriptionKinds(kinds);
    const filteredSubscriptions = accountData.subscriptions.filter((it) => it.available && kinds.map((kind) => kind.id).includes(it.subscription_kind_id));
    setSubscriptions(filteredSubscriptions);
  }, [accountData]);

  const openAppendDialog = useCallback(() => {
    setEditEntity(null);
    setDialogIsOpen(true);
  }, []);

  const openConnectDialog = useCallback(() => {
    setConnectDialogIsOpen(true);
  }, []);

  const openEditDialog = useCallback((entity: AccountLicenseEntity) => {
    setEditEntity(entity);
    setDialogIsOpen(true);
  }, []);

  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState<boolean>(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState<string>('');
  const [accountToDelete, setAccountToDelete] = useState<AccountLicenseEntity>(null);

  const closeConfirmDialog = useCallback(() => {
    setConfirmDialogIsOpen(false);
    setConfirmDialogMessage('');
    setAccountToDelete(null);
  }, []);

  const confirmDialogHandleOk = useCallback(() => {
      try {
        // eslint-disable-next-line no-void
        void deleteLicense.mutateAsync(accountToDelete.id);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as unknown as AxiosError<{ messages: string[] }>;
          enqueueSnackbar(axiosError.response.data.messages.join(' '));
        } else {
          throw error;
        }
      } finally {
        closeConfirmDialog();
      }
  }, [accountToDelete, closeConfirmDialog, deleteLicense, enqueueSnackbar]);

  const confirmDialogHandleCancel = useCallback(() => {
    closeConfirmDialog();
  }, [closeConfirmDialog]);

  const onSubmitRemove = useCallback((entity: AccountLicenseEntity) => {
    setAccountToDelete(entity);
    if (entity.company.name === licenseContext.config.company_name) {
      setConfirmDialogMessage('対象のユーザーに紐づくすべてのデータが削除されます。本当に削除してもよいですか？');
    } else {
      setConfirmDialogMessage('連携を解除します。よろしいですか？');
    }
    setConfirmDialogIsOpen(true);
    setDialogIsOpen(false);
  }, [licenseContext.config]);

  const confirmDialogMmoe = useMemo(() => (
    <ConfirmDialog
      open={confirmDialogIsOpen}
      message={confirmDialogMessage}
      handleOk={confirmDialogHandleOk}
      handleCancel={confirmDialogHandleCancel}
    />
  ), [confirmDialogHandleCancel, confirmDialogHandleOk, confirmDialogIsOpen, confirmDialogMessage]);

  const editDialogMemo = useMemo(() => (
    <EditDialog
      dialogIsOpen={dialogIsOpen}
      setDialogIsOpen={setDialogIsOpen}
      entity={editEntity}
      roles={roles}
    />
  ), [dialogIsOpen, editEntity, roles]);

  const connectDialogMemo = useMemo(() => (
    <ConnectDialog
      dialogIsOpen={connectDialogIsOpen}
      setDialogIsOpen={setConnectDialogIsOpen}
    />
  ), [connectDialogIsOpen]);

  const accountListMemo = useMemo(() => {
    if (!account?.licenses) return null;

    return (
      <AccountList
        account={account}
        openAppendDialog={openAppendDialog}
        onSubmitRemove={onSubmitRemove}
        openEditDialog={openEditDialog}
        openConnectDialog={openConnectDialog}
      />
    );
  }, [account, openAppendDialog, onSubmitRemove, openEditDialog, openConnectDialog]);

  const connectedCompaniesMemo = useMemo(() => (
    <ConnectedCompanyList
      connectedCompanies={connectedCompaniesData}
    />
  ), [connectedCompaniesData]);

  const subscriptionListMemo = useMemo(() => (
    <SubscriptionList
      subscriptionKinds={subscriptionKinds}
      subscriptions={subscriptions}
    />
  ), [subscriptionKinds, subscriptions]);

  if (accountIsLoading || connectedCompaniesIsLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Helmet>
        <title>アカウント管理</title>
      </Helmet>
      <Box p={2}>
        {confirmDialogMmoe}
        {editDialogMemo}
        {connectDialogMemo}
        <Paper>
          {accountListMemo}
          {connectedCompaniesMemo}
          {subscriptionListMemo}
        </Paper>
      </Box>
    </>
  );
});

export default AccountsPresenter;
