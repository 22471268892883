import { FC, memo } from 'react';
import { useLocation } from 'react-router-dom';
import OperationDirectionsComponent from 'src/components/OperationDirectionsComponent';

import OperationDirectionPresenter from './OperationDirectionPresenter';

const OperationDirections: FC = memo(() => {
  const query = new URLSearchParams(useLocation().search);

  const shiftIds = query.get('ids').split(',').map((id) => Number(id));
  const queryAction = query.get('action');
  const action: '積' | '降' | null = queryAction
    ? ['積', '降']
      .find((str) => str === queryAction) as '積' | '降'
    : null;

  return <OperationDirectionsComponent shiftIds={shiftIds} presenter={OperationDirectionPresenter} action={action} />;
});

export default OperationDirections;
