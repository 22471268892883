import TuneIcon from '@mui/icons-material/Tune';
import { Button, Checkbox, colors, FormControlLabel, ListItemText, Menu, MenuItem, Paper, Stack, Switch, Typography } from '@mui/material';
import { FC, memo, useCallback, useContext, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import LicenseContext from 'src/contexts/LicenseContext';

type Props = {
  setShowTruckStatus: (value: boolean) => void;
  displayKindOptions: string[];
  displayKinds: string[];
  setDisplayKinds: (value: string[]) => void;
}

const ActionsOnMapPresenter: FC<Props> = memo((
  {
    setShowTruckStatus,
    displayKindOptions,
    displayKinds,
    setDisplayKinds,
  }
) => {
  const licenseContext = useContext(LicenseContext);

  const onChangeCurrentPosition = useCallback((_: unknown, checked: boolean) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `運行状況表示 ${checked ? 'ON' : 'OFF'}` });
    setShowTruckStatus(checked);
  }, [setShowTruckStatus]);

  const currentPositionSwitchMemo = useMemo(() => (
    <Stack>
      <Paper>
        <FormControlLabel
          control={<Switch color="primary" onChange={onChangeCurrentPosition} />}
          label={<Typography variant="caption" noWrap>運行状況表示</Typography>}
          labelPlacement="top"
        />
      </Paper>
    </Stack>
  ), [onChangeCurrentPosition]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '表示フィルタ' });
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    ReactGA.event('close', { screen_name: SCREEN_NAMES.PLANNING, button_name: '表示フィルタ' });
    setAnchorEl(null);
  }, []);

  const handleToggle = useCallback((value: string) => () => {
    const currentIndex = displayKinds.indexOf(value);
    const newChecked = [...displayKinds];

    let isOn = false;
    if (currentIndex === -1) {
      isOn = true;
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `表示フィルタ ${value} ${isOn ? 'ON' : 'OFF'}` });

    setDisplayKinds(newChecked);
  }, [displayKinds, setDisplayKinds]);

  const displayFilterMemo = useMemo(() => (
    <div>
      <Button
        id="map-display-filter-menu-button"
        onClick={handleClick}
        variant="text"
        sx={{
          borderRadius: '5px',
          height: '35px',
          p: 1.5,
          backgroundColor: '#fff',
          '&:hover': {
            backgroundColor: colors.blue[50],
          },
        }}
      >
        <TuneIcon fontSize="small" sx={{ color: colors.grey[900] }} />
        <Typography variant="body1" sx={{ color: colors.grey[900], fontSize: '95%' }}>
          表示フィルタ
        </Typography>
      </Button>
      <Menu
        id="map-display-filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'map-display-filter-menu-button',
          sx: { p: 0, m: 0, },
        }}
        PaperProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : undefined, // ボタンの幅に合わせる
          },
        }}
      >
        {displayKindOptions.map((option) => (
          <MenuItem
            key={option}
            onClick={handleToggle(option)}
            disableRipple
            sx={{ p: 0, ml: 1 }}
          >
            <Checkbox
              edge="start"
              checked={displayKinds.indexOf(option) !== -1}
              tabIndex={-1}
              disableRipple
            />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  ), [anchorEl, displayKindOptions, displayKinds, handleClick, handleClose, handleToggle, open]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      justifyItems="start"
      alignItems="start"
      gap={2}
    >
      {licenseContext.config?.use_truck_position ? currentPositionSwitchMemo : (<>&nbsp;</>)}
      {displayFilterMemo}
    </Stack>
  );
});

export default ActionsOnMapPresenter;
