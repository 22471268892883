import AddIcon from '@mui/icons-material/Add';
import CurrencyYenRoundedIcon from '@mui/icons-material/CurrencyYenRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { differenceInDays } from 'date-fns';
import { format } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import { FC, memo, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import LicenseContext from 'src/contexts/LicenseContext';
import { AllocateHistoryEntity } from 'src/entities/AllocateHistory.entity';

import OrderFormPresenter from './OrderFormPresenter';

type Props = {
  startOn: string;
  endOn: string;
  isLoading: boolean;
  planningButtonOnClick: () => void;
  selectImportMethodButtonOnClick: () => void;
  estimationButtonOnClick: () => void;
  allocateHistoriesOnClick: () => void;
  deliveryIdSelected: boolean;
  allocateHistoriesForCompany: AllocateHistoryEntity[];
  currentHistoryVersion: string | undefined;
  resetAllocateHistories: () => void;
  setScenarioPlanningDialogIsOpen: (open: boolean) => void;
}

const ActionsPresenter: FC<Props> = memo((
  {
    startOn,
    endOn,
    isLoading,
    planningButtonOnClick,
    selectImportMethodButtonOnClick,
    deliveryIdSelected,
    estimationButtonOnClick,
    allocateHistoriesOnClick,
    allocateHistoriesForCompany,
    currentHistoryVersion,
    resetAllocateHistories,
    setScenarioPlanningDialogIsOpen,
  }
) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const licenseContext = useContext(LicenseContext);

  const [estimationTooltipTitle, setEstimationTooltipTitle] = useState<ReactElement | string>('');
  const [planningTooltipTitle, setPlanningTooltipTitle] = useState<ReactElement | string>('');
  const [historyTooltipTitle, setHistoryTooltipTitle] = useState<ReactElement | string>('');
  const [scenarioPlanningTitle, setScenarioPlanningTitle] = useState<ReactElement | string>('');

  const [useAlgorithmPlanning, setUseAlgorithmPlanning] = useState(false);

  useEffect(() => {
    if (!licenseContext || !licenseContext.config) return;

    setUseAlgorithmPlanning(licenseContext.config.use_algorithm_planning);
  }, [licenseContext]);

  const [newStartOn, setNewStartOn] = useState<Date | undefined>();
  const [newEndOn, setNewEndOn] = useState<Date | undefined>();

  const handleDateRange = useCallback((range: [Date, Date]) => {
    ReactGA.event('apply', { screen_name: SCREEN_NAMES.PLANNING, button_name: '日付選択', label: range });
    const start = range[0];
    const end = range[1];
    if (!end) {
      return;
    }

    const diffDays = differenceInDays(end, start);

    if (diffDays < 0) {
      enqueueSnackbar('終了日は開始日よりも過去に設定できません');
      return;
    }

    if (diffDays > 9) {
      enqueueSnackbar('最大取得可能日数の10日を超過しています');
      return;
    }

    setNewStartOn(start);
    setNewEndOn(end);
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (newStartOn && newEndOn) {
      navigate(`/plans/${format(newStartOn, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' })}/${format(newEndOn, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' })}`);
    }
  }, [newStartOn, newEndOn, navigate]);

  const noDeliveryIdsSelected = useMemo(() => !deliveryIdSelected, [deliveryIdSelected]);

  useEffect(() => {
    if (noDeliveryIdsSelected) {
      setEstimationTooltipTitle((
        <>
          配送計画を選択してください。
          <br />
          選択された配送計画の見積りを表示します。
        </>
      ));
      return;
    }

    setEstimationTooltipTitle(
      (
        <>
          選択された配送計画の見積りを表示します。
        </>
      )
    );
  }, [noDeliveryIdsSelected]);

  useEffect(() => {
    if (useAlgorithmPlanning) {
      if (licenseContext.config?.selected_company_id === 0) {
        setScenarioPlanningTitle('配車対象の事業所を選択してください');
        setPlanningTooltipTitle('配車対象の事業所を選択してください');
        setHistoryTooltipTitle('事業所を選択してください');
      } else {
        setScenarioPlanningTitle('シナリオ配車する');
        setPlanningTooltipTitle('自動配車する');
        setHistoryTooltipTitle('元に戻す');
      }
    } else {
      setScenarioPlanningTitle('自動配車を利用したい場合はセールス (sales@logpose.co.jp) へご連絡ください。');
      setPlanningTooltipTitle('自動配車を利用したい場合はセールス (sales@logpose.co.jp) へご連絡ください。');
      setHistoryTooltipTitle('自動配車を利用したい場合はセールス (sales@logpose.co.jp) へご連絡ください。');
    }
  }, [useAlgorithmPlanning, licenseContext.config?.selected_company_id]);

  const datePickMemo = useMemo(() => (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      marginTop={1}
      marginLeft={1}
    >
      <DateRangePicker
        value={[
          startOn,
          endOn
        ]}
        onOpen={() => ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '日付選択' })}
        onChange={handleDateRange}
        renderInput={(startProps, endProps) => (
          <>
            <TextField
              {...startProps}
              label="開始"
              size="small"
              sx={{
                maxWidth: '120px'
              }}
            />
            <Box sx={{ mx: 0.3 }}> から </Box>
            <TextField
              {...endProps}
              label="終了"
              size="small"
              sx={{
                maxWidth: '120px'
              }}
            />
          </>
        )}
      />
    </Stack>
  ), [startOn, endOn, handleDateRange]);

  const disablePlanning = useMemo(() => (
    isLoading || !useAlgorithmPlanning || !!currentHistoryVersion || licenseContext?.config?.selected_company_id === 0
  ), [currentHistoryVersion, isLoading, licenseContext.config, useAlgorithmPlanning]);

  const shiftButtonOnClick = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '勤務計画' });
    navigate(`/shifts/${startOn}/${endOn}`);
  }, [endOn, navigate, startOn]);

  const shiftButtonMemo = useMemo(() => (
    <Button
      variant="outlined"
      onClick={shiftButtonOnClick}
      disabled={isLoading || !!currentHistoryVersion}
      sx={{ borderRadius: '5px', height: '35px', p: 1.5, }}
    >
      <Typography variant="body1" sx={{ whiteSpace: 'nowrap', fontSize: '95%' }}>
        勤務計画
      </Typography>
    </Button>
  ), [currentHistoryVersion, isLoading, shiftButtonOnClick]);

  const onClickScenarioPlanning = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: 'シナリオ配車' });
    setScenarioPlanningDialogIsOpen(true);
  }, [setScenarioPlanningDialogIsOpen]);

  const scenarioPlanningMemo = useMemo(() => (
    <Tooltip
      title={scenarioPlanningTitle}
      arrow
    >
      <span>
        <IconButton
          onClick={onClickScenarioPlanning}
          disabled={isLoading || !useAlgorithmPlanning || (licenseContext?.config?.selected_company_id === 0)}
          sx={{
            backgroundColor: theme.colors.alpha.trueWhite[100]
          }}
        >
          <PlayCircleIcon />
        </IconButton>
      </span>
    </Tooltip>
  ), [isLoading, licenseContext?.config?.selected_company_id, scenarioPlanningTitle, onClickScenarioPlanning, theme.colors.alpha.trueWhite, useAlgorithmPlanning]);

  const historyMemo = useMemo(() => (
    <Tooltip
      title={historyTooltipTitle}
      arrow
    >
      <span>
        <IconButton
          onClick={allocateHistoriesOnClick}
          disabled={isLoading || !useAlgorithmPlanning || !allocateHistoriesForCompany?.length || (licenseContext?.config?.selected_company_id === 0)}
          sx={{
            backgroundColor: theme.colors.alpha.trueWhite[100]
          }}
        >
          <HistoryRoundedIcon />
        </IconButton>
      </span>
    </Tooltip>
  ), [allocateHistoriesForCompany?.length, allocateHistoriesOnClick, historyTooltipTitle, isLoading, licenseContext?.config?.selected_company_id, theme.colors.alpha.trueWhite, useAlgorithmPlanning]);

  const estimateMemo = useMemo(() => (
    <Tooltip
      title={estimationTooltipTitle}
      arrow
    >
      <span>
        <IconButton
          onClick={estimationButtonOnClick}
          disabled={isLoading || noDeliveryIdsSelected || !!currentHistoryVersion}
          sx={{
            backgroundColor: theme.colors.alpha.trueWhite[100]
          }}
        >
          <CurrencyYenRoundedIcon />
        </IconButton>
      </span>
    </Tooltip>
  ), [currentHistoryVersion, estimationButtonOnClick, estimationTooltipTitle, isLoading, noDeliveryIdsSelected, theme.colors.alpha.trueWhite]);

  const planningMemo = useMemo(() => (
    <Tooltip
      title={planningTooltipTitle}
      arrow
    >
      <span>
        <Button
          onClick={planningButtonOnClick}
          disabled={disablePlanning}
          variant="contained"
          sx={{ borderRadius: '5px', height: '35px', p: 1.5, }}
        >
          <Typography variant="body1" sx={{ whiteSpace: 'noWrap', fontSize: '95%' }}>
            自動配車
          </Typography>
        </Button>
      </span>
    </Tooltip>
  ), [disablePlanning, planningButtonOnClick, planningTooltipTitle]);

  const importMemo = useMemo(() => (
    <Button
      variant="outlined"
      disabled={isLoading || !!currentHistoryVersion}
      onClick={selectImportMethodButtonOnClick}
      sx={{ borderRadius: '5px', height: '35px', p: 1.5, }}
    >
      <Typography variant="body1" sx={{ whiteSpace: 'nowrap', fontSize: '95%' }}>
        インポート
      </Typography>
    </Button>
  ), [currentHistoryVersion, isLoading, selectImportMethodButtonOnClick]);

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  const onClickOpenCreateOrderDialog = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '案件作成' });
    setDialogIsOpen(true);
  }, []);

  const dialogOnClose = useCallback(() => {
    setDialogIsOpen(false);
  }, []);

  const newOrderFormMemo = useMemo(() => (
    <Button
      variant="outlined"
      onClick={onClickOpenCreateOrderDialog}
      disabled={isLoading || !!currentHistoryVersion}
      sx={{ borderRadius: '5px', height: '35px', p: 1.5, }}
    >
      <Typography variant="body1" sx={{ whiteSpace: 'nowrap', fontSize: '95%' }}>
        案件作成
      </Typography>
      <AddIcon fontSize="small" />
    </Button>
  ), [onClickOpenCreateOrderDialog, currentHistoryVersion, isLoading]);

  const dialogOnCloseWithResetAllocateHistories = useCallback(() => {
    resetAllocateHistories();
    setDialogIsOpen(false);
  }, [resetAllocateHistories]);

  const orderFormDialogMemo = useMemo(() => (
    <OrderFormPresenter
      dialogIsOpen={dialogIsOpen}
      dialogOnClose={dialogOnClose}
      orderId={0}
      startOn={startOn}
      endOn={endOn}
      onClose={dialogOnCloseWithResetAllocateHistories}
    />
  ), [dialogIsOpen, dialogOnClose, dialogOnCloseWithResetAllocateHistories, endOn, startOn]);

  return (
    <Paper>
      <Stack
        direction="row"
        gap={2}
        alignItems="flex-end"
      >
        {orderFormDialogMemo}
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          width="100%"
          flexGrow={1}
        >
          {datePickMemo}
          {shiftButtonMemo}
        </Stack>
        <Stack
          direction="row"
          gap={2}
          sx={{ px: 1 }}
        >
          {licenseContext.config?.use_scenario_planning && scenarioPlanningMemo}
          {historyMemo}
          {estimateMemo}
          {planningMemo}
          {importMemo}
          {newOrderFormMemo}
        </Stack>
      </Stack>
    </Paper>
  );
});

export default ActionsPresenter;
