import CachedIcon from '@mui/icons-material/Cached';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns-tz';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import numberDecorator from 'src/decorators/number.decorator';
import { PlanningStatisticsEntity } from 'src/entities/PlanningStatistics.entity';

type Props = {
  planningStatistics: PlanningStatisticsEntity | undefined;
  refreshOperationStatuses: () => void;
  lastOperationStatusUpdated: string;
  isLoading: boolean;
  currentHistoryVersion: string | undefined;
  driverApp: boolean;
  co2Emissions: number;
  selectPrintButtonOnClick: () => void;
  sendOperationMail: () => void;
  selectedDeliveryIds: number[];
}

const PlanningStatisticsPresenter: FC<Props> = memo((
  {
    planningStatistics,
    refreshOperationStatuses,
    lastOperationStatusUpdated,
    isLoading,
    currentHistoryVersion,
    driverApp,
    co2Emissions,
    selectPrintButtonOnClick,
    sendOperationMail,
    selectedDeliveryIds,
  }
) => {
  const totalOrdersCountInitialState = '- 件';
  const totalLoadWeightKgInitialState = '総重量 - kg';
  const totalLoadVolumeM3InitialState = '総体積 - m3';
  const totalDistanceKmInitialState = '総距離 - km';
  const weightLoadingRateInitialState = '重量積載率 - %';
  const volumeLoadingRateInitialState = '体積積載率 - %';
  const utilizationRateInitialState = '稼働率 - %';
  const co2InitialState = 'Co2 - t-co2';

  const [totalOrdersCount, setTotalOrdersCount] = useState(totalOrdersCountInitialState);
  const [totalLoadWeightKg, setTotalLoadWeightKg] = useState(totalLoadWeightKgInitialState);
  const [totalLoadVolumeM3, setTotalLoadVolumeM3] = useState(totalLoadVolumeM3InitialState);
  const [totalDistanceKm, setTotalDistanceKm] = useState(totalDistanceKmInitialState);
  const [weightLoadingRate, setWeightLoadingRate] = useState(weightLoadingRateInitialState);
  const [volumeLoadingRate, setVolumeLoadingRate] = useState(volumeLoadingRateInitialState);
  const [utilizationRate, setUtilizationRate] = useState(utilizationRateInitialState);
  const [co2, setCo2] = useState(co2InitialState);
  const [lastOperationStatusUpdateInitialValue, setLastOperationStatusUpdateInitialValue] = useState<string>(null);
  const [operationStatusUpdatedAgo, setOperationStatusUpdatedAgo] = useState<number>(0);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>(null);

  const totalOrdersCountInitialize = () => {
    setTotalOrdersCount(totalOrdersCountInitialState);
  };

  const totalLoadWeightKgInitialize = () => {
    setTotalLoadWeightKg(totalLoadWeightKgInitialState);
  };

  const totalLoadVolumeM3Initialize = () => {
    setTotalLoadVolumeM3(totalLoadVolumeM3InitialState);
  };

  const totalDistanceKmInitialize = () => {
    setTotalDistanceKm(totalDistanceKmInitialState);
  };

  const weightLoadingRateInitialize = () => {
    setWeightLoadingRate(weightLoadingRateInitialState);
  };

  const volumeLoadingRateInitialize = () => {
    setVolumeLoadingRate(volumeLoadingRateInitialState);
  };

  const utilizationRateInitialize = () => {
    setUtilizationRate(utilizationRateInitialState);
  };

  const initialize = useCallback(() => {
    totalOrdersCountInitialize();
    totalLoadWeightKgInitialize();
    totalLoadVolumeM3Initialize();
    totalDistanceKmInitialize();
    weightLoadingRateInitialize();
    volumeLoadingRateInitialize();
    utilizationRateInitialize();
  }, []);

  useEffect(() => {
    initialize();

    if (!planningStatistics) return;

    if (planningStatistics.totalOrdersCount) {
      setTotalOrdersCount(
        numberDecorator.toRoundedUnit(
          planningStatistics.totalOrdersCount,
          '件',
          1,
          ''
        )
      );
    }

    if (planningStatistics.totalLoadWeightG) {
      setTotalLoadWeightKg(
        [
          '総重量',
          numberDecorator.convertGramToKg(planningStatistics.totalLoadWeightG)
        ].join('')
      );
    }

    if (planningStatistics.totalLoadVolumeMm3) {
      setTotalLoadVolumeM3(
        [
          '総体積',
          numberDecorator.convertMm3ToM3(planningStatistics.totalLoadVolumeMm3)
        ].join(' ')
      );
    }

    if (planningStatistics.totalDistanceMm) {
      setTotalDistanceKm(
        numberDecorator.toRoundedKm(
          planningStatistics.totalDistanceMm / 1000000,
          '総距離'
        )
      );
    }

    setWeightLoadingRate(
      numberDecorator.toRoundedRate(
        planningStatistics.totalMaxLoadWeightG / planningStatistics.loadCapacityWeightG,
        '重量積載率'
      ) || '重量積載率 -%'
    );

    setVolumeLoadingRate(
      numberDecorator.toRoundedRate(
        (planningStatistics.totalMaxLoadVolumeMm3 || 0) / (planningStatistics.loadCapacityVolumeMm3 || 0),
        '体積積載率'
      ) || '体積積載率 -%'
    );

    setUtilizationRate(
      numberDecorator.toRoundedRate(
        planningStatistics.totalWorkMinutes / planningStatistics.totalPlanMinutes,
        '稼働率'
      ) || '稼働率 -%'
    );
  }, [initialize, planningStatistics]);

  useEffect(() => {
    if (!co2Emissions) {
      setCo2(co2InitialState);
      return;
    }

    setCo2(
      [
        'Co2',
        numberDecorator.toRoundedUnit(co2Emissions, 't-co2', 3, '')
      ].join(' ')
    );
  }, [co2Emissions]);

  const onClickRefreshOperationStatuses = useCallback(() => {
    if (timeoutId) clearTimeout(timeoutId);
    setTimeoutId(null);
    setLastOperationStatusUpdateInitialValue(lastOperationStatusUpdated);
    setOperationStatusUpdatedAgo(0);
    refreshOperationStatuses();
  }, [lastOperationStatusUpdated, refreshOperationStatuses, timeoutId]);

  const updateOperationStatusUpdatedAgo = useCallback((lastUpdated: string) => {
    const ago = Math.floor((new Date().valueOf() - new Date(lastUpdated).valueOf()) / 1000 / 60);
    setOperationStatusUpdatedAgo(ago);

    const tid = setTimeout(() => {
      updateOperationStatusUpdatedAgo(lastUpdated);
    }, 20 * 1000);
    setTimeoutId(tid);

    if (ago > 30) onClickRefreshOperationStatuses();
  }, [onClickRefreshOperationStatuses]);

  useEffect(() => {
    if (!lastOperationStatusUpdated) return;

    if (lastOperationStatusUpdateInitialValue === null) {
      setLastOperationStatusUpdateInitialValue(lastOperationStatusUpdated);
    } else if (lastOperationStatusUpdateInitialValue !== lastOperationStatusUpdated) {
      updateOperationStatusUpdatedAgo(lastOperationStatusUpdated);
    }
  }, [lastOperationStatusUpdateInitialValue, lastOperationStatusUpdated, updateOperationStatusUpdatedAgo]);

  const [canSendMail, setCanSendMail] = useState<boolean>(false);
  const [sendMailDialogIsOpen, setSendMailDialogIsOpen] = useState<boolean>(false);

  const sendMailIconOnClick = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '配車指示メール' });
    setSendMailDialogIsOpen(true);
  }, []);

  const sendMailDialogClose = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_MAIL, button_name: 'キャンセル' });
    setSendMailDialogIsOpen(false);
  }, []);

  const sendMailButtonOnClick = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_MAIL, button_name: '送信する' });
    sendOperationMail();
    sendMailDialogClose();
  }, [sendMailDialogClose, sendOperationMail]);

  useEffect(() => {
    if (!driverApp) return;
    setCanSendMail(driverApp && selectedDeliveryIds.length > 0);
  }, [driverApp, selectedDeliveryIds.length]);

  const sendMailMemo = useMemo(() => (
    canSendMail && (
      <IconButton
        onClick={sendMailIconOnClick}
        disabled={!!currentHistoryVersion}
      >
        <MobileScreenShareIcon />
      </IconButton>
    )
  ), [canSendMail, currentHistoryVersion, sendMailIconOnClick]);

  const printingMemo = useMemo(() => (
    <IconButton
      onClick={selectPrintButtonOnClick}
      disabled={isLoading || !!currentHistoryVersion}
    >
      <Tooltip
        title="配送指示書を出力する"
        arrow
      >
        <LocalPrintshopRoundedIcon />
      </Tooltip>
    </IconButton>
  ), [currentHistoryVersion, isLoading, selectPrintButtonOnClick]);

  const driverAppMemo = useMemo(() => (
    driverApp && (
      <Dialog
        open={sendMailDialogIsOpen}
        onClose={sendMailDialogClose}
      >
        <Stack
          sx={{
            p: 2
          }}
          spacing={1}
        >
          <Stack>
            <Typography variant="h4">
              ドライバーに配送指示メールを送信します。よろしいですか？
            </Typography>
          </Stack>
          <Divider />
          <Stack direction="row">
            <LoadingButton
              size="small"
              variant="contained"
              sx={{ width: '60%', marginRight: '10pt' }}
              loading={isLoading}
              onClick={sendMailButtonOnClick}
            >
              送信する
            </LoadingButton>
            <LoadingButton
              size="small"
              variant="outlined"
              sx={{ width: '40%' }}
              loading={isLoading}
              onClick={sendMailDialogClose}
            >
              キャンセル
            </LoadingButton>
          </Stack>
        </Stack>
      </Dialog>
    )
  ), [driverApp, isLoading, sendMailButtonOnClick, sendMailDialogClose, sendMailDialogIsOpen]);

  return (
    <Stack direction="row" gap={0} sx={{ width: '100%' }}>
      <Stack sx={{ width: '100%' }}>
        <Stack direction="row" gap={1} justifyContent="space-between">
          <Stack>
            <Stack direction="row" gap={1}>
              <Typography>
                {totalOrdersCount}
              </Typography>
              <Typography>
                {totalLoadWeightKg}
              </Typography>
              <Typography>
                {totalLoadVolumeM3}
              </Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography>
                {weightLoadingRate}
              </Typography>
              <Typography>
                {volumeLoadingRate}
              </Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography whiteSpace="nowrap">
                {utilizationRate}
              </Typography>
              <Typography whiteSpace="nowrap">
                {totalDistanceKm}
              </Typography>
              <Typography whiteSpace="nowrap">
                {co2}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ marginX: 0 }} direction="row" alignItems="flex-start">
            {sendMailMemo}
            {printingMemo}
          </Stack>
        </Stack>
        {driverApp && (
          <Stack
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            alignContent="center"
            direction="row"
            gap={1}
          >
            {operationStatusUpdatedAgo > 0 && (
              <Stack direction="row">
                <Typography variant="body2" fontSize={13}>
                  {operationStatusUpdatedAgo}
                  分前に更新あり
                </Typography>
                <Button size="small" sx={{ minWidth: 30, p: 0, m: 0 }} startIcon={<CachedIcon />} onClick={onClickRefreshOperationStatuses} />
              </Stack>
            )}
            {(operationStatusUpdatedAgo === 0 && lastOperationStatusUpdateInitialValue) && (
              <Stack>
                <Typography variant="body2" fontSize={13}>
                  配送状況最終更新：
                  {format(new Date(lastOperationStatusUpdateInitialValue), 'MM/dd HH:mm', { timeZone: 'Asia/Tokyo' })}
                </Typography>
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
      {driverAppMemo}
    </Stack>
  );
});

export default PlanningStatisticsPresenter;
