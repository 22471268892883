import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { CompanyConnectionEntity, CompanyConnectionRequestEntity } from 'src/entities/CompanyConnectionEntity';

const requestPath = '/api/v2/accounts/company_connections' as const;

const getCompanyConnections = (queryClient: QueryClient) => async () => {
  const res = await axios.get<ApiResponse>(requestPath);
  return res.data.data as unknown as CompanyConnectionEntity[];
};

export const useQueryCompanyConnections = () => {
  const queryClient = useQueryClient();
  return useQuery(['connected_companies'], getCompanyConnections(queryClient), {
    staleTime: Infinity
  });
};

export const useMutationCompanyConnection = () => {
  const queryClient = useQueryClient();
  return {
    addConnection: useMutation(
      (entity: CompanyConnectionRequestEntity) => axios.post(requestPath, entity),
      {
        onSuccess: () => queryClient.invalidateQueries(['connected_companies']),
      }
    ),
    updateConnection: useMutation(
      ({ id, status }: { id: number, status: 'approved' | 'rejected' }) => axios.put(`${requestPath}/${id}`, { status }),
      {
        onSuccess: () => queryClient.invalidateQueries(['connected_companies']),
      }
    ),
    deleteConnection: useMutation(
      (id: number) => axios.delete(`${requestPath}/${id}`),
      {
        onSuccess: () => queryClient.invalidateQueries(['connected_companies']),
      }
    ),
  };
};
