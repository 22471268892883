import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import SystemUpdateAltRoundedIcon from '@mui/icons-material/SystemUpdateAltRounded';
import { Box, Button } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector
} from '@mui/x-data-grid';
import { FC, memo } from 'react';

import MyGridToolbarFilterButton from '../common/MyGridToolbarFilterButton';

const CustomToolbar: FC<{
  toggleDialogIsOpen: () => void;
  handleDownloadCSV: () => void;
}> = memo(({ toggleDialogIsOpen, handleDownloadCSV }) => (
  <GridToolbarContainer
    sx={{
      width: '100%'
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
        flexGrow={1}
      >
        <GridToolbarColumnsButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
        <MyGridToolbarFilterButton />
        <GridToolbarDensitySelector nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
        <Button
          startIcon={<SystemUpdateAltRoundedIcon />}
          onClick={handleDownloadCSV}
          sx={{ textTransform: 'none' }}
        >
          エクスポート
        </Button>
        <Button
          startIcon={<AddCircleOutlinedIcon />}
          onClick={toggleDialogIsOpen}
        >
          グループ追加
        </Button>
      </Box>
    </Box>
  </GridToolbarContainer>
));

export default CustomToolbar;
