import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DirectionConditionEntity } from 'src/entities/DirectionCondition.entity';

const requestPath = '/api/v4/direction_conditions';

export const useDisplayDirectionConditionsMutation = () => ({
  create: useMutation((dto: DirectionConditionEntity[]) => axios.post(requestPath, {
    conditions: JSON.stringify(dto)
  }))
});
