import { Backdrop, Box, CircularProgress, Paper } from '@mui/material';
import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import useImport from 'src/hooks/useImport';

import Mapping from './Mapping';
import NewImport from './NewImport';
import Process from './Process';
import Validation from './Validation';

const ImportPresenter: FC = memo(() => {
  const importContext = useImport();

  const mainComponent = () => {
    switch (importContext.currentStep) {
      case 1:
        return <NewImport />;
      case 2:
        return <Mapping />;
      case 3:
        return <Validation />;
      case 4:
        return <Process />;
      default:
        return <NewImport />;
    }
  };

  return (
    <>
      <Helmet>
        <title>インポート</title>
      </Helmet>
      <main
        style={{
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
        }}
      >
        <Box p={2} height="100%">
          <Paper sx={{ height: '100%' }}>
            {mainComponent()}
          </Paper>
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={importContext.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </main>
    </>
  );
});

export default ImportPresenter;
