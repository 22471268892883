import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { DriverEntity, DriverRequestEntity } from 'src/entities/driverEntity';

const getDrivers = (queryClient: QueryClient, page: number) => async () => {
  const res = await axios.get<ApiResponse>('/api/v2/drivers?with_data=1');
  const list = res.data.data;
  return list.map((entity: DriverEntity) => {
    queryClient.setQueryData(['drivers', { id: entity.id }], entity);
    return entity;
  });
};

const getDriverIds = (queryClient: QueryClient) => async () => {
  const res = await axios.get<ApiResponse>('/api/v2/drivers');
  const driverIds = res.data.ids as unknown as number[];
  queryClient.setQueryData(['driver_ids'], driverIds);
  return driverIds;
};

const getDriver = (id: number) => async () => {
  const res = await axios.get<DriverEntity>(`/api/v2/drivers/${id}`);
  return res.data;
};

export const useQueryDrivers = (page: number) => {
  const queryClient = useQueryClient();
  return useQuery(['drivers', { page }], getDrivers(queryClient, page), {
    staleTime: Infinity
  });
};

export const useQueryDriverIds = () => {
  const queryClient = useQueryClient();
  return useQuery(['driver_ids'], getDriverIds(queryClient), {
    staleTime: Infinity
  });
};

export const useQueryDriver = (id: number) => {
  const queryClient = useQueryClient();
  return useQuery(['drivers', { id }], getDriver(id), {
    staleTime: Infinity,
    enabled: Boolean(id)
  });
};

export const useMutationDriver = () => {
  const queryClient = useQueryClient();
  return {
    addDriver: useMutation(
      (driver: DriverRequestEntity) => axios.post('/api/v2/drivers', driver),
      {
        onSuccess: () => queryClient.invalidateQueries(['drivers']),
      }
    ),
    updateDriver: useMutation(
      (driver: DriverEntity) => axios.put(`/api/v2/drivers/${driver.id}`, driver),
      {
        onSuccess: () => queryClient.invalidateQueries(['drivers']),
      }
    ),
    deleteDrivers: useMutation(
      (ids: number[]) => axios.delete('/api/v2/drivers/bulk', { data: { ids } }),
      {
        onSuccess: () => queryClient.invalidateQueries(['drivers']),
      }
    ),
  };
};
