import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DoneIcon from '@mui/icons-material/Done';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, LinearProgress, Paper, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import LoadingComponent from 'src/components/LoadingComponent';
import { ConnectedCompanyEntity, DelegatedLicenseEntity, LicenseEntity, LicenseRequestEntity, LicenseResponseEntity } from 'src/entities/admin/licenseEntity';
import { useQueryCompany } from 'src/hooks/admin/useQueryCompanies';
import { useMutationLicense, useQueryLicenses } from 'src/hooks/admin/useQueryLicenses';
import { useQueryRoles } from 'src/hooks/admin/useQueryRoles';
import * as yup from 'yup';

import { AdminLicensesToolbar } from './Toolbar';

const schema = yup.object({
  email: yup.string().required('必須です').email(),
  password: yup.string(),
  internal_account: yup.boolean().required('必須です'),
  role: yup.object().shape({
    id: yup.mixed().oneOf([1, 2]).required('権限を選択してください')
  }),
  license_profile: yup.object().shape({
    branch_name: yup.string().optional().nullable(),
    person_name: yup.string().optional().nullable(),
    person_phone_number: yup.string().optional().nullable(),
  }),
});

const AdminLicensesPresenter: FC = memo(() => {
  const { companyId } = useParams();
  const companyQuery = useQueryCompany(companyId);
  const rolesQuery = useQueryRoles();

  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const [pageSise, setPageSize] = useState(25);
  const [roleValue, setRoleValue] = useState<string>('');
  const [internalACcount, setInternalAccount] = useState<boolean>(false);
  const licensesQuery = useQueryLicenses(companyId, page, pageSise);

  const { addLicense, updateLicense, deleteLicense } = useMutationLicense();

  const {
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<(LicenseRequestEntity | LicenseEntity)>({
    resolver: yupResolver(schema)
  });

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const toggleDialogIsOpen = () => {
    initializeForEdit(!dialogIsOpen);
  };

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const onSubmitAdd: SubmitHandler<LicenseRequestEntity | LicenseEntity> = async (
    data
  ): Promise<void> => {
    data.company_id = companyId;
    try {
      if ('id' in data) {
        await updateLicense.mutateAsync(data);
      } else {
        await addLicense.mutateAsync(data);
      }
      setDialogIsOpen(false);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, quotes,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument
      error.response.data.map((str) => enqueueSnackbar(str));
    }
  };

  const onSubmitRemove: SubmitHandler<LicenseEntity> = async (
    data
  ): Promise<void> => {
    data.company_id = companyId;
    if (window.confirm('対象のライセンスに紐づくすべてのデータが削除されます。本当に削除してもよいですか？')) {
      await deleteLicense.mutateAsync(data);
    }
    setDialogIsOpen(false);
  };

  const openAppendDialog = () => {
    initializeForEdit(true);
  };

  const openEditDialog: SubmitHandler<LicenseEntity> = async (
    data
    // eslint-disable-next-line @typescript-eslint/require-await
  ): Promise<void> => {
    initializeForEdit(true, data);
  };

  const initializeForEdit = (isOpen: boolean, data?: LicenseEntity) => {
    reset(data || {});
    setIsEdit(!!data);
    setDialogIsOpen(isOpen);
    setRoleValue(String(data?.role?.id));
    const internalAccount = data?.internal_account || false;
    setInternalAccount(internalAccount);
    setValue('internal_account', internalAccount);
  };

  const Columns: GridColDef[] = [
    { field: 'id', headerName: 'id', flex: 1 },
    {
      field: 'email',
      headerName: 'メールアドレス',
      flex: 8,
      renderCell: (params: { row: LicenseEntity }) => {
        if (params.row.email === null || params.row.email === '') return '';

        let activationState = <CloseIcon sx={{ color: red[500] }} />;
        if (params.row.activation_state === 'active') {
          activationState = <DoneIcon color="success" />;
        }

        return (
          <>
            {activationState}
            <Typography variant="body1" paddingLeft={1}>
              {params.row.email}
            </Typography>
          </>
        );
      }
    },
    {
      field: 'person_name',
      headerName: '氏名',
      valueGetter: (params: { row: LicenseEntity }) => params.row.license_profile?.person_name,
      flex: 3,
    },
    {
      field: 'branch_name',
      headerName: '支店名',
      valueGetter: (params: { row: LicenseEntity }) => params.row.license_profile?.branch_name,
      flex: 3,
    },
    {
      field: 'phone_number',
      headerName: '電話番号',
      valueGetter: (params: { row: LicenseEntity }) => params.row.license_profile?.phone_number,
      flex: 3,
    },
    {
      field: 'role_name_ja',
      headerName: '権限',
      flex: 2,
      valueGetter: (params: { row: LicenseEntity }) => params.row?.role?.name_ja,
    },
    {
      field: 'internal_account',
      headerName: 'Logpose社内アカウント',
      flex: 2,
      valueGetter: (params: { row: LicenseEntity }) => (params.row.internal_account ? '◯' : ''),
    },
    {
      field: 'edit',
      headerName: '編集',
      flex: 1,
      renderCell: (params: { row: LicenseEntity }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        <IconButton onClick={() => openEditDialog(params.row)}>
          <EditOutlinedIcon />
        </IconButton>
      )
    },
    {
      field: 'delete',
      headerName: '削除',
      flex: 1,
      renderCell: (params: { row: LicenseEntity }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        <IconButton onClick={() => onSubmitRemove(params.row)}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
      )
    }
  ];

  const DelegatedLicenseColumns: GridColDef[] = [
    { field: 'id', headerName: 'id', flex: 1 },
    {
      field: 'email',
      headerName: 'メールアドレス',
      flex: 10,
      valueGetter: (params: { row: DelegatedLicenseEntity }) => params.row?.email,
    },
    {
      field: 'person_name',
      headerName: '氏名',
      valueGetter: (params: { row: DelegatedLicenseEntity }) => params.row.license_profile?.person_name,
      flex: 2,
    },
    {
      field: 'branch_name',
      headerName: '支店名',
      valueGetter: (params: { row: DelegatedLicenseEntity }) => params.row.license_profile?.branch_name,
      flex: 2,
    },
    {
      field: 'phone_number',
      headerName: '電話番号',
      valueGetter: (params: { row: DelegatedLicenseEntity }) => params.row.license_profile?.phone_number,
      flex: 2,
    },
    {
      field: 'role_name_ja',
      headerName: '権限',
      flex: 5,
      valueGetter: (params: { row: DelegatedLicenseEntity }) => params.row?.role?.name_ja,
    },
    {
      field: 'company_name',
      headerName: '事業所名',
      flex: 5,
      valueGetter: (params: { row: DelegatedLicenseEntity }) => params.row?.company?.name,
    },
  ];

  const ConnectedCompanyColumns: GridColDef[] = [
    { field: 'id', headerName: 'id', flex: 1 },
    {
      field: '企業名',
      flex: 10,
      valueGetter: (params: { row: ConnectedCompanyEntity }) => `${params.row.company.name} => ${params.row.connected_company.name}`,
    },
    {
      field: 'status',
      headerName: 'ステータス',
      flex: 5,
      valueGetter: (params: { row: ConnectedCompanyEntity }) => {
        switch (params.row.status) {
          case 'requested':
            return '確認待ち';
          case 'approved':
            return '確認済み';
          case 'rejected':
            return '拒否';
          default:
            return '';
        }
      }
    }
  ];

  const onChangeAccountRole = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    setValue('role.id', Number(value));
    setRoleValue(value);
  };

  const onChangeInternalAccount = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('internal_account', event.target.checked);
    setInternalAccount(event.target.checked);
  };

  const [mainDataGridHeightWidth, setMainDataGridHeightWidth] = useState<{ height: string, width: string }>({ height: '50%', width: '100%' });
  const [subDataGridHeightWidth, setSubDataGridHeightWidth] = useState<{ height: string, width: string }>({ height: '46%', width: '100%' });
  useEffect(() => {
    if (!licensesQuery.data) return;

    const res: LicenseResponseEntity = licensesQuery.data.entity;
    let i = 1;
    if (res.delegated_licenses.length > 0) i++;
    if (res.connected_companies.length > 0) i++;
    if (i === 1) {
      setMainDataGridHeightWidth({ height: '100%', width: '100%' });
    } else {
      setMainDataGridHeightWidth({ height: '50%', width: '100%' });
      setSubDataGridHeightWidth({ height: `${46 / (i - 1)}%`, width: '100%' });
    }
  }, [licensesQuery.data]);

  if (licensesQuery.isLoading || companyQuery.isLoading || rolesQuery.isLoading) {
    return <LoadingComponent />;
  }

  if (licensesQuery.isError || companyQuery.isError || rolesQuery.isLoading) {
    return <>Error</>;
  }

  const licenseResponse = licensesQuery.data;
  const company = companyQuery.data;
  const roles = rolesQuery.data;

  return (
    <>
      <Helmet>
        <title>ADMIN | ライセンス一覧</title>
      </Helmet>
      <Box width="100%" height="100%" p={2}>
        <Dialog open={dialogIsOpen} maxWidth="md" fullWidth onClose={toggleDialogIsOpen}>
          <DialogTitle>
            ライセンス
            {isEdit ? '編集' : '追加'}
          </DialogTitle>
          <DialogContent>
            <Stack component="form" noValidate spacing={2}>
              <TextField
                required
                margin="dense"
                id="email"
                label="メールアドレス"
                type="text"
                fullWidth
                variant="standard"
                {...register('email')}
                error={'email' in errors}
                helperText={errors.email?.message}
              />
              <TextField
                margin="dense"
                id="person_name"
                label="氏名"
                fullWidth
                variant="standard"
                {...register('license_profile.person_name')}
                error={'license_profile.person_name' in errors}
                helperText={errors.license_profile?.person_name?.message}
              />
              <TextField
                margin="dense"
                id="branch_name"
                label="支店名"
                fullWidth
                variant="standard"
                {...register('license_profile.branch_name')}
                error={'license_profile.branch_name' in errors}
                helperText={errors.license_profile?.branch_name?.message}
              />
              <TextField
                margin="dense"
                id="phone_number"
                label="電話番号"
                fullWidth
                variant="standard"
                {...register('license_profile.phone_number')}
                error={'license_profile.phone_number' in errors}
                helperText={errors.license_profile?.phone_number?.message}
              />
              <TextField
                required={!isEdit}
                margin="dense"
                id="password"
                label="パスワード"
                type="text"
                fullWidth
                variant="standard"
                {...register('password')}
                error={'password' in errors}
                helperText={errors.password?.message}
              />
              <FormControl error={'role' in errors}>
                <FormLabel>権限</FormLabel>
                <FormHelperText>{errors.role?.id?.message}</FormHelperText>
                <RadioGroup
                  row
                  id="role.id"
                  name="role.id"
                  value={roleValue}
                  onChange={(event, value) => onChangeAccountRole(event, value)}
                >
                  {roles.map((role) => (
                    <FormControlLabel key={`role-${role.id}`} value={`${role.id}`} control={<Radio />} label={role.name_ja} />
                  ))}
                </RadioGroup>
              </FormControl>
              <FormControlLabel
                onChange={onChangeInternalAccount}
                control={(
                  <Checkbox
                    checked={internalACcount}
                  />
                )}
                label="Logpose社内アカウント"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialogIsOpen}>キャンセル</Button>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Button onClick={handleSubmit(onSubmitAdd)} variant="contained">
              保存する
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          width="100%"
          height="100%"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Box width="100%" height="100%" flexGrow={1}>
            <Paper
              style={mainDataGridHeightWidth}
            >
              <DataGridPremium
                columns={Columns}
                rows={licenseResponse.entity.licenses}
                rowCount={licenseResponse.total}
                pagination
                paginationMode="server"
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: AdminLicensesToolbar
                }}
                componentsProps={{
                  toolbar: { openAppendDialog, company },
                }}
                onPageChange={(val) => { setPage(val); }}
                onPageSizeChange={(val) => { setPageSize(val); }}
                page={page}
                pageSize={pageSise}
              />
            </Paper>
            {licenseResponse.entity?.delegated_licenses?.length > 0 && (
              <>
                <Typography variant="h4" mt={1} p={1}>連携アカウント</Typography>
                <Paper
                  style={subDataGridHeightWidth}
                >
                  <DataGridPremium
                    columns={DelegatedLicenseColumns}
                    rows={licenseResponse.entity.delegated_licenses}
                    rowCount={licenseResponse.entity.delegated_licenses.length}
                  />
                </Paper>
              </>
            )}
            {licenseResponse.entity?.connected_companies?.length > 0 && (
              <>
                <Typography variant="h4" mt={1} p={1}>事業所連携</Typography>
                <Paper
                  style={subDataGridHeightWidth}
                >
                  <DataGridPremium
                    columns={ConnectedCompanyColumns}
                    rows={licenseResponse.entity.connected_companies}
                    rowCount={licenseResponse.entity.connected_companies.length}
                  />
                </Paper>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default AdminLicensesPresenter;
