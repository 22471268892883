import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DoneIcon from '@mui/icons-material/Done';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Typography, Paper, Box, Button, IconButton, Theme, ThemeOptions, useTheme } from '@mui/material';
import { red } from '@mui/material/colors';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import LicenseContext from 'src/contexts/LicenseContext';
import { AccountEntity, AccountLicenseEntity } from 'src/entities/AccountEntity';

interface Props {
  account: AccountEntity
  openAppendDialog: () => void;
  onSubmitRemove: (entity: AccountLicenseEntity) => void;
  openEditDialog: (entity: AccountLicenseEntity) => void;
  openConnectDialog: () => void;
}

export const AccountList: FC<Props> = memo((props: Props) => {
  const { account, openAppendDialog, onSubmitRemove, openEditDialog, openConnectDialog } = props;

  const [isAdmin, setIsAdmin] = useState(false);
  const [useControlCenter, setUseControlCenter] = useState(false);
  const [userId, setUserId] = useState<number | null>(null);
  const [licenses, setLicenses] = useState<AccountLicenseEntity[]>([]);

  useEffect(() => {
    if (!account) return;

    let data = account.licenses || [];
    if (account.delegated_licenses) {
      data = data.concat(account.delegated_licenses);
    }
    setLicenses(data);
  }, [account]);

  const licenseContext = useContext(LicenseContext);
  const theme: Theme & ThemeOptions = useTheme();

  useEffect(() => {
    if (!licenseContext?.config) return;

    setIsAdmin(licenseContext.config.role.name === 'admin');
    setUserId(licenseContext.config.id);
    setUseControlCenter(licenseContext.config.use_control_center);
  }, [licenseContext?.config]);

  const canEdit = useCallback((entity: AccountLicenseEntity) => {
    if (isAdmin) return true;
    return entity.id === userId;
  }, [isAdmin, userId]);

  const canDelete = useCallback((entity: AccountLicenseEntity) => {
    if (entity.id === userId) return false;
    return isAdmin;
  }, [isAdmin, userId]);

  const Columns: GridColDef[] = useMemo(() => {
    if (!account) return [];
    if (!licenseContext?.config) return [];

    const ret: GridColDef[] = [
      {
        field: 'email',
        headerName: 'メールアドレス',
        flex: 3,
        renderCell: (params: { row: AccountLicenseEntity }) => {
          if (params.row.email === null || params.row.email === '') return '';

          let activationState = <CloseIcon sx={{ color: red[500] }} />;
          if (params.row.activation_state === 'active') {
            activationState = <DoneIcon color="success" />;
          }

          return (
            <>
              {activationState}
              <Typography variant="body1" paddingLeft={1}>
                {params.row.email}
              </Typography>
            </>
          );
        }
      },
      {
        field: 'person_name',
        headerName: '氏名',
        valueGetter: (params: { row: AccountLicenseEntity }) => params.row.license_profile?.person_name,
        flex: 2,
      },
      {
        field: 'branch_name',
        headerName: '支店名',
        valueGetter: (params: { row: AccountLicenseEntity }) => params.row.license_profile?.branch_name,
        flex: 2,
      },
      {
        field: 'phone_number',
        headerName: '電話番号',
        valueGetter: (params: { row: AccountLicenseEntity }) => params.row.license_profile?.phone_number,
        flex: 2,
      }
    ];
    if (account.delegated_licenses.length > 0) {
      ret.push(
        {
          field: 'company',
          headerName: '所属',
          flex: 2,
          valueGetter: (params: { row: AccountLicenseEntity }) => {
            if (params.row.company.name === licenseContext.config.company_name) {
              return '';
            }
            return params.row.company.name;
          }
        }
      );
    }
    ret.push(...[
      {
        field: 'role',
        headerName: '権限',
        valueGetter: (params: { row: AccountLicenseEntity }) => params.row.role.name_ja,
        flex: 1,
      },
      {
        field: 'edit',
        headerName: '編集',
        renderCell: (params: { row: AccountLicenseEntity }) => {
          if (canEdit(params.row)) {
            return (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              <IconButton onClick={() => openEditDialog(params.row)}>
                <EditOutlinedIcon />
              </IconButton>
            );
          }
          return '-';
        }
      },
      {
        field: 'delete',
        headerName: '削除',
        renderCell: (params: { row: AccountLicenseEntity }) => {
          if (canDelete(params.row)) {
            return (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              <IconButton onClick={() => onSubmitRemove(params.row)}>
                <DeleteForeverOutlinedIcon />
              </IconButton>
            );
          }
          return '-';
        }
      }
    ]);
    return ret;
  }, [account, licenseContext.config, canEdit, openEditDialog, canDelete, onSubmitRemove]);

  if (!licenseContext || !licenseContext.config) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
        flexGrow={1}
      >
        <Typography variant="h3" margin={1}>
          {licenseContext.config.company_name}
        </Typography>
        {isAdmin && (
          <Button
            startIcon={<AddCircleOutlinedIcon />}
            onClick={openAppendDialog}
          >
            ユーザー追加
          </Button>
        )}
        {isAdmin && useControlCenter && (
          <Button
            startIcon={<AddCircleOutlinedIcon />}
            onClick={openConnectDialog}
          >
            事業所連携
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Box flexGrow={1}>
          <Typography variant="h4" ml={2} pt={2}>
            ユーザー一覧
          </Typography>
          <Paper
            style={{
              width: '100%',
              height: `calc(40vh - ${theme.spacing(4)})`,
              padding: '8px'
            }}
          >
            <DataGridPremium
              columns={Columns}
              rows={licenses}
              rowCount={licenses.length}
              pagination
              paginationMode="server"
              page={1}
              pageSize={25}
            />
          </Paper>
        </Box>
      </Box>
    </>
  );
});
