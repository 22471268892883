import {
  QueryClient,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { LicenseEntity } from 'src/entities/licenseEntity';

const getCompany = (queryClient: QueryClient, companyId: number) => async () => {
  if (!companyId) return null;

  const requestPath = `/api/v2/company/?company_id=${companyId}`;
  const res = await axios.get<ApiResponse>(requestPath);
  // const entity = JSON.parse(res.data as unknown as string) as LicenseEntity;
  const entity = res.data as unknown as LicenseEntity;
  return entity;
};

export const useQueryCompany = (companyId: number) => {
  const queryClient = useQueryClient();
  return useQuery(['company', { companyId }], getCompany(queryClient, companyId), {
    staleTime: Infinity,
  });
};
