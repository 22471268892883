import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { DataGridPremium, GridColDef, GridToolbarContainer, jaJP } from '@mui/x-data-grid-premium';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import { PlanningScenario } from 'src/entities/PlanningHistory.entity';
import { useMutationPlanningScenario, useQueryPlanningScenario } from 'src/hooks/useQueryPlanningScenarios';

import ScenarioPlanningEditDialog from './ScenarioPlanningEditDialog';

const CustomToolbar: FC<{
  openCreateDialog: () => void;
}> = memo(({
  openCreateDialog,
}) => (
  <GridToolbarContainer
    sx={{
      width: '100%'
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        width: '100%'
      }}
    >
      <Button
        startIcon={<AddCircleOutlinedIcon />}
        onClick={openCreateDialog}
      >
        シナリオ追加
      </Button>
    </Box>
  </GridToolbarContainer>
));

type Props = {
  dialogIsOpen: boolean;
  setDialogIsOpen: (open: boolean) => void;
  customInputFields: string[];
  requestScenarioPlanning: (scenarioId: number) => void;
}

export const ScenarioPlanningListDialog: FC<Props> = memo(({
  dialogIsOpen,
  setDialogIsOpen,
  customInputFields,
  requestScenarioPlanning,
}) => {
  const { data: planningScenarios, isLoading } = useQueryPlanningScenario(dialogIsOpen);
  const { deleteScenario } = useMutationPlanningScenario();
  const [scenarioEditDialogIsOpen, setScenarioEditDialogIsOpen] = useState(false);
  const [planningScenarioForEdit, setPlanningScenarioForEdit] = useState<PlanningScenario>(null);

  useEffect(() => {
    if (dialogIsOpen) {
      ReactGA.event('open', { screen_name: SCREEN_NAMES.SCENARIO_LIST });
    }
  }, [dialogIsOpen]);

  const gaClickEvent = useCallback((buttonName: string) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.SCENARIO_LIST, button_name: buttonName });
  }, []);

  const openEditDialog = (scenario: PlanningScenario) => {
    gaClickEvent('編集');
    setPlanningScenarioForEdit(scenario);
    setScenarioEditDialogIsOpen(true);
  };

  const openCreateDialog = () => {
    setPlanningScenarioForEdit(null);
    setScenarioEditDialogIsOpen(true);
  };

  const deleteButtonOnClick = (scenario: PlanningScenario) => {
    gaClickEvent('削除');
    // eslint-disable-next-line no-alert
    if (!window.confirm('削除します。よろしいですか？')) {
      return;
    }

    deleteScenario.mutate(scenario.id);
  };

  const execute = (scenario: PlanningScenario) => {
    gaClickEvent('実行');
    // eslint-disable-next-line no-alert
    if (window.confirm('シナリオを実行します。よろしいですか？')) {
      requestScenarioPlanning(scenario.id);
      setDialogIsOpen(false);
    }
  };

  const COLUMNS: GridColDef[] = [
    {
      field: 'execute',
      headerName: '実行',
      renderCell: (params: { row: PlanningScenario }) => (
        <IconButton onClick={() => execute(params.row)}>
          <PlayCircleIcon />
        </IconButton>
      ),
      filterable: false,
      sortable: false,
      disableExport: true,
    },
    {
      field: 'companyName',
      headerName: '事業所',
      flex: 2,
    },
    {
      field: 'name',
      headerName: '名前',
      flex: 8
    },
    {
      field: 'scenarioCount',
      headerName: 'シナリオ数',
      flex: 4,
      type: 'number',
      valueGetter: (params: { row: PlanningScenario }) => params.row.planningScenarioItemsAttributes.length,
    },
    {
      field: 'edit',
      headerName: '編集',
      renderCell: (params: { row: PlanningScenario }) => (
        <IconButton onClick={() => openEditDialog(params.row)}>
          <EditOutlinedIcon />
        </IconButton>
      ),
      filterable: false,
      sortable: false,
      disableExport: true,
    },
    {
      field: 'delete',
      headerName: '削除',
      renderCell: (params: { row: PlanningScenario }) => (
        <IconButton onClick={() => deleteButtonOnClick(params.row)}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
      ),
      filterable: false,
      sortable: false,
      disableExport: true,
    },
  ];

  const onClose = () => {
    setDialogIsOpen(false);
  };

  if (!dialogIsOpen || isLoading) {
    return null;
  }

  return (
    <div>
      <ScenarioPlanningEditDialog
        dialogIsOpen={scenarioEditDialogIsOpen}
        setDialogIsOpen={setScenarioEditDialogIsOpen}
        customInputFields={customInputFields}
        planningScenario={planningScenarioForEdit}
      />
      <Dialog
        fullScreen
        fullWidth
        open={dialogIsOpen}
        sx={{ mt: 8 }}
      >
        <DialogTitle>シナリオ一覧</DialogTitle>
        <DialogContent>
          <DataGridPremium
            rows={planningScenarios}
            columns={COLUMNS}
            // pagination
            // disableRowGrouping
            components={{
              Toolbar: CustomToolbar
            }}
            componentsProps={{
              toolbar: {
                openCreateDialog,
              }
            }}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            // loading={requestIsLoading || isLoading}
            disableSelectionOnClick
            sortingMode="client"
            paginationMode="client"
            filterMode="client"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
          >
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
