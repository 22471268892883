/* eslint-disable @typescript-eslint/no-misused-promises */
import { yupResolver } from '@hookform/resolvers/yup';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Paper, Stack, TextField, Theme, ThemeOptions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { jaJP, GridColDef, DataGridPremium } from '@mui/x-data-grid-premium';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { MappingRule } from 'src/entities/import';
import { useMutationMappingRules, useQueryMappingRules } from 'src/hooks/useQueryMappingRules';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required('必須です')
});

const MappingRulesPresenter: FC = memo(() => {
  const { enqueueSnackbar } = useSnackbar();
  const theme: Theme & ThemeOptions = useTheme();
  const navigate = useNavigate();

  const mappingRulesQuery = useQueryMappingRules();
  const { updateMappingRule, deleteMappingRule } = useMutationMappingRules();

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleDialogIsOpen = () => {
    reset({});
    setDialogIsOpen(!dialogIsOpen);
  };

  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<MappingRule>({
    resolver: yupResolver(schema)
  });

  const onSubmitRemove: SubmitHandler<MappingRule> = async (
    data
  ): Promise<void> => {
    if (window.confirm('削除してもよろしいですか？')) {
      await deleteMappingRule.mutateAsync(data.id)
        .catch((e: AxiosError<{ message: string; }>) => {
          enqueueSnackbar(e.response?.data?.message);
        });
    }
    setDialogIsOpen(false);
  };

  const openEdit: SubmitHandler<MappingRule> = async (
    data
    // eslint-disable-next-line @typescript-eslint/require-await
  ): Promise<void> => {
    reset(data);
    setDialogIsOpen(true);
  };

  const onSubmitAdd: SubmitHandler<MappingRule> = async (
    data
  ): Promise<void> => {
    try {
      await updateMappingRule.mutateAsync(data);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, quotes,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument
      error.response.data.map((str) => enqueueSnackbar(str));
    } finally {
      setDialogIsOpen(false);
    }
  };

  const Columns: GridColDef[] = [
    { field: 'name', headerName: '車庫', width: 400 },
    {
      field: 'edit',
      headerName: '編集',
      renderCell: (params: { row: MappingRule }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        <IconButton onClick={() => openEdit(params.row)}>
          <EditOutlinedIcon />
        </IconButton>
      )
    },
    {
      field: 'delete',
      headerName: '削除',
      renderCell: (params: { row: MappingRule }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        <IconButton onClick={() => onSubmitRemove(params.row)}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
      )
    }
  ];

  const closeButtonOnClick = () => {
    navigate('/import');
  };

  if (mappingRulesQuery.isLoading) {
    return <>Loading</>;
  }

  if (mappingRulesQuery.isError) {
    return <>Error</>;
  }

  const mappingRules = mappingRulesQuery.data;

  return (
    <>
      <Helmet>
        <title>インポート マッピング設定</title>
      </Helmet>
      <Stack>
        <Stack direction="row" gap={1} px={2} pt={1}>
          <IconButton
            onClick={closeButtonOnClick}
            disabled={isLoading}
          >
            <CancelRoundedIcon />
          </IconButton>
        </Stack>
        <Box p={2}>
          <Dialog open={dialogIsOpen} maxWidth="md" fullWidth>
            <DialogTitle>マッピング名称変更</DialogTitle>
            <DialogContent>
              <Stack component="form" noValidate spacing={2}>
                <TextField
                  required
                  margin="dense"
                  id="name"
                  label="名前"
                  type="text"
                  fullWidth
                  variant="standard"
                  {...register('name')}
                  error={'name' in errors}
                  helperText={errors.name?.message}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleDialogIsOpen}>キャンセル</Button>
              <Button onClick={handleSubmit(onSubmitAdd)} variant="contained">
                保存する
              </Button>
            </DialogActions>
          </Dialog>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Box flexGrow={1}>
              <Paper
                style={{
                  width: '100%',
                  height: `calc(100vh - ${theme.spacing(4)})`
                }}
              >
                <DataGridPremium
                  rows={mappingRules}
                  columns={Columns}
                  pagination
                  pageSize={30}
                  disableRowGrouping
                  components={{
                    LoadingOverlay: LinearProgress
                  }}
                  componentsProps={{
                    toolbar: {
                      toggleDialogIsOpen
                    }
                  }}
                  localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                  loading={isLoading}
                />
              </Paper>
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  );
});

export default MappingRulesPresenter;
