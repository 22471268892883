import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

export const useMutationPlanningOperations = () => ({
  operationSendMail: useMutation(
    ({ startOn, endOn, shiftTruckIds }: {
      startOn: string;
      endOn: string;
      shiftTruckIds: number[]
    }) => axios.post(`/api/v3/plannings/${startOn}/operatios/send_mail?end_on=${endOn}`, { shift_truck_ids: shiftTruckIds })
  )
});
