import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { AreaEntity } from 'src/entities/areaEntity';

export const Columns: (openEditDialog: (entity: AreaEntity) => void, deleteButtonOnClick: (entity: AreaEntity) => void) => GridColDef[] = (
  openEditDialog,
  deleteButtonOnClick
) => [
    {
      field: 'edit',
      headerName: '編集',
      renderCell: (params: { row: AreaEntity }) => (
        <IconButton onClick={() => openEditDialog(params.row)}>
          <EditOutlinedIcon />
        </IconButton>
      ),
      filterable: false,
      sortable: false,
      disableExport: true,
    },
    {
      field: 'delete',
      headerName: '削除',
      renderCell: (params: { row: AreaEntity }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        <IconButton onClick={() => deleteButtonOnClick(params.row)}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
      ),
      filterable: false,
      sortable: false,
      disableExport: true,
    },
    {
      field: 'companyName',
      headerName: '事業所',
      flex: 1,
    },
    { field: 'name', headerName: '名前', flex: 4 },
    {
      field: 'adresses',
      headerName: '市区町村名',
      flex: 4,
      type: 'string',
      valueGetter: (params: { row: AreaEntity }) => params.row.areaAddressesAttributes.map((it) => it.address).join(', '),
      filterable: false,
      sortable: false,
    },
  ];
