import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { AsyncPlanningResponseEntity, PlanningResponseEntity } from 'src/entities/planningResponseEntity';

const requestPath = (request: AsyncPlanningResponseEntity) => {
  let query = '';
  if (request.session_id) {
    query = `session_id=${request.session_id}`;
  } else if (request.respect_recent_id) {
    query = `respect_recent_id=${request.respect_recent_id}`;
  } else if (request.scenario_planning_id) {
    query = `scenario_planning_id=${request.scenario_planning_id}`;
  }
  return `/api/v3/plannings/result?${query}`;
};

const getAll = (request: AsyncPlanningResponseEntity) => async () => {
  const response: AxiosResponse<PlanningResponseEntity> = await axios.get(requestPath(request));

  return response.data;
};

export const useQueryPlanningsResults = (request: AsyncPlanningResponseEntity) => useQuery(
  ['useQueryPlanningsResults', { sessionId: request?.session_id, respectRecentId: request?.respect_recent_id, scenarioPlanningId: request?.scenario_planning_id }],
  getAll(request),
  {
    onError: (e: AxiosError<{ message: string; }>) => e,
    refetchInterval: 1 * 1000,
    enabled: request != null,
  }
);
