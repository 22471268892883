import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates, useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import ExploreRoundedIcon from '@mui/icons-material/ExploreRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {
  Button, Checkbox, Dialog,
  DialogActions,
  DialogTitle, Divider, FormControl,
  FormControlLabel, IconButton,
  InputAdornment, InputLabel, OutlinedInput, Paper,
  Switch,
  Theme,
  ThemeOptions,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Breakpoint, Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useState } from 'react';
import LicenseContext from 'src/contexts/LicenseContext';
import { DirectionDisplaySettingEntity } from 'src/entities/DirectionDisplaySetting.entity';

const Card: FC<{
  setting: DirectionDisplaySettingEntity
}> = memo((
  {
    setting,
  }
) => {
  const {
    setActivatorNodeRef,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: setting.id });

  return (
    <Stack
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'grabbing',
      }}
      {...attributes}
      {...listeners}
    >
      <Paper
        sx={{
          p: 1,
          width: '100%',
        }}
        ref={setActivatorNodeRef}
        square
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          {setting.title}
          <DragIndicatorRoundedIcon
            sx={{
              cursor: 'grab',
            }}
            color="secondary"
          />
        </Stack>
      </Paper>
    </Stack>
  );
});

type Props = {
  open: boolean;
  onClose: () => void;
  width: Breakpoint;
  navigateToPath: string;
}

const DirectionSettingDialog: FC<Props> = memo((
  {
    open,
    onClose,
    width,
    navigateToPath,
  }
) => {
  const theme: Theme & ThemeOptions = useTheme();
  const licenseContext = useContext(LicenseContext);
  const { enqueueSnackbar } = useSnackbar();

  const searchBoxHeight = 55;
  const contentHeight = '70vh';
  const maximumNumberOfDisplaySettings = 15;

  const selectableDisplayDirectionConditionSettings = licenseContext?.selectableDisplayDirectionConditionSettings || [];
  let settings = licenseContext?.displayDirectionConditions?.find((it) => it.name === 'default')?.settings || [];
  if (selectableDisplayDirectionConditionSettings.length > 0 && settings.length > 0) {
    const ids = selectableDisplayDirectionConditionSettings.map((it) => it.id);
    settings = settings.filter((it) => ids.includes(it.id));
  }

  const canNavigate = !!settings.length;
  const numberOfDisplaySettings = settings.length;
  const canAddDisplaySettings = numberOfDisplaySettings < maximumNumberOfDisplaySettings;

  const [searchKw, setSearchKw] = useState<string | null>(null);
  const [displayOnlyUnloading, setDisplayOnlyUnloading] = useState<boolean>(false);

  const navigateButtonOnClick = () => {
    const path = [
      `${navigateToPath}`,
      displayOnlyUnloading ? 'action=降' : null
    ].filter((it) => it).join('&');

    window.open(path, '_blank');
  };

  const displayOnlyUnloadingOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayOnlyUnloading(event.target.checked);
  };

  const displayDirectionConditionCardOnClick = (setting: DirectionDisplaySettingEntity) => {
    if (!licenseContext?.displayDirectionConditions) return;

    const settingIsExists = settings.some((it) => it.id === setting.id);

    if (settingIsExists) {
      const value = {
        name: 'default',
        settings: settings.filter((it) => it.id !== setting.id)
      };

      licenseContext.updateDisplayDirectionCondition(value);
    } else {
      if (!canAddDisplaySettings) {
        enqueueSnackbar(`表示項目は${maximumNumberOfDisplaySettings}個までです。`, {
          variant: 'warning',
        });

        return;
      }

      const value = {
        name: 'default',
        settings: [
          setting,
          ...settings,
        ]
      };

      licenseContext.updateDisplayDirectionCondition(value);
    }
  };

  const updateSearchKw = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setSearchKw(null);
      return;
    }

    setSearchKw(event.target.value);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      fullWidth
      maxWidth={width}
    >
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.colors.alpha.trueWhite[100],
        }}
      >
        表示項目設定
      </DialogTitle>
      <Stack
        direction="row"
        sx={{
          height: `calc(${contentHeight} - ${searchBoxHeight}px)`,
        }}
        justifyContent="space-between"
      >
        <Stack
          flexGrow={1}
        >
          <Stack
            p={1}
            sx={{
              height: `${searchBoxHeight}px`,
            }}
          >
            <FormControl variant="outlined">
              <InputLabel
                size="small"
                htmlFor="direction-display-settings-search"
              >
                検索
              </InputLabel>
              <OutlinedInput
                id="direction-display-settings-search"
                onChange={updateSearchKw}
                size="small"
                value={searchKw || ''}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setSearchKw(null);
                      }}
                      edge="end"
                      disabled={!searchKw}
                    >
                      <HighlightOffRoundedIcon color={searchKw ? 'error' : 'secondary'} />
                    </IconButton>
                  </InputAdornment>
                )}
                label="検索"
              />
            </FormControl>
          </Stack>
          <Stack
            divider={<Divider />}
            sx={{
              height: contentHeight,
              overflowY: 'scroll'
            }}
          >
            {
              selectableDisplayDirectionConditionSettings
                .filter((it) => {
                  if (!searchKw) return true;

                  return it.title.includes(searchKw);
                })
                .map(((selectableSetting) => (
                  <Stack
                    key={[
                      'directionSettingDialog',
                      'selectableDisplayDirectionConditionSettings',
                      selectableSetting.id
                    ].join('-')}
                    direction="row"
                    alignItems="center"
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      displayDirectionConditionCardOnClick(selectableSetting);
                    }}
                  >
                    <Checkbox
                      checked={
                        settings.some((item) => item.id === selectableSetting.id)
                      }
                      tabIndex={-1}
                      disableRipple
                    />
                    {selectableSetting.title}
                  </Stack>
                )))
            }
          </Stack>
        </Stack>
        <Stack
          flexGrow={1}
        >
          <Stack
            justifyContent="center"
            alignItems="flex-start"
            sx={{
              height: `${searchBoxHeight}px`,
              p: 1
            }}
          >
            <Typography>
              {
                [
                  '表示項目',
                  `${numberOfDisplaySettings} / ${maximumNumberOfDisplaySettings}項目`,
                ].join(' ')
              }
            </Typography>
          </Stack>
          <Stack
            spacing={1}
            sx={{
              height: `calc(${contentHeight} - ${searchBoxHeight}px)`,
              p: 1,
              overflowY: 'scroll',
              overflowX: 'hidden'
            }}
          >
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={(event) => {
                const { active, over } = event;
                if (over == null || active.id === over.id) {
                  return;
                }

                const oldIndex = settings.findIndex((item) => item.id === active.id);
                const newIndex = settings.findIndex((item) => item.id === over.id);
                const newSettings = arrayMove(settings, oldIndex, newIndex);

                const value = {
                  name: 'default',
                  settings: newSettings
                };

                licenseContext.updateDisplayDirectionCondition(value);
              }}
              modifiers={[
                restrictToVerticalAxis,
                restrictToParentElement
              ]}
            >
              <SortableContext
                items={
                  settings
                }
              >
                {
                  settings.map((setting) => (
                    <Card
                      key={
                        [
                          'directionSettingDialog',
                          'displayDirectionConditionSettings',
                          setting.id,
                        ].join('-')
                      }
                      setting={setting}
                    />
                  ))
                }
              </SortableContext>
            </DndContext>
          </Stack>
        </Stack>
      </Stack>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            width: '100%',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <FormControlLabel
              control={(
                <Switch
                  checked={displayOnlyUnloading}
                  onChange={displayOnlyUnloadingOnChange}
                />
              )}
              label="納品のみ表示する"
            />
            <Button
              variant="contained"
              startIcon={<ExploreRoundedIcon />}
              disabled={!canNavigate}
              onClick={navigateButtonOnClick}
            >
              配送指示書を表示する
            </Button>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
          >
            <Button
              sx={{
                mr: 2
              }}
              startIcon={<SaveRoundedIcon />}
              onClick={() => {
                licenseContext.saveDisplayDirectionConditions();
              }}
              disabled={settings.length === 0}
            >
              初期状態として保存
            </Button>
            <Button
              sx={{
                mr: 2
              }}
              startIcon={<RotateLeftRoundedIcon />}
              onClick={() => {
                licenseContext.resetDisplayDirectionCondition();
              }}
            >
              初期状態にリセット
            </Button>
            <Button
              sx={{
                mr: 2
              }}
              onClick={() => {
                onClose();
              }}
            >
              キャンセル
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
});

export default DirectionSettingDialog;
