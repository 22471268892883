import { format } from 'date-fns';
import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { PresenterProps } from 'src/components/PickingListComponent';

import style from './print.module.css';

const PickingListPresenter: FC<PresenterProps> = memo(({ models, date, unit }) => (
  <>
    <Helmet>
      <title>ピッキングリスト</title>
    </Helmet>
    <div className={style.main}>
      {models.map((shipper) => (
        <div className={style.page} key={shipper.shipper_name}>
          <div className={style.pageCount} />
          <h1>
            {format(new Date(date), 'yyyy-MM-dd')}
            {' '}
            集荷分 納品先毎ピッキングリスト
          </h1>
          <table key="keyTable" style={{ width: '100%', margin: '0' }}>
            <thead key="keyTHead">
              <tr className={style.headerTR}>
                <th colSpan={5} className={style.shipperName}>{shipper.shipper_name}</th>
              </tr>
              <tr key={['tr', shipper.shipper_name].join('-')}>
                <th>納品先</th>
                <th>商品名</th>
                <th>重量</th>
                <th>立米</th>
                <th>数量</th>
              </tr>
            </thead>
            <tbody key="keyTBody">
              {shipper.unloads.map((unload) => (
                <>
                  {unload.items.map((item, index) => (
                    <tr key={['tr', shipper.shipper_name, item.item_name, index].join('-')}>
                      {index === 0 && <td rowSpan={unload.items.length}>{unload.unloading_name}</td>}
                      <td>{item.item_name}</td>
                      <td className={style.textRight}>
                        {[Math.round((item.item_total_weight_g * 100) / 1000) / 100, unit].join('')}
                      </td>
                      <td className={style.textRight}>
                        {item.item_total_volume_mm3 > 0
                        ? [Math.round((item.item_total_volume_mm3 * 100) / 1000 ** 3) / 100, 'm3'].join('')
                        : ''}
                      </td>
                      <td className={style.textRight}>{item.item_count}</td>
                    </tr>
                  ))}
                  {unload.items.length > 1 ? (
                    <tr className={style.summery}>
                      <td colSpan={2}>合計</td>
                      <td className={style.textRight}>
                        {[Math.round((unload.items.reduce((prev, current) => prev + current.item_total_weight_g, 0) * 100) / 1000) / 100, unit].join('')}
                      </td>
                      <td className={style.textRight}>
                        {[Math.round((unload.items.reduce((prev, current) => prev + current.item_total_volume_mm3, 0) * 100) / 1000 ** 3) / 100, 'm3'].join('')}
                      </td>
                      <td className={style.textRight}>
                        {unload.items.reduce((prev, current) => prev + current.item_count, 0)}
                      </td>
                    </tr>
                    )
                    : ('')}
                </>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  </>
));

export default PickingListPresenter;
