import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { OperationDirectionEntity } from 'src/entities/OperationDirection.entity';
import arrayUtil from 'src/utils/array.util';

const getOperations = (ids: number[]) => async () => {
  const basePath = '/api/v4/operation_directions';
  const params = arrayUtil.convertToParams('ids', ids);
  const url = [
    basePath,
    params,
  ].join('?');

  const response = await axios.get<OperationDirectionEntity>(url);

  return response.data;
};

export const useOperationDirectionsRequestQuery = (ids: number[]) => useQuery(
  ['useOperationDirectionsRequestQuery', [ids]],
  getOperations(ids),
  {
    enabled: Boolean(ids.length),
  },
);
