import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import datetimeDecorator from 'src/decorators/datetime.decorator';

const requestPath = (startOn: Date, endOn: Date) => {
  const startDay = datetimeDecorator.toYyyyMmDd(startOn);
  const endDay = datetimeDecorator.toYyyyMmDd(endOn);
  const requestQueryParam = [
    `start_on=${startDay}`,
    `end_on=${endDay}`,
  ].join('&');
  return [
    '/api/v4/master/delete_all/orders',
    requestQueryParam,
  ].join('?');
};

export const useDeleteAllOrdersMutation = () => ({
  post: useMutation(
    (entity: { startOn: Date, endOn: Date }) => axios.post(requestPath(entity.startOn, entity.endOn)),
  ),
});
