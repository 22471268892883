import {
  Paper,
  Stack, Typography
} from '@mui/material';
import React, { FC, memo, useEffect, useState } from 'react';
import numberDecorator from 'src/decorators/number.decorator';
import { useQueryOrder } from 'src/hooks/useQueryOrders';

import datetimeDecorator from '../../../decorators/datetime.decorator';

type Props = {
  orderId: number;
  customInputFields: string[];
}

const PlanningOrderPresenter: FC<Props> = memo((
  {
    orderId,
    customInputFields,
  }
) => {
  const { data } = useQueryOrder(orderId);

  const [code, setCode] = useState('-');
  const [note, setNote] = useState('-');
  const [shipperName, setShipperName] = useState('-');
  const [phoneNumber, setPhoneNumber] = useState('-');
  const [emailAddress, setEmailAddress] = useState('-');
  const [loadingName, setLoadingName] = useState('');
  const [loadingAddress, setLoadingAddress] = useState('');
  const [loadingStartAt, setLoadingStartAt] = useState('');
  const [loadingEndAt, setLoadingEndAt] = useState('');
  const [loadingStayMinutes, setLoadingStayMinutes] = useState('- 分');
  const [unloadingName, setUnloadingName] = useState('');
  const [unloadingAddress, setUnloadingAddress] = useState('');
  const [unloadingStartAt, setUnloadingStartAt] = useState('');
  const [unloadingEndAt, setUnloadingEndAt] = useState('');
  const [unloadingStayMinutes, setUnloadingStayMinutes] = useState('- 分');
  const [itemPackingStyle, setItemPackingStyle] = useState('-');
  const [itemCount, setItemCount] = useState('-');
  const [itemTotalWeightKg, setItemTotalWeightKg] = useState('- kg');
  const [itemTotalVolumeM3, setItemTotalVolumeM3] = useState('- m3');
  const [itemKlass, setItemKlass] = useState('-');
  const [itemName, setItemName] = useState('-');
  const [itemHandlingOfCargoStyle, setItemHandlingOfCargoStyle] = useState('-');
  const [itemCanBeMixed, setItemCanBeMixed] = useState('-');
  const [designatedTruckKlass, setDesignatedTruckKlass] = useState('無し');
  const [designatedTruckCarModel, setDesignatedTruckCarModel] = useState('無し');
  const [designatedTruckLoadingPlatformHeight, setDesignatedTruckLoadingPlatformHeight] = useState('無し');
  const [designatedTruckLoadingPlatformWidth, setDesignatedTruckLoadingPlatformWidth] = useState('無し');
  const [designatedTruckLoadingPlatformLength, setDesignatedTruckLoadingPlatformLength] = useState('無し');
  const [designatedTruckFloorSpecification, setDesignatedTruckFloorSpecification] = useState('無し');
  const [designatedTruckFeature, setDesignatedTruckFeature] = useState('無し');
  const [chargeBasicFeeYen, setChargeBasicFeeYen] = useState('￥ -');
  const [chargeHighwayFeeYen, setChargeHighwayFeeYen] = useState('￥ -');
  const [chargeLoadingFeeYen, setChargeLoadingFeeYen] = useState('￥ -');
  const [chargeAncillaryFeeYen, setChargeAncillaryFeeYen] = useState('￥ -');
  const [chargeWaitingTimeFeeYen, setChargeWaitingTimeFeeYen] = useState('￥ -');
  const [chargeUnloadingFeeYen, setChargeUnloadingFeeYen] = useState('￥ -');
  const [chargeExpensesFeeYen, setChargeExpensesFeeYen] = useState('￥ -');
  const [chargeAncillaryContent, setChargeAncillaryContent] = useState('-');
  const [wasSplit, setWasSplit] = useState(false);
  const [originalWeightKg, setOriginalWeightKg] = useState('');
  const [originalVolumeM3, setOriginalVolumeM3] = useState('');
  const [originalItemCount, setOriginalItemCount] = useState('');

  useEffect(() => {
    if (!data) return;

    if (data.code) {
      setCode(
        data.code
      );
    }

    if (data.memo) {
      setNote(
        data.memo
      );
    }

    if (data.shipper_name) {
      setShipperName(
        data.shipper_name
      );
    }

    if (data.shipper_phone_number) {
      setPhoneNumber(
        data.shipper_phone_number
      );
    }

    if (data.shipper_email_address) {
      setEmailAddress(
        data.shipper_email_address
      );
    }

    if (data.loading_name) {
      setLoadingName(
        data.loading_name
      );
    }

    if (data.loading_address) {
      setLoadingAddress(
        data.loading_address
      );
    }

    if (data.loading_start_at) {
      setLoadingStartAt(
        datetimeDecorator.toDayAndHourMinutes(
          new Date(data.loading_start_at)
        )
      );
    }

    if (data.loading_end_at) {
      setLoadingEndAt(
        datetimeDecorator.toDayAndHourMinutes(
          new Date(data.loading_end_at)
        )
      );
    }

    if (data.loading_staying_minutes) {
      setLoadingStayMinutes(
        numberDecorator.toMinutes(
          data.loading_staying_minutes
        )
      );
    }

    if (data.unloading_name) {
      setUnloadingName(
        data.unloading_name
      );
    }

    if (data.unloading_address) {
      setUnloadingAddress(
        data.unloading_address
      );
    }

    if (data.unloading_start_at) {
      setUnloadingStartAt(
        datetimeDecorator.toDayAndHourMinutes(
          new Date(data.unloading_start_at)
        )
      );
    }

    if (data.unloading_end_at) {
      setUnloadingEndAt(
        datetimeDecorator.toDayAndHourMinutes(
          new Date(data.unloading_end_at)
        )
      );
    }

    if (data.unloading_staying_minutes) {
      setUnloadingStayMinutes(
        numberDecorator.toMinutes(
          data.unloading_staying_minutes
        )
      );
    }

    if (data.item_packing_style) {
      setItemPackingStyle(
        data.item_packing_style
      );
    }

    if (data.item_count) {
      setItemCount(
        data.item_count.toLocaleString()
      );
    }

    if (data.item_total_weight_kg) {
      setItemTotalWeightKg(
        numberDecorator.toRoundedUnit(
          data.item_total_weight_kg,
          'kg',
          1,
          ''
        )
      );
    }

    if (data.item_total_volume_m3) {
      setItemTotalVolumeM3(
        numberDecorator.toRoundedUnit(
          data.item_total_volume_m3,
          'm3',
          1,
          ''
        )
      );
    }

    if (data.item_klass) {
      setItemKlass(
        data.item_klass
      );
    }

    if (data.item_name) {
      setItemName(
        data.item_name
      );
    }

    if (data.item_handling_of_cargo_style) {
      setItemHandlingOfCargoStyle(
        data.item_handling_of_cargo_style
      );
    }

    setItemCanBeMixed(
      data.item_can_be_mixed ? '可' : '不可'
    );

    if ([data.designated_truck_klasses, data.designated_truck_klasses.length].every((maybe) => maybe)) {
      setDesignatedTruckKlass(
        data.designated_truck_klasses.join(', ')
      );
    }

    if ([data.designated_truck_car_models, data.designated_truck_car_models.length].every((maybe) => maybe)) {
      setDesignatedTruckCarModel(
        data.designated_truck_car_models.join('-')
      );
    }

    if ([data.designated_truck_loading_platform_heights, data.designated_truck_loading_platform_heights.length].every((maybe) => maybe)) {
      setDesignatedTruckLoadingPlatformHeight(
        data.designated_truck_loading_platform_heights.join('-')
      );
    }

    if ([data.designated_truck_loading_platform_widths, data.designated_truck_loading_platform_widths.length].every((maybe) => maybe)) {
      setDesignatedTruckLoadingPlatformWidth(
        data.designated_truck_loading_platform_widths.join('-')
      );
    }

    if ([data.designated_truck_loading_platform_lengths, data.designated_truck_loading_platform_lengths.length].every((maybe) => maybe)) {
      setDesignatedTruckLoadingPlatformLength(
        data.designated_truck_loading_platform_lengths.join('-')
      );
    }

    if ([data.designated_truck_floor_specifications, data.designated_truck_floor_specifications.length].every((maybe) => maybe)) {
      setDesignatedTruckFloorSpecification(
        data.designated_truck_floor_specifications.join('-')
      );
    }

    if ([data.designated_truck_features, data.designated_truck_features.length].every((maybe) => maybe)) {
      setDesignatedTruckFeature(
        data.designated_truck_features.join('-')
      );
    }

    if (data.charge_basic_fee_yen) {
      setChargeBasicFeeYen(
        numberDecorator.toYen(
          data.charge_basic_fee_yen
        )
      );
    }

    if (data.charge_highway_fee_yen) {
      setChargeHighwayFeeYen(
        numberDecorator.toYen(
          data.charge_highway_fee_yen
        )
      );
    }

    if (data.charge_loading_fee_yen) {
      setChargeLoadingFeeYen(
        numberDecorator.toYen(
          data.charge_loading_fee_yen
        )
      );
    }

    if (data.charge_ancillary_fee_yen) {
      setChargeAncillaryFeeYen(
        numberDecorator.toYen(
          data.charge_ancillary_fee_yen
        )
      );
    }

    if (data.charge_waiting_time_fee_yen) {
      setChargeWaitingTimeFeeYen(
        numberDecorator.toYen(
          data.charge_waiting_time_fee_yen
        )
      );
    }

    if (data.charge_unloading_fee_yen) {
      setChargeUnloadingFeeYen(
        numberDecorator.toYen(
          data.charge_unloading_fee_yen
        )
      );
    }

    if (data.charge_expenses_fee_yen) {
      setChargeExpensesFeeYen(
        numberDecorator.toYen(
          data.charge_expenses_fee_yen
        )
      );
    }

    if (data.charge_ancillary_content) {
      setChargeAncillaryContent(
        data.charge_ancillary_content
      );
    }

    if (data.was_split) {
      setWasSplit(data.was_split);
      const orderSplit = data.order_split;
      if (orderSplit.original_item_total_weight_kg) {
        setOriginalWeightKg(
          numberDecorator.toRoundedUnit(
            orderSplit.original_item_total_weight_kg,
            'kg',
            1,
            ''
          )
        );
      }
      if (orderSplit.original_item_total_volume_m3) {
        setOriginalVolumeM3(
          numberDecorator.toRoundedUnit(
            orderSplit.original_item_total_volume_m3,
            'm3',
            1,
            ''
          )
        );
      }
      if (orderSplit.original_item_count) {
        setOriginalItemCount(
          orderSplit.original_item_count.toLocaleString()
        );
      }
    }
  }, [data]);

  const customFields = data ? customInputFields.map((it) => ({
    key: it,
    value: data.custom_input_fields.find(({ key }) => key === it)?.value || '',
  })) : [];

  return (
    <Paper>
      <Stack
        spacing={1}
        p={1}
      >
        <Paper
          square
        >
          <Stack
            p={1}
            spacing={1}
          >
            <Stack>
              <Typography>
                注文番号
              </Typography>
              <Typography>
                {code}
              </Typography>
            </Stack>
            <Stack>
              <Typography>
                備考
              </Typography>
              <Typography>
                {note}
              </Typography>
            </Stack>
            {!!customFields.length && (
              <Stack
                direction={{
                  sm: 'column',
                  md: 'row'
                }}
                gap={1}
                spacing={1}
              >
                {
                  customFields.map(({ key, value }) => (
                    <Stack
                      key={[
                        'customFields',
                        key
                      ].join('-')}
                    >
                      <Typography>
                        {key}
                      </Typography>
                      <Typography>
                        {value || '-'}
                      </Typography>
                    </Stack>
                  ))
                }
              </Stack>
            )}
          </Stack>
        </Paper>

        <Paper
          square
        >
          <Stack
            p={1}
            spacing={1}
          >
            <Stack>
              <Typography>
                荷主名
              </Typography>
              <Typography>
                {shipperName}
              </Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Stack>
                <Typography>
                  電話番号
                </Typography>
                <Typography>
                  {phoneNumber}
                </Typography>
              </Stack>
              <Stack>
                <Typography>
                  メールアドレス
                </Typography>
                <Typography>
                  {emailAddress}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Paper>

        <Paper
          square
        >
          <Stack
            p={1}
            spacing={1}
          >
            <Stack>
              <Typography>
                積地
              </Typography>
              <Typography>
                {loadingName}
              </Typography>
              <Typography>
                {loadingAddress}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              gap={1}
              alignItems="baseline"
            >
              <Typography>
                {
                  [
                    loadingStartAt,
                    loadingEndAt,
                  ].join(' - ')
                }
              </Typography>
              <Typography>
                {loadingStayMinutes}
              </Typography>
            </Stack>
          </Stack>
        </Paper>

        <Paper
          square
        >
          <Stack
            p={1}
            spacing={1}
          >
            <Stack>
              <Typography>
                降地
              </Typography>
              <Typography>
                {unloadingName}
              </Typography>
              <Typography>
                {unloadingAddress}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              gap={1}
              alignItems="baseline"
            >
              <Typography>
                {
                  [
                    unloadingStartAt,
                    unloadingEndAt,
                  ].join(' - ')
                }
              </Typography>
              <Typography>
                {unloadingStayMinutes}
              </Typography>
            </Stack>
          </Stack>
        </Paper>

        <Paper
          square
        >
          <Stack
            p={1}
            spacing={1}
          >
            <Stack
              direction="row"
              gap={1}
            >
              <Typography>
                数量
              </Typography>
              <Typography>
                {itemCount}
              </Typography>
              <Typography>
                重量
              </Typography>
              <Typography>
                {itemTotalWeightKg}
              </Typography>
              <Typography>
                立米
              </Typography>
              <Typography>
                {itemTotalVolumeM3}
              </Typography>
              {wasSplit && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ color: '#777' }}
                >
                  <Typography>
                    (分割前の情報:
                  </Typography>
                  <Typography>
                    数量
                  </Typography>
                  <Typography>
                    {originalItemCount}
                  </Typography>
                  <Typography>
                    重量
                  </Typography>
                  <Typography>
                    {originalWeightKg}
                    {!originalVolumeM3 && (
                      <>
                        )
                      </>
                    )}
                  </Typography>
                  {originalVolumeM3 && (
                    <>
                      <Typography>
                        立米
                      </Typography>
                      <Typography>
                        {originalVolumeM3}
                        )
                      </Typography>
                    </>
                  )}
                </Stack>
              )}
            </Stack>
            <Stack direction="row" gap={1} alignItems="baseline">
              <Typography>
                荷姿
              </Typography>
              <Typography>
                {itemPackingStyle}
              </Typography>
              <Typography>
                輸送区分
              </Typography>
              <Typography>
                {itemKlass}
              </Typography>
              <Typography>
                品名
              </Typography>
              <Typography>
                {itemName}
              </Typography>
              <Typography>
                荷扱
              </Typography>
              <Typography>
                {itemHandlingOfCargoStyle}
              </Typography>
              <Typography>
                積み合わせ可否
              </Typography>
              <Typography>
                {itemCanBeMixed}
              </Typography>
            </Stack>
          </Stack>
        </Paper>

        <Paper
          square
        >
          <Stack
            p={1}
            spacing={1}
          >
            <Stack>
              <Typography>
                指定車両タイプ
              </Typography>
              <Typography>
                {designatedTruckKlass}
              </Typography>
            </Stack>
            <Stack>
              <Typography>
                指定車種
              </Typography>
              <Typography>
                {designatedTruckCarModel}
              </Typography>
            </Stack>
            <Stack>
              <Typography>
                荷台高さ
              </Typography>
              <Typography>
                {designatedTruckLoadingPlatformHeight}
              </Typography>
            </Stack>
            <Stack>
              <Typography>
                荷台幅
              </Typography>
              <Typography>
                {designatedTruckLoadingPlatformWidth}
              </Typography>
            </Stack>
            <Stack>
              <Typography>
                荷台長さ
              </Typography>
              <Typography>
                {designatedTruckLoadingPlatformLength}
              </Typography>
            </Stack>
            <Stack>
              <Typography>
                床仕様
              </Typography>
              <Typography>
                {designatedTruckFloorSpecification}
              </Typography>
            </Stack>
            <Stack>
              <Typography>
                装置
              </Typography>
              <Typography>
                {designatedTruckFeature}
              </Typography>
            </Stack>
          </Stack>
        </Paper>

        <Paper
          square
        >
          <Stack
            p={1}
            spacing={1}
          >
            <Stack
              direction="row"
              gap={1}
            >
              <Typography>
                基本運賃
              </Typography>
              <Typography>
                {chargeBasicFeeYen}
              </Typography>
              <Typography>
                高速代
              </Typography>
              <Typography>
                {chargeHighwayFeeYen}
              </Typography>
              <Typography>
                積込料
              </Typography>
              <Typography>
                {chargeLoadingFeeYen}
              </Typography>
              <Typography>
                付帯業務料
              </Typography>
              <Typography>
                {chargeAncillaryFeeYen}
              </Typography>
              <Typography>
                待機時間料
              </Typography>
              <Typography>
                {chargeWaitingTimeFeeYen}
              </Typography>
              <Typography>
                取卸料
              </Typography>
              <Typography>
                {chargeUnloadingFeeYen}
              </Typography>
              <Typography>
                諸経費
              </Typography>
              <Typography>
                {chargeExpensesFeeYen}
              </Typography>
            </Stack>
            <Stack>
              <Typography>
                付帯業務内容
              </Typography>
              <Typography>
                {chargeAncillaryContent}
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Paper>
  );
});

export default PlanningOrderPresenter;
