import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { PlanningsNotAllocReason } from 'src/entities/PlanningsNotAllocReasons.entity';

const requestMemberPath = (sessionId: string) => `/api/v3/plannings/not_alloc_reasons/${sessionId}`;

const getEntity = (sessionId: string) => async () => {
  const response: AxiosResponse<PlanningsNotAllocReason[]> = await axios.get(requestMemberPath(sessionId));
  return response.data;
};

export const useQueryPlanningsNotAllocReasons = (sessionId: string) => useQuery(
  ['useQueryPlanningsNotAllocReasons', { sessionId }],
  getEntity(sessionId),
  {
    staleTime: Infinity,
    enabled: Boolean(sessionId)
  }
);
