import { FC, memo, ReactNode, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import AdminContext from 'src/contexts/AdminContext';

type Props = {
  children: ReactNode;
};

const AdminDefaultLayout: FC<Props> = memo(({ children }) => {
  const adminContext = useContext(AdminContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!adminContext.initialized) return;

    if (adminContext.authenticated) return;

    navigate('/admin/login');
  }, [adminContext.authenticated, adminContext.initialized]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
});

export default AdminDefaultLayout;
