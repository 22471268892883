import { Stack, Typography } from '@mui/material';
import Leaflet from 'leaflet';
import { FC, memo } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import { GarageEntity } from 'src/entities/Garage.entity';
import { PositionEntity } from 'src/entities/PositionEntity';

type Props = {
  position: PositionEntity;
  garage: GarageEntity;
}

const PlanningMapGarageMarkerPresenter: FC<Props> = memo((
  {
    position,
    garage,
  }
) => {
  const icon = new Leaflet.Icon({
    iconUrl: '/static/images/markers/gray.png',
    iconSize: [24, 42],
    iconAnchor: [12, 42],
    tooltipAnchor: [-12, -42],
  });

  if (!garage) return null;

  return (
    <Marker
      position={position}
      icon={icon}
    >
      <Tooltip
        offset={
          [24, 21]
        }
      >
        <Stack>
          <Typography>{garage.name}</Typography>
          <Typography>{garage.address}</Typography>
        </Stack>
      </Tooltip>
    </Marker>
  );
});

export default PlanningMapGarageMarkerPresenter;
