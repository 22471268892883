import { QueryClient, useQueryClient, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { PickingList } from 'src/entities/pickingListEntity';

const getPickingList = (queryClient: QueryClient, shiftIds: number[]) => async () => {
  const res = await axios.get<ApiResponse>(`/api/v3/picking_list?shift_ids=${shiftIds.join(',')}`);
  return res.data.data as unknown as PickingList[];
};

export const useQueryPickingList = (shiftIds: number[]) => {
  const queryClient = useQueryClient();
  return useQuery(['pickingLists'], getPickingList(queryClient, shiftIds), {
    staleTime: Infinity
  });
};
