import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { addDays } from 'date-fns';
import { format } from 'date-fns-tz';
import { DriverShiftRequestEntity, DriverShiftSummaryEntity } from 'src/entities/driverShiftEntity';

const getDriverShifts = (queryClient: QueryClient, driverId: number, date: Date) => async () => {
  const res = await axios.get<DriverShiftSummaryEntity>(
    `/api/v2/drivers/${driverId}/shifts?around=${format(date, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' })}`
  );
  return res.data;
};

export const useQueryDriverShifts = (driverId: number, date: Date) => {
  const queryClient = useQueryClient();
  return useQuery(['driverShifts', driverId, date], getDriverShifts(queryClient, driverId, date), {
    staleTime: Infinity
  });
};

const buildRequest = (shift: DriverShiftRequestEntity) => {
  const startAt = new Date(`${shift.on} ${shift.start_at}`);
  const endAt = new Date(`${shift.on} ${shift.end_at}`);
  const workingAvailableDurationHours = shift.workingAvailableDurationHours || null;

  if (startAt < endAt) {
    return {
      driver_id: shift.driver_id,
      start_at: format(
        startAt,
        'yyyy-MM-dd HH:mm:ss xxx',
        {
          timeZone: 'Asia/Tokyo'
        }
      ),
      end_at: format(
        endAt,
        'yyyy-MM-dd HH:mm:ss xxx',
        {
          timeZone: 'Asia/Tokyo'
        }
      ),
      working_available_duration_hours: workingAvailableDurationHours,
    };
  }
    return {
      driver_id: shift.driver_id,
      start_at: format(
        startAt,
        'yyyy-MM-dd HH:mm:ss xxx',
        {
          timeZone: 'Asia/Tokyo'
        }
      ),
      end_at: format(
        addDays(endAt, 1),
        'yyyy-MM-dd HH:mm:ss xxx',
        {
          timeZone: 'Asia/Tokyo'
        }
      ),
      working_available_duration_hours: workingAvailableDurationHours,
    };
};

export const useMutationDriverShift = () => {
  const queryClient = useQueryClient();
  return {
    addDriverShift: useMutation(
      (shift: DriverShiftRequestEntity) => axios.post('/api/v2/shifts', buildRequest(shift)),
      {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        onSuccess: (res) => queryClient.invalidateQueries(['driverShifts', res.data.driver_id])
      }
    ),
    updateDriverShift: useMutation(
      (shift: DriverShiftRequestEntity) => axios.patch(`/api/v2/shifts/${shift.id}`, buildRequest(shift)),
      {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        onSuccess: (res) => queryClient.invalidateQueries(['driverShifts', res.data.driver_id])
      }
    ),
    deleteDriverShift: useMutation(
      (id: number) => axios.delete(`/api/v2/shifts/${id}`),
      {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        onSuccess: (res) => queryClient.invalidateQueries(['driverShifts', res.data.driver_id])
      }
    )
  };
};

export const useMutationShiftClone = () => {
  const queryClient = useQueryClient();
  return {
    cloneLastWeekShifts: useMutation(
      (shiftDay: string) => axios.post(`/api/v2/shifts/${shiftDay}/clone`),
      {
        onSuccess: () => queryClient.invalidateQueries(['driverShifts'])
      }
    )
  };
};

export const useMutationShiftBulk = () => {
  const queryClient = useQueryClient();
  return {
    createShiftsBulk: useMutation(
      (shift: DriverShiftRequestEntity) => axios.post(`/api/v2/shifts/${shift.on}/bulk`, { shift: buildRequest(shift) }),
      {
        onSuccess: () => queryClient.invalidateQueries(['driverShifts'])
      }
    ),
    deleteShiftsBulk: useMutation(
      (shiftDay: string) => axios.delete(`/api/v2/shifts/${shiftDay}/bulk`),
      {
        onSuccess: () => queryClient.invalidateQueries(['driverShifts'])
      }
    )
  };
};

const buildWeekRequest = (shift: DriverShiftRequestEntity) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const shifts = [...new Array(7)].map((_, idx) => format(addDays(new Date(shift.on), idx), 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' }))
  .map((day: string) => {
    const tmp: DriverShiftRequestEntity = {
      on: day,
      driver_id: shift.driver_id,
      start_at: shift.start_at,
      end_at: shift.end_at,
      workingAvailableDurationHours: shift.workingAvailableDurationHours
    };
    return buildRequest(tmp);
  });
  return {
    shift: {
      shifts
    }
  };
};

export const useMutationDriverShiftBulk = () => {
  const queryClient = useQueryClient();
  return {
    createDriverShiftBulk: useMutation(
      (shift: DriverShiftRequestEntity) => axios.post(`/api/v2/drivers/${shift.driver_id}/shifts`, buildWeekRequest(shift)),
      {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        onSuccess: (res) => queryClient.invalidateQueries(['driverShifts', res.data.driver_id])
      }
    ),
    deleteDriverShiftBulk: useMutation(
      (shift: DriverShiftRequestEntity) => axios.delete(`/api/v2/drivers/${shift.driver_id}/shifts/${shift.on}`),
      {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        onSuccess: (res) => queryClient.invalidateQueries(['driverShifts', res.data.driver_id])
      }
    )
  };
};
