import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Button, Checkbox, Collapse, IconButton, TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { Stack } from '@mui/system';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DriverEntity } from 'src/entities/Driver.entity';
import { SelectedShiftCell } from 'src/entities/SelectedShiftCell.entity';
import { TruckEntity } from 'src/entities/Truck.entity';

import datetimeDecorator from '../../../decorators/datetime.decorator';

import BodyCellsPresenter from './BodyCells.presenter';
import { EditPropsBase, RequestEntity } from './EditDialog.presenter';
import StatisticPresenter from './Statistic.presenter';

type Props = {
  daysOfWeek: Date[];
  truckData: TruckEntity[];
  driver: DriverEntity;
  isLoading: boolean;
  expandAll: boolean;
  updateEditPropsBase: (props: EditPropsBase) => void;
  defaultStartAt: string;
  defaultEndAt: string;
  defaultWorkingAvailableDurationHours: number | undefined;
  selectedShiftCell: SelectedShiftCell[];
  updateSelectedShiftCell: (cell: SelectedShiftCell[], checked: boolean) => void;
  appendDeliveryExistingCells: (cells: SelectedShiftCell[]) => void;
}

const BodyRowPresenter: FC<Props> = memo((
  {
    daysOfWeek,
    truckData,
    driver,
    isLoading,
    expandAll,
    updateEditPropsBase,
    defaultStartAt,
    defaultEndAt,
    defaultWorkingAvailableDurationHours,
    selectedShiftCell,
    updateSelectedShiftCell,
    appendDeliveryExistingCells,
  }
) => {
  const [collapseIsOpen, setCollapseIsOpen] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [defaultRequestEntities, setDefaultRequestEntities] = useState<RequestEntity[]>([]);
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<'every' | 'some' | 'none'>('none');

  const onClickCheckbox = useCallback(() => {
    updateSelectedShiftCell(daysOfWeek.map((it) => ({ driver_id: driver.id, date: it })), !checked);
    setChecked((prev) => !prev);
  }, [checked, daysOfWeek, driver, updateSelectedShiftCell]);

  useEffect(() => {
    const filtered = daysOfWeek.filter((it) => selectedShiftCell.find((elem) => elem.driver_id === driver.id && elem.date === it));
    if (filtered.length === 0) {
      setSelectedStatus((prev) => (prev !== 'none' ? 'none' : prev));
      if (checked) setChecked((prev) => !prev);
    } else if (filtered.length === daysOfWeek.length) {
      setSelectedStatus((prev) => (prev !== 'every' ? 'every' : prev));
      if (!checked) setChecked((prev) => !prev);
    } else {
      setSelectedStatus((prev) => (prev !== 'some' ? 'some' : prev));
    }
  }, [checked, daysOfWeek, driver, selectedShiftCell]);

  const collapseButtonOnClick = useCallback(() => {
    setCollapseIsOpen(
      !collapseIsOpen
    );
  }, [collapseIsOpen]);

  const buttonOnClick = useCallback(() => {
    const shiftCell: SelectedShiftCell[] = daysOfWeek.map((it) => ({ driver_id: driver.id, date: it }));

    updateEditPropsBase({
      dialogTitle,
      defaultRequestEntities,
      driver,
      truckData,
      date: daysOfWeek[6],
      defaultStartAt,
      defaultEndAt,
      defaultWorkingAvailableDurationHours,
      shiftCell,
    });
  }, [daysOfWeek, defaultEndAt, defaultRequestEntities, defaultStartAt, defaultWorkingAvailableDurationHours, dialogTitle, driver, truckData, updateEditPropsBase]);

  useEffect(() => {
    if (!driver) return;
    if (!daysOfWeek) return;
    if (daysOfWeek.length < 2) return;

    const firstDay = datetimeDecorator.toDayAndDayOfTheWeek(daysOfWeek[0]);
    const lastDay = datetimeDecorator.toDayAndDayOfTheWeek([...daysOfWeek].slice(-1)[0]);

    const week = [
      firstDay,
      lastDay,
    ].join(' - ');

    setDialogTitle(
      [
        week,
        `${driver.name}`
      ].join(' ')
    );
  }, [daysOfWeek, driver]);

  useEffect(() => {
    if (!driver) return;

    setDefaultRequestEntities(
      [
        {
          startAt: defaultStartAt,
          endAt: defaultEndAt,
          workingAvailableDurationHours: defaultWorkingAvailableDurationHours,
          truckId: driver.defaultTruckId,
        }
      ]
    );
  }, [defaultEndAt, defaultStartAt, defaultWorkingAvailableDurationHours, driver]);

  useEffect(() => {
    setCollapseIsOpen(expandAll);
  }, [expandAll]);

  const contentsCellMemo = useMemo(() => (
    <TableCell component="th" scope="row" style={{ position: 'sticky', left: 0, zIndex: 2, backgroundColor: 'white' }}>
      <Stack direction="row" alignItems="left" justifyContent="flex-start">
        <IconButton
          aria-label="expand row"
          size="small"
          sx={{ width: '30px' }}
          onClick={collapseButtonOnClick}
        >
          {collapseIsOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </IconButton>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Checkbox
            checked={selectedStatus === 'every'}
            indeterminate={selectedStatus === 'some'}
            onClick={onClickCheckbox}
            disabled={isLoading || !driver.isActive}
          />
          <Button
            onClick={buttonOnClick}
            disabled={isLoading || !driver.isActive}
          >
            {driver.name}
          </Button>
        </Stack>
      </Stack>
    </TableCell>
  ), [buttonOnClick, collapseButtonOnClick, collapseIsOpen, driver, isLoading, onClickCheckbox, selectedStatus]);

  return (
    <>
      <TableRow>
        {contentsCellMemo}
        <BodyCellsPresenter
          daysOfWeek={daysOfWeek}
          truckData={truckData}
          driver={driver}
          isLoading={isLoading}
          updateEditPropsBase={updateEditPropsBase}
          defaultStartAt={defaultStartAt}
          defaultEndAt={defaultEndAt}
          defaultWorkingAvailableDurationHours={defaultWorkingAvailableDurationHours}
          selectedShiftCell={selectedShiftCell.filter((it) => (it.driver_id === driver.id))}
          updateSelectedShiftCell={updateSelectedShiftCell}
          appendDeliveryExistingCells={appendDeliveryExistingCells}
        />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9} align="left">
          <Collapse in={collapseIsOpen} timeout="auto" unmountOnExit>
            <StatisticPresenter
              driver={driver}
              startOfWeek={daysOfWeek[0]}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
});

export default BodyRowPresenter;
