import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { LicenseEntity, LicenseRequestEntity, LicenseResponseEntity } from 'src/entities/admin/licenseEntity';

const baseUrl = (companyId: number | string) => `/api/admin/v1/companies/${companyId}/licenses/`;

const getLicenses = (queryClient: QueryClient, companyId: number | string, page: number, pageSize: number) => async () => {
  const res = await axios.get<ApiResponse>(`${baseUrl(companyId)}?page=${page + 1}&per=${pageSize}`);
  const entity = res.data.data as unknown as LicenseResponseEntity;
  const total = res.data.total_pages_count;
  return { entity, total };
};

export const useQueryLicenses = (companyId: number | string, page: number, pageSize: number) => {
  const queryClient = useQueryClient();
  return useQuery(['licenses', { companyId, page, pageSize }], getLicenses(queryClient, companyId, page, pageSize), {
    staleTime: Infinity
  });
};

const getLicense = (companyId: number | string, id: number) => async () => {
  const res = await axios.get<ApiResponse>(`${baseUrl(companyId)}${id}`);
  return res.data as unknown as LicenseEntity;
};

export const useQueryLicense = (companyId: number | string, id: number) => {
  const queryClient = useQueryClient();
  return useQuery(['licenses', { companyId, id }], getLicense(companyId, id), {
    staleTime: Infinity,
    enabled: Boolean(id)
  });
};

export const useMutationLicense = () => {
  const queryClient = useQueryClient();
  return {
    addLicense: useMutation(
      (license: LicenseRequestEntity) => axios.post(baseUrl(license.company_id), license),
      {
        onSuccess: () => queryClient.invalidateQueries(['licenses'])
      }
    ),
    updateLicense: useMutation(
      (license: LicenseEntity) => axios.put(`${baseUrl(license.company_id)}${license.id}`, license),
      {
        onSuccess: () => queryClient.invalidateQueries(['licenses'])
      }
    ),
    deleteLicense: useMutation(
      (license: LicenseEntity) => axios.delete(`${baseUrl(license.company_id)}${license.id}`),
      {
        onSuccess: () => queryClient.invalidateQueries(['licenses'])
      }
    ),
  };
};
