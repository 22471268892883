import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';

const requestCollectionPath = (startOn: string, endOn: string) => `/api/v3/plannings/${startOn}/deliveries?end_on=${endOn}`;
const requestMemberPath = (id: number) => `/api/v3/plannings/deliveries/${id}`;

const getEntities = (startOn: string, endOn: string, queryClient: QueryClient) => async () => {
  const response: AxiosResponse<PlanningsDeliveryEntity[]> = await axios.get(requestCollectionPath(startOn, endOn));

  return response.data.map((entity) => {
    queryClient.setQueryData(['useQueryPlanningsDelivery', { id: entity.id }], entity);

    return entity;
  });
};

const getEntity = (id: number) => async () => {
  const response: AxiosResponse<PlanningsDeliveryEntity> = await axios.get(requestMemberPath(id));

  return response.data;
};

export const useQueryPlanningsDeliveries = (startOn: string, endOn: string) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['useQueryPlanningsDeliveries', { startOn, endOn }],
    getEntities(startOn, endOn, queryClient),
    {
      staleTime: Infinity,
      enabled: Boolean(startOn) && Boolean(endOn)
    }
  );
};

export const useQueryPlanningsDelivery = (id: number) => useQuery(
  ['useQueryPlanningsDelivery', { id }],
  getEntity(id),
  {
    staleTime: Infinity,
    enabled: Boolean(id)
  }
);
