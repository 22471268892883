import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns-tz';
import { ApiResponse } from 'src/constants/apiResponse';
import { KpiSummaries } from 'src/entities/KpiSummary';

const BASE_URL = '/api/v3/kpis/summaries';

const getKpiSummaries = (queryClient: QueryClient, endDate: string, withCo2emissions: boolean) => async () => {
  const res = await axios.get<ApiResponse>(`${BASE_URL}?endDate=${endDate}&withCo2emissions=${withCo2emissions ? 'true' : 'false'}`);
  return res.data.data as unknown as KpiSummaries;
};

export const useQueryKpiSummaries = (endDate: Date, withCo2emissions: boolean) => {
  const queryClient = useQueryClient();
  const endDateString = format(endDate, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' });
  return useQuery(['KpiSummaries', { endDateString, withCo2emissions }], getKpiSummaries(queryClient, endDateString, withCo2emissions), {
    staleTime: Infinity
  });
};
