/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  LinearProgress,
  Paper,
  Theme,
  ThemeOptions,
  Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { jaJP, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import {
  DataGridPremium,
  GridColumnResizeParams, GridFilterItem, GridFilterModel,
  GridRowParams,
  GridSortModel,
  useGridApiRef
} from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { format } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler } from 'react-hook-form';
import { appBarHeight } from 'src/constants/layout';
import LicenseContext from 'src/contexts/LicenseContext';
import { RansackPredicateEntity } from 'src/entities/RansackPredicate.entity';
import { TruckEntity } from 'src/entities/truckEntity';
import { useDeleteAllTrucksMutation } from 'src/hooks/useDeleteAllTrucks.mutation';
import { useMutationTruck } from 'src/hooks/useQueryTrucks';
import { queryKey, useTrucksRequest as usePaginationRequest } from 'src/hooks/useTrucks.request';
import { dataGridUtil } from 'src/utils/dataGrid.util';
import numberUtil from 'src/utils/number.util';
import operatorValueUtil from 'src/utils/operatorValue.util';
import stringUtil from 'src/utils/string.util';

import { SelectAllSnackbars } from '../common/SelectAllSnackbars';

import { Columns } from './Columns';
import CustomToolbar from './CustomToolbar';
import FormDialog from './FormDialog';

const V2TrucksPresenter: FC = memo(() => {
  const licenseContext = useContext(LicenseContext);
  const theme: Theme & ThemeOptions = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(1);
  const [rowCount, setRowCount] = useState<number>(0);
  const [sortCondition, setSortCondition] = useState<string>('');
  const [orConditions, setOrConditions] = useState<RansackPredicateEntity[]>([]);
  const [andConditions, setAndConditions] = useState<RansackPredicateEntity[]>([]);
  const [searchCondition, setSearchCondition] = useState<string>('');
  const [editEntity, setEditEntity] = useState<TruckEntity|null>(null);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [canDeleteAll, setCanDeleteAll] = useState<boolean>(false);
  const apiRef = useGridApiRef();

  const { data: requestData, isLoading: requestIsLoading } = usePaginationRequest(page, sortCondition, searchCondition);
  const { data: allRequestData } = usePaginationRequest(0, sortCondition, searchCondition, Number.MAX_SAFE_INTEGER);
  const { deleteTrucks } = useMutationTruck();

  const { post } = useDeleteAllTrucksMutation();

  const deleteAll = (settledFnk: () => void) => {
    if (window.confirm(`選択された ${rowCount} 件の車両を削除します。よろしいですか？`)) {
      setIsLoading(true);

      post.mutate(undefined, {
        onSuccess: () => {
          enqueueSnackbar('すべて削除しました');
        },
        onError: (error: AxiosError<{ message: string; }>) => {
          enqueueSnackbar(error.response?.data?.message);
        },
        onSettled: () => {
          queryClient
            .invalidateQueries([queryKey])
            .finally(() => {
              settledFnk();
              setIsLoading(false);
            });
        }
      });
    }
  };

  const localStorageItemPrefix = 'truck-columns';

  const fieldNameThatNeedsToBeRenamed = [
    {
      current: 'maximum_loading_capacity_kg',
      server: 'maximum_loading_capacity_weight_for_calculation',
      convert: numberUtil.convertFromKgToGram,
    },
    {
      current: 'loading_platform_volume_m3',
      server: 'loading_platform_volume_mm3',
      convert: numberUtil.convertFromM3ToMm3,
    },
  ];

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length === 0) {
      setSortCondition('');
      return;
    }

    const maybeServerFieldName = fieldNameThatNeedsToBeRenamed.find((it) => it.current === stringUtil.toSnakeCase(sortModel[0].field))?.server;

    const field = maybeServerFieldName || sortModel[0].field;

    setSortCondition(
      [
        stringUtil.toSnakeCase(field),
        sortModel[0].sort
      ].join('+')
    );
  };

  const onFilterChange = ((filterModel: GridFilterModel) => {
    const items = filterModel?.items;

    if (!items || items.length === 0) {
      setOrConditions([]);
      setAndConditions([]);
      return;
    }

    const ransackPredicates: RansackPredicateEntity[] = items.flatMap((item: GridFilterItem) => {
      const maybeServerField = fieldNameThatNeedsToBeRenamed.find((it) => it.current === stringUtil.toSnakeCase(item.columnField));

      if (!maybeServerField) {
        return operatorValueUtil.convertToRansackPredicate(
          stringUtil.toSnakeCase(item.columnField),
          item.operatorValue,
          item.value as string | string[] | undefined,
          filterModel.linkOperator
        );
      }

      const maybeValue = parseFloat(item.value as string);

      return operatorValueUtil.convertToRansackPredicate(
        maybeServerField.server,
        item.operatorValue,
      maybeValue ? maybeServerField.convert(maybeValue).toString() : '0',
        filterModel.linkOperator
      );
    }).filter((maybe) => !!maybe);

    setOrConditions(ransackPredicates.filter((predicate) => predicate.link === 'or'));
    setAndConditions(ransackPredicates.filter((predicate) => predicate.link === 'and'));
  });

  const openNewTruckForm = () => {
    setEditEntity(null);
    setDialogIsOpen(true);
  };

  const onClickDeleteTrucks = () => {
    if (selectedIds.length === 0) {
      enqueueSnackbar('削除する車両を選択してください');
      return;
    }

    const selectedTrucks = (requestData?.data || []).filter((truck) => selectedIds.includes(truck.id));

    const usedTrucks = selectedTrucks.filter((truck) => truck.driver_names);

    if (usedTrucks.length > 0) {
      const driverNames = usedTrucks.flatMap((truck) => truck.driver_names?.split(','));
      const alertMessage = `ドライバー「${driverNames.join(',')}」の利用するトラックに指定されているため削除できません。削除前に別のトラックに変更してください。`;
      enqueueSnackbar(alertMessage, { variant: 'error' });
      return;
    }

    let confirmMsg = `選択された ${selectedIds.length} 件の車両を削除します。`;

    const maxShiftTruck = selectedTrucks.filter((e) => selectedIds.includes(e.id)).reduce((a, b) => (new Date(a.last_shift_end_at).getTime() > new Date(b.last_shift_end_at).getTime() ? a : b));
    if (maxShiftTruck && new Date(maxShiftTruck.last_shift_end_at).getTime() >= new Date().getTime()) {
      const date:string = format(new Date(maxShiftTruck.last_shift_end_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' });
      confirmMsg += `${date} までのシフトに利用されています。登録済みのシフトも削除されます。`;
    }
    confirmMsg += 'よろしいですか？';

    if (window.confirm(confirmMsg)) {
      setIsLoading(true);
      deleteTrucks.mutateAsync(selectedIds)
        .then(() => setSelectedIds([]))
        .catch((e: AxiosError<{ message: string; }>) => {
          enqueueSnackbar(e.response?.data?.message);
          setIsLoading(false);
        })
        .finally(() => {
          queryClient
            .invalidateQueries([queryKey])
            .finally(() => {
              setIsLoading(false);
            });
        });
    }
    setDialogIsOpen(false);
  };

  const openEdit: SubmitHandler<TruckEntity> = async (
    data
    // eslint-disable-next-line @typescript-eslint/require-await
  ): Promise<void> => {
    setEditEntity(data);
    setDialogIsOpen(true);
  };

  const dataGridColumns: GridColDef[] = useMemo(() => Columns(
    openEdit
  ).filter((col) => {
    if (licenseContext?.config?.unit_used_for_calculation === '重量と体積') {
      return true;
    }

    return col.field !== 'loading_platform_volume_m3';
  }).filter((col) => {
    if (licenseContext?.config?.selectable_companies?.length > 1) {
      return true;
    }
    return col.field !== 'company_name';
  }), [licenseContext?.config]);

  const onSelectionModelChange = (truckIds: number[]) => {
    if (truckIds.length === requestData.totalCount) {
      setSelectedIds(truckIds);
    } else {
      // 全選択以外は画面上の項目のみ選択できる
      const set = new Set(requestData.data.map((it) => it.id));
      setSelectedIds(truckIds.filter((id) => set.has(id)));
    }
  };

  const onRowDoubleClick = (params: GridRowParams) => {
    openEdit(params.row as unknown as TruckEntity);
  };

  const columnVisibilityModel = () => {
    const savedColumnVisibilities = dataGridUtil.getSavedColumnVisibilities(localStorageItemPrefix);
    if (Object.keys(savedColumnVisibilities).length === 0) return { garage_address: false, };

    return savedColumnVisibilities;
  };

  useEffect(() => {
    setSearchCondition(
      [
        operatorValueUtil.convertToQueryParams('or_conditions', orConditions),
        operatorValueUtil.convertToQueryParams('and_conditions', andConditions),
      ].filter((maybe) => maybe).join('&')
    );
  }, [andConditions, orConditions]);

  useEffect(() => {
    setRowCount(requestData?.totalCount || 0);
    setPageSize(requestData?.perPage || 1);
  }, [requestData]);

  useEffect(() => {
    const canPaginate = rowCount > pageSize;

    if (!canPaginate) {
      setCanDeleteAll(false);
    }

    const selectedAll = selectedIds?.length === pageSize;

    setCanDeleteAll(selectedAll);
  }, [selectedIds, pageSize, rowCount]);

  const handleDownloadCSV = () => {
    const options = {
      utf8WithBom: true,
      allColumns: true,
      fileName: `LOG-車両一覧-${format(new Date(), 'yyyyMMddHHmmss', { timeZone: 'Asia/Tokyo' })}`,
    };
    apiRef.current.setRows(allRequestData.data);
    apiRef.current.exportDataAsCsv(options);
    apiRef.current.setRows(requestData.data);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setSelectedIds([]);
  };

  return (
    <>
      <Helmet>
        <title>車両管理</title>
      </Helmet>
      <Box p={2}>
        {
          dialogIsOpen && (
            <FormDialog
              isOpen={dialogIsOpen}
              onClose={() => {
                setDialogIsOpen(false);
              }}
              updateIsLoading={(bool: boolean) => {
                setIsLoading(bool);
              }}
              editEntity={editEntity}
            />
          )
        }
        <SelectAllSnackbars
          totalPageCount={requestData?.totalPageCount || 1}
          totalCount={requestData?.totalCount || 0}
          perPage={pageSize}
          allIds={allRequestData?.data.map((it) => it.id) || requestData?.allIds || []}
          selectedIds={selectedIds}
          apiRef={apiRef}
          setSelectedIds={setSelectedIds}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Box flexGrow={1}>
            <Paper
              style={{
                width: '100%',
                height: `calc(100vh - ${theme.spacing(4)} - ${appBarHeight}px)`
              }}
            >
              <DataGridPremium
                apiRef={apiRef}
                rows={requestData?.data || []}
                columns={dataGridColumns}
                pagination
                checkboxSelection
                disableRowGrouping
                initialState={{
                  columns: { columnVisibilityModel: columnVisibilityModel() }
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: CustomToolbar
                }}
                componentsProps={{
                  toolbar: {
                    toggleDialogIsOpen: openNewTruckForm,
                    onClickDeleteTrucks,
                    setIsLoading,
                    canDeleteAll,
                    deleteAll,
                    handleDownloadCSV
                  }
                }}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                loading={isLoading || requestIsLoading}
                onSelectionModelChange={onSelectionModelChange}
                selectionModel={selectedIds}
                onRowDoubleClick={onRowDoubleClick}
                disableSelectionOnClick
                sortingMode="server"
                paginationMode="server"
                filterMode="server"
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
                onPageChange={onPageChange}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onSortModelChange={handleSortModelChange}
                onFilterModelChange={onFilterChange}
                onColumnWidthChange={(params: GridColumnResizeParams) => {
                  dataGridUtil.setSavedColumnWidth(localStorageItemPrefix, params);
                }}
                onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) => {
                  dataGridUtil.setSavedColumnVisibilities(localStorageItemPrefix, newModel);
                }}
                keepNonExistentRowsSelected
              />
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default V2TrucksPresenter;
