import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC, memo } from 'react';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';

type Props = {
  options: PlanningsGroupEntity[];
  value: PlanningsGroupEntity | undefined;
  onChange: (newValue: PlanningsGroupEntity | undefined) => void;
  fullWidth: boolean;
};

const PlanningsTruckGroupSelectPresenter: FC<Props> = memo(
  (
    {
      options,
      value,
      onChange,
      fullWidth
    }
  ) => {
    const handleChange = (event: SelectChangeEvent<number>) => {
      const selectedIndex = event.target.value as number;
      const newValue = selectedIndex === 0 ? undefined : options[selectedIndex - 1];
      onChange(newValue);
    };

    if (!options) return null;

    return (
      <FormControl
        fullWidth={fullWidth}
        size="small"
        sx={{
          width: '100%',
        }}
      >
        <InputLabel>表示するグループ</InputLabel>
        <Select value={value ? options.indexOf(value) + 1 : 0} onChange={handleChange}>
          <MenuItem value={0}>すべて表示する</MenuItem>
          {options?.map((option, index) => (
            <MenuItem key={option.name} value={index + 1}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

export default PlanningsTruckGroupSelectPresenter;
