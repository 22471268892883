import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';

const getLatestAlgorithmRequestVersion = (queryClient: QueryClient, startOn: string, endOn: string) => async () => {
  const res = await axios.get<ApiResponse>(`/api/v3/latest_algorithm_request_version?start_on=${startOn}&end_on=${endOn}`);
  return res.data.data as unknown as number;
};

export const useQueryLatestAlgorithmRequestVersion = (startOn: string, endOn: string) => {
  const queryClient = useQueryClient();
  return useQuery(['useQueryLatestAlgorithmRequestVersion'], getLatestAlgorithmRequestVersion(queryClient, startOn, endOn), {
    staleTime: Infinity,
  });
};
