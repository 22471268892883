import { yupResolver } from '@hookform/resolvers/yup';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl,
  FormGroup,
  FormLabel,
  IconButton, InputLabel,
  LinearProgress, MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { FC, memo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import LoadingComponent from 'src/components/LoadingComponent';
import MultiCheckbox from 'src/components/MultiCheckBox';
import { ALL_ML_SOURCE_TYPES, CompanyEntity, CompanyRequestEntity, MLSourceTypes } from 'src/entities/admin/companyEntity';
import { useMutationCompany, useQueryCompanies } from 'src/hooks/admin/useQueryCompanies';
import { useQuerySubscriptionKinds } from 'src/hooks/admin/useQuerySubscriptionKinds';
import * as yup from 'yup';

import { AdminCompaniesToolbar } from './Toolbar';

const schema = yup.object({
  name: yup.string().required('必須です'),
  address: yup.string().optional().nullable().max(255, '住所は255文字以内で入力してください'),
  phone_number: yup.string().optional().nullable().max(255, '電話番号は255文字以内で入力してください'),
  default_preset: yup.string().optional().nullable()
});

const AdminCompaniesPresenter: FC = memo(() => {
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const companiesQuery = useQueryCompanies(page, pageSize);
  const { data: subscriptionKindData, isLoading: subscriptionKindDataIsLoading, isError: subscriptionKindDataIsError } = useQuerySubscriptionKinds();

  const { addCompany, updateCompany, deleteCompany } = useMutationCompany();

  const form = useForm<(CompanyRequestEntity | CompanyEntity)>({
    resolver: yupResolver(schema)
  });
  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = form;

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const toggleDialogIsOpen = () => {
    reset({});
    setDialogIsOpen(!dialogIsOpen);
  };

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [mlSourceTypes, setMLSourceTypes] = useState<MLSourceTypes[]>([]);

  const onSubmitAdd: SubmitHandler<CompanyRequestEntity | CompanyEntity> = async (
    data
  ): Promise<void> => {
    try {
      data.ml_source_types = mlSourceTypes.map((it) => ALL_ML_SOURCE_TYPES.indexOf(it));
      if ('id' in data) {
        await updateCompany.mutateAsync(data);
      } else {
        await addCompany.mutateAsync(data);
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, quotes,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument
      error.response.data.map((str) => enqueueSnackbar(str));
    } finally {
      setDialogIsOpen(false);
    }
  };

  const onSubmitRemove: SubmitHandler<CompanyEntity> = async (
    data
  ): Promise<void> => {
    if (window.confirm('対象の企業のすべてのデータが削除されます。本当に削除してもよいですか？')) {
      await deleteCompany.mutateAsync(data.id);
    }
    setDialogIsOpen(false);
  };

  const openAppendDialog = () => {
    reset();
    setIsEdit(false);
    setDialogIsOpen(true);
  };

  const openEditDialog: SubmitHandler<CompanyEntity> = async (
    data
    // eslint-disable-next-line @typescript-eslint/require-await
  ): Promise<void> => {
    reset({
      ...data,
      default_preset: data.default_preset ? JSON.stringify(data.default_preset) : null
    });
    setIsEdit(true);
    setDialogIsOpen(true);
    const sourceTypes = data.ml_source_types ? data.ml_source_types.map((it) => ALL_ML_SOURCE_TYPES[it]) : [];
    setMLSourceTypes(sourceTypes);
  };

  const Columns: GridColDef[] = [
    { field: 'id', headerName: 'id', flex: 1 },
    {
      field: 'name',
      headerName: '企業名',
      // width: 300
      flex: 3,
    },
    {
      field: 'address',
      headerName: '住所',
      // width: 300
      flex: 3,
    },
    {
      field: 'phone_number',
      headerName: '電話番号',
      // width: 300
      flex: 3,
    },
    {
      field: 'subscriptions',
      headerName: '契約状況',
      flex: 5,
      renderCell: (params: { row: CompanyEntity }) => {
        const kinds = params.row.subscription_kind_ids.map((id) => subscriptionKindData.data.find((it) => it.id === id).name_ja);
        return (
          <Stack direction="column">
            <Typography variant="body2">
              {kinds.join(',')}
            </Typography>
            {/* {kinds.map((kind) => (
              <Typography variant="body2">
                {kind}
              </Typography>
            ))} */}
          </Stack>
        );
      }
    },
    {
      field: 'request_algorithm_host',
      headerName: 'リクエスト先アルゴリズムのURL',
      flex: 3,
    },
    {
      field: 'links',
      headerName: 'リンク',
      // width: 300,
      flex: 5,
      renderCell: (params: { row: CompanyEntity }) => (
        <Stack direction="row" gap={1}>
          <NavLink style={{ marginRight: '5px' }} to={`/admin/companies/${params.row.id}/licenses`}>
            ライセンス
          </NavLink>
          <NavLink to={`/admin/companies/${params.row.id}/subscriptions`}>
            サブスクリプション
          </NavLink>
          <NavLink to={`/admin/companies/${params.row.id}/allowed-ip-addresses`}>
            接続元IP制限
          </NavLink>
        </Stack>
      )
    },
    {
      field: 'edit',
      headerName: '編集',
      flex: 1,
      renderCell: (params: { row: CompanyEntity }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        <IconButton onClick={() => openEditDialog(params.row)}>
          <EditOutlinedIcon />
        </IconButton>
      )
    },
    {
      field: 'delete',
      headerName: '削除',
      flex: 1,
      renderCell: (params: { row: CompanyEntity }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        <IconButton onClick={() => onSubmitRemove(params.row)}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
      )
    }
  ];

  if (companiesQuery.isLoading || subscriptionKindDataIsLoading) {
    return <LoadingComponent />;
  }

  if (companiesQuery.isError || subscriptionKindDataIsError) {
    return <>Error</>;
  }

  const companies = companiesQuery.data;

  return (
    <>
      <Helmet>
        <title>ADMIN | 企業一覧</title>
      </Helmet>
      <Box width="100%" height="100%" p={2}>
        <Dialog open={dialogIsOpen} maxWidth="md" fullWidth>
          <DialogTitle>
            企業
            {isEdit ? '編集' : '追加'}
          </DialogTitle>
          <DialogContent>
            <Stack component="form" noValidate spacing={2}>
              <TextField
                required
                margin="dense"
                id="name"
                label="企業名"
                type="text"
                fullWidth
                variant="standard"
                {...register('name')}
                error={'name' in errors}
                helperText={errors.name?.message}
              />
              <TextField
                required
                margin="dense"
                id="address"
                label="住所"
                type="text"
                fullWidth
                variant="standard"
                {...register('address')}
                error={'address' in errors}
                helperText={errors.address?.message}
              />
              <TextField
                required
                margin="dense"
                id="phone_number"
                label="電話番号"
                type="text"
                fullWidth
                variant="standard"
                {...register('phone_number')}
                error={'phone_number' in errors}
                helperText={errors.phone_number?.message}
              />
              <FormControl
                variant="standard"
                fullWidth
                size="small"
              >
                <InputLabel>リクエスト先</InputLabel>
                <Controller
                  control={control}
                  name="request_algorithm_host"
                  render={({ field }) => (
                    <Select
                      id="request_algorithm_host"
                      margin="dense"
                      label="リクエスト先"
                      type="text"
                      fullWidth
                      variant="standard"
                      {...field}
                    >
                      <MenuItem value="">設定しない</MenuItem>
                      <MenuItem value="https://santa-routing-stg-2jvza7k7cq-an.a.run.app/">
                        ステージング
                      </MenuItem>
                      <MenuItem value="https://santa-routing-2jvza7k7cq-an.a.run.app/">
                        本番環境
                      </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
              <FormControl component="fieldset">
                <FormLabel component="legend">学習対象データ</FormLabel>
                <FormGroup row>
                  <MultiCheckbox
                    form={form}
                    control={control}
                    name="ml_source_types"
                    options={ALL_ML_SOURCE_TYPES}
                    defaultCheckedValues={mlSourceTypes}
                    valueSetter={setMLSourceTypes}
                  />
                </FormGroup>
              </FormControl>
              <TextField
                margin="dense"
                id="default_preset"
                label="アルゴプリセット"
                type="text"
                fullWidth
                variant="standard"
                {...register('default_preset')}
                multiline
                rows={10}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialogIsOpen}>キャンセル</Button>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Button onClick={handleSubmit(onSubmitAdd)} variant="contained">
              保存する
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          width="100%"
          height="100%"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Box width="100%" height="100%" flexGrow={1}>
            <Paper
              style={{
                width: '100%',
                height: '100%'
              }}
            >
              <DataGridPremium
                columns={Columns}
                rows={companies.entities}
                rowCount={companies.total}
                pagination
                paginationMode="server"
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: AdminCompaniesToolbar
                }}
                componentsProps={{
                  toolbar: { openAppendDialog }
                }}
                onPageChange={(val) => { setPage(val); }}
                onPageSizeChange={(val) => { setPageSize(val); }}
                page={page}
                pageSize={pageSize}
              />
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default AdminCompaniesPresenter;
