import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { PlanningOrderStatisticsEntity } from 'src/entities/PlanningOrderStatistics.entity';

const getCollection = (startOn: string, endOn: string, orderIds: number[]) => async () => {
  const requestPath = `/api/v3/plannings/${startOn}/orders/statistics?end_on=${endOn}`;

  const response: AxiosResponse<PlanningOrderStatisticsEntity> = await axios.post(requestPath, {
    order_ids: orderIds
  });

  return response.data;
};

export const useQueryPlanningOrderStatistics = (startOn: string, endOn: string, orderIds: number[]) => useQuery(
  ['useQueryPlanningOrderStatistics', { startOn, endOn, orderIds }],
  getCollection(startOn, endOn, orderIds),
  {
    staleTime: Infinity,
    enabled: Boolean(startOn) && Boolean(endOn)
  }
);
