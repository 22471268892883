import { TableCell } from '@mui/material';
import { FC, memo } from 'react';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { DriverEntity } from 'src/entities/Driver.entity';
import { SelectedShiftCell } from 'src/entities/SelectedShiftCell.entity';
import { TruckEntity } from 'src/entities/Truck.entity';

import BodyCellPresenter from './BodyCell.presenter';
import { EditPropsBase } from './EditDialog.presenter';

type Props = {
  daysOfWeek: Date[];
  truckData: TruckEntity[];
  driver: DriverEntity;
  isLoading: boolean;
  updateEditPropsBase: (props: EditPropsBase) => void;
  defaultStartAt: string;
  defaultEndAt: string;
  defaultWorkingAvailableDurationHours: number | undefined;
  selectedShiftCell: SelectedShiftCell[];
  updateSelectedShiftCell: (cell: SelectedShiftCell[], checked: boolean) => void;
  appendDeliveryExistingCells: (cells: SelectedShiftCell[]) => void;
}

const BodyCellsPresenter: FC<Props> = memo((
  {
    daysOfWeek,
    truckData,
    driver,
    isLoading,
    updateEditPropsBase,
    defaultStartAt,
    defaultEndAt,
    defaultWorkingAvailableDurationHours,
    selectedShiftCell,
    updateSelectedShiftCell,
    appendDeliveryExistingCells,
  }
) => (
  <>
    {daysOfWeek.map((dayOfWeek) => (
      <TableCell
        align="center"
        key={
          [
            'BodyCellsPresenter',
            'BodyCellPresenter',
            driver.id,
            datetimeDecorator.toMmDd(dayOfWeek),
          ].join('-')
        }
      >
        <BodyCellPresenter
          dayOfWeek={dayOfWeek}
          driver={driver}
          truckData={truckData}
          isLoading={isLoading}
          updateEditPropsBase={updateEditPropsBase}
          defaultStartAt={defaultStartAt}
          defaultEndAt={defaultEndAt}
          defaultWorkingAvailableDurationHours={defaultWorkingAvailableDurationHours}
          checked={selectedShiftCell.filter((it) => (it.driver_id === driver.id && it.date === dayOfWeek)).length > 0}
          updateSelectedShiftCell={updateSelectedShiftCell}
          appendDeliveryExistingCells={appendDeliveryExistingCells}
        />
      </TableCell>
    ))}
  </>
));

export default BodyCellsPresenter;
