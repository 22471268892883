import { FC, memo } from 'react';
import { PickingList } from 'src/entities/pickingListEntity';
import { useQueryPickingList } from 'src/hooks/useQueryPickingList';

import LoadingComponent from './LoadingComponent';

export type PresenterProps = {
  models: PickingList[];
  date: string;
  unit: string;
}

type Props = {
  shiftIds: number[];
  date: string;
  presenter: FC<PresenterProps>;
  unit: string;
}

const PickingListComponent: FC<Props> = memo(({ shiftIds, date, presenter: Presenter, unit }) => {
  const pickingQuery = useQueryPickingList(shiftIds);

  if (pickingQuery.isLoading) {
    return <LoadingComponent />;
  }

  if (pickingQuery.isError) {
    return <>Error</>;
  }

  const pickigLists = pickingQuery.data;

  return <Presenter models={pickigLists} date={date} unit={unit} />;
});

export default PickingListComponent;
