import { FC, memo } from 'react';
import { OrderEntity } from 'src/entities/orderEntity';

import OrderPolylinePresenter from './OrderPolylinePresenter';

type Props = {
  selectedIds: number[];
  orderEntityMap: Map<number, OrderEntity>;
}

const OrderPolylinesPresenter: FC<Props> = memo((
  {
    selectedIds,
    orderEntityMap
  }
) => (
  <>
    {selectedIds.map((id) => (
      <OrderPolylinePresenter
        key={[
          'OrderPolylinesPresenter',
          'OrderPolylinePresenter',
          id
        ].join('-')}
        id={id}
        orderEntityMap={orderEntityMap}
      />
      ))}
  </>
));

export default OrderPolylinesPresenter;
