import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MapIcon from '@mui/icons-material/Map';
import { Chip, IconButton, Stack, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { GridValueFormatterParams } from '@mui/x-data-grid-premium';
import { TruckDriverIdAndName } from 'src/entities/orderEntity';
import { PlaceEntity } from 'src/entities/placeEntity';
import { dataGridUtil } from 'src/utils/dataGrid.util';

export const Columns: (
  openEditDialog?: (placeEntity: PlaceEntity) => void,
  openMapDialog?: (placeEntity: PlaceEntity) => void,
) => GridColDef[] = (
  openEditDialog,
  openMapDialog
) => {
  const getWidth = (name: string, width: number) => {
    const maybeWidth: number = dataGridUtil.getSavedColumnWidth('place-columns', name);

    return maybeWidth || width;
  };

  return [
    {
      field: 'edit',
      headerName: '編集',
      disableExport: true,
      renderCell: (params: { row: PlaceEntity }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        <IconButton onClick={() => openEditDialog(params.row)}>
          <EditOutlinedIcon />
        </IconButton>
      ),
      filterable: false,
      sortable: false,
    },
    {
      field: 'encoded_id',
      headerName: 'ID',
      minWidth: 0,
      maxWidth: 0,
      filterable: false,
      sortable: false,
    },
    {
      field: 'new_from_order',
      headerName: 'NEW',
      valueGetter: (params: { row: PlaceEntity }) => (params.row.new_from_order ? 'NEW' : ''),
      renderCell: (params: { row: PlaceEntity }) => {
        if (params.row.new_from_order) return <Chip size="small" color="error" label="NEW" />;
        return '';
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'company_name',
      headerName: '事業所',
      width: getWidth('company_name', 300),
      filterable: false,
      sortable: false,
    },
    {
      field: 'name',
      headerName: '名前',
      width: getWidth('name', 300),
    },
    {
      field: 'address',
      headerName: '住所',
      width: getWidth('address', 400),
    },
    {
      field: 'position',
      headerName: '座標',
      disableExport: true,
      width: getWidth('position', 250),
      valueGetter: (params: { row: PlaceEntity }) => {
        if (params.row.latitude && params.row.longitude) {
          return `${params.row.latitude}, ${params.row.longitude}`;
        }
        return '';
      },
      renderCell: (params: { row: PlaceEntity }) => {
        if (params.row.latitude && params.row.longitude) {
          return (
            <Stack direction="row" alignContent="center" alignItems="center">
              <Tooltip title="位置を調整します">
                <IconButton onClick={() => openMapDialog(params.row)}>
                  <MapIcon />
                </IconButton>
              </Tooltip>
              {[params.row.latitude, params.row.longitude].join(', ')}
            </Stack>
          );
        }
        return '';
      }
    },
    {
      field: 'latitude',
      headerName: '緯度',
      filterable: false,
      sortable: false,
    },
    {
      field: 'longitude',
      headerName: '経度',
      filterable: false,
      sortable: false,
    },
    {
      field: 'specified_time_start_at_1',
      headerName: '受付',
      width: getWidth('specified_time_start_at_1', 100),
      valueGetter: (params: { row: PlaceEntity }) => {
        if (params.row.place_specified_times_attributes?.length >= 1) {
          return params.row.place_specified_times_attributes[0].start_at;
        }
        return '';
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'specified_time_end_at_1',
      headerName: '締切',
      width: getWidth('specified_time_end_at_1', 100),
      valueGetter: (params: { row: PlaceEntity }) => {
        if (params.row.place_specified_times_attributes?.length >= 1) {
          return params.row.place_specified_times_attributes[0].end_at;
        }
        return '';
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'specified_time_start_at_2',
      headerName: '受付②',
      width: getWidth('specified_time_start_at_2', 100),
      valueGetter: (params: { row: PlaceEntity }) => {
        if (params.row.place_specified_times_attributes?.length >= 2) {
          return params.row.place_specified_times_attributes[1].start_at;
        }
        return '';
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'specified_time_end_at_2',
      headerName: '締切②',
      width: getWidth('specified_time_end_at_2', 100),
      valueGetter: (params: { row: PlaceEntity }) => {
        if (params.row.place_specified_times_attributes?.length >= 2) {
          return params.row.place_specified_times_attributes[1].end_at;
        }
        return '';
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'specified_time_start_at_3',
      headerName: '受付③',
      width: getWidth('specified_time_start_at_3', 100),
      valueGetter: (params: { row: PlaceEntity }) => {
        if (params.row.place_specified_times_attributes?.length >= 3) {
          return params.row.place_specified_times_attributes[2].start_at;
        }
        return '';
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'specified_time_end_at_3',
      headerName: '締切③',
      width: getWidth('specified_time_end_at_3', 100),
      valueGetter: (params: { row: PlaceEntity }) => {
        if (params.row.place_specified_times_attributes?.length >= 3) {
          return params.row.place_specified_times_attributes[2].end_at;
        }
        return '';
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'staying_minutes',
      headerName: '滞在時間',
      type: 'number',
      width: getWidth('staing_minutes', 100),
    },
    {
      field: 'designated_truck_klasses',
      headerName: '車両タイプ',
      width: getWidth('designated_truck_klasses', 80),
      valueFormatter: (params: GridValueFormatterParams<string[]>) => {
        if (!params.value || params.value.length === 0 || params.value.length === 1) { // CSVに出力する場合
          return params.value;
        }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: PlaceEntity}) => { // 画面に表示する場合
        if (!params.row.designated_truck_klasses) { return ''; }
        return params.row.designated_truck_klasses.join(',');
      }
    },
    {
      field: 'designated_truck_car_models',
      headerName: '指定車種',
      width: getWidth('designated_truck_car_models', 80),
      valueFormatter: (params: GridValueFormatterParams<string[]>) => {
        if (!params.value || params.value.length === 0 || params.value.length === 1) { // CSVに出力する場合
          return params.value;
        }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: PlaceEntity}) => { // 画面に表示する場合
        if (!params.row.designated_truck_car_models) { return ''; }
        return params.row.designated_truck_car_models.join(',');
      }
    },
    {
      field: 'designated_truck_loading_platform_heights',
      headerName: '荷台高',
      width: getWidth('designated_truck_loading_platform_heights', 80),
      valueFormatter: (params: GridValueFormatterParams<string[]>) => {
        if (!params.value || params.value.length === 0 || params.value.length === 1) { // CSVに出力する場合
          return params.value;
        }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: PlaceEntity}) => { // 画面に表示する場合
        if (!params.row.designated_truck_loading_platform_heights) { return ''; }
        return params.row.designated_truck_loading_platform_heights.join(',');
      }
    },
    {
      field: 'designated_truck_loading_platform_widths',
      headerName: '荷台幅',
      width: getWidth('designated_truck_loading_platform_widths', 80),
      valueFormatter: (params: GridValueFormatterParams<string[]>) => {
        if (!params.value || params.value.length === 0 || params.value.length === 1) { // CSVに出力する場合
          return params.value;
        }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: PlaceEntity}) => { // 画面に表示する場合
        if (!params.row.designated_truck_loading_platform_widths) { return ''; }
        return params.row.designated_truck_loading_platform_widths.join(',');
      }
    },
    {
      field: 'designated_truck_loading_platform_lengths',
      headerName: '荷台長',
      width: getWidth('designated_truck_loading_platform_lengths', 80),
      valueFormatter: (params: GridValueFormatterParams<string[]>) => {
        if (!params.value || params.value.length === 0 || params.value.length === 1) { // CSVに出力する場合
          return params.value;
        }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: PlaceEntity}) => { // 画面に表示する場合
        if (!params.row.designated_truck_loading_platform_lengths) { return ''; }
        return params.row.designated_truck_loading_platform_lengths.join(',');
      }
    },
    {
      field: 'designated_truck_floor_specifications',
      headerName: '床仕様',
      width: getWidth('designated_truck_floor_specifications', 80),
      valueFormatter: (params: GridValueFormatterParams<string[]>) => {
        if (!params.value || params.value.length === 0 || params.value.length === 1) { // CSVに出力する場合
          return params.value;
        }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: PlaceEntity}) => { // 画面に表示する場合
        if (!params.row.designated_truck_floor_specifications) { return ''; }
        return params.row.designated_truck_floor_specifications.join(',');
      }
    },
    {
      field: 'designated_truck_features',
      headerName: '指定装置・特徴',
      width: getWidth('designated_truck_features', 80),
      valueFormatter: (params: GridValueFormatterParams<string[]>) => {
        if (!params.value || params.value.length === 0 || params.value.length === 1) { // CSVに出力する場合
          return params.value;
        }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: PlaceEntity}) => { // 画面に表示する場合
        if (!params.row.designated_truck_features) { return ''; }
        return params.row.designated_truck_features.join(',');
      }
    },
    { field: 'shipper_name', headerName: '荷主名', width: getWidth('shipper_name', 100), },
    { field: 'shipper_phone_number', headerName: '電話番号', width: getWidth('shipper_phone_number', 100) },
    { field: 'shipper_email_address', headerName: 'メールアドレス', width: getWidth('shipper_email_address', 100), },
    {
      field: 'allowed_trucks',
      headerName: '指定トラック',
      width: 200,
      valueFormatter: (params: GridValueFormatterParams<TruckDriverIdAndName[]>) => { // CSVに出力する場合
        if (!params.value) { return ''; }
        if (params.value.length < 2) { return params.value.map((obj) => obj.name).join(','); }
        return `"${params.value.map((obj) => obj.name).join(',')}"`;
      },
      renderCell: (params: { row: PlaceEntity }) => { // 画面に表示する場合
        if (!params.row.allowed_trucks) { return ''; }
        return params.row.allowed_trucks.map((obj) => obj.name).join(',');
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'denied_drivers',
      headerName: 'NGドライバー',
      width: 200,
      valueFormatter: (params: GridValueFormatterParams<TruckDriverIdAndName[]>) => { // CSVに出力する場合
        if (!params.value) { return ''; }
        if (params.value.length < 2) { return params.value.map((obj) => obj.name).join(','); }
        return `"${params.value.map((obj) => obj.name).join(',')}"`;
      },
      renderCell: (params: { row: PlaceEntity }) => { // 画面に表示する場合
        if (!params.row.denied_drivers) { return ''; }
        return params.row.denied_drivers.map((obj) => obj.name).join(',');
      },
      filterable: false,
      sortable: false,
    },
  ];
};
