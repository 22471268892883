const sliceByNumber = <T>(array: T[], number: number): T[][] => {
  const len = Math.ceil(array.length / number);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return new Array(len).fill().map((_, i) => array.slice(i * number, (i + 1) * number));
};

const maxAt: (ats: Date[]) => Date = (ats) => {
  const times: number[] = ats.map((at) => at.getTime());
  const maxTime: number = Math.max(...times);

  return new Date(maxTime);
};

const minAt: (ats: Date[]) => Date = (ats) => {
  const times: number[] = ats.map((at) => at.getTime());
  const minTime: number = Math.min(...times);

  return new Date(minTime);
};

const sample: <T>(ts: T[]) => T = (ts) => ts[Math.floor(Math.random() * ts.length)];

const uniq: <T>(ts: T[]) => T[] = (ts) => Array.from(new Set(ts));

const equals = <T>(arr1: T[], arr2: T[]): boolean => (
  arr1.length === arr2.length && arr1.every((it, index) => it === arr2[index])
);
const convertToParams = (key: string, values: (string | number)[]) => values
  .map((value) => `${key}[]=${value}`)
  .join('&');

const max: (ts: number[]) => number = (ts) => {
  if (ts.length === 0) return 0;

  return Math.max(...ts);
};

const calculateRunningSum = (inputArray: number[]): number[] => {
  let runningSum = 0;

  return inputArray.map((value) => {
    runningSum += value;
    return runningSum;
  });
};

const arrayUtil = {
  sliceByNumber,
  maxAt,
  minAt,
  sample,
  uniq,
  equals,
  convertToParams,
  max,
  calculateRunningSum,
};

export default arrayUtil;
