import { Checkbox, Divider, Stack, Typography } from '@mui/material';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import numberDecorator from 'src/decorators/number.decorator';
import { AllocateHistoryEntity } from 'src/entities/AllocateHistory.entity';
import { GarageEntity } from 'src/entities/Garage.entity';
import { PastOperationAndDriverIdEntity } from 'src/entities/PastOperationAndDriverId.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsOperationEntity } from 'src/entities/PlanningsOperation.entity';
import { PlanningsOperationDeliveryByDeliveryIdEntity } from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PlanningsWorkingStatisticEntity } from 'src/entities/PlanningsWorkingStatistic.entity';
import { SelectedCycle } from 'src/entities/SelectedCycle.entity';
import { TransferRequestEntity } from 'src/entities/transferRequestEntity';
import { PlanningsOperationPlace } from 'src/models/PlanningsOperationGroup.model';
import { SelectedStatusVo } from 'src/vo/SelectedStatus.vo';

import PlanningsDeliveriesPresenter from './PlanningsDeliveries.presenter';

type Props = {
  entity: PlanningsTruckEntity;
  garage: GarageEntity;
  updateDisplayOrderId: (orderId: number) => void;
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => void;
  openTransferDialog: (entity: TransferRequestEntity) => void;
  isLoading: boolean;
  driverEntities: PlanningsDriverEntity[];
  deliveryEntities: PlanningsDeliveryEntity[];
  operationEntities: PlanningsOperationEntity[];
  truckEntities: PlanningsTruckEntity[];
  updateWorkingStatisticses: (entity: PlanningsWorkingStatisticEntity) => void;
  currentHistoryVersion: string | undefined;
  updateEditDriverId: (driverId: number) => void;
  deliveriesOnTheTruck: PlanningsDeliveryEntity[];
  pastOperationsData: undefined | PastOperationAndDriverIdEntity[];
  latestAllocateHistory: AllocateHistoryEntity | undefined;
  expandAll: boolean;
  requestSingleAlgorithmPlanning: (deliveryId: number, orderOperationIdsForSort: number[], deleteOrderIdsFromOperations: number[], orderOperationCycleIndexes?: { [key: number]: number }) => void;
  startOn: string;
  endOn: string;
  selectedCycleIndexes: SelectedCycle[];
  updateSelectedCycleIndexes: (deliveryId: number, cycleIndexes: number[]) => void;
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
  resetEditPlaces: () => void;
  updateEditPlaces: (deliveryId: number, cycleIndex: number, places: PlanningsOperationPlace[]) => void;
  addEmptyCycle: (deliveryId: number) => void;
  removeEmptyCycle: (deliveryId: number, cycleIndex: number) => void;
  addDeliveryElementRef: (deliveryId: number, element: HTMLElement) => void;
}

const PlanningTruckPresenter: FC<Props> = memo(
  (
    {
      entity,
      garage,
      updateDisplayOrderId,
      mutateDeleteOrdersOperations,
      openTransferDialog,
      isLoading,
      driverEntities,
      deliveryEntities,
      operationEntities,
      truckEntities,
      updateWorkingStatisticses,
      currentHistoryVersion,
      updateEditDriverId,
      deliveriesOnTheTruck,
      pastOperationsData,
      latestAllocateHistory,
      expandAll,
      requestSingleAlgorithmPlanning,
      startOn,
      endOn,
      selectedCycleIndexes,
      updateSelectedCycleIndexes,
      planningsOperationDeliveryByDeliveryIdEntity,
      resetEditPlaces,
      updateEditPlaces,
      addEmptyCycle,
      removeEmptyCycle,
      addDeliveryElementRef,
    }
  ) => {
    const selectedStatusInitialState = 'none';
    const [selectedStatus, setSelectedStatus] = useState<SelectedStatusVo>(selectedStatusInitialState);

    const driversOnTheTruck = useMemo(() => (
      driverEntities.filter(({ id }) => deliveriesOnTheTruck.map(({ driverId }) => driverId).includes(id))
    ), [deliveriesOnTheTruck, driverEntities]);

    const checkBoxOnClick = useCallback((event: React.MouseEvent) => {
      if (!entity) return;

      event.stopPropagation();
      ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `車両チェック ${selectedStatus === 'none' ? 'ON' : 'OFF'}`, label: entity.id });

      const allDeliveryIds = deliveriesOnTheTruck.map((it) => it.id);
      if (selectedStatus === 'none') {
        allDeliveryIds.forEach((it) => {
          const delivery = planningsOperationDeliveryByDeliveryIdEntity[it];
          if (delivery) {
            updateSelectedCycleIndexes(it, delivery.cycles.map((cycl) => cycl.cycleIndex));
          }
        });

        return;
      }

      allDeliveryIds.forEach((it) => updateSelectedCycleIndexes(it, []));
    }, [entity, deliveriesOnTheTruck, planningsOperationDeliveryByDeliveryIdEntity, selectedStatus, updateSelectedCycleIndexes]);

    const checkboxMemo = useMemo(() => (
      <Checkbox
        onClick={checkBoxOnClick}
        indeterminate={selectedStatus === 'some'}
        checked={selectedStatus === 'every'}
        disabled={isLoading}
        size="small"
        sx={{
          m: 0,
          p: 0.3,
        }}
      />
    ), [checkBoxOnClick, selectedStatus, isLoading]);

    const truckMemo = useMemo(() => (
      <Stack
        direction="row"
        gap={0.5}
      >
        <Stack>
          <Typography variant="h5">
            {entity.licensePlateValue}
          </Typography>
        </Stack>
        <Stack direction="row">
          <Typography variant="body2">
            {`(${[
              numberDecorator.convertGramToKg(entity.maximumLoadingCapacityWeightForCalculation, 1),
              numberDecorator.convertMm3ToM3(entity.loadingPlatformVolumeMm3)
            ].filter((maybe) => maybe).join(', ')})`}
          </Typography>
        </Stack>
      </Stack>
    ), [entity]);

    useEffect(() => {
      const selectedDeliveryIds = selectedCycleIndexes.map((it) => it.deliveryId);
      if (deliveriesOnTheTruck.every((it) => selectedDeliveryIds.includes(it.id))) {
        setSelectedStatus(
          'every'
        );

        return;
      }

      if (deliveriesOnTheTruck.some((it) => selectedDeliveryIds.includes(it.id))) {
        setSelectedStatus(
          'some'
        );

        return;
      }

      setSelectedStatus(
        'none'
      );
    }, [deliveriesOnTheTruck, selectedCycleIndexes]);

    return (
      <>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap={0.5}
            onClick={checkBoxOnClick}
            style={{
              cursor: 'pointer'
            }}
          >
            {checkboxMemo}
            {truckMemo}
          </Stack>
          <PlanningsDeliveriesPresenter
            truck={entity}
            garage={garage}
            driversOnTheTruck={driversOnTheTruck}
            deliveriesOnTheTruck={deliveriesOnTheTruck}
            operationEntities={operationEntities}
            updateDisplayOrderId={updateDisplayOrderId}
            deliveryEntities={deliveryEntities}
            driverEntities={driverEntities}
            truckEntities={truckEntities}
            mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
            openTransferDialog={openTransferDialog}
            isLoading={isLoading}
            updateWorkingStatisticses={updateWorkingStatisticses}
            currentHistoryVersion={currentHistoryVersion}
            updateEditDriverId={updateEditDriverId}
            pastOperationsData={pastOperationsData}
            latestAllocateHistory={latestAllocateHistory}
            expandAll={expandAll}
            requestSingleAlgorithmPlanning={requestSingleAlgorithmPlanning}
            startOn={startOn}
            endOn={endOn}
            selectedCycleIndexes={selectedCycleIndexes}
            updateSelectedCycleIndexes={updateSelectedCycleIndexes}
            planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
            resetEditPlaces={resetEditPlaces}
            updateEditPlaces={updateEditPlaces}
            addEmptyCycle={addEmptyCycle}
            removeEmptyCycle={removeEmptyCycle}
            addDeliveryElementRef={addDeliveryElementRef}
          />
        </Stack>
        <Divider sx={{ height: '1px' }} />
      </>
    );
  }
);

export default PlanningTruckPresenter;
