import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import React from 'react';
import { GroupEntity } from 'src/entities/groupEntity';

export const Columns: (openEditDialog: (entity: GroupEntity) => void, deleteButtonOnClick: (entity: GroupEntity) => void) => GridColDef[] = (
  openEditDialog,
  deleteButtonOnClick
) => [
    {
      field: 'edit',
      headerName: '編集',
      renderCell: (params: { row: GroupEntity }) => (
        <IconButton onClick={() => openEditDialog(params.row)}>
          <EditOutlinedIcon />
        </IconButton>
      ),
      filterable: false,
      sortable: false,
    },
    {
      field: 'delete',
      headerName: '削除',
      renderCell: (params: { row: GroupEntity }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        <IconButton onClick={() => deleteButtonOnClick(params.row)}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
      ),
      filterable: false,
      sortable: false,
    },
    {
      field: 'company_name',
      headerName: '事業所',
      flex: 1,
    },
    { field: 'name', headerName: '名前', flex: 4 },
    {
      field: 'joined_truck_ids',
      headerName: '登録台数',
      flex: 4,
      type: 'number',
      valueGetter: (params: { row: GroupEntity }) => {
        const ids = (params.row.joined_truck_ids || '').split(',');

        return ids.length;
      },
      filterable: false,
      sortable: false,
    },
  ];
