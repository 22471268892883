import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const requestPath = '/api/v4/master/delete_all/drivers';

export const useDeleteAllDriversMutation = () => ({
  post: useMutation(
    () => axios.post(requestPath),
  ),
});
