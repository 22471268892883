import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { Button, Stack, Typography } from '@mui/material';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router';

const FourZeroFour: FC = memo(() => {
  const navigate = useNavigate();
  const buttonOnClick = () => {
    navigate('/');
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{
        height: '100vh'
      }}
    >
      <Stack>
        <Typography variant="h3">
          お探しのページは見つかりません。
        </Typography>
        <Button
          size="large"
          startIcon={<HomeRoundedIcon />}
          onClick={buttonOnClick}
        >
          TOPページに戻る
        </Button>
      </Stack>
    </Stack>
  );
});

export default FourZeroFour;
