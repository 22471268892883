import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { FC, memo, useEffect, useState } from 'react';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { DriverEntity } from 'src/entities/Driver.entity';
import { DriverStatisticEntity } from 'src/entities/DriverStatistic.entity';
import { useDriverStatisticsQuery } from 'src/hooks/useDriverStatistics.query';

type Props = {
  startOfWeek: Date;
  driver: DriverEntity
}

const StatisticPresenter: FC<Props> = memo((
  {
    startOfWeek,
    driver,
  }
) => {
  const [tableWidth, setTableWidth] = useState<number>(0);
  const [statistics, setStatistics] = useState<DriverStatisticEntity[]>([]);
  const [isMultipleMonths, setIsMultipleMonths] = useState<boolean>(false);

  const { data } = useDriverStatisticsQuery(driver.id, datetimeDecorator.toYyyyMmDd(startOfWeek));

  useEffect(() => {
    if (!statistics) return;

    setIsMultipleMonths(
      statistics.length === 2
    );
  }, [statistics]);

  useEffect(() => {
    if (isMultipleMonths) {
      setTableWidth(700);
    } else {
      setTableWidth(400);
    }
  }, [isMultipleMonths]);

  useEffect(() => {
    if (!data) return;

    setStatistics(data);
  }, [data]);

  return (
    <TableContainer sx={{ maxWidth: tableWidth }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell colSpan={2} align="center">
              {statistics[0]?.month}
              月
            </TableCell>
            {isMultipleMonths
              ? (
                <TableCell colSpan={2} align="center">
                  {statistics[1].month}
                  月
                </TableCell>
              )
              : '' }
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>休日</TableCell>
            <TableCell colSpan={2}>
              {statistics[0]?.dayOffCount}
              日
            </TableCell>
            {isMultipleMonths
              ? (
                <TableCell colSpan={2}>
                  {statistics[1].dayOffCount}
                  日
                </TableCell>
              )
              : '' }
          </TableRow>
          <TableRow>
            <TableCell>労働時間</TableCell>
            <TableCell>
              配車計画:
              {statistics[0]?.workingTimeHoursOnPlan}
              時間
            </TableCell>
            <TableCell>
              シフト:
              {statistics[0]?.workingTimeHoursOnShift}
              時間
            </TableCell>
            {isMultipleMonths
              ? (
                <>
                  <TableCell>
                    配車計画:
                    {statistics[1].workingTimeHoursOnPlan}
                    時間
                  </TableCell>
                  <TableCell>
                    シフト:
                    {statistics[1].workingTimeHoursOnShift}
                    時間
                  </TableCell>
                </>
              )
              : '' }
          </TableRow>
          <TableRow>
            <TableCell>休憩時間</TableCell>
            <TableCell>
              配車計画:
              {statistics[0]?.breakTimeHoursOnPlan}
              時間
            </TableCell>
            <TableCell>
              シフト:
              {statistics[0]?.breakTimeHoursOnShift}
              時間
            </TableCell>
            {isMultipleMonths
              ? (
                <>
                  <TableCell>
                    配車計画:
                    {statistics[1].breakTimeHoursOnPlan}
                    時間
                  </TableCell>
                  <TableCell>
                    シフト:
                    {statistics[1].breakTimeHoursOnShift}
                    時間
                  </TableCell>
                </>
              )
              : '' }
          </TableRow>
          <TableRow>
            <TableCell>13h超え</TableCell>
            <TableCell colSpan={2}>
              {statistics[0]?.overThirteenHoursCount}
              回
            </TableCell>
            {isMultipleMonths
              ? (
                <TableCell colSpan={2}>
                  {statistics[1].overThirteenHoursCount}
                  回
                </TableCell>
              )
              : '' }
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default StatisticPresenter;
