import { memo } from 'react';

type Props = {
  value: number;
  max: number;
  color: string;
  unit: string;
};

const Bar: React.FC<Props> = memo(({ value, max, color, unit }) => {
  const width = max > 0 ? (100 * value) / max : 0;

  const barStyle = {
    width: `${width}%`,
    backgroundColor: color,
    borderRadius: '3px',
    position: 'absolute' as const,
    top: 0,
    right: 0,
    bottom: 0,
  };

  const textStyle = {
    position: 'relative' as const,
    color: width < 20 ? '#6E7781' : '#ffffff',
    right: width < 20 ? `${width}%` : '0',
    padding: '0.1em 0.2em',
    zIndex: 2,
    fontWeight: 'bold',
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={barStyle} />
      <div style={textStyle}>
        {value}
        <span style={{ fontSize: '0.6em', marginLeft: '2px' }}>
          {unit}
        </span>
      </div>
    </div>
  );
});

export default Bar;
