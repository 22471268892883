export const holidays = [
  { on: '2015-01-01', name: '元日' },
  { on: '2015-01-12', name: '成人の日' },
  { on: '2015-02-11', name: '建国記念の日' },
  { on: '2015-03-21', name: '春分の日' },
  { on: '2015-04-29', name: '昭和の日' },
  { on: '2015-05-03', name: '憲法記念日' },
  { on: '2015-05-04', name: 'みどりの日' },
  { on: '2015-05-05', name: 'こどもの日' },
  { on: '2015-05-06', name: '憲法記念日 振替休日' },
  { on: '2015-07-20', name: '海の日' },
  { on: '2015-09-21', name: '敬老の日' },
  { on: '2015-09-22', name: '国民の休日' },
  { on: '2015-09-23', name: '秋分の日' },
  { on: '2015-10-12', name: '体育の日' },
  { on: '2015-11-03', name: '文化の日' },
  { on: '2015-11-23', name: '勤労感謝の日' },
  { on: '2015-12-23', name: '天皇誕生日' },
  { on: '2016-01-01', name: '元日' },
  { on: '2016-01-11', name: '成人の日' },
  { on: '2016-02-11', name: '建国記念の日' },
  { on: '2016-03-20', name: '春分の日' },
  { on: '2016-03-21', name: '春分の日 振替休日' },
  { on: '2016-04-29', name: '昭和の日' },
  { on: '2016-05-03', name: '憲法記念日' },
  { on: '2016-05-04', name: 'みどりの日' },
  { on: '2016-05-05', name: 'こどもの日' },
  { on: '2016-07-18', name: '海の日' },
  { on: '2016-08-11', name: '山の日' },
  { on: '2016-09-19', name: '敬老の日' },
  { on: '2016-09-22', name: '秋分の日' },
  { on: '2016-10-10', name: '体育の日' },
  { on: '2016-11-03', name: '文化の日' },
  { on: '2016-11-23', name: '勤労感謝の日' },
  { on: '2016-12-23', name: '天皇誕生日' },
  { on: '2017-01-01', name: '元日' },
  { on: '2017-01-02', name: '元日 振替休日' },
  { on: '2017-01-09', name: '成人の日' },
  { on: '2017-02-11', name: '建国記念の日' },
  { on: '2017-03-20', name: '春分の日' },
  { on: '2017-04-29', name: '昭和の日' },
  { on: '2017-05-03', name: '憲法記念日' },
  { on: '2017-05-04', name: 'みどりの日' },
  { on: '2017-05-05', name: 'こどもの日' },
  { on: '2017-07-17', name: '海の日' },
  { on: '2017-08-11', name: '山の日' },
  { on: '2017-09-18', name: '敬老の日' },
  { on: '2017-09-23', name: '秋分の日' },
  { on: '2017-10-09', name: '体育の日' },
  { on: '2017-11-03', name: '文化の日' },
  { on: '2017-11-23', name: '勤労感謝の日' },
  { on: '2017-12-23', name: '天皇誕生日' },
  { on: '2018-01-01', name: '元日' },
  { on: '2018-01-08', name: '成人の日' },
  { on: '2018-02-11', name: '建国記念の日' },
  { on: '2018-02-12', name: '建国記念の日 振替休日' },
  { on: '2018-03-21', name: '春分の日' },
  { on: '2018-04-29', name: '昭和の日' },
  { on: '2018-04-30', name: '昭和の日 振替休日' },
  { on: '2018-05-03', name: '憲法記念日' },
  { on: '2018-05-04', name: 'みどりの日' },
  { on: '2018-05-05', name: 'こどもの日' },
  { on: '2018-07-16', name: '海の日' },
  { on: '2018-08-11', name: '山の日' },
  { on: '2018-09-17', name: '敬老の日' },
  { on: '2018-09-23', name: '秋分の日' },
  { on: '2018-09-24', name: '秋分の日 振替休日' },
  { on: '2018-10-08', name: '体育の日' },
  { on: '2018-11-03', name: '文化の日' },
  { on: '2018-11-23', name: '勤労感謝の日' },
  { on: '2018-12-23', name: '天皇誕生日' },
  { on: '2018-12-24', name: '天皇誕生日 振替休日' },
  { on: '2019-01-01', name: '元日' },
  { on: '2019-01-14', name: '成人の日' },
  { on: '2019-02-11', name: '建国記念の日' },
  { on: '2019-03-21', name: '春分の日' },
  { on: '2019-04-29', name: '昭和の日' },
  { on: '2019-04-30', name: '祝日' },
  { on: '2019-05-01', name: '天皇の即位の日' },
  { on: '2019-05-02', name: '祝日' },
  { on: '2019-05-03', name: '憲法記念日' },
  { on: '2019-05-04', name: 'みどりの日' },
  { on: '2019-05-05', name: 'こどもの日' },
  { on: '2019-05-06', name: 'こどもの日 振替休日' },
  { on: '2019-07-15', name: '海の日' },
  { on: '2019-08-11', name: '山の日' },
  { on: '2019-08-12', name: '休日 山の日' },
  { on: '2019-09-16', name: '敬老の日' },
  { on: '2019-09-23', name: '秋分の日' },
  { on: '2019-10-14', name: '体育の日' },
  { on: '2019-10-22', name: '即位礼正殿の儀の行われる日' },
  { on: '2019-11-03', name: '文化の日' },
  { on: '2019-11-04', name: '文化の日 振替休日' },
  { on: '2019-11-23', name: '勤労感謝の日' },
  { on: '2020-01-01', name: '元日' },
  { on: '2020-01-13', name: '成人の日' },
  { on: '2020-02-11', name: '建国記念の日' },
  { on: '2020-02-23', name: '天皇誕生日' },
  { on: '2020-02-24', name: '天皇誕生日 振替休日' },
  { on: '2020-03-20', name: '春分の日' },
  { on: '2020-04-29', name: '昭和の日' },
  { on: '2020-05-03', name: '憲法記念日' },
  { on: '2020-05-04', name: 'みどりの日' },
  { on: '2020-05-05', name: 'こどもの日' },
  { on: '2020-05-06', name: '憲法記念日 振替休日' },
  { on: '2020-07-23', name: '海の日' },
  { on: '2020-07-24', name: 'スポーツの日' },
  { on: '2020-08-10', name: '山の日' },
  { on: '2020-09-21', name: '敬老の日' },
  { on: '2020-09-22', name: '秋分の日' },
  { on: '2020-11-03', name: '文化の日' },
  { on: '2020-11-23', name: '勤労感謝の日' },
  { on: '2021-01-01', name: '元日' },
  { on: '2021-01-11', name: '成人の日' },
  { on: '2021-02-11', name: '建国記念の日' },
  { on: '2021-02-23', name: '天皇誕生日' },
  { on: '2021-03-20', name: '春分の日' },
  { on: '2021-04-29', name: '昭和の日' },
  { on: '2021-05-03', name: '憲法記念日' },
  { on: '2021-05-04', name: 'みどりの日' },
  { on: '2021-05-05', name: 'こどもの日' },
  { on: '2021-07-22', name: '海の日' },
  { on: '2021-07-23', name: 'スポーツの日' },
  { on: '2021-08-08', name: '山の日' },
  { on: '2021-08-09', name: '休日 山の日' },
  { on: '2021-09-20', name: '敬老の日' },
  { on: '2021-09-23', name: '秋分の日' },
  { on: '2021-11-03', name: '文化の日' },
  { on: '2021-11-23', name: '勤労感謝の日' },
  { on: '2022-01-01', name: '元日' },
  { on: '2022-01-10', name: '成人の日' },
  { on: '2022-02-11', name: '建国記念の日' },
  { on: '2022-02-23', name: '天皇誕生日' },
  { on: '2022-03-21', name: '春分の日' },
  { on: '2022-04-29', name: '昭和の日' },
  { on: '2022-05-03', name: '憲法記念日' },
  { on: '2022-05-04', name: 'みどりの日' },
  { on: '2022-05-05', name: 'こどもの日' },
  { on: '2022-07-18', name: '海の日' },
  { on: '2022-08-11', name: '山の日' },
  { on: '2022-09-19', name: '敬老の日' },
  { on: '2022-09-23', name: '秋分の日' },
  { on: '2022-10-10', name: 'スポーツの日' },
  { on: '2022-11-03', name: '文化の日' },
  { on: '2022-11-23', name: '勤労感謝の日' },
  { on: '2023-01-01', name: '元日' },
  { on: '2023-01-02', name: '休日 元日' },
  { on: '2023-01-09', name: '成人の日' },
  { on: '2023-02-11', name: '建国記念の日' },
  { on: '2023-02-23', name: '天皇誕生日' },
  { on: '2023-03-21', name: '春分の日' },
  { on: '2023-04-29', name: '昭和の日' },
  { on: '2023-05-03', name: '憲法記念日' },
  { on: '2023-05-04', name: 'みどりの日' },
  { on: '2023-05-05', name: 'こどもの日' },
  { on: '2023-07-17', name: '海の日' },
  { on: '2023-08-11', name: '山の日' },
  { on: '2023-09-18', name: '敬老の日' },
  { on: '2023-09-23', name: '秋分の日' },
  { on: '2023-10-09', name: 'スポーツの日' },
  { on: '2023-11-03', name: '文化の日' },
  { on: '2023-11-23', name: '勤労感謝の日' },
  { on: '2024-01-01', name: '元日' },
  { on: '2024-01-08', name: '成人の日' },
  { on: '2024-02-11', name: '建国記念の日' },
  { on: '2024-02-12', name: '建国記念の日 振替休日' },
  { on: '2024-02-23', name: '天皇誕生日' },
  { on: '2024-03-20', name: '春分の日' },
  { on: '2024-04-29', name: '昭和の日' },
  { on: '2024-05-03', name: '憲法記念日' },
  { on: '2024-05-04', name: 'みどりの日' },
  { on: '2024-05-05', name: 'こどもの日' },
  { on: '2024-05-06', name: 'こどもの日 振替休日' },
  { on: '2024-07-15', name: '海の日' },
  { on: '2024-08-11', name: '山の日' },
  { on: '2024-08-12', name: '休日 山の日' },
  { on: '2024-09-16', name: '敬老の日' },
  { on: '2024-09-22', name: '秋分の日' },
  { on: '2024-09-23', name: '秋分の日 振替休日' },
  { on: '2024-10-14', name: 'スポーツの日' },
  { on: '2024-11-03', name: '文化の日' },
  { on: '2024-11-04', name: '文化の日 振替休日' },
  { on: '2024-11-23', name: '勤労感謝の日' },
  { on: '2025-01-01', name: '元日' },
  { on: '2025-01-13', name: '成人の日' },
  { on: '2025-02-11', name: '建国記念の日' },
  { on: '2025-02-23', name: '天皇誕生日' },
  { on: '2025-02-24', name: '天皇誕生日 振替休日' },
  { on: '2025-03-20', name: '春分の日' },
  { on: '2025-04-29', name: '昭和の日' },
  { on: '2025-05-03', name: '憲法記念日' },
  { on: '2025-05-04', name: 'みどりの日' },
  { on: '2025-05-05', name: 'こどもの日' },
  { on: '2025-05-06', name: 'みどりの日 振替休日' },
  { on: '2025-07-21', name: '海の日' },
  { on: '2025-08-11', name: '山の日' },
  { on: '2025-09-15', name: '敬老の日' },
  { on: '2025-09-23', name: '秋分の日' },
  { on: '2025-10-13', name: 'スポーツの日' },
  { on: '2025-11-03', name: '文化の日' },
  { on: '2025-11-23', name: '勤労感謝の日' },
  { on: '2025-11-24', name: '勤労感謝の日 振替休日' },
];
