import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { AreaEntity, AreaRequestEntity } from 'src/entities/areaEntity';

const requestPath: (id?: number) => string = (id) => {
  const base = '/api/v4/master/areas';

  if (!id) return base;

  return `${base}/${id}`;
};

const buildRequestData = (entity: AreaEntity) => ({
    area_id: entity?.id,
    company_id: entity.companyId,
    name: entity.name,
    area_addresses_attributes: entity.areaAddressesAttributes.map((it) => (
      {
        id: it.id,
        area_id: it.areaId,
        address: it.address,
        _destroy: it.delete ? '1' : '0',
      }
    ))
  }
);

export const useAreaMutation = () => ({
  add: useMutation(
    (entity: AreaRequestEntity) => axios.post(requestPath(), buildRequestData(entity as AreaEntity)),
  ),
  update: useMutation(
    (entity: AreaEntity) => axios.put(requestPath(entity.id), buildRequestData(entity)),
  ),
  deleteArea: useMutation(
    (id: number) => axios.delete(requestPath(id)),
  ),
});
