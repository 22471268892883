import { Button, Dialog, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { addDays } from 'date-fns';
import { format } from 'date-fns-tz';
import ja from 'date-fns/locale/ja';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useState } from 'react';
import PlansContext from 'src/contexts/PlansContext';
import { DriverShiftDriverEntity, DriverShiftRequestEntity, DriverShiftShiftEntity } from 'src/entities/driverShiftEntity';
import { useMutationDriverShift } from 'src/hooks/useQueryDriverShifts';

import ShiftsTableEditDialog from './ShiftsTableEditDialog';

type Props = {
  driver: DriverShiftDriverEntity;
  shift: DriverShiftShiftEntity | null;
  on: string;
}

const DriverShiftPresenter: FC<Props> = memo(({ driver, shift, on }) => {
  const context = useContext(PlansContext);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const buttonOnClick = () => {
    setIsOpen(!isOpen);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const buildRequest = (startTime: string, endTime: string, workingAvailableDuration: number) => {
    const startAt = new Date(`${on} ${startTime}`);
    const endAt = new Date(`${on} ${endTime}`);
    const workingAvailableDurationHours = workingAvailableDuration || null;

    if (startAt < endAt) {
      return {
        driver_id: driver.id,
        start_at: format(
          startAt,
          'yyyy-MM-dd HH:mm:ss xxx',
          {
            timeZone: 'Asia/Tokyo'
          }
        ),
        end_at: format(
          endAt,
          'yyyy-MM-dd HH:mm:ss xxx',
          {
            timeZone: 'Asia/Tokyo'
          }
        ),
        working_available_duration_hours: workingAvailableDurationHours,
      };
    }
    return {
      driver_id: driver.id,
      start_at: format(
        startAt,
        'yyyy-MM-dd HH:mm:ss xxx',
        {
          timeZone: 'Asia/Tokyo'
        }
      ),
      end_at: format(
        addDays(endAt, 1),
        'yyyy-MM-dd HH:mm:ss xxx',
        {
          timeZone: 'Asia/Tokyo'
        }
      ),
      working_available_duration_hours: workingAvailableDurationHours,
    };
  };

  const { addDriverShift, updateDriverShift, deleteDriverShift } = useMutationDriverShift();

  const addDriverShiftAsync = async (data: DriverShiftRequestEntity) => {
    await addDriverShift.mutateAsync(data);
  };

  const updateDriverShiftAsync = async (data: DriverShiftRequestEntity) => {
    await updateDriverShift.mutateAsync(data);
  };

  const deleteDriverShiftAsync = async (id: number) => {
    await deleteDriverShift.mutateAsync(id);
  };

  const resetRelatedQueries = () => {
    // eslint-disable-next-line no-void
    void queryClient.resetQueries({
      predicate: (query) => query.queryKey[0] === 'shifts' || query.queryKey[0] === 'unallocatedOrders' || query.queryKey[0] === 'shift' || query.queryKey[0] === 'order',
    });
  };

  const createRequest = (startTime: string, endTime: string, workingAvailableDuration: number) => {
    context.updateIsLoading(true);

    const data: DriverShiftRequestEntity = {
      on,
      driver_id: driver.id,
      start_at: startTime,
      end_at: endTime,
      workingAvailableDurationHours: workingAvailableDuration
    };

    addDriverShiftAsync(data)
      .catch((error: AxiosError) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        enqueueSnackbar(error.response.data);
      })
      .finally(() => {
        setIsOpen(false);
        context.updateIsLoading(false);
        resetRelatedQueries();
        context.reset();
      });
  };

  if (!shift) {
    return (
      <>
        <Button
          fullWidth
          color="secondary"
          onClick={buttonOnClick}
        >
          新規登録
        </Button>

        <Dialog
          open={isOpen}
          onClose={onClose}
          maxWidth="xs"
          fullWidth
        >
          <ShiftsTableEditDialog
            isLoading={context.isLoading}
            data={shift}
            title={`${on}の勤務計画を登録する`}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            saveButtonOnClick={createRequest}
          />
        </Dialog>
      </>
    );
  }

  const updateRequest = (startTime: string, endTime: string, workingAvailableDurationHours: number) => {
    context.updateIsLoading(true);

    const data: DriverShiftRequestEntity = {
      id: shift.id,
      on,
      driver_id: driver.id,
      start_at: startTime,
      end_at: endTime,
      workingAvailableDurationHours
    };

    updateDriverShiftAsync(data)
      .catch((error: AxiosError) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        enqueueSnackbar(error.response.data);
      })
      .finally(() => {
        setIsOpen(false);
        context.updateIsLoading(false);
        resetRelatedQueries();
        context.reset();
      });
  };

  const deleteRequest = () => {
    context.updateIsLoading(true);

    deleteDriverShiftAsync(shift.id)
      .finally(() => {
      setIsOpen(false);
      context.updateIsLoading(false);
      resetRelatedQueries();
      context.reset();
    });
  };

  return (
    <>
      <Button
        fullWidth
        onClick={buttonOnClick}
        style={{
          display: 'block'
        }}
      >
        <Typography>
          {format(new Date(shift.start_at), 'HH:mm', { locale: ja })}
          -
          {format(new Date(shift.end_at), 'HH:mm', { locale: ja })}
        </Typography>
        <Typography>
          {
            shift.working_available_duration_hours
            && (
              `${shift.working_available_duration_hours}時間`
            )
          }
          {
            !shift.working_available_duration_hours
            && (
              '-'
            )
          }
        </Typography>
      </Button>
      <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
      >
        <ShiftsTableEditDialog
          isLoading={context.isLoading}
          data={shift}
          title={`${on}の勤務計画を編集する`}
          deleteButtonOnClick={deleteRequest}
          saveButtonOnClick={updateRequest}
        />
      </Dialog>
    </>
  );
});

export default DriverShiftPresenter;
