/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Box,
  LinearProgress,
  Paper,
  Theme,
  ThemeOptions
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { jaJP, GridColDef } from '@mui/x-data-grid';
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns-tz';
import { FC, memo, useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler } from 'react-hook-form';
import { appBarHeight } from 'src/constants/layout';
import LicenseContext from 'src/contexts/LicenseContext';
import { AreaEntity } from 'src/entities/areaEntity';
import { useAreaMutation } from 'src/hooks/useArea.mutation';
import { queryKey, useAreasRequest as usePaginationRequest } from 'src/hooks/useAreas.request';

import { Columns } from './Columns';
import CustomToolbar from './CustomToolbar';
import FormDialog from './FormDialog';

const V2AreasPresenter: FC = memo(() => {
  const theme: Theme & ThemeOptions = useTheme();
  const licenseContext = useContext(LicenseContext);
  const queryClient = useQueryClient();

  const [selectedEntity, setSelectedEntity] = useState<AreaEntity|null>(null);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiRef = useGridApiRef();

  const { data: requestData, isLoading: requestIsLoading } = usePaginationRequest(0, '', '', Number.MAX_SAFE_INTEGER);
  const { deleteArea } = useAreaMutation();

  const editButtonOnclick = (entity: AreaEntity) => {
    setSelectedEntity(entity);
    setDialogIsOpen(true);
  };

  const openNewGroupDialog = () => {
    setDialogIsOpen(true);
    setSelectedEntity(null);
  };

  const closeDialogIsOpen = () => {
    setDialogIsOpen(false);
  };

  const onSubmitRemove: SubmitHandler<AreaEntity> = async (
    data,
    event
  ): Promise<void> => {
    if (window.confirm('削除してもよいですか？')) {
      await deleteArea.mutateAsync(data.id);
      await queryClient.invalidateQueries([queryKey]);
    }
    setDialogIsOpen(false);
  };

  const dataGridColumns: GridColDef[] = Columns(editButtonOnclick, onSubmitRemove).filter((col) => {
    if (licenseContext?.config?.selectable_companies?.length > 1) {
      return true;
    }
    return col.field !== 'companyName';
  });

  const handleDownloadCSV = () => {
    const options = {
      utf8WithBom: true,
      fileName: `LOG-エリア一覧-${format(new Date(), 'yyyyMMddHHmmss', { timeZone: 'Asia/Tokyo' })}`
    };
    apiRef.current.exportDataAsCsv(options);
  };

  return (
    <>
      <Helmet>
        <title>エリア管理</title>
      </Helmet>
      <Box p={2}>
        {
          dialogIsOpen && (
            <FormDialog
              open={dialogIsOpen}
              onClose={closeDialogIsOpen}
              entity={selectedEntity}
            />
          )
        }
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Box flexGrow={1}>
            <Paper
              style={{
                width: '100%',
                height: `calc(100vh - ${theme.spacing(4)} - ${appBarHeight}px)`
              }}
            >
              <DataGridPremium
                apiRef={apiRef}
                rows={requestData?.data || []}
                columns={dataGridColumns}
                pagination
                disableRowGrouping
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: CustomToolbar
                }}
                componentsProps={{
                  toolbar: {
                    toggleDialogIsOpen: openNewGroupDialog,
                    handleDownloadCSV,
                    setIsLoading,
                  }
                }}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                loading={requestIsLoading || isLoading}
                disableSelectionOnClick
                sortingMode="client"
                paginationMode="client"
                filterMode="client"
              />
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default V2AreasPresenter;
