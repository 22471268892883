import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import SaveAsRoundedIcon from '@mui/icons-material/SaveAsRounded';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { addDays, parse } from 'date-fns';
import { format } from 'date-fns-tz';
import ja from 'date-fns/locale/ja';
import { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { DriverShiftShiftEntity } from 'src/entities/driverShiftEntity';

type Props = {
  title: string;
  saveButtonOnClick: (startTime: string, endTime: string, workingAvailableDuration: '' | number) => void;
  deleteButtonOnClick?: () => void;
  isLoading: boolean;
  data?: null | {id: number, start_at: string, end_at: string, working_available_duration_hours: number} | DriverShiftShiftEntity;
}

const ShiftsTableEditDialog: FC<Props> = memo(({ title, saveButtonOnClick, deleteButtonOnClick, data, isLoading }) => {
  const [startTime, setStartTime] = useState<string>('09:00');
  const startTimeOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStartTime(e.target.value);
  };

  const [endTime, setEndTime] = useState<string>('20:00');
  const endTimeOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEndTime(e.target.value);
  };

  const [workingAvailableDurationHours, setWorkingAvailableDurationHours] = useState<'' | number>('');
  const onWorkingAvailableDurationHoursChange = (e: SelectChangeEvent) => {
    if (e.target.value) {
      setWorkingAvailableDurationHours(Number(e.target.value));
    } else {
      setWorkingAvailableDurationHours('');
    }
  };

  const [selectableHours, setSelectableHours] = useState<number[]>([]);

  useEffect(() => {
    if (!data) return;

    setStartTime(format(new Date(data.start_at), 'HH:mm', { locale: ja }));
    setEndTime(format(new Date(data.end_at), 'HH:mm', { locale: ja }));

    if (data.working_available_duration_hours) {
      setWorkingAvailableDurationHours(data.working_available_duration_hours);
    }
  }, [data]);

  useEffect(() => {
    const canRun = [startTime, endTime].every((maybe) => !!maybe);

    if (!canRun) {
      setSelectableHours([]);
      return;
    }

    const startAt = parse(startTime, 'HH:mm', new Date());
    let endAt = parse(endTime, 'HH:mm', new Date());
    if (endAt < startAt) {
      endAt = parse(endTime, 'HH:mm', addDays(endAt, 1),);
    }
    const diffMicroSeconds = endAt.getTime() - startAt.getTime();
    const diffHours = Math.round(Math.abs(diffMicroSeconds) / (60 * 60 * 1000));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const hours = [...Array(diffHours)].map((_, idx) => idx + 1);
    setSelectableHours(hours);
    if (workingAvailableDurationHours && !hours.includes(workingAvailableDurationHours)) {
      setWorkingAvailableDurationHours('');
    }
  }, [startTime, endTime]);

  return (
    <>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <TextField
              type="time"
              value={startTime}
              onChange={startTimeOnChange}
              sx={{
                minWidth: 180
              }}
            />
            <TextField
              type="time"
              value={endTime}
              onChange={endTimeOnChange}
              sx={{
                minWidth: 180
              }}
            />
          </Stack>
          <Stack>
            <InputLabel>労働時間</InputLabel>
            <Select value={`${workingAvailableDurationHours}`} onChange={onWorkingAvailableDurationHoursChange}>
              <MenuItem value="">
                未指定
              </MenuItem>
              {selectableHours.map((val) => (
                <MenuItem key={['workingAvailableDurationHours', val].join('-')} value={`${val}`}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        { deleteButtonOnClick && (
          <LoadingButton
            startIcon={<DeleteForeverRoundedIcon />}
            color="error"
            onClick={deleteButtonOnClick}
            loadingPosition="start"
            loading={isLoading}
          >
            削除する
          </LoadingButton>
        )}
        <LoadingButton
          onClick={() => {
            saveButtonOnClick(startTime, endTime, workingAvailableDurationHours);
          }}
          loadingPosition="start"
          startIcon={<SaveAsRoundedIcon />}
          loading={isLoading}
        >
          登録する
        </LoadingButton>
      </DialogActions>
    </>
  );
});

export default ShiftsTableEditDialog;
