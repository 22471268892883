import { Button, Snackbar, SnackbarContent } from '@mui/material';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';

type Props = {
  totalPageCount: number;
  totalCount: number;
  perPage: number;
  allIds: number[];
  selectedIds: number[];
  apiRef: MutableRefObject<GridApiPremium>;
  setSelectedIds: (ids: number[]) => void;
}

export function SelectAllSnackbars(props: Props) {
  const [selectAllSnackbarOpen, setSelectAllSnackbarOpen] = useState(false);
  const [allSelectedSnackbarOpen, setAllSelectedSnackbarOpen] = useState(false);

  const { totalPageCount, totalCount, perPage, allIds, selectedIds, apiRef, setSelectedIds } = props;

  useEffect(() => {
    if (totalPageCount === 1) return;
    setSelectAllSnackbarOpen(selectedIds.length === perPage);
  }, [perPage, selectedIds, totalPageCount]);

  const selectedMessage = useMemo(() => (
    `このページの${perPage}件がすべて選択されています。`
  ), [perPage]);

  const handleSelectAllClick = useCallback(() => {
    setSelectedIds(allIds);
    apiRef.current.setSelectionModel(allIds);
  }, [allIds, apiRef, setSelectedIds]);

  const selectAllAction = useMemo(() => (
    <Button size="small" color="secondary" onClick={handleSelectAllClick}>
      {totalCount}
      件のレコードすべてを選択します。
    </Button>
  ), [handleSelectAllClick, totalCount]);

  const allSelectedMessage = useMemo(() => (
    `${totalCount}件すべてが選択されています。`
  ), [totalCount]);

  const handleClearSelectionClick = useCallback(() => {
    setSelectedIds([]);
    apiRef.current.selectRows([], false, true);
  }, [apiRef, setSelectedIds]);

  const clearSelectionAction = useMemo(() => (
    <Button size="small" color="secondary" onClick={handleClearSelectionClick}>
      選択を解除する。
    </Button>
  ), [handleClearSelectionClick]);

  useEffect(() => {
    if (totalPageCount === 1) return;
    setAllSelectedSnackbarOpen(allIds.length === selectedIds.length);
  }, [selectedIds.length, allIds, totalPageCount]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={selectAllSnackbarOpen}
        key="selectAllSnackber"
      >
        <SnackbarContent
          message={selectedMessage}
          action={selectAllAction}
        />
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={allSelectedSnackbarOpen}
        key="allSelectedSnackber"
      >
        <SnackbarContent
          message={allSelectedMessage}
          action={clearSelectionAction}
        />
      </Snackbar>
    </>
  );
}
