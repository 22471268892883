import { differenceInDays } from 'date-fns';
import { FC, memo, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FourZeroFourComponent from 'src/components/FourZeroFourComponent';
import LicenseContext from 'src/contexts/LicenseContext';
import { DateModel } from 'src/models/DateModel';

import V2OrdersPresenter from './V2OrdersPresenter';

const V2Orders: FC = memo(() => {
  const { startOn, endOn } = useParams();
  const navigate = useNavigate();
  const context = useContext(LicenseContext);

  const start = new Date(startOn);
  const end = new Date(endOn);

  useEffect(() => {
    if (!context.config?.on) return;

    if (startOn) {
      const paramsOnModel = new DateModel(startOn);
      let paramsendDateModel = new DateModel(endOn);
      const dateDiff = differenceInDays(new Date(endOn), new Date(startOn));
      if (dateDiff < 0 || dateDiff > 30) {
        paramsendDateModel = new DateModel(startOn);
      }

      if (paramsOnModel.isValid()) {
        navigate(`/orders/${paramsOnModel.toString()}/${paramsendDateModel.toString()}`);
        return;
      }
    }

    const configOnModel = new DateModel(context.config.on);

    if (configOnModel.isValid()) {
      navigate(`/orders/${configOnModel.toString()}/${configOnModel.toString()}`);
      return;
    }

    navigate('/404');
  }, [startOn, endOn, context.config, navigate]);

  if (!startOn) return <FourZeroFourComponent />;

  return <V2OrdersPresenter startDate={start} endDate={end} />;
});

export default V2Orders;
