import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import { LoadingButton } from '@mui/lab';
import { Dialog, IconButton, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { startOfMonth, addDays, parse } from 'date-fns';
import { ChangeEvent, FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { DriverEntity } from 'src/entities/Driver.entity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { SelectedShiftCell } from 'src/entities/SelectedShiftCell.entity';
import { TruckEntity } from 'src/entities/Truck.entity';

import EditDialogPresenter, { EditProps } from './presenters/EditDialog.presenter';
import TablePresenter from './presenters/Table.presenter';

type Props = {
  daysOfWeek: Date[];
  truckData: TruckEntity[];
  driverData: DriverEntity[];
  groupData: PlanningsGroupEntity[];
  selectedGroup: PlanningsGroupEntity | undefined;
  updateSelectedGroup: (entity: PlanningsGroupEntity | undefined) => void;
  isLoading: boolean;
  updateIsLoading: (bool: boolean) => void;
  mutateCopyFromLastWeek: () => void;
  mutateExportExcelInThisMonth: (date: string) => void;
  startOn: string;
  endOn: string;
  initialStartOn: string;
  initialEndOn: string;
  backTo?: string;
  selectedShiftCell: SelectedShiftCell[];
  updateSelectedShiftCell: (cell: SelectedShiftCell[], checked: boolean) => void;
  updateStartEndOn: (week: number) => void;
}

const Presenter: FC<Props> = memo((
  {
    daysOfWeek,
    truckData,
    driverData,
    groupData,
    selectedGroup,
    updateSelectedGroup,
    isLoading,
    updateIsLoading,
    mutateCopyFromLastWeek,
    mutateExportExcelInThisMonth,
    startOn,
    endOn,
    initialStartOn,
    initialEndOn,
    backTo,
    selectedShiftCell,
    updateSelectedShiftCell,
    updateStartEndOn,
  }
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [thisMonthStartOfDay, setThisMonthStartOfDay] = useState<Date | undefined>(undefined);
  const [nextMonthStartOfDay, setNextMonthStartOfDay] = useState<Date | undefined>(undefined);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [editProps, setEditProps] = useState<EditProps | undefined>(undefined);
  const [defaultStartAt, setDefaultStartAt] = useState<string>('09:00');
  const [defaultEndAt, setDefaultEndAt] = useState<string>('19:00');
  const [defaultWorkingAvailableDurationHours, setDefaultWorkingAvailableDurationHours] = useState<number | undefined>(undefined);

  const updateEditProps: (props: EditProps | undefined) => void = useCallback(
    (props) => {
      setEditProps(props);
    },
    [],
  );

  const dialogOnClose = () => {
    setEditProps(undefined);
  };

  const closeButtonOnClick = useCallback(() => {
    if (backTo === 'timeline') {
      const key = ['useQueryPlanningsDeliveries', { initialStartOn, initialEndOn }];
      queryClient.invalidateQueries(key).finally(() => {
        navigate(`/timeline/${initialStartOn}/${initialEndOn}`);
      });

      return;
    }

    navigate(`/plans/${initialStartOn}/${initialEndOn}`);
  }, [backTo, initialEndOn, navigate, queryClient, initialStartOn]);

  const copyButtonOnClick = useCallback(() => {
    mutateCopyFromLastWeek();
  }, [mutateCopyFromLastWeek]);

  const exportThisMonthButtonOnClick = useCallback(() => {
    if (!thisMonthStartOfDay) return;

    mutateExportExcelInThisMonth(datetimeDecorator.toYyyyMmDd(thisMonthStartOfDay));
  }, [mutateExportExcelInThisMonth, thisMonthStartOfDay]);
  const exportNextMonthButtonOnClick = useCallback(() => {
    if (!nextMonthStartOfDay) return;

    mutateExportExcelInThisMonth(datetimeDecorator.toYyyyMmDd(nextMonthStartOfDay));
  }, [mutateExportExcelInThisMonth, nextMonthStartOfDay]);

  useEffect(() => {
    if (!daysOfWeek) return;

    const firstDate = daysOfWeek[0];
    const lastDate = [...daysOfWeek].slice(-1)[0];

    if (![firstDate, lastDate].every((maybe) => maybe)) return;

    setThisMonthStartOfDay(
      startOfMonth(firstDate)
    );

    if (datetimeDecorator.toMonth(firstDate) !== datetimeDecorator.toMonth(lastDate)) {
      setNextMonthStartOfDay(
        startOfMonth(lastDate)
      );
    }
  }, [daysOfWeek]);

  useEffect(() => {
    setDialogIsOpen(
      !!editProps
    );
  }, [editProps]);

  const tablePresenterMemo = useMemo(() => (
    <TablePresenter
      daysOfWeek={daysOfWeek}
      truckData={truckData}
      driverData={driverData}
      groupData={groupData}
      selectedGroup={selectedGroup}
      updateSelectedGroup={updateSelectedGroup}
      isLoading={isLoading}
      updateIsLoading={updateIsLoading}
      updateEditProps={updateEditProps}
      defaultStartAt={defaultStartAt}
      defaultEndAt={defaultEndAt}
      defaultWorkingAvailableDurationHours={defaultWorkingAvailableDurationHours}
      selectedShiftCell={selectedShiftCell}
      updateSelectedShiftCell={updateSelectedShiftCell}
      updateStartEndOn={updateStartEndOn}
    />
  ), [daysOfWeek, truckData, driverData, groupData, selectedGroup, updateSelectedGroup, isLoading, updateIsLoading, updateEditProps, defaultStartAt, defaultEndAt, defaultWorkingAvailableDurationHours, selectedShiftCell, updateSelectedShiftCell, updateStartEndOn]);

  const editDialogMemo = useMemo(() => <EditDialogPresenter {...editProps} isLoading={isLoading} />, [
    editProps, isLoading
  ]);

  const startTimeOnChange: (e: ChangeEvent<HTMLInputElement>) => void = (e) => {
    setDefaultStartAt(e.target.value);
    localStorage.setItem('shift_default_start_at', e.target.value);
  };

  const endTimeOnChange: (e: ChangeEvent<HTMLInputElement>) => void = (e) => {
    setDefaultEndAt(e.target.value);
    localStorage.setItem('shift_default_end_at', e.target.value);
  };

  const workingAvailableDurationHoursOnChange: (e: SelectChangeEvent<number>) => void = (e) => {
    setDefaultWorkingAvailableDurationHours(Number(e.target.value));
    localStorage.setItem('shift_default_working_available_duration_hours', e.target.value.toString());
  };

  const getSelectableHours: () => number[] = useCallback(() => {
    const startAt = parse(defaultStartAt, 'HH:mm', new Date());
    let endAt = parse(defaultEndAt, 'HH:mm', new Date());
    if (endAt < startAt) {
      endAt = parse(defaultEndAt, 'HH:mm', addDays(endAt, 1),);
    }
    const diffMicroSeconds = endAt.getTime() - startAt.getTime();
    const diffHours = Math.round(Math.abs(diffMicroSeconds) / (60 * 60 * 1000));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return [...Array(diffHours)].map((_, index) => index + 1);
  }, [defaultEndAt, defaultStartAt]);

  useEffect(() => {
    const startAt = localStorage.getItem('shift_default_start_at');
    const endAt = localStorage.getItem('shift_default_end_at');
    const duration = localStorage.getItem('shift_default_working_available_duration_hours');

    setDefaultStartAt(startAt || '09:00');
    setDefaultEndAt(endAt || '19:00');
    setDefaultWorkingAvailableDurationHours(duration ? Number(duration) : undefined);
  }, []);

  const headerMemo = useMemo(() => (
    <Stack direction="row">
      <Stack direction="row" gap={1} px={2} pt={1}>
        <IconButton
          onClick={closeButtonOnClick}
          disabled={isLoading}
        >
          <CancelRoundedIcon />
        </IconButton>
        <LoadingButton
          startIcon={<FileCopyRoundedIcon />}
          loading={isLoading}
          onClick={copyButtonOnClick}
        >
          先週の勤務計画をコピーする
        </LoadingButton>
        <LoadingButton
          startIcon={<FileCopyRoundedIcon />}
          loading={isLoading}
          onClick={exportThisMonthButtonOnClick}
        >
          {`${datetimeDecorator.toMonth(thisMonthStartOfDay)}の勤務計画を出力する`}
        </LoadingButton>
        {nextMonthStartOfDay && (
          <LoadingButton
            startIcon={<FileCopyRoundedIcon />}
            loading={isLoading}
            onClick={exportNextMonthButtonOnClick}
          >
            {`${datetimeDecorator.toMonth(nextMonthStartOfDay)}の勤務計画を出力する`}
          </LoadingButton>
        )}
      </Stack>
      <Stack direction="row" gap={1} pt={1} alignItems="center">
        <Typography>
          開始・終了時間、労働時間の初期値設定
        </Typography>
        <Stack>
          <TextField
            type="time"
            value={defaultStartAt}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => startTimeOnChange(e)}
            sx={{
              minWidth: 100
            }}
            size="small"
            disabled={isLoading}
          />
        </Stack>
        <Stack>
          <TextField
            type="time"
            value={defaultEndAt}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => endTimeOnChange(e)}
            sx={{
              minWidth: 100
            }}
            size="small"
            disabled={isLoading}
          />
        </Stack>
        <Stack>
          <Select
            value={defaultWorkingAvailableDurationHours || ''}
            onChange={(e: SelectChangeEvent<number>) => workingAvailableDurationHoursOnChange(e)}
            sx={{ minWidth: 100 }}
            size="small"
            disabled={isLoading}
          >
            <MenuItem value="">
              未指定
            </MenuItem>
            {getSelectableHours().map((hour) => (
              <MenuItem key={['workingAvailableDurationHours', hour].join('-')} value={hour}>
                {hour}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
    </Stack>
  ), [closeButtonOnClick, copyButtonOnClick, defaultEndAt, defaultStartAt, defaultWorkingAvailableDurationHours, exportNextMonthButtonOnClick, exportThisMonthButtonOnClick, getSelectableHours, isLoading, nextMonthStartOfDay, thisMonthStartOfDay]);

  return (
    <>
      <Helmet>
        <title>勤務計画</title>
      </Helmet>
      <Stack>
        {headerMemo}
        {tablePresenterMemo}
        <Dialog
          open={dialogIsOpen}
          onClose={dialogOnClose}
          maxWidth="lg"
        >
          { editProps && editDialogMemo }
        </Dialog>
      </Stack>
    </>
  );
});

export default Presenter;
