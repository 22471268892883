import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { FC, memo, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

const schema = yup.object({
  email: yup
    .string()
    .email('正しい形式で入力してください')
    .required('メールアドレスを入力してください')
});

const PasswordResetDialog: FC<{
  passwordResetDialogOpened: boolean,
  setPasswordResetDialogOpened: (bool: boolean) => void,
  setResetShowConfirmMessage: (bool: boolean) => void,
}> = memo(({
  passwordResetDialogOpened,
  setPasswordResetDialogOpened,
  setResetShowConfirmMessage,
}) => {
  interface PasswordResetForm {
    email: string
  }

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const { register, reset, handleSubmit, formState: { errors } } = useForm<PasswordResetForm>({
    resolver: yupResolver(schema)
  });

  const closeDialog = () => {
    setPasswordResetDialogOpened(false);
  };

  const onSubmit: SubmitHandler<PasswordResetForm> = async (
    data: PasswordResetForm
  ): Promise<void> => {
    setIsLoading(true);
    setErrorMessages([]);
    setResetShowConfirmMessage(false);

    try {
      await axios.post('/api/v2/accounts/password_resets', data);
      setResetShowConfirmMessage(true);
      setPasswordResetDialogOpened(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    reset({});
  }, [passwordResetDialogOpened]);

  return (
    <Dialog
      open={passwordResetDialogOpened}
      onClose={closeDialog}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3>パスワードリセット</h3>
        <IconButton onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {errorMessages.map((message: string) => (
        <Typography variant="h5" style={{ color: '#f00' }} pl={2}>
          {message}
        </Typography>
      ))}
      <DialogContent>
        <TextField
          required
          margin="dense"
          id="email"
          label="メールアドレス"
          type="text"
          fullWidth
          variant="standard"
          {...register('email')}
          error={'email' in errors}
          helperText={errors.email?.message}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={isLoading}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSubmit(onSubmit)}
        >
          パスワードをリセットする
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});

export default PasswordResetDialog;
