import { GridColDef } from '@mui/x-data-grid';
import { GridValueFormatterParams } from '@mui/x-data-grid-premium';
import numberDecorator from 'src/decorators/number.decorator';
import { EstimationEntity } from 'src/entities/Estimation.entity';
import numberUtil from 'src/utils/number.util';

const costDistanceColumns: GridColDef[] = [
  {
    field: 'deliveryName',
    headerName: '勤務計画',
    type: 'string',
  },
  {
    field: 'totalOrderOperationDistanceMm',
    headerName: '車庫帰着を除く合計距離',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromMmToKm(params.row.totalOrderOperationDistanceMm),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedKm(
      params.value,
      ''
    )
  },
  {
    field: 'totalDistanceMm',
    headerName: '合計距離',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromMmToKm(params.row.totalOrderOperationDistanceMm + params.row.arrivalOperationDistanceMm),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedKm(
      params.value,
      ''
    )
  },
  {
    field: 'expectedFeeYenByKm',
    headerName: '🖊️️1kmあたり運賃',
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'feeExcludeArrivalOperation',
    headerName: '運賃（車庫帰着を除く）',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => {
      if (!params.row.expectedFeeYenByKm) return undefined;

      const distanceMm = params.row.totalOrderOperationDistanceMm;

      return distanceMm * (params.row.expectedFeeYenByKm / 1000000);
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value,
    ),
  },
  {
    field: 'fee',
    headerName: '運賃',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => {
      if (!params.row.expectedFeeYenByKm) return undefined;

      const distanceMm = params.row.totalOrderOperationDistanceMm + params.row.arrivalOperationDistanceMm;

      return distanceMm * (params.row.expectedFeeYenByKm / 1000000);
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value,
    ),
  },
];

export default costDistanceColumns;
