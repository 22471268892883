import { MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { FC, memo, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';

import PlanningTransferSelectDeliveryPresenter from './PlanningTransferSelectDelivery.presenter';

type Props = {
  currentDeliveryIds: number[];
  updateTransferToDeliveryId: (transferDeliveryId: number | undefined) => void;
  deliveryEntities: PlanningsDeliveryEntity[];
  driverEntities: PlanningsDriverEntity[];
  truckEntities: PlanningsTruckEntity[];
  isAllFixed: boolean;
}

const PlanningTransferSelectDeliveriesPresenter: FC<Props> = memo((
  {
    currentDeliveryIds,
    updateTransferToDeliveryId,
    deliveryEntities,
    driverEntities,
    truckEntities,
    isAllFixed
  }
) => {
  const theme = useTheme();
  const [entities, setEntities] = useState<PlanningsDeliveryEntity[]>([]);
  const [transferToDeliveryId, setTransferToDeliveryId] = useState<string>('');

  useEffect(() => {
    if (!transferToDeliveryId) {
      updateTransferToDeliveryId(undefined);

      return;
    }

    updateTransferToDeliveryId(
      Number(
        transferToDeliveryId
      )
    );
  }, [transferToDeliveryId]);

  useEffect(() => {
    if (!deliveryEntities || deliveryEntities.length === 0) return;

    const MAX_ORDER = 999999;
    const filteredEntities = deliveryEntities.filter((datum) => !currentDeliveryIds.includes(datum.id))
                            .filter((datum) => !datum.operations.some((it) => it.fixed));
    filteredEntities.sort((a, b) => {
      let ret = 0;
      const aTruck = truckEntities.find((it) => it.id === a.truckId);
      const bTruck = truckEntities.find((it) => it.id === b.truckId);
      if (aTruck && bTruck) {
        ret = (aTruck.sortOrder ?? MAX_ORDER) - (bTruck.sortOrder ?? MAX_ORDER);
        if (ret !== 0) {
          return ret;
        }
      }
      const aDriver = driverEntities.find((it) => it.id === a.driverId);
      const bDriver = driverEntities.find((it) => it.id === b.driverId);
      if (aDriver && bDriver) {
        ret = (aDriver.sortOrder ?? MAX_ORDER) - (bTruck.sortOrder ?? MAX_ORDER);
        if (ret !== 0) {
          return ret;
        }
      }
      return ret;
    });
    setEntities(filteredEntities);
  }, [deliveryEntities, currentDeliveryIds, truckEntities, driverEntities]);

  const handleChange = (event: SelectChangeEvent) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING_PLACE_DIALOG, button_name: '移動先選択', label: event.target.value });
    setTransferToDeliveryId(
      event.target.value
    );
  };

  return (
    <Select
      variant="standard"
      size="small"
      sx={{
        minWidth: theme.spacing(30),
        backgroundColor: (isAllFixed ? '#ddd' : '')
      }}
      displayEmpty
      value={transferToDeliveryId}
      onChange={handleChange}
      renderValue={(value: string) => {
        const maybe = entities.find((entity) => `${entity.id}` === `${value}`);

        if (!maybe) return undefined;

        const driverEntity = driverEntities.find((it) => it.id === maybe.driverId);
        const truckEntity = truckEntities.find((it) => it.id === maybe.truckId);
        const val = [
          driverEntity?.name,
          truckEntity?.licensePlateValue
        ].join(' ');

        const limit = 22;

        if (val.length >= limit) {
          return `${val.slice(0, limit)}..`;
        }
          return val;
      }}
      disabled={isAllFixed || entities.length === 0}
    >
      <MenuItem value="" key="empty-option">
        {'　　'}
      </MenuItem>
      {entities.map((entity) => (
        <MenuItem
          value={entity.id}
          key={[
            'PlanningTransferSelectDeliveriesPresenter',
            ...currentDeliveryIds,
            'PlanningTransferSelectDeliveryPresenter',
            entity.id
          ].join('-')}
        >
          <PlanningTransferSelectDeliveryPresenter
            deliveryEntity={entity}
            driverEntities={driverEntities}
            truckEntities={truckEntities}
          />
        </MenuItem>
      ))}
    </Select>
  );
});

export default PlanningTransferSelectDeliveriesPresenter;
