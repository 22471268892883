import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { format } from 'date-fns-tz';
import ja from 'date-fns/locale/ja';
import saveAs from 'file-saver';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import useImport from 'src/hooks/useImport';

import { fiveZeroZeroErrorMessage } from '../../constants/messages';

import Header from './Header';

const Process: FC = memo(() => {
  const importContext = useImport();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const buildRuleName = () => {
    const today = format(new Date(), 'yyyy-MM-dd', { locale: ja });
    const randomNumber = `${Math.floor(Math.random() * 100000) + 1}`;

    return [today, randomNumber].join('-');
  };

  const [importLength, setImportLength] = useState<undefined | number>(undefined);
  const [ruleIsSaved, setRuleIsSaved] = useState<boolean>(false);
  const [ruleName, setRuleName] = useState<string>(buildRuleName());
  const [isSameMappingRule, setIsSameMappingRule] = useState<boolean>(false);

  const textFieldOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRuleName(event.target.value);
  };

  const [existSameMappingName, setExistSameMappingName] = useState<boolean>(false);

  const importRequest = () => {
    importContext.setIsLoading(true);

    const requestPath = `/api/v3/orders/imports/${importContext.importId}`;
    axios.patch(requestPath, { skip_mapping: importContext.skipMapping.toString() })
      .then((response: AxiosResponse<number>) => {
        setImportLength(response.data);
      })
      .catch((e) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (e.response.status === 400) {
          enqueueSnackbar('取り込めない案件情報がありました。データをご確認ください。');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          const blob = new Blob([e.response.data], { type: e.response.data.type });
          saveAs(blob, 'errors.txt');
        } else {
          enqueueSnackbar(fiveZeroZeroErrorMessage);
        }
        throw e;
      })
      .finally(() => {
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['dashboards']);
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['orders']);
        importContext.setIsLoading(false);
      });
  };

  const upsertMappingRule = () => {
    const requestPath = '/api/v3/orders/mappings';
    const requestBody = {
      import_id: importContext.importId,
      name: ruleName
    };

    importContext.setIsLoading(true);

    axios
      .post(requestPath, requestBody)
      .then(() => {
        enqueueSnackbar(`${ruleName}を保存しました`);
        setRuleIsSaved(true);
      })
      .catch((e) => {
        enqueueSnackbar(fiveZeroZeroErrorMessage);
        throw e;
      })
      .finally(() => {
        importContext.setIsLoading(false);
      });
  };

  useEffect(() => {
    importRequest();

    const currentMappingRule = importContext.mappings;
    const selectedMappingRule = importContext.mappingRules.find((rule) => rule.id === importContext.selectedRuleId)?.mapping;
    const isSame = selectedMappingRule
      ? Object.keys(currentMappingRule).every((key) => currentMappingRule[key] === selectedMappingRule[key])
      : false;
    setIsSameMappingRule(isSame);
  }, []);

  useEffect(() => {
    setExistSameMappingName(importContext.mappingRules.map((rule) => rule.name).includes(ruleName));
  }, [ruleName]);

  useEffect(() => {
    const maybeSelectedRuleName = importContext.mappingRules.find((rule) => rule.id === importContext.selectedRuleId)?.name;

    if (!maybeSelectedRuleName) return;

    setRuleName(maybeSelectedRuleName);
  }, [importContext.selectedRuleId]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box>
        <Header step={4} />
      </Box>
      <Box
        flexGrow={1}
        paddingX={2}
        paddingY={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img
          src="/static/images/import_done.png"
          alt="import_csv"
          height={172}
        />
        <p>
          {importLength ? `${importLength}件をインポートしました` : 'インポートが完了しました'}
        </p>

        {[ruleIsSaved, isSameMappingRule, importContext.isDefaultMappings()].every((bool) => !bool) && (
          <Stack spacing={1}>
            <Typography
              textAlign="center"
              variant="h5"
            >
              このルールを保存しますか？
            </Typography>
            {existSameMappingName && (
              <Stack textAlign="center">
                <Typography
                  variant="caption"
                >
                  {ruleName}
                  は、既に設定されています。
                </Typography>
                <Typography
                  variant="caption"
                >
                  別名で保存する場合は、名前を変更してください。
                </Typography>
              </Stack>
            )}
            <Stack direction="row" gap={1}>
              <TextField
                onChange={textFieldOnChange}
                value={ruleName}
              />
              <LoadingButton
                loading={importContext.isLoading}
                variant="outlined"
                onClick={upsertMappingRule}
              >
                {existSameMappingName
                  ? '上書き保存する'
                  : '保存する'}
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </Box>
    </Box>
  );
});

export default Process;
