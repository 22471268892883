import { FC, memo } from 'react';
import { EditPlaceEntity } from 'src/entities/EditPlace.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import {
  PlanningsOperationDeliveryByDeliveryIdEntity
} from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { SelectedCycle } from 'src/entities/SelectedCycle.entity';

import PlanningMapDeliveryPolylinesPresenter from './PlanningMapDeliveryPolylines.presenter';

type Props = {
  editPlaces: EditPlaceEntity[];
  deliveries: PlanningsDeliveryEntity[];
  selectedCycleIndexes: SelectedCycle[];
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
}

const PlanningMapDeliveriesPolylinesPresenter: FC<Props> = memo((
  {
    editPlaces,
    deliveries,
    selectedCycleIndexes,
    planningsOperationDeliveryByDeliveryIdEntity,
  }
) => (
  <>
    {deliveries.map((delivery) => (
      <PlanningMapDeliveryPolylinesPresenter
        key={[
            'PlanningMapDeliveriesPolylinesPresenter',
            'PlanningMapDeliveryPolylinesPresenter',
            delivery.id
          ].join('-')}
        editPlaces={editPlaces.filter((it) => it.deliveryId === delivery.id)}
        delivery={delivery}
        selectedCycleIndexes={selectedCycleIndexes.find((it) => it.deliveryId === delivery.id)}
        planningsOperationDelivery={planningsOperationDeliveryByDeliveryIdEntity[`${delivery.id}`]}
      />
      ))}
  </>
  ));

export default PlanningMapDeliveriesPolylinesPresenter;
