import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const requestPath = '/api/v4/master/delete_all/garages';

export const useDeleteAllGaragesMutation = () => ({
  post: useMutation(
    () => axios.delete(requestPath),
  ),
});
