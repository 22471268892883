import { FC, memo } from 'react';
import { PlanningsMapGaragePositionEntity } from 'src/entities/PlanningsMapGaragePosition.entity';

import PlanningMapGarageMarkerPresenter from './PlanningMapGarageMarker.presenter';

type Props = {
  entities: PlanningsMapGaragePositionEntity[];
}

const PlanningMapGarageMarkersPresenter: FC<Props> = memo((
  {
    entities
  }
) => (
  <>
    {entities.map((entity) => (
      <PlanningMapGarageMarkerPresenter
        key={
          [
            'PlanningMapGarageMarkersPresenter',
            'PlanningMapGarageMarkerPresenter',
            ...entity.deliveryIds,
            ...entity.position
          ].join('-')
        }
        position={entity.position}
        garage={entity.garageEntity}
      />
    ))}
  </>
));

export default PlanningMapGarageMarkersPresenter;
