import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import SaveAsRoundedIcon from '@mui/icons-material/SaveAsRounded';
import { LoadingButton } from '@mui/lab';
import {
  Button, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { addDays, parse } from 'date-fns';
import { FC, FocusEvent, memo, useCallback, useEffect, useState } from 'react';
import { DriverEntity } from 'src/entities/Driver.entity';
import { SelectedShiftCell } from 'src/entities/SelectedShiftCell.entity';
import { TruckEntity } from 'src/entities/Truck.entity';

export type RequestEntity = {
  id?: number;
  startAt: string;
  endAt: string;
  workingAvailableDurationHours?: number;
  truckId?: number;
  key?: number;
}

export type EditPropsBase = {
  dialogTitle: string;
  defaultRequestEntities: RequestEntity[];
  driver?: DriverEntity;
  truckData: TruckEntity[];
  date: Date;
  defaultStartAt: string;
  defaultEndAt: string;
  defaultWorkingAvailableDurationHours: number | undefined;
  shiftCell: SelectedShiftCell[];
}

export type EditProps = EditPropsBase & {
  deleteRequestOnClick: (shiftCell: SelectedShiftCell[]) => void;
  createRequestButtonOnClick: (requestEntities: RequestEntity[], shiftCell: SelectedShiftCell[]) => void;
  deliveryExistingCells: SelectedShiftCell[];
}

const EditDialogPresenter: FC<EditProps & { isLoading: boolean }> = memo((
  {
    deleteRequestOnClick,
    createRequestButtonOnClick,
    dialogTitle,
    isLoading,
    defaultRequestEntities,
    driver,
    truckData,
    date,
    defaultStartAt,
    defaultEndAt,
    defaultWorkingAvailableDurationHours,
    shiftCell,
    deliveryExistingCells,
  }
) => {
  const [existIdxAndChangeStatus, setExistIdxAndChangeStatus] = useState<{
    idx: number;
    dirtyProperties: string[];
  }[]>([]);
  const [requestEntities, setRequestEntities] = useState<RequestEntity[]>([]);
  const [defaultRequestEntity, setDefaultRequestEntity] = useState<RequestEntity>({
    startAt: defaultStartAt,
    endAt: defaultEndAt,
    workingAvailableDurationHours: defaultWorkingAvailableDurationHours
  });
  const [activeTrucks, setActiveTrucks] = useState<TruckEntity[]>(truckData);

  const startTimeOnBlur: (e: FocusEvent<HTMLInputElement>, idx: number) => void = (e, idx) => {
    const isExistEntity = !!existIdxAndChangeStatus.find((it) => it.idx === idx);
    if (isExistEntity) {
      const defaultVal = defaultRequestEntities[idx]?.startAt;

      const key = 'startAt';
      const isDirty = defaultVal !== e.target.value;

      if (isDirty) {
        setExistIdxAndChangeStatus((prev) => {
          const newData: { idx: number; dirtyProperties: string[] }[] = [...prev];
          newData[idx].dirtyProperties = [
            ...prev[idx].dirtyProperties.filter((it) => it !== key),
            key
          ];
          return newData;
        });
      } else {
        setExistIdxAndChangeStatus((prev) => {
          const newData: { idx: number; dirtyProperties: string[] }[] = [...prev];
          newData[idx].dirtyProperties = [
            ...prev[idx].dirtyProperties.filter((it) => it !== key),
          ];
          return newData;
        });
      }
    }

    setRequestEntities((prev) => {
      const newData = [...prev];
      newData[idx].startAt = e.target.value;
      return newData;
    });
  };

  const endTimeOnBlur: (e: FocusEvent<HTMLInputElement>, idx: number) => void = (e, idx) => {
    const isExistEntity = !!existIdxAndChangeStatus.find((it) => it.idx === idx);
    if (isExistEntity) {
      const defaultVal = defaultRequestEntities[idx]?.endAt;

      const key = 'endAt';
      const isDirty = defaultVal !== e.target.value;

      if (isDirty) {
        setExistIdxAndChangeStatus((prev) => {
          const newData: { idx: number; dirtyProperties: string[] }[] = [...prev];
          newData[idx].dirtyProperties = [
            ...prev[idx].dirtyProperties.filter((it) => it !== key),
            key
          ];
          return newData;
        });
      } else {
        setExistIdxAndChangeStatus((prev) => {
          const newData: { idx: number; dirtyProperties: string[] }[] = [...prev];
          newData[idx].dirtyProperties = [
            ...prev[idx].dirtyProperties.filter((it) => it !== key),
          ];
          return newData;
        });
      }
    }

    setRequestEntities((prev) => {
      const newData = [...prev];
      newData[idx].endAt = e.target.value;
      return newData;
    });
  };

  const workingAvailableDurationHoursOnChange: (e: SelectChangeEvent<number>, idx: number) => void = (e, idx) => {
    const isExistEntity = !!existIdxAndChangeStatus.find((it) => it.idx === idx);
    if (isExistEntity) {
      const defaultVal = defaultRequestEntities[idx]?.workingAvailableDurationHours;

      const key = 'workingAvailableDurationHours';
      const isDirty = (defaultVal || 0) !== Number(e.target.value);

      if (isDirty) {
        setExistIdxAndChangeStatus((prev) => {
          const newData: { idx: number; dirtyProperties: string[] }[] = [...prev];
          newData[idx].dirtyProperties = [
            ...prev[idx].dirtyProperties.filter((it) => it !== key),
            key
          ];
          return newData;
        });
      } else {
        setExistIdxAndChangeStatus((prev) => {
          const newData: { idx: number; dirtyProperties: string[] }[] = [...prev];
          newData[idx].dirtyProperties = [
            ...prev[idx].dirtyProperties.filter((it) => it !== key),
          ];
          return newData;
        });
      }
    }

    setRequestEntities((prev) => {
      const newData = [...prev];
      newData[idx].workingAvailableDurationHours = Number(e.target.value);
      return newData;
    });
  };

  const truckOnChange: (e: SelectChangeEvent<number>, idx: number) => void = (e, idx) => {
    const isExistEntity = !!existIdxAndChangeStatus.find((it) => it.idx === idx);
    if (isExistEntity) {
      const defaultVal = defaultRequestEntities[idx]?.truckId;

      const key = 'truckId';
      const isDirty = (defaultVal || 0) !== Number(e.target.value);

      if (isDirty) {
        setExistIdxAndChangeStatus((prev) => {
          const newData: { idx: number; dirtyProperties: string[] }[] = [...prev];
          newData[idx].dirtyProperties = [
            ...prev[idx].dirtyProperties.filter((it) => it !== key),
            key
          ];
          return newData;
        });
      } else {
        setExistIdxAndChangeStatus((prev) => {
          const newData: { idx: number; dirtyProperties: string[] }[] = [...prev];
          newData[idx].dirtyProperties = [
            ...prev[idx].dirtyProperties.filter((it) => it !== key),
          ];
          return newData;
        });
      }
    }

    setRequestEntities((prev) => {
      const newData = [...prev];
      newData[idx].truckId = Number(e.target.value);
      return newData;
    });
  };

  const getSelectableHours: (entity: RequestEntity, idx: number) => number[] = (entity, idx) => {
    const { startAt: startTime, endAt: endTime } = entity;
    const canRun = [startTime, endTime].every((maybe) => !!maybe);

    if (!canRun) {
      return [];
    }

    const startAt = parse(startTime, 'HH:mm', new Date());
    let endAt = parse(endTime, 'HH:mm', new Date());
    if (endAt < startAt) {
      endAt = parse(endTime, 'HH:mm', addDays(endAt, 1),);
    }
    const diffMicroSeconds = endAt.getTime() - startAt.getTime();
    const diffHours = Math.round(Math.abs(diffMicroSeconds) / (60 * 60 * 1000));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const hours = [...Array(diffHours)].map((_, index) => index + 1);
    if (entity.workingAvailableDurationHours && !hours.includes(entity.workingAvailableDurationHours)) {
      setRequestEntities((prev) => {
        const newData = [...prev];
        newData[idx].workingAvailableDurationHours = undefined;
        return newData;
      });
    }
    return hours;
  };

  const addRequestEntityButtonOnClick: () => void = () => {
    setRequestEntities((prev) => {
      const newData = [...prev];
      newData.push({ ...defaultRequestEntity });
      return newData;
    });
  };

  const deleteRequestEntityButtonOnClick: (idx: number) => void = (idx) => {
    setRequestEntities((prev) => {
      const newData = [...prev.filter((_, i) => i !== idx)];

      return newData;
    });
  };

  useEffect(() => {
    if (!driver) return;

    setDefaultRequestEntity({
      startAt: defaultStartAt,
      endAt: defaultEndAt,
      workingAvailableDurationHours: defaultWorkingAvailableDurationHours,
      truckId: driver.defaultTruckId
    });
  }, [defaultEndAt, defaultStartAt, defaultWorkingAvailableDurationHours, driver]);

  useEffect(() => {
    if (!defaultRequestEntities) return;
    if (requestEntities.length) return;

    setRequestEntities(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      JSON.parse(JSON.stringify(defaultRequestEntities)).map((it, idx) => ({ ...it, key: idx }))
    );
  }, [defaultRequestEntities, requestEntities]);

  useEffect(() => {
    if (!defaultRequestEntities) return;

    setExistIdxAndChangeStatus(
      defaultRequestEntities.filter((it) => it.id).map((it, idx) => ({
        idx,
        dirtyProperties: []
      }))
    );
  }, [defaultRequestEntities]);

  useEffect(() => {
    if (requestEntities.length === 0) return;

    const endAts: Date[] = requestEntities.map((entity) => {
      const startAt = parse(entity.startAt, 'HH:mm', new Date(date));
      let endAt = parse(entity.endAt, 'HH:mm', new Date(date));
      if (endAt < startAt) {
        endAt = parse(entity.endAt, 'HH:mm', addDays(endAt, 1),);
      }
      return endAt;
    });
    const lastEndAt = endAts.sort((a, b) => (b.getTime() - a.getTime()))[0];
    setActiveTrucks(
      truckData.filter((truck) => truck.activeUntil === null || lastEndAt.getTime() < new Date(truck.activeUntil).getTime())
    );
  }, [date, requestEntities, truckData]);

  const [deliveryExists, setDeliveryExists] = useState(false);
  useEffect(() => {
    setDeliveryExists(shiftCell.some((cell) => deliveryExistingCells.some((it) => cell.date === it.date && cell.driver_id === it.driver_id)));
  }, [deliveryExistingCells, shiftCell]);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const validateTimes = useCallback(() => {
    const sorted = requestEntities.sort((a, b) => a.startAt.localeCompare(b.startAt));
    for (let i = 0; i < sorted.length - 1; i++) {
      if (sorted[i].endAt > sorted[i + 1].startAt) {
        setErrorMessage('勤務時間が重複しています。');
        return false;
      }
    }
    return true;
  }, [requestEntities]);

  return (
    <>
      <DialogTitle>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          <Typography variant="body1" color="error">{errorMessage}</Typography>
        </DialogContentText>
        <Stack direction="column" spacing={2}>
          {requestEntities.map((entity, idx) => (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              gap={1}
              key={`${entity.key}-${entity.startAt}-${entity.endAt}`}
            >
              <Stack>
                <InputLabel>開始時間</InputLabel>
                <TextField
                  type="time"
                  defaultValue={entity.startAt}
                  onBlur={(e: FocusEvent<HTMLInputElement>) => startTimeOnBlur(e, idx)}
                  sx={{
                    minWidth: 100
                  }}
                  size="small"
                  disabled={isLoading}
                />
              </Stack>
              <Stack>
                <InputLabel>終了時間</InputLabel>
                <TextField
                  type="time"
                  defaultValue={entity.endAt}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => endTimeOnBlur(e, idx)}
                  sx={{
                    minWidth: 100
                  }}
                  size="small"
                  disabled={isLoading}
                />
              </Stack>
              <Stack>
                <InputLabel>労働時間</InputLabel>
                <Select
                  value={entity.workingAvailableDurationHours || ''}
                  onChange={(e: SelectChangeEvent<number>) => workingAvailableDurationHoursOnChange(e, idx)}
                  sx={{ minWidth: 100 }}
                  size="small"
                  disabled={isLoading}
                >
                  <MenuItem value="">
                    未指定
                  </MenuItem>
                  {getSelectableHours(entity, idx).map((hour) => (
                    <MenuItem key={['workingAvailableDurationHours', hour].join('-')} value={hour}>
                      {hour}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              {!!driver && (
                <Stack>
                  <InputLabel>使用車両</InputLabel>
                  <Select
                    value={entity.truckId}
                    sx={{ minWidth: 150 }}
                    size="small"
                    onChange={(e: SelectChangeEvent<number>) => truckOnChange(e, idx)}
                    disabled={isLoading}
                  >
                    {activeTrucks.map((truck) => (
                      <MenuItem
                        key={['EditDialogPresenter', 'truckData', 'MenuItem', truck.id].join('-')}
                        value={truck.id}
                      >
                        {truck.licensePlateValue}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              )}
              <IconButton
                disabled={requestEntities.length === 1 || isLoading}
                onClick={() => deleteRequestEntityButtonOnClick(idx)}
              >
                <HighlightOffRoundedIcon />
              </IconButton>
            </Stack>
          ))}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{
            mt: 2
          }}
        >
          <Button
            onClick={addRequestEntityButtonOnClick}
            disabled={isLoading}
          >
            <AddCircleOutlineIcon />
            シフトを追加
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        {(deliveryExists && deleteRequestEntityButtonOnClick) && (
          <LoadingButton
            startIcon={<DeleteForeverRoundedIcon />}
            color="error"
            onClick={() => deleteRequestOnClick(shiftCell)}
            loadingPosition="start"
            loading={isLoading}
          >
            {shiftCell.length > 1 ? 'すべて削除する' : '削除する'}
          </LoadingButton>
        )}
        <LoadingButton
          loadingPosition="start"
          startIcon={<SaveAsRoundedIcon />}
          loading={isLoading}
          onClick={() => {
            setErrorMessage('');
            if (validateTimes()) {
              createRequestButtonOnClick(requestEntities, shiftCell);
            }
          }}
        >
          登録・変更する
        </LoadingButton>
      </DialogActions>
    </>
  );
});

export default EditDialogPresenter;
