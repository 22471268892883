import { Button, Card, CardActions, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';

const ThanksPage: FC = memo(() => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>サブスクリプション登録完了</title>
      </Helmet>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: '100vh',
        }}
      >
        <Card>
          <CardHeader
            title="サブスクリプション登録完了"
          />
          <CardContent>
            <Typography>
              サブスクリプション登録が完了しました。
            </Typography>
            <Typography>
              登録していただいたメールアドレスに、本人確認メールが送信されています。
              <br />
              メール内のリンクをクリックして、本人確認を完了させてください。
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              onClick={() => navigate('/login')}
              variant="contained"
              color="primary"
              fullWidth
              size="large"
            >
              ログインページに移動する
            </Button>
          </CardActions>
        </Card>
      </Stack>
    </>
  );
});

export default ThanksPage;
