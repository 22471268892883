import { parse } from 'date-fns';

export class DateModel {
  value: string;

  constructor(value: string) {
    this.value = value.trim();
  }

  isValid(): boolean {
    return parse(
      this.value,
      'yyyy-MM-dd',
      new Date()
    ).toString() !== 'Invalid Date';
  }

  isInValid(): boolean {
    return !this.isValid();
  }

  toString(): string {
    return this.value;
  }
}
