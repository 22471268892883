import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ManualPlanningRequestEntity } from 'src/entities/registration/ManualPlanning.request.entity';

const path = '/api/v3/license/subscription/manual_planning';

export const useManualPlanningRequest = () => ({
  create: useMutation(
    (data: ManualPlanningRequestEntity) => axios.post(path, data),
  )
});
