import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined } from '@mui/icons-material';
import SaveAsRoundedIcon from '@mui/icons-material/SaveAsRounded';
import { LoadingButton } from '@mui/lab';
import { IconButton, Paper, Stack, TextField } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, memo, useContext, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm, useFieldArray, UseFormReturn } from 'react-hook-form';
import LicenseContext from 'src/contexts/LicenseContext';
import PlansContext from 'src/contexts/PlansContext';
import { OrderSplitEntity } from 'src/entities/orderSplitEntity';
import { useMutationOrder } from 'src/hooks/useQueryOrders';
import * as yup from 'yup';

type OrderSplitFormProps = {
  onClose?: () => void;
  entity: OrderSplitEntity;
  createAfterCallbackFnc?: () => void;
};

// 分割する数量の合計がもとの値より小さいこと
function validateItemCount(values): boolean {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const originalValue = this.from[0].value.original_item_count;
  // もとの数量が1の場合はそのまま分割する
  if (originalValue === 1) { return true; }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, camelcase, @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-return
  const itemCountSum = values.reduce((sum, x) => sum + x.item_count, 0);
  return (originalValue - itemCountSum) > 0;
}

// 分割する重量の合計がもとの値より小さいこと
function validateItemTotalWeightKg(values): boolean {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const originalValue = this.from[0].value.original_item_total_weight_kg;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, camelcase, @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-return
  const itemWeightSum = values.reduce((sum, x) => sum + x.item_total_weight_kg, 0);
  return (originalValue - itemWeightSum) > 0;
}

// 分割する体積の合計がもとの値より小さいこと
function validateItemTotalVolumeM3(values): boolean {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const originalValue = this.from[0].value.original_item_total_volume_m3;
  if (originalValue === undefined) return true;
  // もとの数量が1の場合はそのまま分割する
  if (originalValue === 0 || originalValue === 1) { return true; }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, camelcase, @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-return
  const itemVolumeSum = values.reduce((sum, x) => sum + x.item_total_volume_m3, 0);
  return (originalValue - itemVolumeSum) > 0;
}

// もとの体積が入力されていない場合に、分割する体積が入力されていないこと
function validateItemTotalVolumeM3Undefined(values): boolean {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const originalValue = this.from[0].value.original_item_total_volume_m3;
  if (originalValue !== undefined) return true;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  return values.every((elem) => (elem.item_total_volume_m3 === null || elem.item_total_volume_m3 === undefined));
}

const schema = yup.object({
  original_item_count: yup.number(),
  original_total_weight_kg: yup.number(),
  original_total_volume_m3: yup.number(),
  is_item_total_volume_m3_required: yup.boolean(),
  data: yup
    .array()
    .of(
      yup.object().shape({
        item_count: yup
          .number()
          .nullable()
          .typeError('数字を入力してください')
          .positive('0より大きい数値を入力してください'),
        item_total_weight_kg: yup
          .number()
          .nullable()
          .typeError('数字を入力してください')
          .positive('0より大きい数値を入力してください'),
        item_total_volume_m3: yup
          .number()
          .nullable()
          .typeError('数字を入力してください')
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
      })
    )
    .test(
      'test1',
      '数量の合計はもとの値以下にしてください',
      validateItemCount
    )
    .test(
      'test2',
      '総重量の合計はもとの値以下にしてください',
      validateItemTotalWeightKg
    )
    .test(
      'test3',
      '総体積の合計はもとの値以下にしてください',
      validateItemTotalVolumeM3
    )
    .test(
      'test4',
      '総体積は入力しないでください',
      validateItemTotalVolumeM3Undefined
    )
});

const OrderSplitForm: FC<OrderSplitFormProps> = memo(
  ({
    onClose,
    entity,
    createAfterCallbackFnc
  }) => {
    const context = useContext(PlansContext);
    const licenseContext = useContext(LicenseContext);
    const queryClient = useQueryClient();

    const { enqueueSnackbar } = useSnackbar();

    function defaultSplitValues() {
      const count = entity.original_item_count === 1 ? 1 : 0;
      let weight = entity.original_item_total_weight_kg === 1 ? 1 : 0;
      if (entity.original_item_total_weight_kg === undefined) weight = null;
      let volume = entity.original_item_total_volume_m3 === 1 ? 1 : 0;
      if (entity.original_item_total_volume_m3 === undefined) volume = null;
      return {
        item_count: count, item_total_weight_kg: weight, item_total_volume_m3: volume
      };
    }

    const form: UseFormReturn<OrderSplitEntity> = useForm<OrderSplitEntity>({
      resolver: yupResolver(schema),
      defaultValues: {
        data: [defaultSplitValues()]
      }
    });

    const { formState, control, reset, register, watch, getValues, setValue } = form;
    const { errors } = formState;

    const { splitOrder } = useMutationOrder();

    const onSubmitSave: SubmitHandler<OrderSplitEntity> = (
      data: OrderSplitEntity
    ): void => {
      const resetQueryKeys = ['shifts', 'shift', 'unallocatedOrders', 'order', 'shippers', 'places', 'useQueryPlanningOrderStatistics'];

      context.updateIsLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      splitOrder.mutateAsync(data).then(() => {
        enqueueSnackbar('注文を分割保存しました');
        if (onClose) onClose();
      }).then(() => {
        if (createAfterCallbackFnc) createAfterCallbackFnc();
      }).catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument
        error.response.data.map((str) => enqueueSnackbar(str));
      })
        .finally(() => {
          context.updateIsLoading(false);
          // eslint-disable-next-line no-void
          void queryClient.resetQueries({
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            predicate: (query) => resetQueryKeys.includes(`${query.queryKey[0]}`),
          });
          context.reset();
        });
    };

    const {
      fields,
      append,
      remove
    } = useFieldArray({
      control,
      name: 'data'
    });

    useEffect(() => {
      if (!entity) return;
      if (!entity.data) {
        entity.data = [defaultSplitValues()];
      }

      reset({
        ...entity
      });
    }, [entity, reset]);

    const [originalItemCount, setOriginalItemCount] = useState(entity.original_item_count);
    const [originalItemTotalWeightKg, setOriginalItemTotalWeightKg] = useState(entity.original_item_total_weight_kg);
    const [originalItemTotalVolumeM3, setOriginalItemTotalVolumeM3] = useState(entity.original_item_total_volume_m3);

    const countElements = useRef<HTMLInputElement[]>([]);
    const weightElements = useRef<HTMLInputElement[]>([]);
    const volumeElements = useRef<HTMLInputElement[]>([]);

    const onChangeItemCount = (event: ChangeEvent<HTMLInputElement>) => {
      const total = countElements.current.reduce((sum, el) => sum + Number(el.value), 0);
      setOriginalItemCount(entity.original_item_count - total);

      const idx = Number(event.target.name.match(/\d+/)[0]);

      const unitWeightKg = Math.round((entity.original_item_total_weight_kg / entity.original_item_count) * 1000) / 1000;
      setValue(`data.${idx}.item_total_weight_kg`, Math.round((unitWeightKg * Number(event.target.value)) * 1000) / 1000);
      calculateOriginalItemTotalWeightKg();

      if (entity.original_item_total_volume_m3 === undefined) { return; }
      const unitVolumeM3 = Math.round((entity.original_item_total_volume_m3 / entity.original_item_count) * 1000) / 1000;
      setValue(`data.${idx}.item_total_volume_m3`, Math.round((unitVolumeM3 * Number(event.target.value)) * 1000) / 1000);
      calculateOriginalItemTotalVolumeM3();
    };

    const onChangeItemTotalWeightKg = (event: ChangeEvent<HTMLInputElement>) => {
      calculateOriginalItemTotalWeightKg();
    };

    const calculateOriginalItemTotalWeightKg = () => {
      const value = entity.original_item_total_weight_kg - weightElements.current.reduce((sum, el) => sum + Number(el.value), 0);
      setOriginalItemTotalWeightKg(Math.round(value * 1000) / 1000);
    };

    const onChangeItemTotalVolumeM3 = (event: ChangeEvent<HTMLInputElement>) => {
      if (entity.original_item_total_volume_m3 === undefined) { return; }
      calculateOriginalItemTotalVolumeM3();
    };

    const calculateOriginalItemTotalVolumeM3 = () => {
      const value = entity.original_item_total_volume_m3 - volumeElements.current.reduce((sum, el) => sum + Number(el.value), 0);
      setOriginalItemTotalVolumeM3(Math.round(value * 1000) / 1000);
    };

    return (
      <Stack>
        <Paper>
          <Stack
            pt={1}
            pb={2}
            px={2}
          >
            <Stack
              direction="row"
              gap={1}
              alignItems="baseline"
            >
              <TextField
                margin="dense"
                type="number"
                variant="standard"
                disabled
                fullWidth
                id="original_item_count"
                {...register('original_item_count')}
                label="数量"
                value={originalItemCount}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                margin="dense"
                type="number"
                variant="standard"
                disabled
                fullWidth
                id="original_item_total_weight_kg"
                {...register('original_item_total_weight_kg')}
                label={`総重量(${licenseContext?.config?.unit})`}
                value={originalItemTotalWeightKg}
                InputLabelProps={{ shrink: true }}
              />
              {licenseContext?.config?.unit_used_for_calculation === '重量と体積' && (
                <TextField
                  margin="dense"
                  type="number"
                  variant="standard"
                  disabled
                  fullWidth
                  id="original_item_total_volume_m3"
                  {...register('original_item_total_volume_m3')}
                  label="総体積(m3)"
                  value={originalItemTotalVolumeM3}
                  InputLabelProps={{ shrink: true }}
                />
              )}
              <Stack marginRight={10} />
            </Stack>
          </Stack>
        </Paper>
        <Paper
          sx={{
             mt: 1,
             mb: 1
          }}
        >
          <Stack
            pt={1}
            pb={2}
            px={2}
          >
            {
              fields.map((field, idx) => (
                <Stack
                  key={['OrderSplitForm-values', idx].join(',')}
                  direction="row"
                  gap={1}
                  alignItems="baseline"
                >
                  <TextField
                    margin="dense"
                    type="number"
                    variant="standard"
                    fullWidth
                    disabled={entity.original_item_count === 1}
                    id={`data[${idx}].item_count`}
                    label="数量"
                    {...register(`data.${idx}.item_count`)}
                    error={!!errors.data?.[idx]?.item_count || errors.data?.type === 'test1'}
                    helperText={errors.data?.[idx]?.item_count?.message || (errors.data?.type === 'test1' ? errors.data?.message : '')}
                    required
                    InputLabelProps={{ shrink: true }}
                    inputRef={(el: HTMLInputElement) => countElements.current[idx] = el}
                    onChange={onChangeItemCount}
                  />
                  <TextField
                    margin="dense"
                    type="number"
                    variant="standard"
                    fullWidth
                    id={`data[${idx}].item_total_weight_kg`}
                    label={`総重量(${licenseContext?.config?.unit})`}
                    {...register(`data.${idx}.item_total_weight_kg`)}
                    error={!!errors.data?.[idx]?.item_total_weight_kg || errors.data?.type === 'test2'}
                    helperText={errors.data?.[idx]?.item_total_weight_kg?.message || (errors.data?.type === 'test2' ? errors.data?.message : '')}
                    required
                    InputLabelProps={{ shrink: true }}
                    inputRef={(el: HTMLInputElement) => weightElements.current[idx] = el}
                    onChange={onChangeItemTotalWeightKg}
                  />
                  {licenseContext?.config?.unit_used_for_calculation === '重量と体積' && (
                    <TextField
                      margin="dense"
                      type="number"
                      variant="standard"
                      fullWidth
                      label="総体積(m3)"
                      id={`data[${idx}].item_total_volume_m3`}
                      {...register(`data.${idx}.item_total_volume_m3`)}
                      error={!!errors.data?.[idx]?.item_total_volume_m3 || errors.data?.type === 'test3' || errors.data?.type === 'test4'}
                      helperText={errors.data?.[idx]?.item_total_volume_m3?.message || (errors.data?.type === 'test3' || errors.data?.type === 'test4' ? errors.data?.message : '')}
                      InputLabelProps={{ shrink: true }}
                      inputRef={(el: HTMLInputElement) => volumeElements.current[idx] = el}
                      onChange={onChangeItemTotalVolumeM3}
                    />
                  )}
                  <IconButton
                    onClick={() => append(defaultSplitValues())}
                  >
                    <AddCircleOutlineOutlined />
                  </IconButton>
                  <IconButton
                    onClick={() => remove(idx)}
                  >
                    <RemoveCircleOutlineOutlined />
                  </IconButton>
                </Stack>
              ))
            }
          </Stack>
        </Paper>
        <Stack direction="row" spacing={2}>
          <LoadingButton
            loading={context.isLoading}
            onClick={() => { onClose(); }}
          >
            キャンセル
          </LoadingButton>
          <LoadingButton
            startIcon={<SaveAsRoundedIcon />}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={form.handleSubmit(onSubmitSave)}
            variant="contained"
            loading={context.isLoading}
            loadingPosition="start"
          >
            保存する
          </LoadingButton>
        </Stack>
      </Stack>
    );
  }
);

export default OrderSplitForm;
