import { format } from 'date-fns-tz';
import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { PresenterProps } from 'src/components/TruckDirectionsComponent';

import style from './print.module.css';

const TruckDirectionPresenter: FC<PresenterProps> = memo(({ action, models, operationsLimit, unit }) => {
  const tdWidth = `${(100 / models.length)}%`;

  return (
    <>
      <Helmet>
        <title>配車表</title>
      </Helmet>
      <div className={style.page}>
        <table
          style={{
            width: '100%'
          }}
        >
          <thead>
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th rowSpan={3} />
              {models.filter(({ idx }) => idx === 0).map((model) => (
                <th
                  colSpan={model.colSpan}
                  key={['driverName', model.model.driverName(), model.model.id].join('-')}
                >
                  {model.model.driverName()}
                </th>
            ))}
            </tr>
            <tr>
              {models.filter(({ idx }) => idx === 0).map((model) => (
                <th
                  colSpan={model.colSpan}
                  key={['licensePlateValue', model.model.driverName(), model.model.id].join('-')}
                >
                  {model.model.licensePlateValue()}
                </th>
            ))}
            </tr>
            <tr>
              {models.filter(({ idx }) => idx === 0).map((model) => (
                <th
                  style={{
                  textAlign: 'center'
                }}
                  colSpan={model.colSpan}
                  key={['orderIdsUniqueCount', model.model.id].join('-')}
                >
                  {Array.from(new Set(model.model.filterIndexedOperations(action).map((order) => order.id))).length}
                  案件
                </th>
            ))}
            </tr>
            {Array.from({ length: operationsLimit }).map((_, idx) => (
              <tr
                key={['operations', idx].join('-')}
              >
                <td
                  style={{
                  textAlign: 'center',
                }}
                >
                  {idx + 1}
                </td>
                {models.map((model) => (
                  <td
                    key={[
                    'operations',
                    'action',
                    model.model.id,
                    model.operations.map((op) => op.id).join('-'),
                    idx
                  ].join('-')}
                    style={{
                    width: tdWidth
                  }}
                  >
                    {
                    model.operations[idx] && `${model.operations[idx].actionJa} ${model.operations[idx].shipperName} ${model.operations[idx].placeName}`
                  }
                    {
                    model.operations[idx] && <br />
                  }
                    {
                    model.operations[idx] && `${format(model.operations[idx].arrivalAt, 'HH:mm', { timeZone: 'Asia/Tokyo' })} ${`${model.operations[idx].order.itemTotalWeightKg} ${unit}`} / ${model.operations[idx].order.itemCount} 個`
                  }
                    {unit !== '才'
                      && [model.operations[idx], model.operations[idx]?.order?.itemTotalVolumeM3].every((maybe) => maybe)
                        ? ` / ${model.operations[idx].order.itemTotalVolumeM3} m3`
                        : ''}
                  </td>
              ))}
              </tr>
          ))}
          </thead>
        </table>
      </div>
    </>
  );
});

export default TruckDirectionPresenter;
