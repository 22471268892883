/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-array-index-key */
import ContentPasteGoRoundedIcon from '@mui/icons-material/ContentPasteGoRounded';
import ControlPointDuplicateRoundedIcon from '@mui/icons-material/ControlPointDuplicateRounded';
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  Select,
  MenuItem,
  Typography,
  Button,
  SelectChangeEvent,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableHead,
  Box,
  TableCell,
  TableRow,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  FormControlLabel,
  TextField,
  DialogActions,
  Divider, IconButton
} from '@mui/material';
import {
  format,
  addMonths,
  subMonths,
  isSameDay,
  startOfWeek,
  isSameMonth,
  isAfter, addDays, eachDayOfInterval
} from 'date-fns';
import { useSnackbar } from 'notistack';
import { memo, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import LoadingComponent from 'src/components/LoadingComponent';
import LicenseContext from 'src/contexts/LicenseContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { PlanDuplicationRequestEntity } from 'src/entities/PlanDuplication.request.entity';
import { usePlanDuplicationRequestMutation } from 'src/hooks/usePlanDuplication.request.mutation';
import { useQueryDashboards } from 'src/hooks/useQueryDashboards';
import { DayOfWeekVo } from 'src/vo/DayOfWeek.vo';

import Bar from './Bar';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

const Calendar: React.FC<Props> = memo(() => {
  const licenseContext = useContext(LicenseContext);
  const [unit, setUnit] = useState('');

  useEffect(() => {
    if (!licenseContext) return;
    if (!licenseContext.config) return;

    setUnit(licenseContext.config.unit);
  }, [licenseContext]);

  const CURRENT_MONTH_KEY = 'dashboardCurretMonth';
  const navigate = useNavigate();
  const { create } = usePlanDuplicationRequestMutation();
  const { enqueueSnackbar } = useSnackbar();

  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [onlyManualPlanning, setOnlyManualPlanning] = useState<boolean>(false);
  const [planningOn, setPlanningOn] = useState<string>('');
  const [dayOfWeekOrSpecifiedDay, setDayOfWeekOrSpecifiedDay] = useState<'dayOfWeek' | 'specifiedDay'>('dayOfWeek');
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<DayOfWeekVo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [specifiedAt, setSpecifiedAt] = useState<Date | null>(null);
  const [copyFromAt, setCopyFromAt] = useState<Date | null>(null);
  const [maximumRepeatableAt, setMaximumRepeatableAt] = useState<Date | null>(null);
  const [repeatableAt, setRepeatableAt] = useState<Date | null>(null);
  const [copyToAts, setCopyToAts] = useState<Date[]>([]);
  const [canDupRequest, setCanDupRequest] = useState<boolean>(false);

  const updateRepeatableAt = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value;

    if (!date) {
      setRepeatableAt(null);
      return;
    }

    const at = new Date(date);

    if (maximumRepeatableAt < at) return;

    setRepeatableAt(at);
  };

  const selectDaysOfWeekOnClick = (dayOfWeek: DayOfWeekVo) => {
    if (dayOfWeekOrSpecifiedDay !== 'dayOfWeek') return;

    if (selectedDaysOfWeek.includes(dayOfWeek)) {
      setSelectedDaysOfWeek(selectedDaysOfWeek.filter((d) => d !== dayOfWeek));
    } else {
      setSelectedDaysOfWeek([...selectedDaysOfWeek, dayOfWeek]);
    }
  };

  const displayDaysOfWeek: DayOfWeekVo[] = [
    '日',
    '月',
    '火',
    '水',
    '木',
    '金',
    '土'
  ];

  const specifiedDayOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === planningOn) {
      enqueueSnackbar('複製元の日付と同じ日付は指定できません。', {
        variant: 'error'
      });
      return;
    }

    const at = new Date(event.target.value);

    setSpecifiedAt(at);
  };

  const duplicationButtonOnClick = () => {
    if (!canDupRequest) return;

    setIsLoading(true);

    const requestEntity: PlanDuplicationRequestEntity = {
      planning_on: planningOn,
      specific_days: copyToAts.map((it) => datetimeDecorator.toYyyyMmDd(it)),
      reset_key: JSON.stringify([
        'dashboards',
        {
          date: format(currentMonth, 'yyyy-MM')
        }
      ])
    };
    enqueueSnackbar('複製を開始します');

    create.mutate(requestEntity, {
      onSuccess: () => {
        licenseContext.updateForceDisabled(true);
      },
      onSettled: () => {
        setPlanningOn('');
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    setCanDupRequest(copyToAts.length > 0);
  }, [copyToAts]);

  useEffect(() => {
    if (!planningOn) {
      setCopyToAts([]);
      return;
    }

    if (dayOfWeekOrSpecifiedDay === 'specifiedDay') {
      if (!specifiedAt) {
        setCopyToAts([]);
        return;
      }

      setCopyToAts([specifiedAt]);
      return;
    }

    if (dayOfWeekOrSpecifiedDay === 'dayOfWeek') {
      if (selectedDaysOfWeek.length === 0 && !repeatableAt) {
        setCopyToAts([]);
        return;
      }

      if ([copyFromAt, repeatableAt].some((it) => it === null)) {
        setCopyToAts([]);
        return;
      }
      if (copyFromAt > repeatableAt) {
        setCopyToAts([]);
        return;
      }

      const copyDaysOfInterval = eachDayOfInterval({
        start: copyFromAt,
        end: repeatableAt
      });

      const selectedDaysOfWeekIdxes = selectedDaysOfWeek.map((dayOfWeek) => displayDaysOfWeek.indexOf(dayOfWeek));

      setCopyToAts(
        copyDaysOfInterval.filter((it) => selectedDaysOfWeekIdxes.includes(it.getDay()))
      );
    }
  }, [planningOn, selectedDaysOfWeek, specifiedAt, repeatableAt, dayOfWeekOrSpecifiedDay, copyFromAt]);

  useEffect(() => {
    if (!planningOn) {
      setMaximumRepeatableAt(null);
      setCopyFromAt(null);
      return;
    }

    const planningAt = new Date(planningOn);

    setMaximumRepeatableAt(
      addDays(planningAt, 30)
    );
    setCopyFromAt(
      addDays(planningAt, 1)
    );
  }, [planningOn]);

  useEffect(() => {
    setRepeatableAt(maximumRepeatableAt);
  }, [maximumRepeatableAt]);

  useEffect(() => {
    if (!licenseContext?.config) {
      return;
    }

    if (licenseContext.config.use_algorithm_planning) {
      setOnlyManualPlanning(false);
      return;
    }

    setOnlyManualPlanning(licenseContext.config.use_manual_planning);
  }, [licenseContext]);

  useEffect(() => {
    const month = sessionStorage.getItem(CURRENT_MONTH_KEY);
    if (month) {
      setCurrentMonth(new Date(month));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem(CURRENT_MONTH_KEY, format(currentMonth, 'yyyy-MM-dd'));
  }, [currentMonth]);

  const handlePrevMonth = () => {
    setCurrentMonth((prevMonth) => subMonths(prevMonth, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => addMonths(prevMonth, 1));
  };

  const handleCurrentMonth = () => {
    setCurrentMonth(new Date());
  };

  const handleMonthChange = (event: SelectChangeEvent<number>) => {
    const selectedMonth = event.target.value as number;
    const newSelectedMonth = new Date(
      currentMonth.getFullYear(),
      selectedMonth
    );
    setCurrentMonth(newSelectedMonth);
  };

  const handleSelectDate = (date: Date) => {
    if (onlyManualPlanning) {
      navigate(`/timeline/${format(date, 'yyyy-MM-dd')}/${format(date, 'yyyy-MM-dd')}`);
    } else {
      navigate(`/plans/${format(date, 'yyyy-MM-dd')}/${format(date, 'yyyy-MM-dd')}`);
    }
  };

  const daysOfWeek = () => {
    const days = ['日', '月', '火', '水', '木', '金', '土'];
    return (
      <TableRow>
        {days.map((day) => (
          <TableCell key={day} align="center" sx={{ padding: 0 }} width="14%">
            {day}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const todayStyle = {
    backgroundColor: '#5498D5',
    color: '#ffffff',
    fontWeight: 'bold',
    borderRadius: '50%',
    display: 'inline-block',
    width: '1.8em',
    height: '1.8em',
    lineHeight: '1.8em',
  };

  const currentMonthStyle = {
    backgroundColor: '#ffffff',
  };

  const notCurrentMonthStyle = {
    backgroundColor: '#f0f0f0',
    filter: 'grayscale(50%) brightness(0.95)',
  };

  const dashboardQuery = useQueryDashboards(format(currentMonth, 'yyyy-MM-dd'));

  if (dashboardQuery.isLoading) {
    return <LoadingComponent />;
  }

  if (dashboardQuery.isError) {
    return <>Error</>;
  }

  const cells = () => {
    const dateFormat = 'd';
    const rows = [];
    let days = [];
    let day = startOfWeek(currentMonth);
    let formattedDate = '';

    dashboardQuery.data.forEach((summary, index) => {
      day = new Date(summary.date);
      formattedDate = format(day, dateFormat);
      const cloneDay = day;
      days.push(
        <TableCell
          key={day.toString()}
          align="center"
          sx={{ padding: 0, border: '1px solid #E5E6E6' }}
        >
          <Box
            sx={{
              position: 'relative'
            }}
            style={isSameMonth(day, currentMonth) ? currentMonthStyle : notCurrentMonthStyle}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                display: 'block',
                width: 40,
                height: 40,
              }}
              onClick={() => {
                setPlanningOn(summary.date);
              }}
            >
              <ContentPasteGoRoundedIcon />
            </IconButton>
            <Box p={1}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                onClick={() => handleSelectDate(cloneDay)}
                sx={{
                  cursor: 'pointer'
                }}
              >
                <Typography
                  variant="h5"
                  align="center"
                  sx={isSameDay(day, new Date()) ? {
                    ...todayStyle
                  } : { padding: '0.1rem 0.5em' }}
                >
                  {formattedDate}
                </Typography>
              </Stack>
              <Box
                mt={1} p={1}
                border="1px solid #A5A6A6" borderRadius={1}
              >
                <Grid container bgcolor="#f5f5f5" my="2px">
                  <Grid item width="5em" color={isSameDay(day, new Date()) || isAfter(day, new Date()) ? '#505050' : '#c3c3c3'} textAlign="left" padding="0.1em 0.2em">案件</Grid>
                  <Grid item flexGrow={1} color={isSameDay(day, new Date()) || isAfter(day, new Date()) ? '#505050' : '#c3c3c3'} textAlign="right">
                    <Bar value={summary.order.count} max={summary.order.count} color="#5498D5" unit="件" />
                  </Grid>
                </Grid>
                <Grid container bgcolor="#f5f5f5" my="2px">
                  <Grid item width="5em" color={isSameDay(day, new Date()) || isAfter(day, new Date()) ? '#505050' : '#c3c3c3'} textAlign="left" padding="0.1em 0.2em">配車済</Grid>
                  <Grid item flexGrow={1} color={isSameDay(day, new Date()) || isAfter(day, new Date()) ? '#505050' : '#c3c3c3'} textAlign="right">
                    <Bar value={summary.order.planned} max={summary.order.count} color="#5498D5" unit="件" />
                  </Grid>
                </Grid>
                <Grid container bgcolor="#f5f5f5" my="2px">
                  <Grid item width="5em" color={isSameDay(day, new Date()) || isAfter(day, new Date()) ? '#505050' : '#c3c3c3'} textAlign="left" padding="0.1em 0.2em">総重量</Grid>
                  <Grid item flexGrow={1} color={isSameDay(day, new Date()) || isAfter(day, new Date()) ? '#505050' : '#c3c3c3'} textAlign="right">
                    <Bar value={summary.order.weightKg} max={summary.order.weightKg} color="#AACF53" unit={unit} />
                  </Grid>
                </Grid>
                <Grid container bgcolor="#f5f5f5" my="2px">
                  <Grid item width="5em" color={isSameDay(day, new Date()) || isAfter(day, new Date()) ? '#505050' : '#c3c3c3'} textAlign="left" padding="0.1em 0.2em">総体積</Grid>
                  <Grid item flexGrow={1} color={isSameDay(day, new Date()) || isAfter(day, new Date()) ? '#505050' : '#c3c3c3'} textAlign="right">
                    <Bar value={summary.order.volumeM3} max={summary.order.volumeM3} color="#AACF53" unit="m3" />
                  </Grid>
                </Grid>
                <Grid container bgcolor="#f5f5f5" my="2px">
                  <Grid item width="5em" color={isSameDay(day, new Date()) || isAfter(day, new Date()) ? '#505050' : '#c3c3c3'} textAlign="left" padding="0.1em 0.2em">使用車両</Grid>
                  <Grid item flexGrow={1} color={isSameDay(day, new Date()) || isAfter(day, new Date()) ? '#505050' : '#c3c3c3'} textAlign="right">
                    <Bar value={summary.order.trucks} max={summary.order.trucks} color="#F9C158" unit="台" />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </TableCell>
      );
      if ((index + 1) % 7 === 0) {
        rows.push(<TableRow key={day.toString()}>{days}</TableRow>);
        days = [];
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return rows;
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs width="100%" mt={5} mb={3}>
        <Grid container alignItems="center">
          <Grid item flexGrow={0} width={200}>
            <Select
              value={currentMonth.getMonth()}
              onChange={handleMonthChange}
              fullWidth
              disableUnderline
            >
              {[...Array(12)].map((_, index) => (
                <MenuItem key={index} value={index}>
                  {format(new Date(currentMonth.getFullYear(), index), 'M月')}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item flexGrow={1}>
            <Typography variant="h3" align="center">
              {format(currentMonth, 'yyyy年 M月')}
            </Typography>
          </Grid>
          <Grid item flexGrow={0} width={200}>
            <Button onClick={handlePrevMonth}>前月</Button>
            <Button onClick={handleCurrentMonth}>今月</Button>
            <Button onClick={handleNextMonth}>翌月</Button>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
        <Table>
          <TableHead>{daysOfWeek()}</TableHead>
          <TableBody>{cells()}</TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={!!planningOn}
        onClose={() => {
          if (isLoading) return;

          setPlanningOn('');
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography
            variant="h5"
          >
            {planningOn}
            {planningOn && [
              '(',
              datetimeDecorator.toDayOfWeek(new Date(planningOn)),
              ')'
            ].join('')}
            の配送計画・未割り当て案件を複製する
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
            divider={<Divider />}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <FormControlLabel
                  control={(
                    <Checkbox
                      size="small"
                    />
                  )}
                  label="曜日指定"
                  checked={dayOfWeekOrSpecifiedDay === 'dayOfWeek'}
                  onClick={() => {
                    setDayOfWeekOrSpecifiedDay('dayOfWeek');
                  }}
                />
                {
                  displayDaysOfWeek.map((dayOfWeek) => (
                    <FormControlLabel
                      key={[
                        planningOn,
                        dayOfWeek,
                        'displayDaysOfWeek'
                      ].join('-')}
                      control={(
                        <Checkbox
                          size="small"
                          disabled={dayOfWeekOrSpecifiedDay !== 'dayOfWeek'}
                        />
                      )}
                      label={dayOfWeek}
                      checked={selectedDaysOfWeek.includes(dayOfWeek)}
                      onClick={() => {
                        selectDaysOfWeekOnClick(dayOfWeek);
                      }}
                    />
                  ))
                }
              </Stack>

              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <FormControlLabel
                  control={(
                    <Checkbox
                      size="small"
                    />
                  )}
                  label="日付指定"
                  checked={dayOfWeekOrSpecifiedDay === 'specifiedDay'}
                  onClick={() => {
                    setDayOfWeekOrSpecifiedDay('specifiedDay');
                  }}
                />
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <TextField
                    size="small"
                    sx={{
                      width: 155
                    }}
                    type="date"
                    value={specifiedAt && datetimeDecorator.toYyyyMmDd(specifiedAt)}
                    onChange={specifiedDayOnChange}
                  />
                </Stack>
              </Stack>
            </Stack>
            {
              (dayOfWeekOrSpecifiedDay === 'dayOfWeek') && copyFromAt && maximumRepeatableAt && repeatableAt && (
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Stack>
                    <Typography
                      variant="h5"
                    >
                      以下の条件で繰り返し登録する
                    </Typography>
                    <Typography variant="caption">
                      繰り返し登録は最大30日先まで可能です。
                      <br />
                      コピー先に既存で計画がある場合、計画・案件は削除されて、上書き保存されます。
                      <br />
                      日を跨ぐ案件のコピーは対応していません。
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0.3}
                  >
                    <TextField
                      size="small"
                      sx={{
                        width: 155
                      }}
                      inputProps={{
                        min: datetimeDecorator.toYyyyMmDd(copyFromAt),
                        max: datetimeDecorator.toYyyyMmDd(maximumRepeatableAt),
                      }}
                      variant="standard"
                      type="date"
                      value={datetimeDecorator.toYyyyMmDd(repeatableAt)}
                      onChange={updateRepeatableAt}
                    />
                    <Typography>
                      まで繰り返し登録する
                    </Typography>
                  </Stack>
                </Stack>
              )
            }
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            fullWidth
            disabled={!canDupRequest}
            loading={isLoading}
            variant="contained"
            startIcon={<ControlPointDuplicateRoundedIcon />}
            size="large"
            onClick={() => {
              duplicationButtonOnClick();
            }}
          >
            複製する
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Grid>
  );
});

export default Calendar;
