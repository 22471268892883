import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { SubscriptionKindEntity } from 'src/entities/SubscriptionKind.entity';

const requestUrl = '/api/admin/v1/subscription_kinds';

const getSubscriptionKinds = (queryClient: QueryClient) => async () => {
  const res = await axios.get<ApiResponse>(requestUrl);
  const data = res.data.data as unknown as SubscriptionKindEntity[];
  const total = res.data.total_pages_count;
  return { data, total };
};

export const useQuerySubscriptionKinds = () => {
  const queryClient = useQueryClient();
  return useQuery(['subscriptionsKinds'], getSubscriptionKinds(queryClient), {
    staleTime: Infinity
  });
};
