import { Stack, Typography, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { FC, memo, useContext, useEffect, useState } from 'react';
import LicenseContext from 'src/contexts/LicenseContext';
import { AccountSubscriptionEntity } from 'src/entities/AccountEntity';
import { SubscriptionKindEntity } from 'src/entities/SubscriptionKind.entity';

interface Props {
  subscriptionKinds: SubscriptionKindEntity[];
  subscriptions: AccountSubscriptionEntity[];
}

export const SubscriptionList: FC<Props> = memo((props: Props) => {
  const { subscriptionKinds, subscriptions } = props;

  const licenseContext = useContext(LicenseContext);
  const [displaySubscriptionKinds, setDisplaySubscriptionKinds] = useState<SubscriptionKindEntity[]>([]);

  useEffect(() => {
    if (!licenseContext?.config) return;
    if (!subscriptionKinds) return;

    setDisplaySubscriptionKinds(
      subscriptionKinds.filter((it) => {
        if (it.name === 'manual_planning' && licenseContext.config.use_algorithm_planning) {
          return false;
        }
        if (it.name === 'trunk_transportation' && licenseContext.config.app_env === 'prd') {
          return false;
        }
        return true;
      })
    );
  }, [licenseContext, subscriptionKinds]);

  return (
    <Stack>
      <Typography variant="h4" ml={2} pt={2}>
        機能設定
      </Typography>
      <Paper
        style={{
          width: '100%',
          padding: '8px'
        }}
      >
        <TableContainer sx={{ width: '80%' }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>機能名</TableCell>
                <TableCell>利用有無</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displaySubscriptionKinds.map((kind) => (
                <TableRow key={`subscription-${kind.id}`}>
                  <TableCell>
                    {kind.name_ja}
                  </TableCell>
                  <TableCell>
                    {
                      subscriptions.find((it) => it.subscription_kind_id === kind.id)
                        ? '有'
                        : [kind.name_ja, 'を利用したい場合はセールス (sales@logpose.co.jp) へご連絡ください。'].join('')
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Stack>
  );
});
