import { FC, memo, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import FourZeroFourComponent from 'src/components/FourZeroFourComponent';
import LicenseContext from 'src/contexts/LicenseContext';
import { DateModel } from 'src/models/DateModel';

import Component from './Shifts/Component';

const Shifts: FC = memo(() => {
  const { startOn, endOn, backTo } = useParams();
  const context = useContext(LicenseContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!context.config?.on) return;

    if (startOn) {
      const paramsStartOnModel = new DateModel(startOn);
      const paramsEndOnModel = new DateModel(endOn);

      if (paramsStartOnModel.isValid() && paramsEndOnModel.isValid()) {
        navigate([
          '/shifts',
          `${paramsStartOnModel.toString()}`,
          `${paramsEndOnModel.toString()}`,
          backTo ? `${backTo}` : '',
        ].filter((maybe) => maybe).join('/'));
        return;
      }
    }

    const configOnModel = new DateModel(context.config.on);

    if (configOnModel.isValid()) {
      navigate([
        '/shifts',
        `${configOnModel.toString()}`,
        `${configOnModel.toString()}`,
        backTo ? `${backTo}` : '',
      ].filter((maybe) => maybe).join('/'));

      return;
    }

    navigate('/404');
  }, [startOn, endOn, context]);

  if (!startOn) return <FourZeroFourComponent />;

  return <Component initialStartOn={startOn} initialEndOn={endOn} backTo={backTo} />;
});

export default Shifts;
