import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { DashboardEntity } from 'src/entities/dashboardEntity';

const BASE_URL = '/api/v2/dashboards';

const getDashboards = (queryClient: QueryClient, date: string) => async () => {
  const res = await axios.get<ApiResponse>(`${BASE_URL}?date=${date}`);
  const list = res.data.data;
  return list.map((entity: DashboardEntity) => entity);
};

export const useQueryDashboards = (date: string) => {
  const queryClient = useQueryClient();
  return useQuery(['dashboards', { date: date.split('-').slice(0, 2).join('-') }], getDashboards(queryClient, date), {
    staleTime: Infinity
  });
};
