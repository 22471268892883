/* eslint-disable @typescript-eslint/no-misused-promises */
import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormGroup, FormLabel, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, TextField, Theme, ThemeOptions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useContext, useEffect, useState } from 'react';
import { SubmitHandler, UseFormReturn, useFieldArray, useForm, Controller } from 'react-hook-form';
import MultiCheckbox from 'src/components/MultiCheckBox';
import LicenseContext from 'src/contexts/LicenseContext';
import { DriverEntity } from 'src/entities/driverEntity';
import { DesignatedTruckKlass, DesignatedTruckCarModel, DesignatedTruckLoadingPlatformHeight, DesignatedTruckLoadingPlatformWidth, DesignatedTruckLoadingPlatformLength, DesignatedTruckFloorSpecification, DesignatedTruckFeature, TruckDriverIdAndName } from 'src/entities/orderEntity';
import { PlaceEntity, PlaceRequestEntity } from 'src/entities/placeEntity';
import { TruckEntity } from 'src/entities/truckEntity';
import { queryKey } from 'src/hooks/usePlaces.request';
import { useMutationPlace } from 'src/hooks/useQueryPlaces';

import { schema } from './schema';
import SelectDriverDialog from './SelectDriverDialog';
import SelectTruckDialog from './SelectTruckDialog';

type Props = {
  dialogIsOpen: boolean,
  toggleDialogIsOpen: () => void;
  entity?: PlaceRequestEntity | PlaceEntity;
}

const FormDialog: FC<Props> = memo(({
  dialogIsOpen,
  toggleDialogIsOpen,
  entity,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme: Theme & ThemeOptions = useTheme();
  const licenseContext = useContext(LicenseContext);
  const queryClient = useQueryClient();

  const [allowedTrucks, setAllowedTrucks] = useState<TruckDriverIdAndName[]>([]);
  const [deniedDrivers, setDeniedDrivers] = useState<TruckDriverIdAndName[]>([]);
  const [companyId, setCompanyId] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [designatedTruckKlasses, setDesignatedTruckKlasses] = useState<DesignatedTruckKlass[]>([]);
  const [designatedTruckCarModels, setDesignatedTruckCarModels] = useState<DesignatedTruckCarModel[]>([]);
  const [designatedTruckLoadingPlatformHeights, setDesignatedTruckLoadingPlatformHeights] = useState<DesignatedTruckLoadingPlatformHeight[]>([]);
  const [designatedTruckLoadingPlatformWidths, setDesignatedTruckLoadingPlatformWidths] = useState<DesignatedTruckLoadingPlatformWidth[]>([]);
  const [designatedTruckLoadingPlatformLengths, setDesignatedTruckLoadingPlatformLengths] = useState<DesignatedTruckLoadingPlatformLength[]>([]);
  const [designatedTruckFloorSpecifications, setDesignatedTruckFloorSpecifications] = useState<DesignatedTruckFloorSpecification[]>([]);
  const [designatedTruckFeatures, setDesignatedTruckFeatures] = useState<DesignatedTruckFeature[]>([]);
  const [selectTruckDialogIsOpen, setSelectTruckDialogIsOpen] = useState<boolean>(false);
  const [selectDriverDialogIsOpen, setSelectDriverDialogIsOpen] = useState<boolean>(false);

  const { addPlace, updatePlace } = useMutationPlace();

  const form: UseFormReturn<PlaceRequestEntity | PlaceEntity> = useForm<PlaceRequestEntity | PlaceEntity>({
    resolver: yupResolver(schema),
    defaultValues: {
      place_specified_times_attributes: [{ start_at: '00:00', end_at: '23:59' }]
    }
  });
  const { setValue, control, reset, register, handleSubmit, formState: { errors } } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'place_specified_times_attributes'
 });

  const openSelectTruckDialog = () => {
    setSelectTruckDialogIsOpen(true);
  };

  const closeSelectTruckDialog = () => {
    setSelectTruckDialogIsOpen(false);
  };

  const updateSelectedTrucks = (entities: TruckEntity[]) => {
    setAllowedTrucks(
      entities.map((it) => ({
        id: it.id,
        name: it.license_plate_value
      }))
    );
  };

  const openSelectDriverDialog = () => {
    setSelectDriverDialogIsOpen(true);
  };

  const closeSelectDriverDialog = () => {
    setSelectDriverDialogIsOpen(false);
  };

  const updateSelectedDrivers = (entities: DriverEntity[]) => {
    setDeniedDrivers(
      entities.map((it) => ({
        id: it.id,
        name: it.name
      }))
    );
  };

  const onSubmitForm: SubmitHandler<PlaceRequestEntity | PlaceEntity> = async (
    data: PlaceRequestEntity | PlaceEntity
  ): Promise<void> => {
    setLoading(true);
    data.staying_seconds = data.staying_seconds ? data.staying_seconds * 60 : null;
    data.new_from_order = false;
    data.allowed_truck_ids = allowedTrucks.map((truck) => truck.id);
    data.denied_driver_ids = deniedDrivers.map((driver) => driver.id);

    try {
      if ('id' in data) {
        await updatePlace.mutateAsync(data);
      } else {
        await addPlace.mutateAsync(data);
      }
      toggleDialogIsOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const e = error as unknown as AxiosError<{ messages: string[] }>;
        enqueueSnackbar(e.response.data.messages.join(' '));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument
        error.response.data.map((str) => enqueueSnackbar(str));
      }
    } finally {
      queryClient.invalidateQueries([queryKey]).finally(() => {
        setLoading(false);
      });
    }
  };

  const onChangeCompany = useCallback((e: SelectChangeEvent) => {
    setValue('company_id', Number(e.target.value));
    setCompanyId(Number(e.target.value));
  }, [setValue]);

  const haveSelectableCompanies = licenseContext?.config?.selectable_companies?.length > 1;
  const isNew = !entity;

  useEffect(() => {
    if (entity) {
      setIsEdit(true);
      reset({
        ...entity,
        place_specified_times_attributes: (entity && entity.place_specified_times_attributes.length > 0)
          ? entity.place_specified_times_attributes.map((dt) => ({ start_at: dt.start_at, end_at: dt.end_at }))
          : [{ start_at: '00:00', end_at: '23:59' }]
      });
      setDesignatedTruckKlasses(entity.designated_truck_klasses || []);
      setDesignatedTruckCarModels(entity.designated_truck_car_models || []);
      setDesignatedTruckLoadingPlatformHeights(entity.designated_truck_loading_platform_heights || []);
      setDesignatedTruckLoadingPlatformWidths(entity.designated_truck_loading_platform_widths || []);
      setDesignatedTruckLoadingPlatformLengths(entity.designated_truck_loading_platform_lengths || []);
      setDesignatedTruckFloorSpecifications(entity.designated_truck_floor_specifications || []);
      setDesignatedTruckFeatures(entity.designated_truck_features || []);
      setCompanyId(entity.company_id);
      setAllowedTrucks(entity.allowed_trucks || []);
      setDeniedDrivers(entity.denied_drivers || []);
    } else {
      setIsEdit(false);
      reset({
        company_id: licenseContext?.config?.selected_company_id,
        place_specified_times_attributes: [{ start_at: '00:00', end_at: '23:59' }]
      });
      setDesignatedTruckKlasses([]);
      setDesignatedTruckCarModels([]);
      setDesignatedTruckLoadingPlatformHeights([]);
      setDesignatedTruckLoadingPlatformWidths([]);
      setDesignatedTruckLoadingPlatformLengths([]);
      setDesignatedTruckFloorSpecifications([]);
      setDesignatedTruckFeatures([]);
      setCompanyId(licenseContext?.config?.selected_company_id);
      setAllowedTrucks([]);
      setDeniedDrivers([]);
    }
  }, [entity, reset, dialogIsOpen, licenseContext?.config]);

  useEffect(() => {
    if (!entity) return;

    if ('id' in entity && entity.new_from_order && dialogIsOpen) {
      entity.new_from_order = false;
      updatePlace.mutate(entity);
    }
  }, [dialogIsOpen, entity, updatePlace]);

  const onClickClose = () => {
    toggleDialogIsOpen();
  };

  return (
    <>
      <Dialog open={dialogIsOpen} maxWidth="md" fullWidth>
        <DialogTitle>
          地点
          {isEdit ? '編集' : '追加'}
        </DialogTitle>
        <DialogContent>
          <Stack component="form" noValidate spacing={2}>
            {[haveSelectableCompanies, isNew].every((bool) => bool) && (
              <Paper>
                <Stack
                  pt={1}
                  pb={2}
                  px={2}
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      marginTop: theme.spacing(1),
                    }}
                  >
                    <Controller
                      control={control}
                      name="company_id"
                      render={({ field }) => (
                        <>
                          <InputLabel>事業所</InputLabel>
                          <Select
                            id="company_id"
                            margin="dense"
                            label="事業所"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={`${companyId}`}
                            onChange={onChangeCompany}
                            disabled={!haveSelectableCompanies}
                          >
                            {licenseContext.config.selectable_companies.map((company) => (
                              <MenuItem value={company.id} key={company.id}>{company.name}</MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    />
                  </FormControl>
                </Stack>
              </Paper>
            )}
            <Paper>
              <Stack
                pt={1}
                pb={2}
                px={2}
              >
                <TextField
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="standard"
                  label="地点名"
                  id="name"
                  {...register('name')}
                  error={'name' in errors}
                  helperText={errors.name?.message}
                  required
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="standard"
                  label="住所"
                  id="address"
                  {...register('address')}
                  error={'address' in errors}
                  helperText={errors.address?.message}
                  required
                  InputLabelProps={{ shrink: true }}
                />
                {
                  fields.map((field, idx) => (
                    <Stack
                      direction="row"
                      gap={1}
                      alignItems="baseline"
                    >
                      <TextField
                        margin="dense"
                        type="time"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        label="受付開始時間"
                        id={`place_specified_times_attributes.${idx}.start_at`}
                        {...register(`place_specified_times_attributes.${idx}.start_at`)}
                        error={!!errors.place_specified_times_attributes?.[idx].start_at}
                        helperText={errors.place_specified_times_attributes?.[idx]?.start_at?.message}
                      />
                      <TextField
                        margin="dense"
                        type="time"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        label="受付終了時間"
                        id={`place_specified_times_attributes.${idx}.end_at`}
                        {...register(`place_specified_times_attributes.${idx}.end_at`)}
                        error={!!errors.place_specified_times_attributes?.[idx].end_at}
                        helperText={errors.place_specified_times_attributes?.[idx]?.end_at?.message}
                      />
                      <IconButton
                        disabled={fields.length >= 3}
                        onClick={() => append({ start_at: '00:00:00', end_at: '23:00:00' })}
                      >
                        <AddCircleOutlineOutlined />
                      </IconButton>
                      <IconButton
                        disabled={fields.length < 2}
                        onClick={() => remove(idx)}
                      >
                        <RemoveCircleOutlineOutlined />
                      </IconButton>
                    </Stack>
                  ))
                }
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="baseline"
                >
                  <TextField
                    margin="dense"
                    type="number"
                    variant="standard"
                    id="staying_minutes"
                    label="滞在時間(分)"
                    {...register('staying_minutes')}
                    error={'staying_minutes' in errors}
                    helperText={errors.staying_minutes?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Stack>
              </Stack>
            </Paper>
            <Paper>
              <Stack
                pt={1}
                pb={2}
                px={2}
              >
                <FormControl component="fieldset">
                  <FormLabel component="legend">指定車両タイプ</FormLabel>
                  <FormGroup row>
                    <MultiCheckbox<PlaceRequestEntity | PlaceEntity, DesignatedTruckKlass>
                      form={form}
                      control={control}
                      name="designated_truck_klasses"
                      options={[
                        '軽貨物',
                        'トラック',
                        'セミトレーラー',
                        'ポールトレーラー',
                        'フルトレーラー'
                      ]}
                      defaultCheckedValues={designatedTruckKlasses}
                      valueSetter={setDesignatedTruckKlasses}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">指定車種</FormLabel>
                  <FormGroup row>
                    <MultiCheckbox<PlaceRequestEntity | PlaceEntity, DesignatedTruckCarModel>
                      form={form}
                      control={control}
                      name="designated_truck_car_models"
                      options={[
                        '平ボディ',
                        'バンボディ',
                        'ウィングボディ',
                        '保冷車',
                        '冷凍車',
                        '車載車',
                        '重機運搬車',
                        '危険物運搬車',
                        'ダンプ',
                        '幌',
                        'ユニック',
                        '海上コンテナー用'
                      ]}
                      defaultCheckedValues={designatedTruckCarModels}
                      valueSetter={setDesignatedTruckCarModels}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">荷台高さ</FormLabel>
                  <FormGroup row>
                    <MultiCheckbox<PlaceRequestEntity | PlaceEntity, DesignatedTruckLoadingPlatformHeight>
                      form={form}
                      control={control}
                      name="designated_truck_loading_platform_heights"
                      options={[
                        '標準',
                        '低床',
                        '中低床'
                      ]}
                      defaultCheckedValues={designatedTruckLoadingPlatformHeights}
                      valueSetter={setDesignatedTruckLoadingPlatformHeights}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">荷台幅</FormLabel>
                  <FormGroup row>
                    <MultiCheckbox<PlaceRequestEntity | PlaceEntity, DesignatedTruckLoadingPlatformWidth>
                      form={form}
                      control={control}
                      name="designated_truck_loading_platform_widths"
                      options={[
                        '標準',
                        'セミワイド',
                        'ワイド',
                      ]}
                      defaultCheckedValues={designatedTruckLoadingPlatformWidths}
                      valueSetter={setDesignatedTruckLoadingPlatformWidths}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">荷台長さ</FormLabel>
                  <FormGroup row>
                    <MultiCheckbox<PlaceRequestEntity | PlaceEntity, DesignatedTruckLoadingPlatformLength>
                      form={form}
                      control={control}
                      name="designated_truck_loading_platform_lengths"
                      options={[
                        '大型',
                        '中型',
                        '小型',
                        'ショート',
                        'ロング'
                      ]}
                      defaultCheckedValues={designatedTruckLoadingPlatformLengths}
                      valueSetter={setDesignatedTruckLoadingPlatformLengths}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">床仕様</FormLabel>
                  <FormGroup row>
                    <MultiCheckbox<PlaceRequestEntity | PlaceEntity, DesignatedTruckFloorSpecification>
                      form={form}
                      control={control}
                      name="designated_truck_floor_specifications"
                      options={[
                        '鉄板',
                        'ステンレス',
                        'ジョルダー',
                        '縞板'
                      ]}
                      defaultCheckedValues={designatedTruckFloorSpecifications}
                      valueSetter={setDesignatedTruckFloorSpecifications}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">装置</FormLabel>
                  <FormGroup row>
                    <MultiCheckbox<PlaceRequestEntity | PlaceEntity, DesignatedTruckFeature>
                      form={form}
                      control={control}
                      name="designated_truck_features"
                      options={[
                        'パワーゲート',
                        '空調',
                        'エアサス',
                        'スタンション'
                      ]}
                      defaultCheckedValues={designatedTruckFeatures}
                      valueSetter={setDesignatedTruckFeatures}
                    />
                  </FormGroup>
                </FormControl>
              </Stack>
            </Paper>
            <Paper>
              <Stack
                pt={1}
                pb={2}
                px={2}
                spacing={2}
              >
                <Button
                  variant="outlined"
                  onClick={openSelectTruckDialog}
                  disabled={!companyId}
                >
                  {
                    (() => {
                      if (allowedTrucks.length === 0) return '指定トラックを選択する';

                      if (allowedTrucks.length === 1) return `指定トラック ${[...allowedTrucks].slice(-1)[0].name}`;

                      return `指定トラック ${[...allowedTrucks].slice(-1)[0].name} 他${allowedTrucks.length - 1}台`;
                    })()
                  }
                </Button>
                <Button
                  variant="outlined"
                  onClick={openSelectDriverDialog}
                  disabled={!companyId}
                >
                  {
                    (() => {
                      if (deniedDrivers.length === 0) return 'NGドライバーを選択する';

                      if (deniedDrivers.length === 1) return `NGドライバー ${[...deniedDrivers].slice(-1)[0].name}`;

                      return `NGドライバー ${[...deniedDrivers].slice(-1)[0].name} 他${deniedDrivers.length - 1}名`;
                    })()
                  }
                </Button>
              </Stack>
            </Paper>
            <Paper>
              <Stack
                pt={1}
                pb={2}
                px={2}
              >
                <TextField
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="standard"
                  id="shipper_name"
                  label="荷主名"
                  {...register('shipper_name')}
                  error={'shipper_name' in errors}
                  helperText={errors.shipper_name?.message}
                  InputLabelProps={{ shrink: true }}
                />
                <Stack direction="row" gap={1}>
                  <TextField
                    margin="dense"
                    type="text"
                    fullWidth
                    label="電話番号"
                    variant="standard"
                    id="shipper_phone_number"
                    {...register('shipper_phone_number')}
                    error={'shipper_phone_number' in errors}
                    helperText={errors.shipper_phone_number?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    margin="dense"
                    type="text"
                    fullWidth
                    label="メールアドレス"
                    variant="standard"
                    id="shipper_email_address"
                    {...register('shipper_email_address')}
                    error={'shipper_email_address' in errors}
                    helperText={errors.shipper_email_address?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Stack>
              </Stack>
            </Paper>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickClose} disabled={loading}>キャンセル</Button>
          <Button onClick={handleSubmit(onSubmitForm)} variant="contained" disabled={loading}>
            保存する
          </Button>
        </DialogActions>
      </Dialog>
      {
        selectTruckDialogIsOpen && (
          <SelectTruckDialog
            open={selectTruckDialogIsOpen}
            onClose={closeSelectTruckDialog}
            onSelect={updateSelectedTrucks}
            currentTruckIds={allowedTrucks.map((truck) => truck.id)}
            selectedCompanyId={companyId}
          />
        )
      }
      {
        selectDriverDialogIsOpen && (
          <SelectDriverDialog
            open={selectDriverDialogIsOpen}
            onClose={closeSelectDriverDialog}
            onSelect={updateSelectedDrivers}
            currentDriverIds={deniedDrivers.map((driver) => driver.id)}
            selectedCompanyId={companyId}
          />
        )
      }
    </>
  );
});

export default FormDialog;
