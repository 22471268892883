import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Button, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { FC, memo, useEffect, useState } from 'react';
import { ErrorValue } from 'src/entities/import';
import { OrderRequestEntity } from 'src/entities/orderEntity';

const ErrorTableRow: FC<{
  errorValue: ErrorValue;
  columns: { [key: string]: string };
  updateEditRowIdx: (num: number) => void;
  openDialog: (requestEntity: OrderRequestEntity) => void;
  customInputFields: string[];
}> = memo(({ errorValue, updateEditRowIdx, openDialog, columns, customInputFields }) => {
  const haveLoadingAddressError = ['loading_latitude', 'loading_longitude'].every((str) => Object.keys(errorValue.errors).includes(str));
  const haveUnloadingAddressError = ['unloading_latitude', 'unloading_longitude'].every((str) => Object.keys(errorValue.errors).includes(str));

  const errors: { [key: string]: string[] } = { ...errorValue.errors };

  delete errors.loading_latitude;
  delete errors.loading_longitude;
  delete errors.unloading_latitude;
  delete errors.unloading_longitude;

  const modifiedErrors: { [key: string]: string[] } = {
    ...errors,
    loading_address: ['の緯度経度情報が取得できません、住所が正しいかご確認ください'],
    unloading_address: ['の緯度経度情報が取得できません、住所が正しいかご確認ください']
  };

  if (!haveLoadingAddressError) {
    delete modifiedErrors.loading_address;
  }

  if (!haveUnloadingAddressError) {
    delete modifiedErrors.unloading_address;
  }

  const additionalFields = customInputFields || [];

  const [errorColumns, setErrorColumns] = useState<{ [key: string]: string }>(columns);

  useEffect(() => {
    const col = { ...columns };
    col.item_total_weight_for_calculation = '総重量';
    col.item_total_volume_mm3 = '総体積';
    setErrorColumns(col);
  }, [columns]);

  return (
    <TableRow
      key={errorValue.idx}
      onClick={() => {
        updateEditRowIdx(errorValue.idx);
        openDialog(errorValue.value);
      }}
    >
      <TableCell
        sx={{
          whiteSpace: 'nowrap'
        }}
      >
        <Tooltip
          title={
            Object.keys(modifiedErrors).map((errorColumn) => (
              modifiedErrors[errorColumn].map((errorMessage) => (
                <Typography>
                  {[errorColumns[errorColumn], errorMessage].join('')}
                </Typography>
              ))
            ))
          }
        >
          <Button
            color="error"
            startIcon={<WarningRoundedIcon />}
          >
            {Object.keys(modifiedErrors).length}
            件のエラーがあります
          </Button>
        </Tooltip>
      </TableCell>
      {
        [
          ...Object.keys(columns),
          ...additionalFields.map((it) => ['customInput', customInputFields.indexOf(it)].join(''))
        ]
          .map((column, idx) => (
            <TableCell
              key={[errorValue.idx, idx, column].join('-')}
              sx={{
                whiteSpace: 'nowrap'
              }}
            >
              {
                modifiedErrors[column]
                  ? (
                    <Tooltip
                      title={
                        modifiedErrors[column] && modifiedErrors[column].map((str) => [columns[column], str].join(''))
                      }
                    >
                      <Button
                        color="error"
                        startIcon={<WarningRoundedIcon />}
                      >
                        {errorValue.value[column]}
                      </Button>
                    </Tooltip>
                  )
                  : (
                    <Button
                      variant="text"
                    >
                      {
                        errorValue.value[column]
                        || errorValue.value.custom_input_fields.find((customInputField) => {
                          const customInputIndex = Number(column.split('customInput')[1]);
                          const customInputFieldKey: string = customInputFields[customInputIndex];

                          return customInputField.key === customInputFieldKey;
                        })?.value
                      }
                    </Button>
                  )
              }
            </TableCell>
          ))
      }
    </TableRow>
  );
});

export default ErrorTableRow;
