import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DriverStatisticEntity } from 'src/entities/DriverStatistic.entity';

const requestPathBuilder = (driverId: number, on: string) => `/api/v3/drivers/${driverId}/statistics/${on}`;
const queryPrefix = 'useDriverStatisticsQuery';

const getOne = (driverId: number, on: string) => async () => {
  const requestPath = requestPathBuilder(driverId, on);
  const axiosResponse = await axios.get<DriverStatisticEntity[]>(requestPath);

  return axiosResponse.data;
};

export const useDriverStatisticsQuery = (driverId: number, on: string) => useQuery(
  [queryPrefix, { driverId, on }],
  getOne(driverId, on),
{
    staleTime: Infinity,
    enabled: [driverId, on].every((maybe) => Boolean(maybe))
  }
);
