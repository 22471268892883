import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { HavePaginationEntity } from 'src/entities/HavePagination.entity';
import { OrderResponseEntity as Entity } from 'src/entities/Order.response.entity';

const requestPathBase = '/api/v4/master/orders';
export const queryKey = 'useOrdersRequest';

const getResources = async (page: number, sortCondition: string, searchCondition: string, startOn?: Date, endOn?: Date, per?: number) => {
  const requestParams = [
    `page=${page + 1}`,
    `start_on=${datetimeDecorator.toYyyyMmDd(startOn)}`,
    `end_on=${datetimeDecorator.toYyyyMmDd(endOn)}`,
    sortCondition ? `sort_condition=${sortCondition}` : '',
    searchCondition ? `${searchCondition}` : '',
    per ? `per=${per}` : '',
  ].filter((maybe) => maybe).join('&');

  const requestPath = [
    requestPathBase,
    requestParams,
  ].join('?');

  const response: AxiosResponse<HavePaginationEntity<Entity>> = await axios.get<HavePaginationEntity<Entity>>(requestPath);

  return response.data;
};

export const useOrdersRequest = (page: number, sortCondition: string, searchCondition: string, startOn?: Date, endOn?: Date, per?: number) => useQuery(
  [queryKey, { page, sortCondition, searchCondition, startOn, endOn, per }],
  () => getResources(page, sortCondition, searchCondition, startOn, endOn, per),
  {
    enabled: [startOn, endOn].every((maybe) => !!maybe),
  }
);
