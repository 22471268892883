import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'src/constants/apiResponse';
import { DriverEventLogEntity } from 'src/entities/DriverEventLog.entity';

const getDriverEventLogs = (startOn: string, endOn: string) => async () => {
  const res = await axios.get<ApiResponse>(`/api/v4/driver_event_logs?start_on=${startOn}&end_on=${endOn}`);
  const list = res.data.data;
  return list.map((entity: DriverEventLogEntity) => entity);
};

export const useQueryDriverEventLogs = (startOn: string, endOn: string) => {
  const queryClient = useQueryClient();
  return useQuery(['useQueryDriverEventLogs'], getDriverEventLogs(startOn, endOn), {
    refetchInterval: 15 * 1000,
  });
};
