import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { DeliveryEntity, UpdateDeliveryEntity } from 'src/entities/Delivery.entity';

const requestPathBase = '/api/v3/deliveries';
const queryPrefix = 'useDeliveriesQuery';

const getAll = (queryClient: QueryClient, on: string) => async () => {
  const requestPath = [requestPathBase, `on=${on}`].join('?');
  const axiosResponse = await axios.get<DeliveryEntity[]>(requestPath);
  const entities = axiosResponse.data;

  return entities.map((it) => {
    queryClient.setQueryData([queryPrefix, { id: it.id }], it);

    return it;
  });
};

const getRecord = (id: number) => async () => {
  const requestPath = `${requestPathBase}/${id}`;
  const axiosResponse = await axios.get<DeliveryEntity>(requestPath);

  return axiosResponse.data;
};

export const useDeliveriesQuery = (on: string) => {
  const queryClient = useQueryClient();

  return useQuery(
    [queryPrefix, { on }],
    getAll(queryClient, on),
    {
      staleTime: Infinity,
      enabled: Boolean(on)
    }
  );
};

export const useDeliveryQuery = (id: number) => useQuery(
  [queryPrefix, { id }],
  getRecord(id),
  {
    staleTime: Infinity,
    enabled: Boolean(id)
  }
);

export const useMutationDeliveries = () => ({
  bulkUpdate: useMutation(
    (data: UpdateDeliveryEntity) => axios.put('/api/v3/deliveries/bulk/update', { data })
  ),
  copyFromLastWeek: useMutation(
    (on: string) => axios.post('/api/v3/deliveries/copy', { on })
  ),
});
