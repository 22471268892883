import { FC, memo, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PickingListComponent from 'src/components/PickingListComponent';
import LicenseContext from 'src/contexts/LicenseContext';

import PickingListPresenter from './PickingListPresenter';

const PickingList: FC = memo(() => {
  const licenseContext = useContext(LicenseContext);
  const query = new URLSearchParams(useLocation().search);
  const shiftIds = query.get('ids').split(',').map((id) => Number(id));
  const date = query.get('date');

  return <PickingListComponent shiftIds={shiftIds} date={date} presenter={PickingListPresenter} unit={licenseContext.config?.unit} />;
});

export default PickingList;
