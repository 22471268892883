import { GridColDef } from '@mui/x-data-grid';
import { GridValueFormatterParams } from '@mui/x-data-grid-premium';
import numberDecorator from 'src/decorators/number.decorator';
import { EstimationEntity } from 'src/entities/Estimation.entity';
import numberUtil from 'src/utils/number.util';

const costStrictColumns: GridColDef[] = [
  {
    field: 'deliveryName',
    headerName: '勤務計画',
    type: 'string',
  },
  {
    field: 'totalOrderOperationDistanceMm',
    headerName: '車庫帰着を除く合計距離',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromMmToKm(params.row.totalOrderOperationDistanceMm),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedKm(
      params.value,
      ''
    )
  },
  {
    field: 'totalDistanceMm',
    headerName: '合計距離',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromMmToKm(params.row.totalOrderOperationDistanceMm + params.row.arrivalOperationDistanceMm),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedKm(
      params.value,
      ''
    )
  },
  {
    field: 'orderOperationSeconds',
    headerName: '車庫帰着を除く時間',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromSecondsToMinutes(params.row.orderOperationSeconds),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedUnit(
      params.value,
      '分',
      1,
      ''
    )
  },
  {
    field: 'totalSeconds',
    headerName: '合計時間',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromSecondsToMinutes(params.row.orderOperationSeconds + params.row.arrivalOperationSeconds),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedUnit(
      params.value,
      '分',
      1,
      ''
    )
  },
  {
    field: 'totalItemCount',
    headerName: '数量',
    description: '宵積みは0.5としてカウントされます',
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedUnit(
      params.value,
      '個',
      10,
      ''
    )
  },
  {
    field: 'totalItemWeightGram',
    headerName: '重量',
    description: '宵積みは半分がカウントされます',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromGramToKg(params.row.totalItemWeightGram),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedUnit(
      params.value,
      'kg',
      10,
      ''
    )
  },
  {
    field: 'totalItemVolumeMm3',
    headerName: '体積',
    description: '宵積みは半分がカウントされます',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => numberUtil.convertFromMm3ToM3(params.row.totalItemVolumeMm3),
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toRoundedUnit(
      params.value,
      'm3',
      10,
      ''
    )
  },
  {
    field: 'expectedFeeYenByKm',
    headerName: '🖊️1kmあたり運賃',
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'expectedFeeYenByHour',
    headerName: '🖊️1時間あたり運賃',
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'expectedFeeYenByItemCount',
    headerName: '🖊️1個あたりの運賃',
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'expectedFeeYenByItemWeightKg',
    headerName: '🖊️1kgあたりの運賃',
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'expectedFeeYenByItemVolumeM3',
    headerName: '🖊️1m3あたりの運賃',
    type: 'number',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value
    )
  },
  {
    field: 'feeExcludeArrivalOperation',
    headerName: '運賃（時間・車庫帰着を除く）',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => {
      const distance = (() => {
        if (!params.row.expectedFeeYenByKm) return undefined;

        const distanceMm = params.row.totalOrderOperationDistanceMm;

        return distanceMm * (params.row.expectedFeeYenByKm / 1000000);
      })();

      const hour = (() => {
        if (!params.row.expectedFeeYenByHour) return undefined;

        const seconds = params.row.orderOperationSeconds;

        return seconds * (params.row.expectedFeeYenByHour / 3600);
      })();

      const count = (() => {
        if (!params.row.expectedFeeYenByItemCount) return undefined;

        return params.row.totalItemCount * params.row.expectedFeeYenByItemCount;
      })();

      const weight = (() => {
        if (!params.row.expectedFeeYenByItemWeightKg) return undefined;

        return params.row.totalItemWeightGram * (params.row.expectedFeeYenByItemWeightKg / 1000);
      })();

      const volume = (() => {
        if (!params.row.expectedFeeYenByItemVolumeM3) return undefined;

        return params.row.totalItemVolumeMm3 * (params.row.expectedFeeYenByItemVolumeM3 / 1000000000);
      })();

      const fares = [
        distance,
        hour,
        count,
        weight,
        volume
      ].filter((maybe) => maybe);

      return fares.reduce((prev, current) => prev + current, 0) / fares.length;
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value,
    ),
  },
  {
    field: 'fee',
    headerName: '運賃',
    type: 'number',
    valueGetter: (params: { row: EstimationEntity }) => {
      const distance = (() => {
        if (!params.row.expectedFeeYenByKm) return undefined;

        const distanceMm = params.row.totalOrderOperationDistanceMm + params.row.arrivalOperationDistanceMm;

        return distanceMm * (params.row.expectedFeeYenByKm / 1000000);
      })();

      const hour = (() => {
        if (!params.row.expectedFeeYenByHour) return undefined;

        const seconds = params.row.orderOperationSeconds + params.row.arrivalOperationSeconds;

        return seconds * (params.row.expectedFeeYenByHour / 3600);
      })();

      const count = (() => {
        if (!params.row.expectedFeeYenByItemCount) return undefined;

        return params.row.totalItemCount * params.row.expectedFeeYenByItemCount;
      })();

      const weight = (() => {
        if (!params.row.expectedFeeYenByItemWeightKg) return undefined;

        return params.row.totalItemWeightGram * (params.row.expectedFeeYenByItemWeightKg / 1000);
      })();

      const volume = (() => {
        if (!params.row.expectedFeeYenByItemVolumeM3) return undefined;

        return params.row.totalItemVolumeMm3 * (params.row.expectedFeeYenByItemVolumeM3 / 1000000000);
      })();

      const fares = [
        distance,
        hour,
        count,
        weight,
        volume
      ].filter((maybe) => maybe);

      return fares.reduce((prev, current) => prev + current, 0) / fares.length;
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => numberDecorator.toYen(
      params.value,
    ),
  },
];

export default costStrictColumns;
