/* eslint-disable @typescript-eslint/no-misused-promises */
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Paper, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { fiveZeroZeroErrorMessage } from 'src/constants/messages';
import AdminContext from 'src/contexts/AdminContext';
import { AdminRequestEntity } from 'src/entities/admin/adminEntity';
import * as yup from 'yup';

const schema = yup.object({
  email: yup
    .string()
    .email('正しい形式で入力してください')
    .required('メールアドレスを入力してください'),
  password: yup.string().required('パスワードを入力してください')
});

const AdminLogin: FC = memo(() => {
  const adminContext = useContext(AdminContext);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<AdminRequestEntity>({
    resolver: yupResolver(schema)
  });

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<AdminRequestEntity> = async (
    data: AdminRequestEntity
  ): Promise<void> => {
    try {
      await adminContext.login(data);

      navigate('/admin');
    } catch (e) {
      if (axios.isAxiosError(e) && e.response && e.response.status < 500) {
        enqueueSnackbar('入力内容をお確かめください');
      } else {
        enqueueSnackbar(fiveZeroZeroErrorMessage);
        throw e;
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>ADMIN | ログイン</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <Paper
          sx={{
            py: 2,
            px: 4,
            minWidth: 380
          }}
        >
          <Typography textAlign="center" variant="h1">
            LOG 管理画面
          </Typography>

          <Stack component="form" noValidate spacing={2}>
            <TextField
              required
              margin="dense"
              id="email"
              label="メールアドレス"
              type="text"
              fullWidth
              variant="standard"
              {...register('email')}
              error={'email' in errors}
              helperText={errors.email?.message}
            />
            <TextField
              required
              margin="dense"
              id="password"
              label="パスワード"
              type="password"
              fullWidth
              variant="standard"
              {...register('password')}
              error={'password' in errors}
              helperText={errors.password?.message}
            />
            <LoadingButton
              loading={adminContext.loading}
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleSubmit(onSubmit)}
            >
              ログインする
            </LoadingButton>
          </Stack>
        </Paper>
      </Box>
    </>
  );
});

export default AdminLogin;
