import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { PlanningRunningEntity } from 'src/entities/PlanningsRunning.entity';

const requestPath = (startOn: string, endOn: string) => `/api/v3/plannings/running?start_on=${startOn}&end_on=${endOn}`;

const getAll = (startOn: string, endOn: string) => async () => {
  const response: AxiosResponse<PlanningRunningEntity[]> = await axios.get(requestPath(startOn, endOn));

  return response.data;
};

export const useQueryPlanningsRunnings = (startOn: string, endOn: string) => useQuery(
  ['useQueryPlanningsRunnings', { startOn, endOn }],
  getAll(startOn, endOn),
  {
    staleTime: 5 * 60 * 1000,
    enabled: Boolean(startOn) && Boolean(endOn)
  }
);

export const useMutationPlanningRunnings = () => {
  const queryClient = useQueryClient();
  return {
    delete: useMutation(
      ({ id, startOn, endOn }: { id: number, startOn: string, endOn: string }) => axios.delete(
        '/api/v3/plannings/running',
        { data: { id, start_on: startOn, end_on: endOn } }
      ),
    ),
  };
};
