import { EventChangeArg, EventClickArg, EventHoveringArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import interactionPlugin, { Draggable, DropArg } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  lighten,
  Paper,
  Popover,
  Stack,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers';
import { addDays, addMinutes, addSeconds, differenceInDays } from 'date-fns';
import { format } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { ConfirmDialog } from 'src/components/ConfirmDialog';
import DirectionSettingDialog from 'src/components/DirectionSettingDialog';
import { appBarHeight } from 'src/constants/layout';
import LicenseContext from 'src/contexts/LicenseContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { CalenderResourceEntity } from 'src/entities/CalenderResource.entity';
import { DeliveryEventEntity } from 'src/entities/DeliveryEvent.entity';
import { GarageEntity } from 'src/entities/garageEntity';
import { OperationEventEntity } from 'src/entities/OperationEvent.entity';
import { OrderEntity } from 'src/entities/orderEntity';
import { OrderSearchConditionEntity } from 'src/entities/OrderSearchCondition.entity';
import { PlanningOrderStatisticsEntity } from 'src/entities/PlanningOrderStatistics.entity';
import { BaseOperationEntityWithPlaceType, PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { PlanningsOperationEntity } from 'src/entities/PlanningsOperation.entity';
import {
  PlanningsOperationDeliveryByDeliveryIdEntity,
  PlanningsOperationEntitiesWithStatsByDeliveryIdEntity
} from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsOperationEntityWithStatsEntity } from 'src/entities/PlanningsOperationEntityWithStats.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PositionEntity } from 'src/entities/PositionEntity';
import { Operations } from 'src/models/Operations';
import { OperationsWithStatusModel } from 'src/models/OperationsWithStatus.model';
import arrayUtil from 'src/utils/array.util';
import numberUtil from 'src/utils/number.util';

import OrdersPresenter from '../V2Plans/presenters/OrdersPresenter';
import PlanningMapDriversPresenter from '../V2Plans/presenters/PlanningMapDrivers.presenter';
import PlanningOrderPresenter from '../V2Plans/presenters/PlanningOrder.presenter';
import PlanningsTruckGroupSelectPresenter from '../V2Plans/presenters/PlanningsTruckGroupSelect.presenter';
import SelectPrintPresenter from '../V2Plans/presenters/SelectPrintPresenter';

import { baseOperationEntityReducer, operationEntitiesReducer, operationEventsReducer } from './Reducer';

type Props = {
  startDate: Date;
  endDate: Date;
  garageData: GarageEntity[];
  deliveryEvents: DeliveryEventEntity[];
  resources: CalenderResourceEntity[];
  orderData: OrderEntity[];
  reset: () => void;
  save: (operationEntities: PlanningsOperationEntity[], baseOperationEntities: BaseOperationEntityWithPlaceType[],
        operationEvents: OperationEventEntity[], afterUpdate: (deliveries: PlanningsDeliveryEntity[]) => void) => void;
  truckData: PlanningsTruckEntity[];
  deliveryData: PlanningsDeliveryEntity[];
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => void;
  isLoading: boolean;
  groupEntities: PlanningsGroupEntity[];
  selectedGroupEntity: PlanningsGroupEntity | undefined;
  updateSelectedGroupEntity: (entity: PlanningsGroupEntity | undefined) => void;
  sendMail: () => void;
  mutateDeleteSpecificOrder: (orderId: number) => void;
  printOperationDirectionUrl: () => string;
  printAllTruckDirectionsOnClick: () => void;
  printUnloadTruckDirectionsOnClick: () => void;
  printPickingListOnClick: () => void;
  downloadPlanCsvOnClick: () => void;
  selectPrintDialogIsOpen: boolean;
  selectPrintButtonOnClick: () => void;
  selectPrintDialogClose: () => void;
  selectedOrderIds: number[];
  addSelectedOrderId: (id: number) => void;
  removeSelectedOrderId: (id: number) => void;
  unit: string;
  mutateDeleteOrder: () => void;
  mutateCloneOrder: (order: OrderEntity) => void;
  planningOrderStatisticsEntity: PlanningOrderStatisticsEntity | undefined;
  customInputFields: string[];
  driverEntities: PlanningsDriverEntity[];
  deliveryEntities: PlanningsDeliveryEntity[];
  orderEntityMap: Map<number, OrderEntity>;
  directionSettingDialogIsOpen: boolean;
  updateDirectionSettingDialogIsOpen: (bool: boolean) => void;
  orderSearchKw: string;
  setOrderSearchKw: (kw: string) => void;
  currentlyOrderSearching: boolean;
  setCurrentlyOrderSearching: (searching: boolean) => void;
  mutateRestoreSplittedOrder: (orderId: number) => void;
  mutateRestoreSplittedOrders: () => void;
}

const Presenter: FC<Props> = memo(({
  startDate,
  endDate,
  garageData,
  deliveryEvents,
  resources,
  orderData,
  reset,
  save,
  truckData,
  deliveryData,
  mutateDeleteOrdersOperations,
  isLoading,
  groupEntities,
  selectedGroupEntity,
  updateSelectedGroupEntity,
  sendMail,
  mutateDeleteSpecificOrder,
  printOperationDirectionUrl,
  printAllTruckDirectionsOnClick,
  printUnloadTruckDirectionsOnClick,
  printPickingListOnClick,
  downloadPlanCsvOnClick,
  selectPrintDialogIsOpen,
  selectPrintButtonOnClick,
  selectPrintDialogClose,
  selectedOrderIds,
  addSelectedOrderId,
  removeSelectedOrderId,
  unit,
  mutateDeleteOrder,
  mutateCloneOrder,
  planningOrderStatisticsEntity,
  customInputFields,
  driverEntities,
  deliveryEntities,
  orderEntityMap,
  directionSettingDialogIsOpen,
  updateDirectionSettingDialogIsOpen,
  orderSearchKw,
  setOrderSearchKw,
  currentlyOrderSearching,
  setCurrentlyOrderSearching,
  mutateRestoreSplittedOrder,
  mutateRestoreSplittedOrders,
}) => {
  const theme: Theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const context = useContext(LicenseContext);

  const unallocatedDrawerWidth = useMemo(() => 300, []);
  const [fixedShiftTruckIds, setFixedShiftTruckIds] = useState<number[]>([]);

  const baseColor = useMemo(() => '#795548', []);
  const baseColorLight = useMemo(() => lighten(baseColor, 0.25), [baseColor]);
  const loadColor = useMemo(() => '#039BE5', []);
  const loadColorLight = useMemo(() => lighten(loadColor, 0.25), [loadColor]);
  const unloadColor = useMemo(() => '#f4511E', []);
  const unloadColorLight = useMemo(() => lighten(unloadColor, 0.25), [unloadColor]);
  const waittimeColor = useMemo(() => '#aaa', []);
  const waittimeColorLight = useMemo(() => lighten(waittimeColor, 0.25), [waittimeColor]);

  const [minEndOn, setMinEndOne] = useState<Date>(null);
  const [maxEndOn, setMaxEndOn] = useState<Date>(null);
  useEffect(() => {
    setMinEndOne(startDate);
    setMaxEndOn(addDays(startDate, 2));
  }, [startDate]);

  const datePickerStartOnChange = useCallback((newValue: Date) => {
    // navigate だとうまくカレンダーが書き換わらないので
    window.location.href = `/timeline/${format(newValue, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' })}/${format(newValue, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' })}`;
  }, []);

  const datePickerEndOnChange = useCallback((newValue: Date) => {
    const dateDiff = differenceInDays(newValue, startDate);
    if (dateDiff < 0) {
      enqueueSnackbar('終了日は開始日よりも過去に設定できません');
      return;
    }
    if (dateDiff > 2) {
      enqueueSnackbar('3日以上設定できません');
      return;
    }
    // navigate だとうまくカレンダーが書き換わらないので
    window.location.href = `/timeline/${format(startDate, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' })}/${format(newValue, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' })}`;
  }, [startDate, enqueueSnackbar]);

  useEffect(() => {
    if (!deliveryData) return;

    const operations = deliveryData.flatMap((it) => it.operations);
    setFixedShiftTruckIds(
      operations.filter((it) => it.fixed).map((it) => it.shiftTruckId)
    );
  }, [deliveryData]);

  const buildOperationEntity: (
    orderEntity: OrderEntity,
    operationId: number,
    startAt: Date,
    shiftTruckId: number,
    action: '積' | '降',
    isChanged: boolean,
  ) => PlanningsOperationEntity = (
    orderEntity,
    operationId,
    startAt,
    shiftTruckId,
    action,
    isChanged,
  ) => {
    const shipperName = orderEntity.shipper_name;
    const itemCount = orderEntity.item_count;
    const itemTotalVolumeMm3 = numberUtil.convertFromM3ToMm3(
      orderEntity.item_total_volume_m3
    );
    const itemTotalWeightForCalculation = numberUtil
      .convertFromKgToGram(
        orderEntity.item_total_weight_kg
      );
    const loadingName = orderEntity.loading_name;
    const loadingAddress = orderEntity.loading_address;
    const unloadingName = orderEntity.unloading_name;
    const unloadingAddress = orderEntity.unloading_address;
    const orderId = orderEntity.id;
    const drivingDurationSeconds = 0;
    const drivingDistanceMm = 0;
    const waitingDurationSeconds = 0;
    const minLoadingStartAt = orderEntity.loading_start_at;
    const maxLoadingEndAt = orderEntity.loading_end_at;
    const minUnloadingStartAt = orderEntity.unloading_start_at;
    const maxUnloadingEndAt = orderEntity.unloading_end_at;
    const arrivalAt = startAt.toISOString();
    const stayingSeconds = action === '積'
      ? orderEntity.loading_staying_minutes
      : orderEntity.unloading_staying_minutes;
    const operationDurationSeconds = numberUtil
      .convertFromMinutesToSeconds(stayingSeconds || 10);
    const departureAt = addSeconds(
      startAt,
      operationDurationSeconds
    ).toISOString();
    const operationStartAt = departureAt;
    const operationWeightG = action === '積'
      ? itemTotalWeightForCalculation
      : itemTotalWeightForCalculation * -1;
    const operationVolumeMm3 = action === '積'
      ? itemTotalVolumeMm3
      : itemTotalVolumeMm3 * -1;
    const latitude = action === '積'
      ? `${orderEntity.loading_lat}`
      : `${orderEntity.unloading_lat}`;
    const longitude = action === '積'
      ? `${orderEntity.loading_lng}`
      : `${orderEntity.unloading_lng}`;

    return {
      id: operationId,
      shipperName,
      itemCount,
      itemTotalVolumeMm3,
      itemTotalWeightForCalculation,
      loadingName,
      loadingAddress,
      unloadingName,
      unloadingAddress,
      shiftTruckId,
      orderId,
      arrivalAt,
      departureAt,
      drivingDurationSeconds,
      operationDurationSeconds,
      operationStartAt,
      waitingDurationSeconds,
      drivingDistanceMm,
      action,
      minLoadingStartAt,
      maxLoadingEndAt,
      minUnloadingStartAt,
      maxUnloadingEndAt,
      operationWeightG,
      operationVolumeMm3,
      latitude,
      longitude,
      isChanged,
      originalShiftTruckId: -1,
      cycle: 1,
      orderWasSplit: false,
    };
  };

  const buildBaseOperationEvents: (
    baseOperations: BaseOperationEntityWithPlaceType[]
  ) => (OperationEventEntity[]) = useCallback((
    baseOperations
  ) => (
    baseOperations.map((it) => {
      const start = new Date(it.arrival_at).toISOString();
      const end = addMinutes(new Date(it.arrival_at), 15).toISOString();
      const id = `${it.shift_truck_id}-${it.place_type}`;

      return {
        id,
        title: it.name,
        start,
        end,
        resourceId: `${it.shift_truck_id}`,
        operations: [],
        planningsOperations: [],
        backgroundColor: it.isChanged ? baseColorLight : baseColor,
        borderColor: it.isChanged ? baseColorLight : baseColor,
        position: null,
        currentWeightG: 0,
        currentVolumeMm3: 0,
        name: it.name,
        operationStart: start,
        operationEnd: start,
        type: 'baseOperationEvent',
        operationsByDeliveryId: null,
        baseOperation: it,
        isChanged: false,
      };
    })
  ), [baseColor, baseColorLight]);

  const buildOperationEvents: (
    operations: (PlanningsOperationEntity[] | undefined),
    orders: (OrderEntity[] | undefined)
  ) => (OperationEventEntity[]) = useCallback((
    operations,
    orders
  ) => {
    if (![operations, orders].every((maybe) => maybe)) return [];
    if (!context.config) return [];

    const model = new Operations(context.config, operations);
    const operationsByDeliveryId: PlanningsOperationEntitiesWithStatsByDeliveryIdEntity = model
      .groupAndSortEntitiesByDeliveryIdWithVolumeAndWeightIdx({ separateAction: true });

    setPlanningsOperationDeliveryByDeliveryIdEntity(model.createPlanningsOperationDeliveryByDeliveryIdEntity());

    return Object.keys(operationsByDeliveryId).flatMap((deliveryId) => {
      const ops: PlanningsOperationEntityWithStatsEntity[] = operationsByDeliveryId[`${deliveryId}`];
      const groupedOperations: PlanningsOperationEntityWithStatsEntity[][] = new OperationsWithStatusModel(ops).groupByIdx();

      return groupedOperations.flatMap((gOps) => {
        const colorBuilder = (actions: ('積' | '降')[], isChanged: boolean) => {
          if (actions.every((it) => it === '積')) {
            return isChanged ? loadColorLight : loadColor;
          }
          if (actions.every((it) => it === '降')) {
            return isChanged ? unloadColorLight : unloadColor;
          }

          return isChanged ? theme.colors.gradients.orange1 : theme.colors.gradients.purple3;
        };

        const id = gOps.map((it) => [
          it.orderId,
          it.action
        ].join('-')).join('-');
        const title = gOps[0]?.action === '積' ? gOps[0]?.loadingName : gOps[0]?.unloadingName;
        const start = arrayUtil.minAt(gOps.map((it) => new Date(it.arrivalAt))).toISOString();
        const end = arrayUtil.maxAt(gOps.map((it) => new Date(it.departureAt))).toISOString();
        const resourceId = `${gOps[0].shiftTruckId}`;
        const opsOnPos = gOps.map((it) => ({
          operationId: it.id,
          order: orders.find((ent) => it.orderId === ent.id),
          action: it.action
        }));
        const backgroundColor = colorBuilder(gOps.map((it) => it.action), gOps[0].isChanged);
        const borderColor = gOps.some((it) => (it.isChanged)) ? '#000' : colorBuilder(gOps.map((it) => it.action), gOps[0].isChanged);
        const position: PositionEntity = [Number(gOps[0]?.latitude), Number(gOps[0]?.longitude)];
        const { currentWeightG, currentVolumeMm3 } = gOps[0];
        const currentOperationsByDeliveryId = { [`${deliveryId}`]: gOps };

        if (gOps[0].waitingDurationSeconds > 0) {
          return [
            {
              id: `${id}-waiting-time`,
              title: `${title} 待ち時間`,
              start,
              end: addSeconds(new Date(start), gOps[0].waitingDurationSeconds).toISOString(),
              resourceId,
              operations: opsOnPos,
              planningsOperations: gOps,
              backgroundColor: gOps[0].isChanged ? waittimeColorLight : waittimeColor,
              borderColor: gOps[0].isChanged ? waittimeColorLight : waittimeColor,
              position,
              currentWeightG,
              currentVolumeMm3,
              name: title,
              operationStart: start,
              operationEnd: end,
              type: 'operationWaitingEvent',
              operationsByDeliveryId: currentOperationsByDeliveryId,
              baseOperation: null,
            },
            {
              id,
              title,
              start: addSeconds(new Date(start), gOps[0].waitingDurationSeconds).toISOString(),
              end,
              resourceId,
              operations: opsOnPos,
              planningsOperations: gOps,
              backgroundColor,
              borderColor,
              position,
              currentWeightG,
              currentVolumeMm3,
              name: title,
              operationStart: start,
              operationEnd: end,
              type: 'operationEvent',
              operationsByDeliveryId: currentOperationsByDeliveryId,
              baseOperation: null,
            }
          ];
        }

        return [{
          id,
          title,
          start,
          end,
          resourceId,
          operations: opsOnPos,
          planningsOperations: gOps,
          backgroundColor,
          borderColor,
          position,
          currentWeightG,
          currentVolumeMm3,
          name: title,
          operationStart: start,
          operationEnd: end,
          type: 'operationEvent',
          operationsByDeliveryId: currentOperationsByDeliveryId,
          baseOperation: null,
        }];
      });
    });
  }, [context, loadColor, loadColorLight, theme.colors.gradients.orange1, theme.colors.gradients.purple3, unloadColor, unloadColorLight, waittimeColor, waittimeColorLight]);

  const findPairedOperation: (
    operation: PlanningsOperationEntity,
    operations: PlanningsOperationEntity[],
  ) => PlanningsOperationEntity | undefined = (
    operation,
    operations,
  ) => operations.filter((it) => {
    const act = operation.action === '積' ? '降' : '積';

    return [
      it.action === act,
      it.orderId === operation.orderId
    ].every((bool) => bool);
  })[0];

  const draggableElementId = 'external-events';
  const draggableElementClassName = 'external-event';

  const [operationEntities, dispatchOperationEntities] = useReducer(operationEntitiesReducer, []);
  const [baseOperationEntities, dispatchBaseOperationEntities] = useReducer(baseOperationEntityReducer, []);
  const [operationEvents, dispatchOperationEvents] = useReducer(operationEventsReducer, []);
  const [baseOperationEvents, dispatchBaseOperationEvents] = useReducer(operationEventsReducer, []);
  const [events, setEvents] = useState<(OperationEventEntity | DeliveryEventEntity)[]>([]);

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [position, setPosition] = useState<PositionEntity>();
  const [uniqDriverEntitiesOnPosition, setUniqDriverEntitiesOnPosition] = useState<PlanningsDriverEntity[]>();
  const [deliveryEntitiesOnPosition, setDeliveryEntitiesOnPosition] = useState<PlanningsDeliveryEntity[]>();
  const [truckEntities, setTruckEntities] = useState<PlanningsTruckEntity[]>([]);

  const [eventDialogSelectedOrderIds, setEventDialogSelectedOrderIds] = useState<number[]>([]);
  const [displayDetailOrderId, setDetailDisplayOrderId] = useState<number | undefined>(undefined);
  const [isAllFixed, setIsAllFixed] = useState<boolean>(false);
  const [displayOrderDialogIsOpen, setDisplayOrderDialogIsOpen] = useState(false);

  const [openUnallocatedOrders, setOpenUnallocatedOrders] = useState<boolean>(true);

  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [appBarWidth, setAppBarWidth] = useState<number>(0);

  const [planningsOperationDeliveryByDeliveryIdEntity, setPlanningsOperationDeliveryByDeliveryIdEntity] = useState<PlanningsOperationDeliveryByDeliveryIdEntity>();

  useEffect(() => {
    const updateWidth = (): void => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    const drawerWidth = openUnallocatedOrders ? unallocatedDrawerWidth : 0;
    const contentWidth = windowWidth - drawerWidth;

    if (contentWidth <= 0) return;

    if (contentWidth <= theme.breakpoints.values.md) {
      enqueueSnackbar('画面幅が狭いため、アプリケーションの操作が制限されます。', { variant: 'warning' });
    }

    if (contentWidth >= theme.breakpoints.values.md) {
      setAppBarWidth(contentWidth);
      return;
    }

    setAppBarWidth(theme.breakpoints.values.md);
  }, [windowWidth, openUnallocatedOrders, theme, unallocatedDrawerWidth, enqueueSnackbar]);

  useEffect(() => {
    const changedShiftTruckIds = Array.from(new Set([
      ...operationEntities.filter((it) => it.isChanged).map((it) => ([it.shiftTruckId, it.originalShiftTruckId])).flat(),
      ...baseOperationEntities.filter((it) => it.isChanged).map((it) => it.shift_truck_id)
    ]));
    setIsChanged(changedShiftTruckIds.length > 0);
  }, [operationEntities, baseOperationEntities]);

  useEffect(() => {
    setDisplayOrderDialogIsOpen(!!displayDetailOrderId);
  }, [displayDetailOrderId]);

  const addEventDialogSelectedOrderIds = useCallback((eventDialogOrderIds: number[]) => {
    setEventDialogSelectedOrderIds(
      [...eventDialogSelectedOrderIds, ...eventDialogOrderIds]
    );
  }, [eventDialogSelectedOrderIds]);

  const removeEventDialogSelectedOrderIds = useCallback((eventDialogOrderIds: number[]) => {
    setEventDialogSelectedOrderIds(
      eventDialogSelectedOrderIds.filter((it) => !eventDialogOrderIds.includes(it))
    );
  }, [eventDialogSelectedOrderIds]);

  const updateDisplayDetailOrderId = useCallback((orderId: number) => {
    setDetailDisplayOrderId(orderId);
  }, []);

  const resetDisplayDetailOrderId = useCallback(() => {
    setDetailDisplayOrderId(undefined);
  }, []);

  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState<boolean>(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState<string>('');

  const deleteButtonOnClick = useCallback(() => {
    if (isLoading) return;
    if (!eventDialogSelectedOrderIds.length) return;

    setConfirmDialogMessage('保存されていない変更は破棄されます。よろしいですか？');
    setConfirmDialogIsOpen(true);
  }, [isLoading, eventDialogSelectedOrderIds]);

  const confirmDialogHandleOk = useCallback(() => {
    setConfirmDialogIsOpen(false);
    setDialogIsOpen(false);
    mutateDeleteOrdersOperations(eventDialogSelectedOrderIds);
  }, [mutateDeleteOrdersOperations, eventDialogSelectedOrderIds]);

  const confirmDialogHandleCancel = useCallback(() => {
    setConfirmDialogIsOpen(false);
  }, []);

  const dialogOnClose = useCallback(() => {
    if (isLoading) return;

    setDialogIsOpen(false);
  }, [isLoading]);

  const planningOrderPresenterMemo = useMemo(() => (
    <Dialog
      fullWidth
      maxWidth="md"
      open={displayOrderDialogIsOpen}
      onClose={resetDisplayDetailOrderId}
    >
      <PlanningOrderPresenter
        orderId={displayDetailOrderId}
        customInputFields={[]}
      />
    </Dialog>
  ), [displayOrderDialogIsOpen, displayDetailOrderId, resetDisplayDetailOrderId]);

  const [allocatedOrderIds, setAllocatedOrderIds] = useState<number[]>([]);
  useEffect(() => {
    const orderIds = operationEvents.flatMap((event) => event.operations.map((it) => it.order?.id)).sort();
    setAllocatedOrderIds((prev) => {
      if (arrayUtil.equals(prev, orderIds)) {
        return prev;
      }
      return orderIds;
    });
  }, [operationEvents]);
  const unallocatedOrders: OrderEntity[] = useMemo(() => (
    orderData.filter((order) => !allocatedOrderIds.includes(order.id))
  ), [allocatedOrderIds, orderData]);
  const currentUnallocatedOrders = useMemo(() => (
    unallocatedOrders.filter((it) => !allocatedOrderIds.includes(it.id))
  ), [allocatedOrderIds, unallocatedOrders]);

  const onClick = useCallback((eventClickArg: EventClickArg) => {
    const { id } = eventClickArg.event;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { type }: {
      type?: string;
    } = eventClickArg.event.extendedProps;

    if (type === 'operationEvent') {
      const operationEventEntity: OperationEventEntity = operationEvents.find((it) => it.id === id);

      setPosition(operationEventEntity.position);

      const resource = resources.find((it) => it.id === operationEventEntity.resourceId);
      setUniqDriverEntitiesOnPosition([{
        id: resource.driverEntity.id,
        name: resource.driverEntity.name,
        driverCostYenPerHours: resource.driverEntity.driverCostYenPerHours,
        emailAddress: resource.driverEntity.emailAddress,
        activationState: resource.driverEntity.activationState,
        isActive: resource.driverEntity.isActive,
        color: resource.driverEntity.color,
        companyId: resource.driverEntity.companyId,
        sortOrder: resource.driverEntity.sortOrder,
      }]);

      setDeliveryEntitiesOnPosition(
        deliveryData.filter((it) => it.id === Number(operationEventEntity.resourceId))
                    .map((it) => ({
                      id: it.id,
                      shiftId: it.shiftId,
                      truckId: it.truckId,
                      startAt: operationEventEntity.operationStart,
                      endAt: operationEventEntity.operationEnd,
                      driverId: it.driverId,
                      operations: operationEventEntity.planningsOperations,
                      arrivalDistanceMm: it.arrivalDistanceMm,
                      garageLatitude: it.garageLatitude,
                      garageLongitude: it.garageLongitude,
                      driverColor: it.driverColor,
                      distanceMmBurdenPerShippers: it.distanceMmBurdenPerShippers,
                    }))
      );
      setTruckEntities(truckData);
      const allFixed = operationEventEntity.operationsByDeliveryId[operationEventEntity.resourceId].every((it) => it.fixed);
      setEventDialogSelectedOrderIds(allFixed ? [] : operationEventEntity.operations.map((it) => it.order?.id));
      setIsAllFixed(allFixed);

      setDialogIsOpen(true);
    }
  }, [deliveryData, operationEvents, resources, truckData]);

  const findDepartureEntity = useCallback((deliveryId: number) => (
    baseOperationEntities.find((it) => it.shift_truck_id === deliveryId && it.place_type === 'departure')
  ), [baseOperationEntities]);

  const findArrivalEntity = useCallback((deliveryId: number) => (
    baseOperationEntities.find((it) => it.shift_truck_id === deliveryId && it.place_type === 'arrival')
  ), [baseOperationEntities]);

  const handleSameResource = useCallback((
    changeArg: EventChangeArg,
    currentOperations: PlanningsOperationEntity[],
    operations: PlanningsOperationEntity[],
    startAt: Date,
    endAt: Date,
  ) => {
    const newOperationDuration = (endAt.getTime() - startAt.getTime()) / 1000;
    const newOperations: PlanningsOperationEntity[] = currentOperations.map((it) => ({
      ...it,
      arrivalAt: datetimeDecorator.fmtIso8601(startAt),
      departureAt: datetimeDecorator.fmtIso8601(endAt),
      operationDurationSeconds: newOperationDuration,
      waitingDurationSeconds: 0,
      isChanged: true,
    }));
    const validatedOperations = newOperations.map((newOperation) => {
      const pairedOperation = findPairedOperation(newOperation, operations);

      if (pairedOperation) {
        if (newOperation.action === '積') {
          if (new Date(newOperation.departureAt).getTime() > new Date(pairedOperation.arrivalAt).getTime()) {
            // 積の場合、降より先にある
            enqueueSnackbar('積地の時間を降地より後ろにできません。先に降地の時間を変更してください。');
            return null;
          }
          const departure = findDepartureEntity(newOperation.shiftTruckId);
          if (new Date(newOperation.arrivalAt).getTime() < new Date(departure.departure_at).getTime()) {
            // 積の場合、出発より後にある
            enqueueSnackbar('積地の時間を出発時間より前にできません。先に出発時間を変更してください。');
            return null;
          }
        }
        if (newOperation.action === '降') {
          if (new Date(newOperation.arrivalAt).getTime() < new Date(pairedOperation.departureAt).getTime()) {
            // 降の場合、積より後にある
            enqueueSnackbar('降地の時間を積地より前にできません。先に積地の時間を変更してください。');
            return null;
          }
          const arrival = findArrivalEntity(newOperation.shiftTruckId);
          if (new Date(newOperation.departureAt).getTime() > new Date(arrival.arrival_at).getTime()) {
            // 降の場合、帰着より先にある
            enqueueSnackbar('降地の時間を帰着時間より後ろにできません。先に帰着時間を変更してください。');
            return null;
          }
        }
      }

      return newOperation;
    });

    // だめなものがあったら変更しない
    if (validatedOperations.some((it) => !it)) {
      changeArg.revert();
      return;
    }

    validatedOperations.forEach((newOperation) => {
      dispatchOperationEntities({
        type: 'update',
        payload: newOperation
      });
    });
  }, [enqueueSnackbar, findArrivalEntity, findDepartureEntity]);

  const handleOtherResource = useCallback((
    changeArg: EventChangeArg,
    currentOperations: PlanningsOperationEntity[],
    operations: PlanningsOperationEntity[],
    startAt: Date,
    endAt: Date,
    resourceId: string
  ) => {
    const diffSeconds = (changeArg.event.start.getTime() - changeArg.oldEvent.start.getTime()) / 1000;

    const newOperations: PlanningsOperationEntity[] = currentOperations.map((it) => ({
      ...it,
      arrivalAt: datetimeDecorator.fmtIso8601(startAt),
      departureAt: datetimeDecorator.fmtIso8601(endAt),
      shiftTruckId: Number(resourceId),
      waitingDurationSeconds: 0,
      originalShiftTruckId: it.originalShiftTruckId || it.shiftTruckId,
      isChanged: true,
    }));

    const departure = findDepartureEntity(Number(resourceId));
    if (departure && new Date(startAt).getTime() < new Date(departure.departure_at).getTime()) {
      enqueueSnackbar('積地の時間を出発時間より前にできません。先に出発時間を変更してください。');
      changeArg.revert();
      return;
    }
    const arrival = findArrivalEntity(Number(resourceId));
    if (arrival && new Date(endAt).getTime() > new Date(arrival.arrival_at).getTime()) {
      enqueueSnackbar('降地の時間を帰着時間より後ろにできません。先に帰着時間を変更してください。');
      changeArg.revert();
      return;
    }

    const pairedOperations: PlanningsOperationEntity[] = newOperations.map((newOperation) => {
      const pairedOperation = findPairedOperation(newOperation, operations);

      if (pairedOperation) {
        const newArrivalAt = addSeconds(new Date(pairedOperation.arrivalAt), diffSeconds);

        const order = orderData.find((it) => it.id === pairedOperation.orderId);
        const diffMinutes = pairedOperation.action === '積' ? order.loading_staying_minutes : order.unloading_staying_minutes;
        const newDepartureAt = addMinutes(newArrivalAt, diffMinutes);

        if (departure && new Date(newArrivalAt).getTime() < new Date(departure.departure_at).getTime()) {
          enqueueSnackbar('積地の時間を出発時間より前にできません。先に出発時間を変更してください。');
          changeArg.revert();
          return null;
        }
        if (arrival && new Date(newDepartureAt).getTime() > new Date(arrival.arrival_at).getTime()) {
          enqueueSnackbar('降地の時間を帰着時間より後ろにできません。先に帰着時間を変更してください。');
          changeArg.revert();
          return null;
        }

        return {
            ...pairedOperation,
            arrivalAt: datetimeDecorator.fmtIso8601(newArrivalAt),
            departureAt: datetimeDecorator.fmtIso8601(newDepartureAt),
            shiftTruckId: Number(resourceId),
            originalShiftTruckId: pairedOperation.originalShiftTruckId || pairedOperation.shiftTruckId,
            waitingDurationSeconds: 0,
            isChanged: true,
        };
      }

      return null;
    });

    if (pairedOperations.some((it) => !it)) {
      // なにかエラーがあるので戻す
      changeArg.revert();
      return;
    }

    newOperations.forEach((newOperation) => {
      dispatchOperationEntities({
        type: 'update',
        payload: newOperation
      });
    });
    pairedOperations.forEach((pairedOperation) => {
      dispatchOperationEntities({
        type: 'update',
        payload: pairedOperation
      });
    });
  }, [enqueueSnackbar, findArrivalEntity, findDepartureEntity, orderData]);

  const handleBaseOperationEvent = useCallback((beforeDeliveryId: string, deliveryId: string, changeArg: EventChangeArg) => {
    if (beforeDeliveryId !== deliveryId) {
      enqueueSnackbar('車庫の予定を他のトラックに移動できません。');
      changeArg.revert();
      return;
    }

    const { start: newStartAt, end: newEndAt, id } = changeArg.event;
    const { extendedProps } = changeArg.event.toPlainObject();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { baseOperation }: OperationEventEntity = extendedProps;
    const operations = operationEntities.filter((it) => it.shiftTruckId === Number(deliveryId));

    let canUpdate = true;
    if (baseOperation.place_type === 'departure') {
      const minTime = Math.min(...operations.map((it) => new Date(it.arrivalAt).getTime()));
      if (newStartAt.getTime() > minTime) {
        enqueueSnackbar('出発時間は案件より後ろにできません。');
        canUpdate = false;
      } else {
        const arrival = findArrivalEntity(Number(deliveryId));
        if (newStartAt.getTime() > new Date(arrival.arrival_at).getTime()) {
          enqueueSnackbar('出発時間は帰着時間より後ろにできません。');
          canUpdate = false;
        }
      }
    } else {
      const maxTime = Math.max(...operations.map((it) => new Date(it.departureAt).getTime()));
      if (newStartAt.getTime() < maxTime) {
        enqueueSnackbar('帰着時間は案件より前にできません。');
        canUpdate = false;
      } else {
        const departure = findDepartureEntity(Number(deliveryId));
        if (newStartAt.getTime() < new Date(departure.departure_at).getTime()) {
          enqueueSnackbar('帰着時間は出発時間より前にできません。');
          canUpdate = false;
        }
      }
    }

    if (!canUpdate) {
      changeArg.revert();
      return;
    }
    dispatchBaseOperationEntities({
      type: 'update',
      payload: {
        ...baseOperation,
        arrival_at: newStartAt.toISOString(),
        departure_at: newEndAt.toISOString(),
        isChanged: true,
      }
    });
  }, [enqueueSnackbar, findArrivalEntity, findDepartureEntity, operationEntities]);

  const onChange = useCallback((changeArg: EventChangeArg) => {
    // eslint-disable-next-line no-underscore-dangle
    const beforeResourceId = changeArg.oldEvent._def.resourceIds[0];
    const { start: newStartAt, end: newEndAt, id } = changeArg.event;
    const { extendedProps } = changeArg.event.toPlainObject();
    // eslint-disable-next-line no-underscore-dangle
    const destinationResourceId = changeArg.event._def.resourceIds[0];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { operations, type }: OperationEventEntity = extendedProps;

    const currentOperations: PlanningsOperationEntity[] = operations
      .map((opEventOp) => operationEntities.find((it) => it.action === opEventOp.action && it.orderId === opEventOp.order?.id))
      .filter((maybe) => maybe);

    const sourceFixed = fixedShiftTruckIds.includes(Number(beforeResourceId));
    const destinationFixed = operationEntities.filter((it) => it.shiftTruckId === Number(destinationResourceId)).some((it) => it.fixed);
    if (sourceFixed || destinationFixed) {
      changeArg.revert();
      enqueueSnackbar('確定した計画は変更できません');
      return;
    }

    const sourceResouce = resources.find((it) => it.id === beforeResourceId);
    const destinationResource = resources.find((it) => it.id === destinationResourceId);
    if (sourceResouce.driverEntity.companyId !== destinationResource.driverEntity.companyId) {
      changeArg.revert();
      enqueueSnackbar('別の事業所の案件は設定できません');
      return;
    }

    if (type === 'baseOperationEvent') {
      handleBaseOperationEvent(
        beforeResourceId,
        destinationResourceId,
        changeArg
      );
      return;
    }

    if (type === 'operationWaitingEvent') {
      changeArg.revert();
      enqueueSnackbar('待機時間は移動できません');
      return;
    }

    currentOperations.forEach((it) => { it.isChanged = true; });

    if (beforeResourceId === destinationResourceId) {
      handleSameResource(
        changeArg,
        currentOperations,
        operationEntities,
        new Date(newStartAt),
        new Date(newEndAt),
      );
    } else {
      handleOtherResource(
        changeArg,
        currentOperations,
        operationEntities,
        new Date(newStartAt),
        new Date(newEndAt),
        destinationResourceId,
      );
    }
  }, [enqueueSnackbar, fixedShiftTruckIds, handleBaseOperationEvent, handleOtherResource, handleSameResource, operationEntities, resources]);

  const buildBaseOperationEntity = useCallback((shiftTruckId: number, at: Date, placeType: string) => {
    const { truckId } = deliveryData.find((it) => it.id === shiftTruckId);
    const { garageId } = truckData.find((it) => it.id === truckId);
    const garage = garageData.find((it) => it.id === garageId);

    return {
      id: 0,
      shift_truck_id: shiftTruckId,
      arrival_at: at.toISOString(),
      departure_at: at.toISOString(),
      driving_duration_seconds: 0,
      operation_duration_seconds: 0,
      operation_start_at: at.toISOString(),
      waiting_duration_seconds: 0,
      driving_distance_mm: 0,
      address: garage.address,
      name: garage.name,
      place_type: placeType
    } as BaseOperationEntityWithPlaceType;
  }, [deliveryData, garageData, truckData]);

  const onDrop = useCallback((arg: DropArg) => {
    if (isLoading) {
      return;
    }

    const { resource: { id }, draggedEl: { dataset: { entity: json, groupedids } }, dateStr: loadingEventStart } = arg;
    const resourceId = id;
    const orderEntity = JSON.parse(json) as OrderEntity;

    const loadingStart = new Date(loadingEventStart);
    const unloadingStart = addMinutes(loadingStart, (orderEntity.loading_staying_minutes || 10) + 30); // 走行時間30分

    const shiftTruckId = Number(resourceId);

    const sourceFixed = fixedShiftTruckIds.includes(Number(shiftTruckId));
    if (sourceFixed) {
      enqueueSnackbar('確定した計画は変更できません');
      return;
    }

    const resource = resources.find((it) => it.id === resourceId);
    if (resource.driverEntity.companyId !== orderEntity.company_id) {
      enqueueSnackbar('別の事業所の案件は設定できません');
      return;
    }

    const departureOperation = baseOperationEntities.find((it) => it.shift_truck_id === shiftTruckId && it.place_type === 'departure');
    if (departureOperation) {
      const departureAt = new Date(departureOperation.departure_at);
      if (loadingStart.getTime() < departureAt.getTime()) {
        enqueueSnackbar('積地の時間を出発時間より前にできません。先に出発時間を変更してください。');
        return;
      }
    }
    const arrivalOperation = baseOperationEntities.find((it) => it.shift_truck_id === shiftTruckId && it.place_type === 'arrival');
    if (arrivalOperation) {
      const arrivalAt = new Date(arrivalOperation.arrival_at);
      if (unloadingStart.getTime() > arrivalAt.getTime()) {
        enqueueSnackbar('降地の時間を帰着時間より後ろにできません。先に帰着時間を変更してください。');
        return;
      }
    }

    const unloadingOperation = buildOperationEntity(
      orderEntity,
      0,
      unloadingStart,
      shiftTruckId,
      '降',
      true,
    );

    dispatchOperationEntities({
      type: 'add',
      payload: unloadingOperation,
    });

    const loadingOperation: PlanningsOperationEntity = buildOperationEntity(
      orderEntity,
      0,
      loadingStart,
      shiftTruckId,
      '積',
      true,
    );

    dispatchOperationEntities({
      type: 'add',
      payload: loadingOperation,
    });

    if (groupedids.length > 0) {
      groupedids.split(',').forEach((it) => {
        const entity = orderEntityMap.get(Number(it));
        if (!entity) return;

        dispatchOperationEntities({
          type: 'add',
          payload: buildOperationEntity(
            entity,
            0,
            unloadingStart,
            shiftTruckId,
            '降',
            true,
          )
        });

        dispatchOperationEntities({
          type: 'add',
          payload: buildOperationEntity(
            entity,
            0,
            loadingStart,
            shiftTruckId,
            '積',
            true,
          ),
        });
      });
    }
  }, [baseOperationEntities, enqueueSnackbar, fixedShiftTruckIds, isLoading, orderEntityMap, resources]);

  useEffect(() => {
    const shiftTruckIds = arrayUtil.uniq(operationEntities.map((it) => it.shiftTruckId));
    baseOperationEntities.forEach((it) => {
      if (!shiftTruckIds.includes(it.shift_truck_id)) {
        dispatchBaseOperationEntities({
          type: 'remove',
          shiftTruckId: it.shift_truck_id,
        });
      }
    });
    shiftTruckIds.forEach((shiftTruckId) => {
      const departureOperation = baseOperationEntities.find((it) => it.shift_truck_id === shiftTruckId && it.place_type === 'departure');
      const arrivalOperation = baseOperationEntities.find((it) => it.shift_truck_id === shiftTruckId && it.place_type === 'arrival');

      const operations = operationEntities.filter((it) => it.shiftTruckId === shiftTruckId);
      const minArrivalAt = operations.map((it) => it.arrivalAt).sort()[0];
      const maxDepartureAt = operations.map((it) => it.departureAt).sort().reverse()[0];

      if (!departureOperation) {
        dispatchBaseOperationEntities({
          type: 'add',
          payload: buildBaseOperationEntity(shiftTruckId, addMinutes(new Date(minArrivalAt), -30), 'departure'),
        });
      }
      if (!arrivalOperation) {
        dispatchBaseOperationEntities({
          type: 'add',
          payload: buildBaseOperationEntity(shiftTruckId, addMinutes(new Date(maxDepartureAt), 30), 'arrival'),
        });
      }
    });
  }, [baseOperationEntities, buildBaseOperationEntity, operationEntities]);

  const [popoverEl, setPopoverEl] = useState<HTMLElement | null>(null);
  const [popoverOperationEventEntity, setPopoverOperationEventEntity] = useState<EventImpl>(null);
  const handlePopoverOpen = useCallback((hoverArg: EventHoveringArg) => {
    const { el, event } = hoverArg;
    const { type }: { type?: string } = event.extendedProps;

    if (type === 'operationEvent' || type === 'operationWaitingEvent' || type === 'baseOperationEvent') {
      setPopoverEl(el);
      setPopoverOperationEventEntity(event);
    }
  }, []);

  const handlePopoverClose = useCallback(() => {
    setPopoverEl(null);
    setPopoverOperationEventEntity(null);
  }, []);

  const createOperationData = useCallback((deliveries: PlanningsDeliveryEntity[]) => (
    deliveries.flatMap((it) => {
      let prev: PlanningsOperationEntity = null;
      return it.operations.map((op) => {
         if (prev && prev.action === op.action && prev.latitude === op.latitude && prev.longitude === op.longitude) {
          // 同一地点の場合、最初以外の所要時間が0になっている。他のDeliveryに移動された場合に元々の時間がわからなくなるので、すべてに同じ開始・終了時間を設定しておく。
          prev = {
            ...op,
            arrivalAt: prev.arrivalAt,
            departureAt: prev.departureAt,
            operationDurationSeconds: prev.operationDurationSeconds,
            operationStartAt: prev.operationStartAt,
          };
          return prev;
        }
        prev = op;
        return op;
      });
    })
  ), []);

  useEffect(() => {
    if (![deliveryData, orderData].every((maybe) => maybe)) return;
    const operationData = createOperationData(deliveryData);
    const baseOperationData: BaseOperationEntityWithPlaceType[] = deliveryData.filter((it) => it.departureOperation && it.arrivalOperation).flatMap((it) => (
      [
        { ...it.departureOperation, place_type: 'departure', isChanged: false },
        { ...it.arrivalOperation, place_type: 'arrival', isChanged: false },
      ]
    ));
    dispatchOperationEntities(
      {
        type: 'set',
        payload: operationData
      }
    );
    dispatchBaseOperationEntities(
      {
        type: 'set',
        payload: baseOperationData
      }
    );
  }, [createOperationData, deliveryData, orderData]);

  useEffect(() => {
    if (![orderData, operationEntities].every((maybe) => maybe)) return;

    dispatchOperationEvents(
      {
        type: 'set',
        payload: buildOperationEvents(operationEntities, orderData)
      }
    );
    dispatchBaseOperationEvents(
      {
        type: 'set',
        payload: buildBaseOperationEvents(baseOperationEntities)
      }
    );
  }, [baseOperationEntities, buildBaseOperationEvents, buildOperationEvents, operationEntities, orderData]);

  useEffect(() => {
    setEvents([
      ...baseOperationEvents,
      ...operationEvents,
      ...deliveryEvents,
    ]);
  }, [operationEvents, deliveryEvents, baseOperationEvents]);

  useEffect(() => {
    const draggableEl = document.getElementById(draggableElementId);
    if (!draggableEl) return;
    // eslint-disable-next-line no-new
    new Draggable(draggableEl, {
      itemSelector: `.${draggableElementClassName}`,
    });
  }, []);

  const getSlotMinTime = useCallback(() => {
    if (deliveryEvents.length === 0) return '00:00:00';

    const minStart = arrayUtil.minAt(deliveryEvents.map((it) => new Date(it.start)));
    if (minStart) {
      return `${minStart.getHours()}:00:00`;
    }
    return '00:00:00';
  }, [deliveryEvents]);

  const getCalendarDuration = useCallback(() => (
    { days: (endDate.getDate() - startDate.getDate() + 1) }
  ), [startDate, endDate]);

  const Views = useMemo(() => ({
    resourceTimelineDay: {
      buttonText: '拡大',
      slotDuration: '00:05',
      duration: getCalendarDuration(),
    },
    resourceTimelineDayTwo: {
      type: 'resourceTimelineDay',
      buttonText: '標準',
      slotDuration: '00:10',
      duration: getCalendarDuration(),
    },
    resourceTimelineDayThree: {
      type: 'resourceTimelineDay',
      buttonText: '縮小',
      slotDuration: '01:00',
      duration: getCalendarDuration(),
    }
  }), [getCalendarDuration]);

  const afterUpdate = useCallback((deliveries: PlanningsDeliveryEntity[]) => {
    const entities = operationEntities.map((it) => {
      it.isChanged = false;
      return it;
    });
    dispatchOperationEntities({
      type: 'set',
      payload: entities
    });
    const baseEntities = baseOperationEntities.map((it) => {
      it.isChanged = false;
      return it;
    });
    dispatchBaseOperationEntities({
      type: 'set',
      payload: baseEntities
    });
    const operationData = createOperationData(deliveries);
    dispatchOperationEntities({
      type: 'set',
      payload: operationData
    });
  }, [baseOperationEntities, createOperationData, operationEntities]);

  const CustomButtons = useMemo(() => {
    if (context?.config?.use_driver_app) {
      const driverAppButtonText = 'ドライバーに通知する';
      const driverAppClickFunction = () => {
        const confirmMsg = 'ドライバーにメールを送信します。よろしいですか？';
        const confirm = window.confirm(confirmMsg);
        if (!confirm) return;

        if (isChanged) {
          save(operationEntities, baseOperationEntities, operationEvents, afterUpdate);
        }

        sendMail();
      };

      return {
        saveButton: {
          text: '保存',
          click: () => { save(operationEntities, baseOperationEntities, operationEvents, afterUpdate); },
        },
        cancelButton: {
          text: 'もとに戻す',
          click: () => { reset(); }
        },
        editShiftButton: {
          text: '勤務計画',
          click: () => { navigate(`/shifts/${datetimeDecorator.toYyyyMmDd(startDate)}/${datetimeDecorator.toYyyyMmDd(endDate)}/timeline`); }
        },
        importButton: {
          text: '案件インポート',
          click: () => { navigate('/import'); }
        },
        printButton: {
          text: '配送指示書',
          click: () => { selectPrintButtonOnClick(); }
        },
        driverAppButton: {
          text: driverAppButtonText,
          click: () => { driverAppClickFunction(); }
        },
      };
    }

    return {
      saveButton: {
        text: '保存',
        click: () => { save(operationEntities, baseOperationEntities, operationEvents, afterUpdate); },
      },
      cancelButton: {
        text: 'もとに戻す',
        click: () => { reset(); }
      },
      editShiftButton: {
        text: '勤務計画',
        click: () => { navigate(`/shifts/${datetimeDecorator.toYyyyMmDd(startDate)}/${datetimeDecorator.toYyyyMmDd(endDate)}/timeline`); }
      },
      importButton: {
        text: '案件インポート',
        click: () => { navigate('/import'); }
      },
      printButton: {
        text: '配送指示書',
        click: () => { selectPrintButtonOnClick(); }
      },
    };
  }, [context?.config?.use_driver_app, isChanged, sendMail, save, operationEntities, baseOperationEntities, operationEvents, afterUpdate, reset, navigate, startDate, endDate, selectPrintButtonOnClick]);

  const displayButtons = useMemo(() => (
    [
      'saveButton',
      'cancelButton',
      'editShiftButton',
      'importButton',
      'printButton',
      context?.config?.use_driver_app ? 'driverAppButton' : ''
    ].filter((maybe) => !!maybe)
  ), [context?.config?.use_driver_app]);

  const HeaderToolbar = useMemo(() => ({
    left: displayButtons.join(' '),
    center: 'title',
    right: 'resourceTimelineDay,resourceTimelineDayTwo,resourceTimelineDayThree'
  }), [displayButtons]);

  const ResourceAreaColumns = useMemo(() => ([
    {
      headerContent: '車両',
      field: 'title',
      width: 400,
    },
    {
      field: 'summaries',
      headerContent: '集計',
    }
  ]), []);

  const toggleDrawer = useCallback(() => {
    setOpenUnallocatedOrders(!openUnallocatedOrders);
  }, [openUnallocatedOrders]);

  useEffect(() => {
    setOpenUnallocatedOrders(!!currentUnallocatedOrders.length);
  }, [currentUnallocatedOrders.length]);

  const backDropMemo = useMemo(() => (
    <Backdrop
      sx={{ color: '#fff', zIndex: 1000 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ), [isLoading]);
  const calendarMemo = useMemo(() => (
    <Stack
      px={1}
    >
      <FullCalendar
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        initialDate={startDate}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        customButtons={CustomButtons}
        headerToolbar={HeaderToolbar}
        views={Views}
        initialView="resourceTimelineDayTwo"
        duration={getCalendarDuration()}
        locale="ja"
        contentHeight={`calc(100vh - ${appBarHeight + 130}px)`}
        events={events}
        resourceGroupField="garageName"
        resources={resources}
        resourceOrder="resourceIndex"
        selectable
        eventChange={onChange}
        eventClick={onClick}
        editable
        droppable
        drop={onDrop}
        eventMouseEnter={handlePopoverOpen}
        eventMouseLeave={handlePopoverClose}
        eventDragStart={handlePopoverClose}
        resourceAreaColumns={ResourceAreaColumns}
        scrollTime={getSlotMinTime()}
      />
    </Stack>
  ), [CustomButtons, HeaderToolbar, ResourceAreaColumns, Views, events, getCalendarDuration, getSlotMinTime, handlePopoverClose, handlePopoverOpen, onChange, onClick, onDrop, resources, startDate]);

  const popoverMemo = useMemo(() => {
    if (popoverOperationEventEntity === null) return null;

    let text = '';
    if (popoverOperationEventEntity.title) {
      text += popoverOperationEventEntity.title;
    }
    if (popoverOperationEventEntity?.start) {
      text += ` ${datetimeDecorator.toHourMinutes(new Date(popoverOperationEventEntity.start))}`;
    }
    if (popoverOperationEventEntity?.end) {
      text += ` - ${datetimeDecorator.toHourMinutes(new Date(popoverOperationEventEntity.end))}`;
    }
    if (text === '') {
      return null;
    }

    return (
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={Boolean(popoverEl)}
        anchorEl={popoverEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        hideBackdrop
        disableScrollLock
      >
        <Typography sx={{ p: 1 }}>
          {text}
        </Typography>
      </Popover>
    );
  }, [handlePopoverClose, popoverEl, popoverOperationEventEntity]);

  const detailDialogMemo = useMemo(() => (
    <Dialog
      open={dialogIsOpen}
      onClose={dialogOnClose}
      fullWidth
      maxWidth="md"
    >
      <PlanningMapDriversPresenter
        position={position}
        driverEntitiesOnPosition={uniqDriverEntitiesOnPosition}
        deliveryEntitiesOnPosition={deliveryEntitiesOnPosition}
        startOn={datetimeDecorator.toYyyyMmDd(startDate)}
        endOn={datetimeDecorator.toYyyyMmDd(endDate)}
        selectedOrderIds={eventDialogSelectedOrderIds}
        addSelectedOrderIds={addEventDialogSelectedOrderIds}
        removeSelectedOrderIds={removeEventDialogSelectedOrderIds}
        updateDisplayOrderId={updateDisplayDetailOrderId}
        isLoading={isLoading}
        truckEntities={truckEntities}
        editPlaces={undefined}
        planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
      />
      <DialogActions>
        <Stack direction="row" gap={1}>
          <Button
            disabled={!eventDialogSelectedOrderIds.length || isLoading || isAllFixed}
            onClick={deleteButtonOnClick}
            startIcon={<DeleteForeverRoundedIcon />}
          >
            割当解除
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  ), [addEventDialogSelectedOrderIds, deleteButtonOnClick, deliveryEntitiesOnPosition, dialogIsOpen, dialogOnClose, endDate, isAllFixed, isLoading, position, removeEventDialogSelectedOrderIds, eventDialogSelectedOrderIds, startDate, truckEntities, uniqDriverEntitiesOnPosition, updateDisplayDetailOrderId, planningsOperationDeliveryByDeliveryIdEntity]);

  const resetAllocateHistories = useCallback(() => {
    // void
  }, []);

  const setOrderSearchConditions = useCallback((conditions: OrderSearchConditionEntity[]) => {
    // void;
  }, []);

  const [unallocatedOrderIds, setUnallocatedOrderIds] = useState<number[]>([]);

  const contentHeight = '100vh';

  useEffect(() => {
    const orderIds = currentUnallocatedOrders.map((it) => it.id).sort();
    setUnallocatedOrderIds((prev) => {
      if (arrayUtil.equals(prev, orderIds)) {
          return prev;
      }
      return orderIds;
    });
  }, [currentUnallocatedOrders]);
  const notAllocReasons = useMemo(() => [], []);

  const ordersMemo = useMemo(() => (
    <OrdersPresenter
      ids={unallocatedOrderIds}
      selectedIds={selectedOrderIds}
      addSelectedId={addSelectedOrderId}
      removeSelectedId={removeSelectedOrderId}
      unit={unit}
      isLoading={isLoading}
      startOn={format(startDate, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' })}
      endOn={format(endDate, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' })}
      mutateDeleteOrder={mutateDeleteOrder}
      mutateDeleteSpecificOrder={mutateDeleteSpecificOrder}
      mutateCloneOrder={mutateCloneOrder}
      planningOrderStatisticsEntity={planningOrderStatisticsEntity}
      resetAllocateHistories={resetAllocateHistories}
      customInputFields={customInputFields}
      notAllocReasons={notAllocReasons}
      truckEntities={truckEntities}
      driverEntities={driverEntities}
      deliveryEntities={deliveryEntities}
      orderEntityMap={orderEntityMap}
      unallocatedDrawerWidth={unallocatedDrawerWidth}
      orderSearchKw={orderSearchKw}
      setOrderSearchKw={setOrderSearchKw}
      currentlyOrderSearching={currentlyOrderSearching}
      setCurrentlyOrderSearching={setCurrentlyOrderSearching}
      setOrderSearchConditions={setOrderSearchConditions}
      mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
      mutateRestoreSplittedOrders={mutateRestoreSplittedOrders}
    />
  ), [addSelectedOrderId, customInputFields, deliveryEntities, driverEntities, endDate, isLoading, mutateCloneOrder, mutateDeleteOrder, mutateDeleteSpecificOrder, notAllocReasons, orderEntityMap, planningOrderStatisticsEntity, removeSelectedOrderId, resetAllocateHistories, selectedOrderIds, startDate, truckEntities, unallocatedOrderIds, unit, unallocatedDrawerWidth, orderSearchKw, setOrderSearchKw, currentlyOrderSearching, setCurrentlyOrderSearching, setOrderSearchConditions, mutateRestoreSplittedOrder, mutateRestoreSplittedOrders]);

  const unallocatedOrdersMemo = useMemo(() => (
    <Stack>
      <IconButton
        sx={{
          position: 'fixed',
          right: openUnallocatedOrders ? `${unallocatedDrawerWidth - 5}px` : '-5px',
          bottom: '5%',
          zIndex: 998,
          backgroundColor: theme.palette.background.paper,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: theme.colors.secondary.light,
        }}
        onClick={() => toggleDrawer()}
      >
        {openUnallocatedOrders
        ? <KeyboardArrowRightIcon />
        : <KeyboardArrowLeftIcon />}
      </IconButton>
      <Box
        sx={{
          width: openUnallocatedOrders ? unallocatedDrawerWidth : 0,
          bgcolor: theme.colors.alpha.white[100],
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 999,
          overflow: 'hidden',
          height: `calc(100vh - ${appBarHeight}px)`,
        }}
      >
        {ordersMemo}
      </Box>
    </Stack>
  ), [openUnallocatedOrders, unallocatedDrawerWidth, theme.palette.background.paper, theme.colors.secondary.light, theme.colors.alpha.white, ordersMemo, toggleDrawer]);

  const truckGroupMemo = useMemo(() => (
    <Stack
      pl={1}
      minWidth="200px"
    >
      <PlanningsTruckGroupSelectPresenter
        options={groupEntities}
        value={selectedGroupEntity}
        onChange={updateSelectedGroupEntity}
        fullWidth={false}
      />
    </Stack>
  ), [groupEntities, selectedGroupEntity, updateSelectedGroupEntity]);

  const dateSelectMemo = useMemo(() => (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      width="100%"
    >
      <DatePicker
        value={startDate}
        disabled={isLoading}
        onChange={datePickerStartOnChange}
        label="開始日"
        renderInput={(params) => (
          <Tooltip
            title="配車計画開始日を変更する"
            arrow
          >
            <TextField
              {...params}
              size="small"
              sx={{
                width: 155
              }}
            />
          </Tooltip>
        )}
      />
      <DatePicker
        value={endDate}
        disabled={isLoading}
        onChange={datePickerEndOnChange}
        label="終了日"
        minDate={minEndOn}
        maxDate={maxEndOn}
        renderInput={(params) => (
          <Tooltip
            title="配車計画終了日を変更する"
            arrow
          >
            <TextField
              {...params}
              size="small"
              sx={{
                width: 155
              }}
            />
          </Tooltip>
        )}
      />
    </Stack>
  ), [datePickerEndOnChange, datePickerStartOnChange, endDate, isLoading, maxEndOn, minEndOn, startDate]);

  const confirmDialogMemo = useMemo(() => (
    <ConfirmDialog
      open={confirmDialogIsOpen}
      message={confirmDialogMessage}
      handleOk={confirmDialogHandleOk}
      handleCancel={confirmDialogHandleCancel}
    />
  ), [confirmDialogHandleCancel, confirmDialogHandleOk, confirmDialogIsOpen, confirmDialogMessage]);

  const selectPrintMemo = useMemo(() => (
    <SelectPrintPresenter
      printAllOperationDirectionsOnClick={() => {
        updateDirectionSettingDialogIsOpen(true);
      }}
      printAllTruckDirectionsOnClick={printAllTruckDirectionsOnClick}
      printUnloadTruckDirectionsOnClick={printUnloadTruckDirectionsOnClick}
      printPickingListOnClick={printPickingListOnClick}
      downloadPlanCsvOnClick={downloadPlanCsvOnClick}
    />
  ), [
    printAllTruckDirectionsOnClick,
    printUnloadTruckDirectionsOnClick,
    printPickingListOnClick,
    downloadPlanCsvOnClick,
    updateDirectionSettingDialogIsOpen,
  ]);

  return (
    <>
      <Helmet>
        <title>配車計画 | タイムライン</title>
      </Helmet>
      <Paper>
        <Stack>
          <Stack
            sx={{
              marginRight: openUnallocatedOrders ? `${unallocatedDrawerWidth}px` : 0,
              minWidth: theme.breakpoints.values.md,
            }}
          >
            <Stack
              pt={1}
              mb={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              gap={2}
            >
              {truckGroupMemo}
              {dateSelectMemo}
            </Stack>
            {calendarMemo}
            {popoverMemo}
          </Stack>
          {unallocatedOrdersMemo}
        </Stack>

        {confirmDialogMemo}
        {detailDialogMemo}
        {planningOrderPresenterMemo}
        <Dialog
          open={selectPrintDialogIsOpen}
          onClose={selectPrintDialogClose}
        >
          {selectPrintMemo}
        </Dialog>
        <DirectionSettingDialog
          open={directionSettingDialogIsOpen}
          onClose={() => {
            updateDirectionSettingDialogIsOpen(false);
          }}
          width="md"
          navigateToPath={printOperationDirectionUrl()}
        />
        {backDropMemo}
      </Paper>
    </>
  );
});

export default Presenter;
