import { createPathComponent, LeafletContextInterface } from '@react-leaflet/core';
import L, { LeafletEventHandlerFn, LeafletMouseEventHandlerFn } from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import React from 'react';

// webpack failing when loading leaflet marker icon
// eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-unsafe-member-access
delete (L.Icon.Default as any).prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, global-require, @typescript-eslint/no-var-requires
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, global-require, @typescript-eslint/no-var-requires
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, global-require, @typescript-eslint/no-var-requires
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});

type ClusterPropType = { [key in string]: string | boolean | number | undefined };
type ClusterEventType = { [key in string]: LeafletEventHandlerFn }

type ClusterEvents = {
  onClick?: LeafletMouseEventHandlerFn
  onDblClick?: LeafletMouseEventHandlerFn
  onMouseDown?: LeafletMouseEventHandlerFn
  onMouseUp?: LeafletMouseEventHandlerFn
  onMouseOver?: LeafletMouseEventHandlerFn
  onMouseOut?: LeafletMouseEventHandlerFn
  onContextMenu?: LeafletMouseEventHandlerFn
}

type MarkerClusterControl = L.MarkerClusterGroupOptions & {
  children: React.ReactNode
} & ClusterEvents

function getPropsAndEvents(props: MarkerClusterControl): [ClusterPropType, ClusterEventType] {
  let clusterProps: ClusterPropType = {};
  let clusterEvents: ClusterEventType = {};
  const { children, ...rest } = props;
  // Splitting props and events to different objects
  Object.entries(rest).forEach(([propName, prop]) => {
    if (typeof propName === 'string') {
      if (propName.startsWith('on') && typeof prop === 'function') {
        clusterEvents = { ...clusterEvents, [propName]: prop as LeafletEventHandlerFn };
      } else if (typeof prop === 'string' || typeof prop === 'boolean' || typeof prop === 'number' || typeof prop === 'undefined') {
        clusterProps = { ...clusterProps, [propName]: prop };
      }
    }
  });
  return [clusterProps, clusterEvents];
}

function createMarkerCluster(props: MarkerClusterControl, context: LeafletContextInterface) {
  const [clusterProps, clusterEvents] = getPropsAndEvents(props);
  const clusterGroup = new L.MarkerClusterGroup(clusterProps);

  Object.entries(clusterEvents).forEach(([eventAsProp, callback]) => {
    const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`;
    clusterGroup.on(clusterEvent, callback);
  });

  return {
    instance: clusterGroup,
    context: { ...context, layerContainer: clusterGroup },
  };
}

const updateMarkerCluster = (
  instance: L.MarkerClusterGroup,
  props: MarkerClusterControl,
  prevProps: MarkerClusterControl,
) => {
  // TODO when prop change update instance
  // if (props.showCoverageOnHover !== prevProps.showCoverageOnHover) {
  // }
};

const MarkerClusterGroup = createPathComponent<L.MarkerClusterGroup, MarkerClusterControl>(
  createMarkerCluster,
);

export default MarkerClusterGroup;
