import { useQueryClient } from '@tanstack/react-query';
import { addDays, endOfWeek, startOfWeek } from 'date-fns';
import { format } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import LoadingComponent from 'src/components/LoadingComponent';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { SelectedShiftCell } from 'src/entities/SelectedShiftCell.entity';
import { useMutationDeliveries } from 'src/hooks/useDeliveries.query';
import { useDriversQuery } from 'src/hooks/useDrivers.query';
import { useQueryPlanningGroups } from 'src/hooks/useQueryPlanningGroups';
import { useTrucksQuery } from 'src/hooks/useTrucks.query';

import datetimeDecorator from '../../decorators/datetime.decorator';

import Presenter from './Presenter';

type Props = {
  initialStartOn: string;
  initialEndOn: string;
  backTo?: string;
}

const Component: FC<Props> = memo((
  {
    initialStartOn,
    initialEndOn,
    backTo
  }
) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const [startOn, setStartOn] = useState<string>(initialStartOn);
  const [endOn, setEndOn] = useState<string>(initialEndOn);

  const updateStartEndOn = useCallback((week: number) => {
    setSelectedShiftCell([]);
    if (week === 0) {
      setStartOn(initialStartOn);
      setEndOn(initialEndOn);
      return;
    }

    const newStartOn = addDays(new Date(startOn), 7 * week);
    const newEndOn = addDays(new Date(endOn), 7 * week);
    setStartOn(datetimeDecorator.toYyyyMmDd(newStartOn));
    setEndOn(datetimeDecorator.toYyyyMmDd(newEndOn));
  }, [endOn, initialEndOn, initialStartOn, startOn]);

  const { data: truckData, isLoading: truckIsLoading, isFetching: truckIsFetching } = useTrucksQuery(startOn, { enabled: true });
  const { data: driverData, isLoading: driverIsLoading, isFetching: driverIsFetching } = useDriversQuery(startOn, { enabled: true });
  const { data: groupData, isLoading: groupIsLoading, isFetching: groupIsFetching } = useQueryPlanningGroups();
  const { copyFromLastWeek } = useMutationDeliveries();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [daysOfWeek, setDaysOfWeek] = useState<Date[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<PlanningsGroupEntity | undefined>(undefined);
  const [selectedShiftCell, setSelectedShiftCell] = useState<SelectedShiftCell[]>([]);

  const updateSelectedGroup = (entity: PlanningsGroupEntity | undefined) => {
    setSelectedGroup(entity);
  };

  const updateIsLoading = useCallback((bool: boolean) => {
    setIsLoading(bool);
  }, []);

  const mutateExportExcelInThisMonth = (date: string) => {
    window.open(`/api/v3/deliveries/exporting/excels/${date}`);
    enqueueSnackbar(`${datetimeDecorator.toMonth(new Date(date))}の勤務計画をエクスポートしました`);
  };

  const mutateCopyFromLastWeek = () => {
    const startAt = new Date(startOn);
    const weekStartAt = format(startOfWeek(startAt), 'MM/dd', { timeZone: 'Asia/Tokyo' });
    const weekEndAt = format(endOfWeek(startAt), 'MM/dd', { timeZone: 'Asia/Tokyo' });

    const message = `・${weekStartAt} 〜 ${weekEndAt}間で作成済みのシフトは上書きされます。
・${weekStartAt} 〜 ${weekEndAt}間で作成済みの配車計画は削除されます。

よろしいですか？`;

    // eslint-disable-next-line no-alert
    if (!window.confirm(message)) return;

    setIsLoading(true);

    copyFromLastWeek.mutateAsync(startOn)
      .catch((e) => {
        enqueueSnackbar('ドライバー・利用トラックの時間帯が重複していないか、ご確認ください。');
        throw e;
      })
      .finally(() => {
        queryClient.invalidateQueries(['useDeliveriesQuery'])
          .then(() => {
            enqueueSnackbar('先週の勤務計画を反映しました');
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
  };

  useEffect(() => {
    if ([truckIsLoading, driverIsLoading, groupIsLoading].every((bool) => bool)) {
      setIsLoading(true);

      return;
    }

    setIsLoading(false);
  }, [truckIsLoading, driverIsLoading, groupIsLoading]);

  useEffect(() => {
    if (!startOn) return;

    const startAt = new Date(startOn);
    const weekStartAt = startOfWeek(startAt);

    setDaysOfWeek(
      [0, 1, 2, 3, 4, 5, 6].map((it) => addDays(weekStartAt, it))
    );
  }, [startOn]);

  const updateSelectedShiftCell = useCallback((cells: SelectedShiftCell[], checked: boolean) => {
    if (checked) {
      setSelectedShiftCell((prev) => {
        const filtered = cells.filter((it) => !(prev.find((cell) => it.driver_id === cell.driver_id && it.date === cell.date)));
        return [...prev, ...filtered];
      });
    } else {
      setSelectedShiftCell((prev) => (
        prev.filter((it) => !(cells.find((cell) => it.driver_id === cell.driver_id && it.date === cell.date)))
      ));
    }
  }, []);

  if ([truckIsFetching, driverIsFetching, groupIsFetching].some((bool) => bool)) return <LoadingComponent />;

  return (
    <Presenter
      startOn={startOn}
      endOn={endOn}
      initialStartOn={initialStartOn}
      initialEndOn={initialEndOn}
      daysOfWeek={daysOfWeek}
      truckData={truckData}
      driverData={driverData}
      groupData={groupData}
      selectedGroup={selectedGroup}
      updateSelectedGroup={updateSelectedGroup}
      isLoading={isLoading}
      updateIsLoading={updateIsLoading}
      mutateCopyFromLastWeek={mutateCopyFromLastWeek}
      mutateExportExcelInThisMonth={mutateExportExcelInThisMonth}
      backTo={backTo}
      selectedShiftCell={selectedShiftCell}
      updateSelectedShiftCell={updateSelectedShiftCell}
      updateStartEndOn={updateStartEndOn}
    />
  );
});

export default Component;
