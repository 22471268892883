import { AdminProvider } from 'src/contexts/AdminContext';
import { LicenseProvider } from 'src/contexts/LicenseContext';
import AdminDefaultLayout from 'src/layouts/AdminDefaultLayout';
import V2DefaultLayout from 'src/layouts/V2DefaultLayout';
import WithoutAppBarLayout from 'src/layouts/WithoutAppBar.layout';
import Accounts from 'src/pages/Accounts';
import AdminAllowedIpAddresses from 'src/pages/Admin/presenters/AllowedIpAddressesPresenter';
import AdminCompaniesPresenter from 'src/pages/Admin/presenters/CompaniesPresenter';
import AdminLicensesPresenter from 'src/pages/Admin/presenters/LicensesPresenter';
import AdminSubscriptionsPresenter from 'src/pages/Admin/presenters/SubscriptionsPresenter';
import AdminLogin from 'src/pages/AdminLogin';
import Company from 'src/pages/Company';
import Dashboard from 'src/pages/Dashboard';
import EngineConfig from 'src/pages/EngineConfig';
import FourZeroFour from 'src/pages/FourZeroFour';
import Import from 'src/pages/import';
import KpiDashboard from 'src/pages/KpiDashboard';
import MappingRules from 'src/pages/MappingRules';
import MatchingPage from 'src/pages/Matching.page';
import OperationDirections from 'src/pages/OperationDirections';
import PickingList from 'src/pages/PickingList';
import RegistrationPage from 'src/pages/Registration.page';
import Shifts from 'src/pages/Shifts';
import ThanksPage from 'src/pages/Thanks.page';
import Timeline from 'src/pages/Timeline';
import TruckDirections from 'src/pages/TruckDirections';
import V2Areas from 'src/pages/V2Areas';
import V2Dashboard from 'src/pages/V2Dashboard';
import V2Drivers from 'src/pages/V2Drivers';
import V2Garages from 'src/pages/V2Garages';
import V2Groups from 'src/pages/V2Groups';
import V2Login from 'src/pages/V2Login';
import V2Orders from 'src/pages/V2Orders';
import V2Places from 'src/pages/V2Places';
import V2Plans from 'src/pages/V2Plans';
import V2ResetPassword from 'src/pages/V2ResetPassword';
import V2Trucks from 'src/pages/V2Trucks';

const router = [
  {
    path: '/',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <V2Dashboard />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/dashboard',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <V2Dashboard />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/thanks',
    element: (
      <ThanksPage />
    )
  },
  {
    path: '/registration',
    element: (
      <RegistrationPage />
    )
  },
  {
    path: '/timeline',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <Timeline />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/timeline/:startOn/:endOn',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <Timeline />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/plans',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <V2Plans />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/plans/:startOn/:endOn',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <V2Plans />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/shifts',
    element: (
      <LicenseProvider>
        <WithoutAppBarLayout>
          <Shifts />
        </WithoutAppBarLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/shifts/:startOn/:endOn',
    element: (
      <LicenseProvider>
        <WithoutAppBarLayout>
          <Shifts />
        </WithoutAppBarLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/shifts/:startOn/:endOn/:backTo',
    element: (
      <LicenseProvider>
        <WithoutAppBarLayout>
          <Shifts />
        </WithoutAppBarLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/login',
    element: (
      <LicenseProvider>
        <V2Login />
      </LicenseProvider>
    )
  },
  {
    path: '/create_password',
    element: (
      <LicenseProvider>
        <V2ResetPassword create />
      </LicenseProvider>
    )
  },
  {
    path: '/reset_password',
    element: (
      <LicenseProvider>
        <V2ResetPassword create={false} />
      </LicenseProvider>
    )
  },
  {
    path: '/orders/:startOn/:endOn',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <V2Orders />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/orders',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <V2Orders />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/places',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <V2Places />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/areas',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <V2Areas />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/drivers',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <V2Drivers />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/garages',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <V2Garages />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/groups',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <V2Groups />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/trucks',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <V2Trucks />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/operation-directions',
    element: (
      <LicenseProvider>
        <WithoutAppBarLayout>
          <OperationDirections />
        </WithoutAppBarLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/truck-directions',
    element: (
      <LicenseProvider>
        <WithoutAppBarLayout>
          <TruckDirections />
        </WithoutAppBarLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/picking-list',
    element: (
      <LicenseProvider>
        <WithoutAppBarLayout>
          <PickingList />
        </WithoutAppBarLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/config',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <EngineConfig />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/accounts',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <Accounts />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/company',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <Company />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/import',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <Import />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/v1-dashboard',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <Dashboard />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/mapping_rules',
    element: (
      <LicenseProvider>
        <WithoutAppBarLayout>
          <MappingRules />
        </WithoutAppBarLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/kpis',
    element: (
      <LicenseProvider>
        <V2DefaultLayout>
          <KpiDashboard />
        </V2DefaultLayout>
      </LicenseProvider>
    )
  },
  {
    path: '/admin/login',
    element: (
      <AdminProvider>
        <AdminLogin />
      </AdminProvider>
    )
  },
  {
    path: '/admin',
    element: (
      <AdminProvider>
        <AdminDefaultLayout>
          <AdminCompaniesPresenter />
        </AdminDefaultLayout>
      </AdminProvider>
    )
  },
  {
    path: '/admin/companies',
    element: (
      <AdminProvider>
        <AdminDefaultLayout>
          <AdminCompaniesPresenter />
        </AdminDefaultLayout>
      </AdminProvider>
    )
  },
  {
    path: '/admin/companies/:companyId/licenses',
    element: (
      <AdminProvider>
        <AdminDefaultLayout>
          <AdminLicensesPresenter />
        </AdminDefaultLayout>
      </AdminProvider>
    )
  },
  {
    path: '/admin/companies/:companyId/subscriptions',
    element: (
      <AdminProvider>
        <AdminDefaultLayout>
          <AdminSubscriptionsPresenter />
        </AdminDefaultLayout>
      </AdminProvider>
    )
  },
  {
    path: '/admin/companies/:companyId/allowed-ip-addresses',
    element: (
      <AdminProvider>
        <AdminDefaultLayout>
          <AdminAllowedIpAddresses />
        </AdminDefaultLayout>
      </AdminProvider>
    )
  },
  {
    path: '/matching',
    element: (
      <MatchingPage />
    )
  },
  {
    path: '/*',
    element: <FourZeroFour />
  }
];

export default router;
