import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField, Theme, ThemeOptions
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import LicenseContext from 'src/contexts/LicenseContext';
import { GarageEntity, GarageRequestEntity } from 'src/entities/garageEntity';
import { queryKey } from 'src/hooks/useGarages.request';
import { useMutationGarage } from 'src/hooks/useQueryGarages';

import { schema } from './schema';

type Props = {
  open: boolean;
  onClose: () => void;
  entity?: GarageEntity;
}

const FormDialog:FC<Props> = memo((
  {
    open,
    onClose,
    entity,
  }
) => {
  const theme: Theme & ThemeOptions = useTheme();
  const licenseContext = useContext(LicenseContext);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { addGarage, updateGarage } = useMutationGarage();

  const {
    control,
    setValue,
    getValues,
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<(GarageRequestEntity | GarageEntity) & { city_id: number; }>({
    resolver: yupResolver(schema)
  });

  const onSubmitAdd: SubmitHandler<GarageRequestEntity | GarageEntity> = async (
    data
  ): Promise<void> => {
    try {
      if ('id' in data) {
        await updateGarage.mutateAsync(data);
      } else {
        await addGarage.mutateAsync(data);
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, quotes,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument
      error.response.data.map((str) => enqueueSnackbar(str));
    } finally {
      await queryClient.invalidateQueries([queryKey]);
      onClose();
    }
  };

  const closeDialog = () => {
    onClose();
  };

  const haveSelectableCompanies = licenseContext?.config?.selectable_companies?.length > 1;
  const isNew = !entity;

  useEffect(() => {
    if (entity) {
      reset(entity);
      return;
    }

    reset({ name: '', address: '', company_id: 0 });
  }, [entity]);

  useEffect(() => {
    if (!licenseContext?.config?.selected_company_id) return;
    if (licenseContext?.config?.selected_company_id === 0) {
      reset({ company_id: 0 });
      return;
    }

    reset({ company_id: licenseContext?.config?.selected_company_id });
  }, [licenseContext?.config?.selected_company_id]);

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        車庫
        {isNew ? '作成' : '編集'}
      </DialogTitle>
      <DialogContent>
        <Stack component="form" noValidate spacing={2}>
          {[haveSelectableCompanies, isNew].every((bool) => bool) && (
            <FormControl
              fullWidth
              size="small"
              sx={{
                marginTop: theme.spacing(1),
              }}
            >
              <Controller
                control={control}
                name="company_id"
                render={({ field }) => (
                  <>
                    <InputLabel>事業所</InputLabel>
                    <Select
                      id="company_id"
                      margin="dense"
                      label="事業所"
                      type="number"
                      fullWidth
                      variant="standard"
                      value={`${getValues('company_id')}`}
                      onChange={(e) => setValue('company_id', Number(e.target.value))}
                      disabled={!haveSelectableCompanies}
                    >
                      {licenseContext?.config?.selectable_companies?.map((company) => (
                        <MenuItem value={company.id} key={company.id}>{company.name}</MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
            </FormControl>
          )}
          <TextField
            required
            margin="dense"
            id="name"
            label="車庫名"
            type="text"
            fullWidth
            variant="standard"
            {...register('name')}
            error={'name' in errors}
            helperText={errors.name?.message}
          />
          <TextField
            required
            margin="dense"
            id="address"
            label="住所"
            type="text"
            fullWidth
            variant="standard"
            {...register('address')}
            error={'address' in errors}
            helperText={errors.address?.message}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>キャンセル</Button>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Button onClick={handleSubmit(onSubmitAdd)} variant="contained">
          保存する
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default FormDialog;
