import {
  Snackbar,
  SnackbarContent,
  Stack
} from '@mui/material';
import { FC, memo, ReactNode, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import AppBarContainerComponent from 'src/components/AppBarContainer.component';
import { appBarHeight } from 'src/constants/layout';
import LicenseContext from 'src/contexts/LicenseContext';

type Props = {
  children: ReactNode;
};

const V2DefaultLayout: FC<Props> = memo(({ children }) => {
  const licenseContext = useContext(LicenseContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!licenseContext.initialized) return;
    if (licenseContext.authenticated) return;

    navigate('/login');
  }, [licenseContext.initialized, licenseContext.authenticated]);

  return (
    <Stack>
      <AppBarContainerComponent />
      <Stack
        sx={{
          position: 'relative',
          marginTop: `${appBarHeight}px`
        }}
      >
        {children}
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={licenseContext.forceDisabled}
        key="DuplicatingSnackbar"
        sx={{ marginTop: `${appBarHeight - 10}px` }}
      >
        <SnackbarContent
          message={` 複製処理を実行中です（残り${licenseContext.pendingCount ? licenseContext.pendingCount.toLocaleString() : '-'}件）。複製中はデータの更新処理はできません。`}
        />
      </Snackbar>
      {/* <Backdrop
        sx={{
          color: '#fff',
          zIndex: 2
      }}
        open={licenseContext.forceDisabled}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography>
            {`処理を実行中です（残り${licenseContext.pendingCount ? licenseContext.pendingCount.toLocaleString() : '-'}件）`}
          </Typography>
          <CircularProgress color="inherit" />
        </Stack>
      </Backdrop> */}
    </Stack>
  );
});

export default V2DefaultLayout;
